const component = () => import(/* webpackChunkName: "developer-test" */'./indeterminate-checkbox-input');

export default {
	component,
	path : 'indeterminate-checkbox-input',
	name : 'indeterminate-checkbox-input-test',
	meta : {
		title  : 'Indeterminate Checkbox Input Test',
		secure : false,
	},
};
