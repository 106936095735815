import Vue from 'vue';
import Component from 'vue-class-component';
import template from './simple-map.html';
import Watch from '@/plugins/watch-decorator';
import Debounce from '@/plugins/debounce-decorator';
import { Map } from '@/services/map.service';

const duration = 1000; // long debounce for loaded
const props = {
	options    : Object, // Map Options, { center, zoom, mapTypeId, isLocked ...}
	collection : Object, // GeoJSON feature collection
	layerUrl   : String, // an x-y-z parameterized url
	isLocked   : Boolean, // map cannot be scrolled or zoomed
};

@Component({
	template,
	props,
})
export class SimpleMap extends Vue {
	data() {
		return {
			map : null,
		};
	}

	mounted() {
		const options = Object.assign({
			mapTypeId         : 'hybrid',
			disableClustering : true,
			allowRecenter     : true,
			isLocked          : this.isLocked,
		}, this.options, { el : this.$el });

		this.map = new Map(options);

		this.map.once('map-tilesloaded', () => this.emitLoaded());
		this.map.on('feature-addfeature', () => this.emitLoaded());
	}

	@Watch.immediate('collection', { deep : true })
	watchCollection() {
		if (!this.collection) return;

		this.map.setFeatureCollection(this.collection);

		this.map.recenter();
	}

	@Watch.immediate('layerUrl')
	watchLayerUrl() {
		if (!this.layerUrl) return;

		this.map.addOverlay('extra', this.layerUrl);
	}

	@Debounce(duration)
	emitLoaded() {
		this.$emit('loaded', { map : this.map });
	}
}

Vue.component('simple-map', SimpleMap);
