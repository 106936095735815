export default [{
	label       : 'Temple Leader',
	placeholder : 'John Smith',
}, {
	label       : 'Total Membership',
	placeholder : '12,345',
}, {
	label       : 'Conversions to Date',
	placeholder : '1,256,911',
}, {
	label       : 'Sacrifices to Date',
	placeholder : '1,234,567',
}, {
	label       : 'Date Built',
	placeholder : 'Jan 1, 2018',
}];
