import api from '@/api';
import service from '@/services/geometry.service.js';
import { farmableAcresLabel, farmableAcresRegExp } from '@/services/farmable-acres.service.js';

export default {
	getTotalFarmableAcreage,
	getTotalParcelAcreage,

	getFarmableAcreage,
	getParcelAcreage,
};

////////

const parcelAcreage = /(apn|parcel).*acre/i;

////////

function getTotalFarmableAcreage(list) {
	return Promise.all(list.map(field => getFarmableAcreage(field)))
		.then(list => list.reduce((total, acres) => total + acres, 0));
}

function getTotalParcelAcreage(list) {
	return Promise.all(list.map(field => getParcelAcreage(field)))
		.then(list => list.reduce((total, acres) => total + acres, 0));
}

function getFarmableAcreage(field) {
	const { field_id } = field;

	if (field.farmableAcres) return Promise.resolve(field.farmableAcres);

	if (!field.farmableAcresPromise) field.farmableAcresPromise = Promise.resolve(field)
		.then(({ attributes }) => attributes || api.attribute.list({ field_id }))
		.then(attributes => attributes.find(({ label }) => farmableAcresRegExp.test(label)))
		.then(attribute => attribute || generateFarmableAcreage(field))
		.then(({ value }) => Number(value) || 0)
		.catch(() => 0);

	return field.farmableAcresPromise;
}

function getParcelAcreage(field) {
	const { field_id } = field;

	if (field.parcelAcres) return Promise.resolve(field.parcelAcres);

	if (!field.parcelAcresPromise) field.parcelAcresPromise = Promise.resolve(field)
		.then(({ attributes }) => attributes || api.attribute.list({ field_id }))
		.then(attributes => attributes.find(({ label }) => parcelAcreage.test(label)))
		.then(attribute => attribute || {})
		.then(({ value }) => Number(value));

	return field.parcelAcresPromise;
}

////////

function generateFarmableAcreage(field) {
	const { field_id } = field;

	return Promise.resolve(field.geometry ? field : service.assignGeometry(field))
		.then(({ geometry }) => geometry.acreage || 0)
		.then(acreage => acreage.toFixed(2))
		.then(value => api.attribute.create({
			field_id,
			label : farmableAcresLabel,
			value,
		}));
}
