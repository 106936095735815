import Vue from 'vue';
import Component from 'vue-class-component';
import template from './feature-lock-icon.html';

@Component({ template })
export class FeatureLockIcon extends Vue {
	get icon() {
		return ['fas', 'fa-lock-alt'];
	}
}

Vue.component('feature-lock-icon', FeatureLockIcon);
