import Vue from 'vue';
import Component from 'vue-class-component';
import template from './add-farm-button.html';
import Async from '@/plugins/async-decorator';
import { checkCompanyPrivilege, isDemoUser } from '@/services/permission.service';
import lockoutService from '@/services/billing-lockout.service';

const props = {
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
};

@Component({
	template,
	props,
})
export class AddFarmButton extends Vue {
	@Async(false)
	get isDemoUser() {
		return isDemoUser();
	}

	@Async(false)
	get hasAllowance() {
		return lockoutService.hasAllowance('farms');
	}

	@Async(false)
	get allowed() {
		if (!this.hasAllowance) return false;

		return checkCompanyPrivilege('create')
			.then(allowed => allowed && !this.isDemoUser);
	}
}

Vue.component('add-farm-button', AddFarmButton);
