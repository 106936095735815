import api from './api-storage-list';
import storage from '@/services/storage.service';

export default {
	get,
	set,
	list,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	Test : _extend,
};

function get() {
	return storage.get('test')
		.catch(() => restore());

	function restore() {
		return set({
			test_id  : 'get-got',
			testName : 'api.test.get()',
		});
	}
}

function set(test) {
	return storage.set('test', test);
}

function list(filters) {
	return api.list('test', filters)
		.then(list => list.map(test => _extend(test)));
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(test_id) {
	return api.read('test', test_id)
		.then(test => _extend(test));
}

function create(test) {
	return api.create('test', test);
}

function update(test) {
	return api.update('test', test.test_id, test);
}

function save(test) {
	return test.test_id ? update(test) : create(test);
}

function _delete(test_id) {
	return api.delete('test', test_id)
		.then(result => Promise.all([result, get()]))
		.then(([result, test]) => [result, test ? test.test_id === test_id : null])
		.then(([result, selected]) => Promise.all([result, selected ? set() : null]))
		.then(([result]) => result);
}

function _extend(test = {}) {
	return Object.assign({

	}, test);
}
