import mapService from '../google.lib';

////

export default {
	overlay : null,

	isOverlayVisible(name) {
		return this.overlay === name;
	},

	addOverlay(name, xyzUrl) {
		this.clearOverlays();

		this.overlays[name] = {
			xyzUrl,
		};

		this.setOverlay(name);
	},

	setOverlay(name) {
		if (this.overlay === name) return;

		this.clearOverlays();

		if (!name) return;

		const layer = this._getOverlayLayer(name);

		this.overlay = name;

		return this._map.overlayMapTypes.push(layer);
	},

	clearOverlays() {
		this._map.overlayMapTypes.clear();
		this.overlay = null;
	},

	_getOverlayLayer(name) {
		const overlay = this.overlays[name] || {};

		if (!overlay.layer) overlay.layer = new mapService.Layer(name, overlay);

		return overlay.layer;
	},
};
