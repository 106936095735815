import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-access-key-display.html';

const props = {
	value : {
		type     : Object, // WorkOrderAccessKey Record
		required : true,
	},
};

@Component({
	template,
	props,
})
export class WorkOrderAccessKeyDisplay extends Vue {
}

Vue.component('work-order-access-key-display', WorkOrderAccessKeyDisplay);
