const options = Object.entries({
	menubar     : 0,
	toolbar     : 0,
	location    : 0,
	personalbar : 0,
	status      : 0,
	dependent   : 1,
	alwaysRaise : 1,
})
	.map(([key, value]) => `${key}=${value}`)
	.join(',');

const frequency = 250;

export default class Popup {
	constructor() {
		this.popup = window.open('/loading.html', '_blank', options);

		this.okay = this.popup && this.popup.closed === false;

		if (!this.okay) throw new Error('failed to launch popup');

		this.interval = setInterval(() => {
			if (!this.okay) {
				clearInterval(this.interval);
				this.resolve();
			}

			if (this.popup.closed !== false) this.okay = false;
		}, frequency);

		this.promise = new Promise(resolve => {
			this.resolve = resolve;
		});
	}

	open(url) {
		if (this.okay) this.popup.location.href = url;

		return this.promise;
	}

	close() {
		if (this.okay) this.popup.close();

		this.okay = false;
	}
}
