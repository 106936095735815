import Vue from 'vue';
import Component from 'vue-class-component';
import template from './spinner.html';

const props = {
	blue  : Boolean,
	dark  : Boolean,
	hide  : Boolean,
	icon  : Boolean,
	tiny  : Boolean,
	white : Boolean,
};

@Component({
	template,
	props,
})
export class Spinner extends Vue {
	get classes() {
		return {
			blue  : this.blue,
			dark  : this.dark,
			icon  : this.icon,
			tiny  : this.tiny,
			white : this.white,
		};
	}
}

Vue.component('spinner', Spinner);
