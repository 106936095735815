import Vue from 'vue';
import Component from 'vue-class-component';
import template from './search-parcel-owner-panel.html';
import api from '@/api';
import Watch from '@/plugins/watch-decorator';
import { getFeaturesByOwner } from '@/services/report-all-usa.service';

const props = {
	value : Object, // { Map, features }
};

@Component({
	template,
	props,
})
export class SearchParcelOwnerPanel extends Vue {
	data() {
		return {
			error      : null,
			loading    : null,
			parcels    : null, // [{ selected, details, geometry }]
			collection : null, // Array of Google Map Data Features
			form       : {
				owner  : null,
				county : null,
				state  : null,
				page   : 1,
			},
		};
	}

	beforeDestroy() {
		if (this.collection) {
			this.map.removeFeatureCollection(this.collection);
			this.collection = null;
		}
	}

	get map() {
		return this.value && this.value.map;
	}

	get selected() {
		if (!this.parcels) return [];

		return this.parcels.filter(({ selected }) => selected)
			.map(({ geometry }) => geometry);
	}

	get page() {
		if (!this.parcels) return;

		const pageSize = 10;
		const { page } = this.form;

		const index = (page - 1) * pageSize;

		return this.parcels.slice(index, index + pageSize);
	}

	prevPage() {
		this.form.page--;
	}

	nextPage() {
		this.form.page++;

		if (this.page.length) return;

		return this.search();
	}

	search() {
		if (this.loading) return;

		this.error = null;
		this.loading = true;

		const { owner, county, state, page } = this.form;

		return getFeaturesByOwner(owner, `${county}, ${state}`, page)
			.then(list => list.map(geometry => ({
				selected : false,
				details  : geometry.properties,
				geometry,
			})))
			.then(parcels => { this.parcels = (this.parcels || []).concat(parcels) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	@Watch('parcels')
	drawGeometries() {
		if (!this.parcels) return;

		const features = this.selected;

		this.collection = this.map.replaceFeatureCollection(this.collection, {
			type : 'FeatureCollection',
			features,
		});

		if (features.length)
			this.map.flyToGeometry(this.collection);
	}

	save() {
		if (this.loading) return;

		this.error = null;
		this.loading = true;

		return Promise.resolve(this.selected)
			.then(geometries => geometries.map(geometry => ({
				geometry,
				color      : '#a7cc14',
				fieldName  : geometry.properties.name,
				attributes : [{
					label : 'Parcel Acres',
					value : geometry.properties.acreage,
				}, {
					label : 'Parcel Number',
					value : geometry.properties.name,
				}, {
					label : 'Parcel Owner',
					value : geometry.properties.parcelOwner,
				}, {
					label : 'Physical Address',
					value : geometry.properties.physicalAddress,
				}, {
					label : 'Mailing Address',
					value : geometry.properties.mailingAddress,
				}]
					.filter(({ value }) => value),
			})))
			.then(fields => Promise.all(fields.map(field => api.field.create(field))))
			.then(fields => fields.map(({ fieldName, color, field_id : feature_id, geometry }) => {
				Object.assign(geometry.properties, {
					color,
					feature_id,
					fieldName,
					featureType : 'field',
				});

				api.parcel.create(Object.assign({ field_id : feature_id }, geometry.properties)).catch(() => null);

				this.value.features.push(geometry);

				return geometry;
			}))
			.then(features => this.map.replaceFeatureCollection(this.collection, {
				type : 'FeatureCollection',
				features,
			}))
			.then(() => { this.$emit('close') })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('search-parcel-owner-panel', SearchParcelOwnerPanel);
