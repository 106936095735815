import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-filters-button.html';

const props = {
	value : Object, // { Map, features: [...], filters }
};

@Component({
	template,
	props,
})
export class MapFiltersButton extends Vue {}

Vue.component('map-filters-button', MapFiltersButton);
