import Vue from 'vue';
import Component from 'vue-class-component';
import template from './edit-field-top-panel.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import { farmableAcresLabel, farmableAcresRegExp } from '@/services/farmable-acres.service.js';

const props = {
	value : Object, // { Map, features, field }
};

@Component({
	template,
	props,
})
export class EditFieldTopPanel extends Vue {
	data() {
		return {
			error   : null,
			loading : null,
			context : null, // { cancel(), done(), setColor(), setIcon(), acres }
			field   : Object.assign({}, this.value.field),
		};
	}

	mounted() {
		const { feature_id } = this.field;

		this.map.edit(feature_id, 'Polygon')
			.then(context => { this.context = context });
	}

	beforeDestroy() {
		this.context.cancel();
	}

	get map() {
		return this.value.map;
	}

	get feature_id() {
		return this.field.feature_id;
	}

	get feature() {
		return this.map.getFeature(this.feature_id);
	}

	get features() {
		return this.value.features.map(({
			properties : {
				feature_id,
				featureType,
				fieldName,
				markerName,
				lineName,
			},
		}) => ({
			feature_id,
			featureType,
			featureName : fieldName || markerName || lineName,
		}));
	}

	@Watch('field.color')
	watchFieldColor() {
		if (this.context) this.context.setColor(this.field.color);
	}

	save() {
		const { field_id } = this.field;

		this.error = null;
		this.loading = true;

		return this.context.done()
			.then(geometry => Object.assign(this.field, { geometry }))
			.then(field => api.field.update(field))
			.then(field => Object.assign(this.value.field, field))
			.then(({ fieldName }) => this.feature.setProperty('fieldName', fieldName))
			.then(() => api.attribute.list({ field_id }))
			.then(attributes => attributes.find(({ label }) => farmableAcresRegExp.test(label)))
			.then(attribute => Object.assign(attribute || { field_id }, {
				value : this.context.acres.toFixed(2),
				label : farmableAcresLabel,
			}))
			.then(attribute => api.attribute.save(attribute))
			.then(() => { this.$emit('close') })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	validateName(name) {
		const feature = this.features.find(({ featureName }) => featureName === name);

		if (feature) return `A ${this.$dsl(feature.featureType)} named '${name}' already exists.`;
	}
}

Vue.component('edit-field-top-panel', EditFieldTopPanel);
