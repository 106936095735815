import Vue from 'vue';
import Component from 'vue-class-component';
import template from './session.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import analytics from '@/services/analytics.service';
import api from '@/api';
import { isDemoUser, checkCompanyPrivilege } from '@/services/permission.service';

@Component({
	template,
	beforeRouteUpdate(to, from, next) {
		this.loading = true;

		return Promise.all([
			to.matched.some(({ meta }) => meta.skipLegal),
			to.matched.some(({ meta }) => meta.skipBilling),
			to.matched.some(({ meta }) => meta.skipOnboarding),
		])
			.then(([skipLegal, skipBilling, skipOnboarding]) => Promise.all([
				skipLegal || this.checkLegalConsent(),
				skipBilling || this.checkBilling(),
				skipOnboarding || this.checkOnboarding(to),
				skipBilling || this.checkAllowance(to),
			]))
			.then(routes => routes.find(route => route && route !== true))
			.then(route => route && Promise.reject(route))
			.then(() => api.resetQueue())
			.then(() => next())
			.catch(error => next(error || { name : 'logout' }))
			.finally(() => { this.loading = false });
	},
})
export default class Session extends Vue {
	data() {
		return {
			loading : null,
		};
	}

	created() {
		this.loading = true;

		return Promise.all([
			this.checkLegalConsent(true),
			this.checkOnboarding(this.$route),
		])
			.then(routes => routes.find(route => route && route !== true))
			.then(route => route ? this.$router.push(route) : this.continueToApplication())
			.catch(() => this.$router.push({ name : 'logout' }))
			.finally(() => { this.loading = false });
	}

	@Async
	get company() {
		return api.company.get();
	}

	@Watch('company')
	watchCompany(newVal, oldVal) {
		this.checkBilling();

		if (newVal && oldVal && newVal.company_id !== oldVal.company_id)
			this.onCompanyChange();
	}

	beforeDestroy() {
		analytics.reset();
	}

	checkBilling(params) {
		return api.billingReplacement.getAccount(params)
			.then(() => true)
			.catch(() => null);
	}

	checkLegalConsent(params) {
		return isDemoUser(params)
			.then(isDemo => isDemo || api.legalConsent.list()
				.then(list => list.every(({ legalConsentGranted }) => legalConsentGranted))
				.then(granted => granted ? null : { name : 'legal-consent' }));
	}

	checkOnboarding(route) {
		return Promise.all([
			route.matched.some(({ meta }) => meta.requireCompany),
			route.matched.some(({ meta }) => meta.requireFarm),
		])
			.then(([
				requireCompany,
				requireFarm,
			]) => Promise.all([
				!requireCompany || api.company.get(),
				!requireFarm || api.farm.get(),
			]))
			.then(([company, farm]) => company && farm)
			.then(onboarded => onboarded ? null : { name : 'onboarding' });
	}

	checkAllowance(route) {
		const use = rfind(route.matched, ({ meta }) => meta.allowance);
		const allowance = use && use.meta.allowance;
		const fallback = { name : 'map' };

		if (!allowance || typeof allowance !== 'string')
			return null;

		return checkCompanyPrivilege(allowance)
			.then(allowed => {
				if (allowed) return null;

				return fallback;
			});
	}

	continueToApplication() {
		return api.user.get()
			.then(user => analytics.identify(user));
	}

	onCompanyChange() {
		const route = rfind(this.$route.matched, ({ meta }) => meta.onCompanyChange);

		if (!route) return;

		const change = route.meta.onCompanyChange;
		const travel = typeof change === 'object' ? change : { name : change };

		this.$router.push(travel);
	}
}

function rfind(list, fn) {
	if (!Array.isArray(list)) return null;
	if (typeof fn !== 'function') return null;

	for (let i = list.length - 1; i >= 0; i--)
		if (fn(list[i], i, list))
			return list[i];
}
