import Vue from 'vue';
import Component from 'vue-class-component';
import template from './message-display.html';

const props = {
	message : String,
	details : [Array, String],
	icon    : String,
};

@Component({
	template,
	props,
})
export class MessageDisplay extends Vue {
	data() {
		return {
			show  : null,
			allow : process.env.NODE_ENV === 'development',
		};
	}

	get iconKey() {
		if (!this.icon) return '';

		return `md.${this.icon.replace(/\s+/g, '-')}`;
	}
}

Vue.component('message-display', MessageDisplay);
