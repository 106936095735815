import Vue from 'vue';
import Component from 'vue-class-component';
import template from './marker-list.html';
import Async from '@/plugins/async-decorator';

const props = {
	markers : Array,
	actions : Array,
};

@Component({
	template,
	props,
})
export class MarkerList extends Vue {
	@Async(null, 'markers')
	get list() {
		return this.markers.map(value => ({
			value,

			avatar : {
				icon  : value.icon,
				color : value.color || '#000',
			},

			title : value.markerName,

			actions : this.actions,
		}));
	}
}

Vue.component('marker-list', MarkerList);
