import api from './api';
import farm from './farm';

export default {
	list,
	find,
	read,
	create,
	update,
	save,
	destroy,
	delete : _delete,

	flights,

	CeresImagingField : _extend,
};

function list(filters) {
	return filters
		? api.list('ceres-imaging-field', filters)
			.then(list => list.map(field => _extend(field)))
		: farm.get()
			.then(farm => farm ? farm.farm_id : null)
			.then(farm_id => farm_id ? list({ farm_id }) : []);
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(ceresImagingField_id) {
	return api.read('ceres-imaging-field', ceresImagingField_id)
		.then(ceresImagingField => _extend(ceresImagingField));
}

function create(ceresImagingField) {
	return api.create('ceres-imaging-field', ceresImagingField);
}

function update(ceresImagingField) {
	return api.update('ceres-imaging-field', ceresImagingField.ceresImagingField_id, ceresImagingField);
}

function save(ceresImagingField) {
	return ceresImagingField.ceresImagingField_id ? update(ceresImagingField) : create(ceresImagingField);
}

function destroy(ceresImagingField_id) {
	return api.destroy('ceres-imaging-field', ceresImagingField_id);
}

function _delete(ceresImagingField_id) {
	return api.delete('ceres-imaging-field', ceresImagingField_id);
}

function flights(ceresImagingField_id) {
	return api.read('ceres-imaging-field/flights', ceresImagingField_id);
}

function _extend(ceresImagingField = {}) {
	return Object.assign({

	}, ceresImagingField);
}
