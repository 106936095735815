import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map.html';
import api from '@/api';
import localStorage from '@/services/local-storage.service';

@Component({
	template,
	beforeRouteEnter(to, from, next) {
		if (!to.query.j) return next();

		const company_id = to.query.j;

		return api.company.find({ company_id })
			.then(company => company && api.company.set(company))
			.catch(() => null)
			.then(() => next({
				name   : to.name,
				params : to.params,
				query  : {},
			}));
	},
})
export default class Map extends Vue {
	data() {
		return {
			// Google Maps instance lives here, value.map -AM Jan 11, 2022
			value         : null, // { map, features: [...], filters: [...], selected }
			panel         : null, // top panel, if any
			error         : null,
			controlsOnMap : localStorage.get('montage.map.controls-on-map') === true,
		};
	}

	get supportUrl() {
		return process.env.BRAND_SUPPORT_URL;
	}

	get mapConfig() {
		return {
			mapOptions : {
				drawingOptions : {},
				mapTypeId      : localStorage.get('montage.map.map-type') || 'hybrid',
			},
		};
	}

	get isMapLoaded() {
		return Boolean(this.value && this.value.map);
	}

	select(selected) {
		if (!this.value) return;

		this.value.selected = selected;
	}

	showPanel({ panel, value }) {
		const handler = {
			'add-map-feature' : () => {
				this.panel = 'add-map-feature-panel';
				this.value.type = value;
			},
			'add-marker' : () => {
				this.panel = 'add-marker-panel';
				this.value.markerType = value;
			},
			'edit-field' : () => {
				this.panel = 'edit-field-top-panel';
				this.value.field = value;
			},
			'edit-marker' : () => {
				this.panel = 'edit-marker-top-panel';
				this.value.marker = value;
			},
			'edit-line' : () => {
				this.panel = 'edit-line-top-panel';
				this.value.line = value;
			},
			'import-parcels' : () => {
				this.panel = 'import-parcels-top-panel';
			},
			'map-filters' : () => {
				this.panel = 'map-filters-top-panel';
			},
			'create-field-from-json' : () => {
				this.panel = 'create-field-from-json-top-panel';
				this.value.createJson = value;
			},
		}[panel];

		if (handler) return handler();

		this.panel = `${panel}-panel`;
	}
}
