import getFeatureAcreage from './get-feature-acreage';

export default function enableEditingFeature(feature, options = {}) {
	if (!feature.setOptions)
		throw new Error('Given feature is not an editable shape');

	const listeners = [
		window.google.maps.event.addListener(feature, 'dblclick', removeVertex(feature)),
		window.google.maps.event.addListener(feature, 'rightclick', removeVertex(feature)),
	];

	if (feature.getPath)
		listeners.push(...[
			window.google.maps.event.addListener(feature.getPath(), 'set_at', () => {
				feature.acreage = getFeatureAcreage(feature);
			}),

			window.google.maps.event.addListener(feature.getPath(), 'insert_at', () => {
				feature.acreage = getFeatureAcreage(feature);
			}),

			window.google.maps.event.addListener(feature.getPath(), 'remove_at', () => {
				feature.acreage = getFeatureAcreage(feature);
			}),
		]);

	feature.acreage = getFeatureAcreage(feature);
	feature.setOptions(Object.assign({}, options));

	return () => {
		feature.setOptions({
			draggable : false,
			editable  : false,
		});
		listeners.forEach(listener => listener.remove());

		return feature;
	};
}

////////

function removeVertex(feature) {
	return event => {
		if (!event.vertex && event.vertex !== 0) return;

		const pathIndex = event.path;
		const vertex = event.vertex;

		const path = pathIndex > 0
			? feature.getPaths().getAt(pathIndex)
			: feature.getPath();

		const triangle = 3;

		if (path.getLength() > triangle)
			path.removeAt(vertex);
	};
}
