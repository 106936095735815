const component = () => import(/* webpackChunkName: "developer-test" */'./file-upload');

export default {
	component,
	path : 'file-upload',
	name : 'file-upload-test',
	meta : {
		title  : 'File Upload Test',
		secure : false,
	},
};
