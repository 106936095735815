import Vue from 'vue';
import Component from 'vue-class-component';
import template from './support-settings-list.html';

@Component({ template })
export class SupportSettingsList extends Vue {
	get supportUrl() {
		return process.env.BRAND_SUPPORT_URL || 'https://intercom.help/mapscout/en';
	}

	get isDevelopment() {
		return process.env.NODE_ENV === 'development';
	}
}

Vue.component('support-settings-list', SupportSettingsList);
