import Vue from 'vue';
import Component from 'vue-class-component';

const props = {};

@Component({
	template : '<span v-if="brand">{{ brand }}</span><span v-else><slot></slot></span>',
	props,
})
export class Brand extends Vue {
	get brand() {
		return process.env.BRAND_NAME;
	}
}

Vue.component('brand-name', Brand);
