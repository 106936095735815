import Vue from 'vue';
import Component from 'vue-class-component';
import template from './left-right-dropdown-button.html';

const props = {
	value : {
		type    : null, // should match a `value` of a list item
		default : null,
	},
	list : {
		type     : Array, // [{ label, value }]
		required : true,
	},

	disabled : Boolean, // permanently disable the button; waiting for user
	isAbove  : Boolean, // dropdown opens upwards
	loading  : Boolean, // temporarily disable the button; waiting for system
};

@Component({
	template,
	props,
})
export class LeftRightDropdownButton extends Vue {
	get index() {
		if (!this.value) return -1;

		return this.list.findIndex(({ value }) => value === this.value);
	}

	get canGoLeft() {
		return this.index > 0;
	}

	get canGoRight() {
		return this.index < this.list.length - 1;
	}

	goLeft() {
		this.$emit('click', this.list[this.index - 1].value);
		this.$emit('input', this.list[this.index - 1].value);
	}

	goRight() {
		this.$emit('click', this.list[this.index + 1].value);
		this.$emit('input', this.list[this.index + 1].value);
	}
}

Vue.component('left-right-dropdown-button', LeftRightDropdownButton);
