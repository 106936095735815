import Vue from 'vue';
import Component from 'vue-class-component';
import template from './marker-type-picker.html';
import markerTypes from '@/config/marker-types';

const props = {
	value : String,
};

@Component({
	template,
	props,
})
export class MarkerTypePicker extends Vue {
	get list() {
		if (!this.value || markerTypes.find(({ type }) => type === this.value)) return markerTypes;

		const custom = {
			type : this.value,
			icon : this.value,
		};

		return [
			...markerTypes,
			custom,
		];
	}
}

Vue.component('marker-type-picker', MarkerTypePicker);
