import Vue from 'vue';
import Component from 'vue-class-component';

const props = {
	value : Object,
};

@Component({
	template : '<span>{{ type | proper-case }}</span>',
	props,
})
export class CloudFolderType extends Vue {
	get type() {
		const {
			vendor = '',
			type = 'Folder',
		} = this.value;

		return `${vendor} ${type}`;
	}
}

Vue.component('cloud-folder-type', CloudFolderType);
