import Vue from 'vue';
import Component from 'vue-class-component';
import template from './external-layout.html';

const props = {
	noLink : {
		type    : Boolean, // do not link the Montage Logo to the Login page.
		default : () => false,
	},
};

@Component({
	template,
	props,
})
export class ExternalLayout extends Vue {}

Vue.component('external-layout', ExternalLayout);
