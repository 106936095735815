import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-employee-form.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

const props = {
	value : Object, // Work Order Employee record
};

@Component({
	template,
	props,
})
export class WorkOrderEmployeeForm extends Vue {
	data() {
		return {
			error   : null,
			loading : null,

			employee : null,
		};
	}

	@Watch.immediate('value')
	watchValue() {
		if (!this.value) return;

		this.employee = Object.assign({}, this.value);
	}

	submit() {
		if (this.loading) return;

		this.loading = true;
		this.error = null;

		api.workOrder.employee.save(this.employee)
			.then(employee => { this.$emit('input', employee) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('work-order-employee-form', WorkOrderEmployeeForm);
