/* eslint id-length: [warn, { exceptions: [x, y, z, _] } ] */

export default function Layer(name, { xyzUrl }) {
	const tileUrl = createTileUrlFunction(xyzUrl);
	const tileSize = 256;

	if (tileUrl) return new window.google.maps.ImageMapType({
		name,
		opacity : 0.5,
		size    : new window.google.maps.Size(tileSize, tileSize),
		getTileUrl({ x, y }, z) {
			return tileUrl({
				x,
				y,
				z,
			});
		},
	});

	function createTileUrlFunction(xyzUrl) {
		if (xyzUrl) return replacements => xyzUrl.replace(/{(\w+)}/g, (_, match) => replacements[match] || 0);
	}
}
