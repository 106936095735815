import Vue from 'vue';

import pluralize from 'pluralize';

export default function acres(value, label = 'acres') {
	const number = Number(Number.parseFloat(value, 10).toFixed(2));

	if (!Number.isFinite(number)) return value;

	const string = number.toLocaleString();

	return label
		? `${string} ${pluralize(label, number)}`
		: string;
}

Vue.filter('acres', acres);
