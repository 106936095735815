import Vue from 'vue';
import Component from 'vue-class-component';
import template from './cloud-source-list.html';
import Async from '@/plugins/async-decorator';
import { checkFarmPrivilege, isDemoUser } from '@/services/permission.service';

const duration = 250;

const props = {
	list     : Array,
	readonly : Boolean,
};

@Component({
	template,
	props,
})
export class CloudSourceList extends Vue {
	@Async(false)
	get isDemoUser() {
		return isDemoUser();
	}

	@Async(false)
	get hasAccess() {
		return checkFarmPrivilege('delete')
			.then(allowed => allowed && !this.isDemoUser);
	}

	select(item) {
		clearTimeout(this.timeout);

		return new Promise(resolve => {
			this.timeout = setTimeout(resolve, duration);
		})
			.then(() => this.$emit('select', item));
	}
}

Vue.component('cloud-source-list', CloudSourceList);
