import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-print-button.html';
import sessionStorage from '@/services/session-storage.service';

const delay = 250;

const props = {
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
	map      : {
		type     : Object,
		required : true,
	},
};

@Component({
	template,
	props,
})
export class MapPrintButton extends Vue {
	data() {
		return {
			locked : true,
		};
	}

	created() {
		this.cycleLock();
	}

	click() {
		this.cycleLock();

		this.store();

		return this.$router.push({ name : 'map-print-preview-redux' });
	}

	cycleLock() {
		this.locked = true;

		setTimeout(() => {
			this.locked = false;
		}, delay);
	}

	store() {
		const center = this.map.center;
		const zoom = this.map.zoom;
		const mapTypeId = this.map.mapType;

		return sessionStorage.set('map-view', {
			center,
			zoom,
			mapTypeId,
		});
	}
}

Vue.component('map-print-button', MapPrintButton);
