import defaultColors from '@/config/colors.json';
import defaultDrawingOptions from '../drawing-options.js';
import mapService from '../google.lib';
const mapMarker = window.mapMarkerAwesomeFactory(true);

////

const defaultColor = defaultColors[0];

////

export default {
	enableDrawing() {
		return this._setupDrawingManager();
	},

	disableDrawing() {
		return this._removeDrawingManager();
	},

	draw(type, options = {}) {
		return Promise.resolve(this.cancelDraw())
			.then(() => new mapService.Geometry(type))
			.then(geometry => geometry || Promise.reject(new Error(`Unsupported geometry type ${type}`)))
			.then(geometry => { options.geometry = geometry })
			.then(() => Promise.all([new mapService.Feature(options), this._draw(type)]))
			.then(([original, editable]) => ({
				cleanup : [],
				editing : [editable],
				original,
			}))
			.then(pendingChange => { this.pendingChange = pendingChange })
			.then(() => this._finishPendingChange())
			.catch(error => {
				this.cancelDraw();

				return Promise.reject(error);
			});
	},

	buildFeature(feature, type) {
		const options = feature.geometry
			? Object.assign(feature, {
				geometry : new mapService.Geometry(type || feature.type, feature.geometry),
			})
			: {
				geometry : new mapService.Geometry(type, feature),
			};

		return new mapService.Feature(options);
	},

	edit(original, type = 'Polygon') {
		this._cancelPendingChanges();

		if (typeof original === 'string')
			original = this._map.data.getFeatureById(original);

		const options = { draggable : type === 'Point' || !original };

		return Promise.resolve(original || this.draw(type))
			.then(original => this._edit(original, type, options));
	},

	cancelDraw() {
		if (this.pendingChange) {
			const { cleanup, editing } = this.pendingChange;

			cleanup.forEach(callback => callback());
			editing.map(feature => feature.setMap(null));

			this.pendingChange = null;
		}

		if (this._drawingInstance) {
			this._drawingInstance.end();
			this._drawingInstance = null;
		}
	},

	_cancelPendingChanges() {
		if (!this.pendingChange) return;

		const { cleanup, editing, original } = this.pendingChange;

		cleanup.forEach(callback => callback());
		editing.map(feature => feature.setMap(null));
		this._map.data.add(original);

		this.pendingChange = null;
	},

	_finishPendingChange() {
		if (!this.pendingChange) return;

		const map = this._map;
		const { original, editing, cleanup } = this.pendingChange;

		mapService.updateFeatureGeometry(original, editing);

		cleanup.forEach(callback => callback());
		editing.map(feature => feature.setMap(null));
		map.data.add(original);

		this.pendingChange = null;

		return original;
	},

	_setupDrawingManager(options) {
		const map = this._map;

		if (this._drawingManager) return this._drawingManager.setMap(map);
		if (!window.google.maps.drawing) throw new Error('Drawing library not loaded.');

		options = Object.assign({}, defaultDrawingOptions, options, { map });

		this._drawingManager = new window.google.maps.drawing.DrawingManager(options);

		return this._drawingManager;
	},

	_removeDrawingManager() {
		if (!this._drawingManager) return;

		this._drawingManager.setMap(null);

		this._drawingManager = null;
	},

	_draw(type) {
		const manager = this._drawingManager;

		if (!manager) return Promise.reject(new Error(`Drawing not Enabled.`));

		const mode = {
			Point      : 'marker',
			LineString : 'polyline',
			Polygon    : 'polygon',
		}[type];

		if (!mode) return Promise.reject(new Error(`Unsupported drawing type ${type}`));

		this._drawingInstance = {
			type,
			listener : null,
			end() {
				this.canceling = true;
				manager.setDrawingMode(null);
				if (this.listener) this.listener.remove();
			},
		};

		return new Promise((resolve, reject) => {
			const minLength = 3;

			manager.setDrawingMode(mode);

			this._drawingInstance.listener = window.google.maps.event.addListener(manager, 'overlaycomplete', event => {
				if (this._drawingInstance.canceling) {
					event.overlay.setMap(null);

					return reject(new Error('Drawing was canceled'));
				}

				this._drawingInstance.end();
				this._drawingInstance = null;

				if (event.type === 'polygon' && event.overlay.getPath().length < minLength) {
					event.overlay.setMap(null);

					return reject(new Error(`Polygons must have at least ${minLength} points.`));
				}

				return resolve(event.overlay);
			});
		});
	},

	_edit(original, type, options) {
		let color = original.getProperty('color');

		const methods = {
			cancel : () => {
				if (color) original.setProperty('color', color || defaultColor);

				this._cancelPendingChanges();
			},
			done : () => {
				const original = this._finishPendingChange();

				color = null;

				return new Promise(resolve => original.toGeoJson(resolve));
			},
			get acres() { return this.editing.reduce((total, { acreage }) => total + acreage, 0) },
			setColor(color) {
				this.original.setProperty('color', color || defaultColor);

				return this.editing.forEach(edit => edit.setOptions({
					strokeColor : color || defaultColor,
					fillColor   : color || defaultColor,
				}));
			},
			setIcon(icon, color) {
				this.original.setProperty('icon', icon || '');
				this.original.setProperty('color', color || defaultColor);

				return this.editing.forEach(edit => edit.setOptions({
					icon : {
						url : mapMarker(icon || '', {
							fill : color || defaultColor,
						}),
					},
				}));
			},
		};

		return Promise.resolve(this._map.data.remove(original))
			.then(() => this._map)
			.then(map => mapService.copyFeature(original, { map }))
			.then(editing => Array.isArray(editing) ? editing : [editing])
			.then(editing => Promise.all([
				editing,
				editing.map(feature => mapService.enableEditingFeature(feature, options)),
			]))
			.then(([editing, cleanup]) => Object.assign(methods, {
				original,
				editing,
				cleanup,
			}))
			.then(pendingChange => { this.pendingChange = pendingChange })
			.then(() => this.pendingChange);
	},
};
