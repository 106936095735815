export default function Geometry(type, geometry) {
	return {
		get Point() {
			return new window.google.maps.Data.Point(geometry || {
				lat : 0,
				lng : 0,
			});
		},
		get LineString() {
			return new window.google.maps.Data.LineString([]);
		},
		get Polygon() {
			return new window.google.maps.Data.Polygon([]);
		},
	}[type] || null;
}
