import Vue from 'vue';
import Component from 'vue-class-component';
import Multiselect from 'vue-multiselect';
import template from './chip-list.html';

const components = {
	Multiselect,
};

const props = {
	value : Array, // the list of current (selected) items

	list : { // the list of available (unselected) items
		type     : Array,
		required : true,
	},

	field : { // which item property to use as the pill label
		type    : String,
		default : 'label',
	},

	label       : String, // what to label the input field
	placeholder : String, // what to display in the input field of the dropdown

	readonly : {
		type    : Boolean,
		default : false,
	},
};

@Component({
	template,
	components,
	props,
})
export class ChipList extends Vue {
	created() {
		if (!this.value) this.$emit('input', []);
	}
}

Vue.component('chip-list', ChipList);
