const component = () => import(/* webpackChunkName: "developer-test" */'./validated-input');

export default {
	component,
	path : 'validated-input',
	name : 'validated-input-test',
	meta : {
		title  : 'Validated Input Test',
		secure : false,
	},
};
