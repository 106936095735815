const component = () => import(/* webpackChunkName: "developer-test" */'./main-header');

export default {
	component,
	path : 'main-header',
	name : 'main-header-test',
	meta : {
		title  : 'Main Header Test',
		secure : false,
	},
};
