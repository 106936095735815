import storage from '@/services/storage.service';

export default {
	getCurrent,
	getData,
	getVisibility,
	toggle,
	show,
	hide,
	push,
	pop,
	resolve,
	reject,
};

function getCurrent() {
	return storage.get('card/current')
		.then(current => current.card)
		.catch(() => null);
}

function getData() {
	return storage.get('card/current')
		.then(current => current.data)
		.catch(() => null);
}

function getVisibility() {
	return storage.get('card/visible')
		.catch(() => null);
}

function toggle() {
	return storage.toggle('card');
}

function show() {
	return storage.show('card');
}

function hide() {
	return storage.hide('card');
}

function push(card, data) {
	return storage.push('card', {
		card,
		data,
	});
}

function pop() {
	return storage.pop('card');
}

function resolve(value) {
	return storage.resolve('card', value);
}

function reject(value) {
	return storage.reject('card', value);
}
