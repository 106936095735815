const component = () => import(/* webpackChunkName: "developer-test" */'./parcel-display');

export default {
	component,
	path : 'parcel-display',
	name : 'parcel-display-test',
	meta : {
		title  : 'Parcel Display Test',
		secure : false,
	},
};
