import Vue from 'vue';
import Component from 'vue-class-component';
import template from './dropdown-button.html';

const props = {
	value : {
		type    : null, // currently selected value, if any
		default : null,
	},
	list : {
		type     : Array, // [{ label, value }]
		required : true,
	},

	disabled : Boolean, // permanently disable the button; waiting for user
	isAbove  : Boolean, // dropdown should open above the trigger instead of below
	isRight  : Boolean, // dropdown should align to the right instead of left
	loading  : Boolean, // temporarily disable the button; waiting for system
	noIcon   : Boolean, // don't show the open/close chevrons
	primary  : Boolean, // dropdown trigger should be primary color
};

@Component({
	template,
	props,
})
export class DropdownButton extends Vue {
	data() {
		return {
			open : null,
		};
	}

	get className() {
		return {
			'has-active'     : this.value !== null,
			'dropdown-right' : this.isRight,
			'dropdown-above' : this.isAbove,
		};
	}

	get selection() {
		return this.list.find(item => this.isSelected(item));
	}

	isSelected(item) {
		return this.value === (typeof item === 'object' ? item.value : item);
	}

	click(item) {
		this.open = false;

		const value = typeof item === 'object' ? item.value : item;

		this.$emit('click', value);
		this.$emit('input', value);
	}
}

Vue.component('dropdown-button', DropdownButton);
