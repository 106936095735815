/* eslint-disable new-cap */

const calculateArea = window.google.maps.geometry.spherical.computeSignedArea;
const acresPerSquareMeter = 0.000247105;

export default function getFeatureAcreage(feature) {
	const convert = {
		Overlay : feature => feature.getPaths()
			.getArray() // retrieve the underlying Array instead of the stupid MVCArray. -CH 2019.05.24
			.map(path => calculateArea(path))
			.reduce((total, area) => total + area, 0),

		LinearRing : geometry => calculateArea(geometry.getArray()),

		Polygon : geometry => geometry.getArray()
			.map(linearRing => convert.LinearRing(linearRing))
			.reduce((total, area) => total + area, 0),

		MultiPolygon : geometry => geometry.getArray()
			.map(polygon => convert.Polygon(polygon))
			.reduce((total, area) => total + area, 0),
	};

	const type = getType(feature);

	if (!convert[type]) return 0;

	const area = convert[type](feature.getGeometry ? feature.getGeometry() : feature);

	return Math.abs(area * acresPerSquareMeter);
}

////

function getType(feature) {
	if (feature.getPaths) return 'Overlay';

	if (feature.getGeometry) return feature.getGeometry().getType();
}
