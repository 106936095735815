export default [{
	type       : 'Eye of the Beholder',
	icon       : 'film',
	attributes : [
		'Total Beauty',
		'Total Murders', // because it's a murder mystery movie
	],
}, {
	type       : 'Eye of Kilrogg', // World of Warcraft
	icon       : 'cauldron',
	attributes : [
		'Caster',
	],
}, {
	type       : 'Eye of Providence', // 'all-seeing eye', etc
	icon       : 'eye-slash',
	attributes : [
		'Coat of Arms',
		'Currency',
		'Other Context',
	],
}, {
	type       : 'Eye of Cthultu', // Terreria
	icon       : 'eye-evil',
	attributes : [
		'Summoned',
		'Defeated',
		'Player Max Health',
		'Player Defense',
		'Townsperson Count',
		'Current Phase',
		'Servants Spawned',
	],
}, {
	type       : 'Eye of the Storm', // meteorology
	icon       : 'hurricane',
	attributes : [
		'Storm Name',
		'Eye Diameter',
		'Wall Height',
	],
}, {
	type       : 'Eye of Ra', // Tgyptian mythology
	icon       : 'ankh',
	attributes : [
		'Personification',
		'Year Engraved',
		'Color',
	],
}, {
	type       : 'Eye of Horus', // Tgyptian mythology
	icon       : 'horse',
	attributes : [
		'Year Recorded',
		'Medium',
		'Fractional Sum',
	],
}, {
	type       : 'Eye of the Tiger', // music
	icon       : 'cat',
	attributes : [
		'Album',
		'Year Recorded',
	],
}, {
	type       : 'Eye of the Tigger', // trademarked by Disney
	icon       : 'trademark',
	attributes : [
		'Top  Material',
		'Bottom Material',
		'The Most Wonderful Thing',
	],
}, {
	type       : 'other',
	icon       : 'circle',
	attributes : [
		'Type',
	],
}];
