import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-recenter-button.html';

const delay = 250;

const props = {
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
	map      : {
		type     : Object,
		required : true,
	},
	icon : {
		type    : String,
		default : 'true',
	},
};

@Component({
	template,
	props,
})
export class MapRecenterButton extends Vue {
	data() {
		return {
			locked : true,
		};
	}

	created() {
		this.cycleLock();
	}

	click() {
		this.cycleLock();

		this.map.recenter();
	}

	cycleLock() {
		this.locked = true;

		setTimeout(() => {
			this.locked = false;
		}, delay);
	}
}

Vue.component('map-recenter-button', MapRecenterButton);
