import api from '../api';

export default {
	list,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	TimeseriesData : _extend,
};

function list(filters) {
	return api.list('timeseries-data', filters)
		.then(list => list.map(timeseries => _extend(timeseries)));
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(timeseries_id) {
	return api.read('timeseries-data', timeseries_id)
		.then(timeseries => _extend(timeseries));
}

function create(timeseries) {
	return api.create('timeseries-data', timeseries);
}

function update(timeseries) {
	return api.update('timeseries-data', timeseries.timeseries_id, timeseries);
}

function save(timeseries) {
	return timeseries.timeseries_id ? update(timeseries) : create(timeseries);
}

function _delete(timeseries_id) {
	return api.delete('timeseries-data', timeseries_id);
}

function _extend(timeseries = {}) {
	return Object.assign({

	}, timeseries);
}
