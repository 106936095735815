export default {
	getGeoJson,
	filterGeojson,
	convertToExportGeoJson,
};

////////

function getGeoJson(object) {
	return object.toGeoJson ? new Promise(resolve => object.toGeoJson(resolve)) : convertToGeoJson(object);
}

function convertToGeoJson(object) {
	const blacklist = ['geometry', 'properties', 'toGeoJson', /__/, 'permissions'];

	if (!object.geometry || !object.geometry.geometry) return null;

	const geometry = object.geometry.geometry;
	const properties = Object.keys(object)
		.filter(key => !blacklist.some(test => test.test ? test.test(key) : test === key))
		.reduce((properties, key) => Object.assign(properties, { [key] : object[key] }), {});

	return {
		type : 'Feature',
		geometry,
		properties,
	};
}

export function convertToExportGeoJson(object) {
	const blacklist = [
		'geometry',
		'properties',
		'toGeoJson',
		/__/,
		'permissions',
		'createdBy',
		'updatedBy',
		'version',
		/_id$/,
	];

	if (!object.geometry || !object.geometry.geometry) return null;

	const geometry = object.geometry.geometry;
	const properties = Object.keys(object)
		.filter(key => !blacklist.some(test => test.test ? test.test(key) : test === key))
		.reduce((properties, key) => Object.assign(properties, { [key] : object[key] }), {});

	if (properties.attributes) {
		const attributes = properties.attributes;

		delete properties.attributes;

		const attrs = attributes.reduce((coll, { label, value }) => {
			coll[label] = value;

			return coll;
		}, {});

		Object.assign(properties, attrs);
	}

	return {
		type : 'Feature',
		geometry,
		properties,
	};
}

function filterGeojson(type, features) {
	const handler = {
		Polygon(features) { return features.filter(feature => feature.geometry.type === 'Polygon') },
		Point(features) { return features.filter(feature => feature.geometry.type === 'Point') },
		LineString(features) { return features.filter(feature => feature.geometry.type === 'LineString') },
		MultiPolygon(features) {
			return features.filter(feature => feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon')
				.map(feature => {
					if (feature.geometry.type === 'MultiPolygon') return feature;

					feature.geometry.coordinates = [feature.geometry.coordinates];
					feature.geometry.type = 'MultiPolygon';

					return feature;
				});
		},
	}[type];

	return handler ? handler(features) : features;
}
