import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "settings-core" */'./farm-settings');

const redirect = { name : 'farm-export' };

import farmCsvImport from './farm-csv-import/farm-csv-import.route';
import farmDetails from './farm-details/farm-details.route';
import farmExport from './farm-export/farm-export.route';
import farmImport from './farm-import/farm-import.route';

const children = [
	farmCsvImport,
	farmDetails,
	farmExport,
	farmImport,
	{
		path : '*',
		redirect,
	},
];

export default {
	children,
	component,
	redirect,
	path : 'farm-settings',
	name : 'farm-settings',
	meta : {
		title       : `${dsl('Farm')} Settings`,
		secure      : true,
		requireFarm : true,
	},
};
