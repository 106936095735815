import api from './api-storage-list';
import storage from '@/services/storage.service';
import config from './config';
import analytics from './../services/analytics.service';

export default {
	set,
	get,
	list,
	count,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,
	destroy,

	Company : _extend,
};

function set(company) {
	return Promise.all([company, config.read(), company && company.company_id])
		.then(([company, record, company_id]) => Promise.all([company, Object.assign(record, { company_id })]))
		.finally(() => api.resetQueue())
		.then(([company, record]) => Promise.all([storage.set('company', company), config.save(record)]))
		.then(([company]) => company)
		.finally(() => { list.expires = 0 });
}

function get() {
	return storage.get('company')
		.then(company => restore(company))
		.catch(() => restore())
		.catch(() => null);

	function restore(stored = null) {
		return Promise.all([stored || config.read(), list()])
			.then(([{ company_id }, list]) => Promise.all([list.find(company => company.company_id === company_id), list[0]]))
			.then(([restored, company]) => restored || set(company));
	}
}

function list(filters) {
	if (filters) return api.list('company', filters);

	const duration = 5000;

	if (!list.promise || list.expires < Date.now()) {
		list.expires = Date.now() + duration;
		list.promise = api.list('company', filters)
			.then(list => list.map(company => _extend(company)));
	}

	return list.promise;
}

function count(filters) {
	return api.count('company', filters);
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(company_id) {
	return api.read('company', company_id)
		.then(company => _extend(company));
}

function create(company) {
	return storage.get('user')
		.then(user => user ? user.user_id : null)
		.then(user_id => Object.assign(company, { user_id }))
		.then(company => api.create('company', company))
		.then(company => set(company)
			.then(() => storage.get('user'))
			.then(user => analytics.update({
				user,
				company,
			}))
			.then(() => company))
		.finally(() => { list.expires = 0 });
}

function update(company) {
	return api.update('company', company.company_id, company)
		.then(company => set(company)
			.then(() => company))
		.finally(() => { list.expires = 0 });
}

function save(company) {
	return company.company_id ? update(company) : create(company);
}

function _delete(company_id) {
	// This will remove current user from the company unless
	// they are the last administrative user.
	// In that case, it will delete the company.
	// -AM Nov 19, 2021
	return api.delete('company', company_id)
		.finally(() => { list.expires = 0 });
}

function destroy(company_id) {
	return api.destroy('company', company_id)
		.finally(() => { list.expires = 0 });
}

function _extend(company = {}) {
	return Object.assign({
		companyName : '',
	}, company);
}
