import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-filters-status.html';

const props = {
	map      : Object, // Google Map object
	features : Array, // Array of GeoJSON objects
	filters  : Array, // Array of MapFilter objects
};

@Component({
	template,
	props,
})
export class MapFiltersStatus extends Vue {
	data() {
		return {
		};
	}

	get list() {
		return this.filters || [];
	}

	get total() {
		return this.list.length;
	}

	get count() {
		return this.list.filter(({ active }) => active).length;
	}

	clearFilters() {
		this.filters.forEach(filter => {
			filter.active = false;
		});

		this.features.forEach(feature => {
			const mapFeature = this.map.getFeature(feature.properties.feature_id);

			if (mapFeature) mapFeature.setProperty('visible', true);
		});
	}
}

Vue.component('map-filters-status', MapFiltersStatus);
