import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-print-preview-redux.html';

import api from '@/api';
import Async from '@/plugins/async-decorator';
import Buf from './icomm.class';
import fetchFeatures from './feature-collection';
import MapFilter from '@/components/map-filters/classes/map-filter.class';
import mapFilterService from '@/services/map-filter.service';
import openModal from './print-settings-modal';
import print from '@/services/print.service';
import sessionStorage from '@/services/session-storage.service';

import PrintMapPage from './print-map-page';
import PrintDetailsPage from './print-details-page';

const components = {
	PrintMapPage,
	PrintDetailsPage,
};

@Component({
	template,
	components,
})
export class MapPrintPreviewRedux extends Vue {
	data() {
		return {
			features     : null,
			filters      : null,
			visibleAttrs : null,

			showMap         : null,
			showDetailsPage : null,
			showMapPage     : null,

			// Loading Progress, { current, total }
			progress : null,
			error    : null,
			loading  : false,
		};
	}

	mounted() {
		this.loadFeaturesAndOptions().catch(() => null);
	}

	async loadFeaturesAndOptions() { // eslint-disable-line
		if (this.loading) return;
		const buf = new Buf();

		try {
			buf.on('progress', ([current, total]) => {
				this.progress = { current, total }; // eslint-disable-line object-property-newline
			});
			this.loading = true;
			this.features = await fetchFeatures(buf);
			this.filters = fetchFilters();


			const options = await openModal({
				features   : this.features,
				filters    : this.filters,
				hasFilters : Boolean(this.filters.length),
			});

			if (options.filters)
				this.filters = options.filters;
			if (options.attrs)
				this.visibleAttrs = options.attrs;

			const features = this.features;

			this.loading = false;

			if (this.filters)
				await mapFilterService.updateVisibleFeatures(null, features, this.filters);

			this.features = null;
			this.features = features;
			// The above pattern is to force a data update for this.features
			// updateVisibleFeatures is changing a deep value that vue.js doesn't normally watch.
			// -AM May 05, 2022

			this.showMapPage = Boolean(options.map);
			this.showDetailsPage = true;
		}
		catch (error) {
			this.error = error;
		}
		finally {
			this.loading = false;
			buf.close();

			if (!this.showMapPage)
				this.printAndExit();
		}
	}

	async printAndExit() {
		await this.$nextTick();
		await new Promise(resolve => setTimeout(resolve, 100));
		await api.config.set('printMap', true).catch(() => null);
		await print.openDialog();
		this.$router.push({ name : 'map' });
	}

	@Async(null)
	get company() {
		return api.company.get();
	}

	@Async(null)
	get farm() {
		return api.farm.get();
	}

	get collection() {
		if (!this.features || !this.features.length) return null;

		return {
			type     : 'FeatureCollection',
			features : this.features,
		};
	}
}

function fetchFilters() {
	const raw = sessionStorage.get('map-filters') || [];

	return raw.filter(filter => filter.active).map(filter => new MapFilter(filter));
}
