import Vue from 'vue';
import Component from 'vue-class-component';
import template from './billing-usage-display.html';

const props = {
	value : {
		type     : Object,
		required : true,
	},
};

@Component({
	template,
	props,
})
export class BillingUsageDisplay extends Vue {
	get resource() {
		return aliasResource(this.value.resource);
	}

	get used() {
		return this.value.usage;
	}

	get remaining() {
		return this.value.remaining;
	}

	get total() {
		return this.value.total;
	}

	get hasAllowance() {
		return Boolean(this.value.total);
	}
}

Vue.component('billing-usage-display', BillingUsageDisplay);


function aliasResource(resource) {
	switch (resource) {
		case 'clicks':
			return 'parcel clicks';
		default:
			return resource;
	}
}
