export default [{
	type       : 'open-air preacher',
	icon       : 'place-of-worship',
	attributes : [
		'Denomination',
	],
}, {
	type       : 'collector',
	icon       : 'recycle',
	attributes : [
		'Brand Preference',
		'Trashbag Color',
		'Can vs Bottle',
	],
}, {
	type       : 'bum',
	icon       : 'smoking',
	attributes : [
		'Brand Preference',
		'Packs per day',
	],
}, {
	type       : 'other',
	icon       : 'circle',
	attributes : [
		'Type',
	],
}];
