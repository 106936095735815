const component = () => import(/* webpackChunkName: "developer-test" */'./field-default-attributes');

export default {
	component,
	path : 'field-default-attributes',
	name : 'field-default-attributes-test',
	meta : {
		title  : 'Field Default Attributes Test',
		secure : false,
	},
};
