import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-employee-table.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';

@Component({ template })
export class WorkOrderEmployeeTable extends Vue {
	data() {
		return {
			error : null,
		};
	}

	@AsyncLoading
	get employees() {
		return api.workOrder.employee.list()
			.catch(error => { this.error = error });
	}

	deleteEmployee({ employee_id }) {
		if (this.loading) return;

		this.loading = true;
		this.error = null;

		const index = this.employees.findIndex(employee => employee_id === employee.employee_id);

		api.workOrder.employee.delete(employee_id)
			.then(() => { this.employees.splice(index, 1) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('work-order-employee-table', WorkOrderEmployeeTable);
