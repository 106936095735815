const component = () => import(/* webpackChunkName: "developer-test" */'./map-filters');

export default {
	component,
	path : 'map-filters',
	name : 'map-filters-test',
	meta : {
		title  : 'Map Filters Test',
		secure : false,
	},
};
