import Vue from 'vue';
import Component from 'vue-class-component';
import template from './test-buttons.html';

const props = {
	loading  : Boolean,
	disabled : Boolean,
};

@Component({
	template,
	props,
})
export class TestButtons extends Vue {
	get config() {
		return {
			loading  : this.loading,
			disabled : this.disabled,
		};
	}

	get basicButtonList() {
		return [
			'basic-button',
			'edit-button',
			'cancel-button',
			'back-button',
			'primary-button',
			'save-button',
			'plus-button',
			'danger-button',
			'delete-button',
			'close-button',
		];
	}
	get extraButtonList() {
		return [
			'add-cloud-storage-dropdown-button',
			'add-company-button',
			'add-farm-button',
			'add-field-button',
			'add-line-button',
			'add-marker-button',
			'add-team-button',
			'delete-icon-button',
			'draw-by-hand-button',
			'edit-icon-button',
			'invite-user-button',
			'outline-button',
			'side-panel-close-button',
			'side-panel-open-button',
			'user-location-button',
		];
	}
}

Vue.component('test-buttons', TestButtons);
