import api from './api';
import company from './company';
import storage from '@/services/storage.service';

export default {
	get,
	list,
	count,
	read,
	create,
	update,
	save,
	delete : _delete,

	Group : _extend,
};

function get() {
	return storage.get('group')
		.catch(() => list().then(list => list[0]));
}

function list(filters) {
	return filters
		? api.list('group', filters)
			.then(list => list.map(group => _extend(group)))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? list({ company_id }) : []);
}

function count(filters) {
	return filters
		? api.count('group', filters)
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? count({ company_id }) : { count : 0 });
}

function read(group_id) {
	return api.read('group', group_id)
		.then(group => _extend(group));
}

function create(group) {
	return api.create('group', group);
}

function update(group) {
	return api.update('group', group.group_id, group);
}

function save(group) {
	return group.group_id ? update(group) : create(group);
}

function _delete(group_id) {
	return api.delete('group', group_id);
}

function _extend(group = {}) {
	return Object.assign({

	}, group);
}
