import Vue from 'vue';
import Component from 'vue-class-component';
import template from './onboarding-progress-carousel.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import service from '@/services/onboarding.service';

@Component({ template })
export class OnboardingProgressCarousel extends Vue {
	data() {
		return {
			index     : 0,
			completed : new service.Progress(),
			loading   : null,
		};
	}

	get total() {
		return this.tasks.length;
	}

	get tasks() {
		return service.tasks.filter(task => !this.completed[task]);
	}

	@Async
	get farm() {
		this.loading = true;

		return api.farm.get()
			.catch(() => ({}));
	}

	@Watch('farm')
	watchFarm() {
		this.loading = true;
		this.index = 0;

		service.updateProgress(this.completed)
			.finally(() => { this.loading = false });
	}

	icon(task) {
		return `far ${service.icons[task]}`;
	}

	link(task) {
		return service.links[task];
	}

	label(task) {
		return service.labels[task];
	}

	style(task) {
		return {
			active : this.active(task),
		};
	}

	active(task) {
		return this.index === this.tasks.indexOf(task);
	}

	nextTask() {
		this.setIndex(this.index + 1);
	}

	prevTask() {
		this.setIndex(this.index - 1);
	}

	setIndex(index) {
		if (this.total > 1)
			this.index = (index + this.total) % this.total;

		else
			this.index = 0;
	}
}

Vue.component('onboarding-progress-carousel', OnboardingProgressCarousel);
