/* eslint-disable max-lines */
import api from './api';
import company from './company';
import store from '@/storage';

export default {
	isEnabled,
	isTrialEnabled,
	getAccount,
	forceGetAccount : getAccountNow,
	createAccount,
	updateAccount,
	getUsage,
	canUse,
	expireUsage,
	reportUsage,
	getAllowance,
	listCards,
	addCard,
	removeCard,
	setDefaultPaymentMethod,
	getPlans,
	subscribe,
	unsubscribe,
	getPackages,
	buyPackage,
	listInvoices,
	getInvoice,
	getInvoiceIntent,
	payInvoice,
	getUpcomingInvoice,
};

function isEnabled() {
	return Promise.resolve({})
		.then(params => api.queueGet('billing-replacement/enabled', params));
}

function isTrialEnabled() {
	return Promise.resolve({})
		.then(params => api.queueGet('billing-replacement/trial-enabled', params));
}

/* Account Management */
function getAccount(params) {
	return Promise.resolve(appendCompany(params))
		.then(params => getCachedAccount(params.company_id)
			.catch(error => {
				if (error)
					return Promise.reject(error);

				return api.queueGet('billing-replacement/account', params)
					.then(account => extendAccount(account))
					.then(account => setCachedAccount(account, params.company_id));
			}));
}
function getAccountNow(params) {
	return Promise.resolve(appendCompany(params))
		.then(params => api.queueGet('billing-replacement/account', params))
		.then(account => extendAccount(account))
		.then(account => setCachedAccount(account, params.company_id));
}
function updateAccount(body) {
	return Promise.resolve(appendCompany(body))
		.then(body => api.post('billing-replacement/account', body))
		.then(account => extendAccount(account))
		.then(account => setCachedAccount(account, body.company_id));
}
function createAccount(body) {
	return Promise.resolve(appendCompany(body))
		.then(body => api.post('billing-replacement/new-account', body))
		.then(account => extendAccount(account))
		.then(account => setCachedAccount(account, body.company_id));
}

/* App Allowances, Usages */
async function getUsage(params) {
	if (!params || !params.company_id) return getUsage(await appendCompany(params));

	try {
		return await getCachedUsage(params);
	}
	catch (error) {
		if (error)
			return Promise.reject(error);

		const usage = await api.queueGet('billing-replacement/usage', params);

		await setCachedUsage(usage, params.company_id);

		return store.getters['billingReplacement/usage'];
	}
}
function canUse(resource, wanted = 1) {
	return getUsage()
		.then(usage => {
			if (!usage[resource]) return false;

			const remaining = usage[resource].remaining;

			return remaining >= wanted;
		});
}
function expireUsage() {
	return Promise.resolve()
		.then(clearCache('usage'))
		.then(() => getUsage());
}
function reportUsage(params) {
	return Promise.resolve(appendCompany(params))
		.then(params => api.post('billing-replacement/report-usage', params));
}
function getAllowance(params) {
	return Promise.resolve(appendCompany(params))
		.then(params => api.queueGet('billing-replacement/allowance', params));
}

/* Cards */
function listCards(params) {
	return Promise.resolve(appendCompany(params))
		.then(params => api.queueGet('billing-replacement/wallet', params))
		.then(list => list.sort((a, b) => a.created - b.created));
}
function addCard(body) {
	return Promise.resolve(appendCompany(body))
		.then(body => api.post('billing-replacement/wallet/new-card', body));
}
function removeCard(paymentMethod) {
	return Promise.resolve(appendCompany({ paymentMethod }))
		.then(body => api.post('billing-replacement/wallet/remove-card', body));
}
function setDefaultPaymentMethod(paymentMethod) {
	return Promise.resolve(appendCompany({ paymentMethod }))
		.then(body => api.post('billing-replacement/subscription-update-payment-method', body))
		.then(clearCache('account'));
}

/* Plans */
function getPlans(params) {
	return api.queueGet('billing-replacement/plans', params)
		.then(list => list.map(plan => Object.assign({
			subscription_type : 'access',
		}, plan)));
}
function subscribe(body) {
	return Promise.resolve(appendCompany(body))
		.then(body => api.post('billing-replacement/subscribe', body))
		.then(clearCache('account'))
		.then(clearCache('usage'));
}
function unsubscribe(body) {
	return Promise.resolve(appendCompany(body))
		.then(body => api.post('billing-replacement/unsubscribe', body))
		.then(clearCache('account'))
		.then(clearCache('usage'));
}

/* Packages */
function getPackages() {
	return api.queueGet('billing-replacement/packages');
}
function buyPackage(body) {
	return Promise.resolve(appendCompany(body))
		.then(body => api.post('billing-replacement/buy-package', body))
		.then(clearCache('usage'));
}

/* Invoices */
function listInvoices(params) {
	return Promise.resolve(appendCompany(params))
		.then(params => api.queueGet('billing-replacement/invoices', params));
}
function getInvoice(id) {
	return Promise.resolve(appendCompany())
		.then(params => api.queueGet(`billing-replacement/invoice/${id}`, params));
}
function getInvoiceIntent(id) {
	return Promise.resolve(appendCompany())
		.then(params => api.queueGet(`billing-replacement/invoice-intent/${id}`, params));
}
function payInvoice(body) {
	return Promise.resolve(appendCompany(body))
		.then(body => api.post('billing-replacement/invoice-pay', body))
		.then(clearCache('account'));
}
function getUpcomingInvoice() {
	return Promise.resolve(appendCompany())
		.then(params => api.queueGet('billing-replacement/invoice-upcoming', params));
}

/* Utility */
function appendCompany(params = {}) {
	return company.get()
		.then(company => company ? company.company_id : null)
		.then(company_id => Object.assign(params, { company_id }));
}

function extendAccount(account = {}) {
	return Object.assign({
		account_id             : null,
		company_id             : null,
		account_type           : null,
		status                 : null,
		status_reason          : null,
		valid_until            : null,
		subscription_key       : null,
		subscription_display   : null,
		subscription_type      : null,
		stripe_customer_id     : null,
		stripe_subscription_id : null,
		stripe_balance         : null,
		stripe_current_pm      : null,
		invoice_memo           : null,
		billing_name           : null,
		billing_email          : null,
		billing_phone          : null,
	}, account);
}

function getCached(type, company_id) {
	return store.dispatch('billingReplacement/isExpired', {
		type,
		company_id,
	})
		.then(() => store.getters[`billingReplacement/${type}`]);
}

function clearCache(type) {
	return results => Promise.resolve(store.dispatch('billingReplacement/expire', { type }))
		.then(() => results);
}

function getCachedAccount(company_id) {
	return getCached('account', company_id);
}

function setCachedAccount(account, company_id) {
	return Promise.resolve(store.dispatch('billingReplacement/setAccount', {
		account,
		company_id,
	}))
		.then(() => store.getters[`billingReplacement/account`]);
}

function getCachedUsage({ company_id }) {
	return getCached('usage', company_id);
}
function setCachedUsage(usage, company_id) {
	return Promise.resolve(store.dispatch('billingReplacement/setUsage', {
		usage,
		company_id,
	}))
		.then(() => store.getters[`billingReplacement/usage`]);
}
