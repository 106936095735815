const component = () => import(/* webpackChunkName: "developer-test" */'./error-display');

export default {
	component,
	path : 'error-display',
	name : 'error-display-test',
	meta : {
		title  : 'Error Display Test',
		secure : false,
	},
};
