/* eslint-disable id-match */
import Vue from 'vue';
import Component from 'vue-class-component';
import template from './stripe-card-form.html';
import Watch from '@/plugins/watch-decorator';

const Stripe = process.env.STRIPE_PUBLIC_KEY && window.Stripe(process.env.STRIPE_PUBLIC_KEY); // eslint-disable-line
const icard = '#card';
const isecret = '#client_secret';

const props = {
	secret : {
		type     : String,
		required : true,
	},
};

@Component({
	template,
	props,
})
export class StripeCardForm extends Vue {
	data() {
		return {
			[icard]   : null,
			[isecret] : null,
			iloading  : false,
			ierror    : null,
			disabled  : true,
		};
	}

	mounted() {
		if (this.secret)
			this.mountForm(this.secret);
	}

	beforeDestroy() {
		this.unmountForm();
	}

	get el() {
		return this.$refs['stripe-card-form'] || '#stripe-card-form';
	}

	@Watch('secret')
	mountOnSecretLoad() {
		if (this.secret)
			this.mountForm(this.secret);
	}

	mountForm(client_secret) { // eslint-disable-line max-statements, complexity
		if (this.loading || this[isecret]) return;

		this[isecret] = client_secret;
		this.loading = true;

		if (!client_secret || this[icard]) return;

		try {
			if (!Stripe) throw new Error('Stripe is not enabled for this site');

			const style = {};
			const elements = Stripe.elements();
			const card = elements.create('card', { style });

			card.on('change', event => {
				this.disabled = Boolean(event.empty);
				this.error = event.error || null;
			});

			card.mount(this.el);

			this[icard] = card;
			this.disabled = false;
		}
		catch (error) {
			this.error = error;
		}

		this.loading = false;
	}

	unmountForm() {
		if (!this[icard]) return;

		this[icard].destroy();
		this[icard] = null;
	}

	submit() {
		if (this.loading || this.disabled || !this[isecret]) return;
		if (!Stripe) return;

		this.loading = true;
		this.error = null;

		Stripe.confirmCardSetup(this[isecret], {
			payment_method : {
				card : this[icard],
			},
		})
			.then(result => result.error ? Promise.reject(result.error) : result.setupIntent)
			.then(intent => this.done(intent))
			.catch(error => { this.error = error })
			.then(() => { this.loading = false });
	}

	get brand() {
		return process.env.BRAND_NAME || 'your Business';
	}

	get loading() {
		return this.iloading;
	}

	set loading(isLoading) {
		this.$emit('loading', isLoading);
		this.iloading = isLoading;
	}

	get error() {
		return this.ierror;
	}

	set error(error) {
		this.$emit('error', error);
		this.ierror = error;
	}

	done(intent) {
		this.$emit('submit', intent);
	}
}

Vue.component('stripe-card-form', StripeCardForm);
