import Vue from 'vue';
import Component from 'vue-class-component';
import template from './company-settings-list.html';
import Async from '@/plugins/async-decorator';
import { isDemoUser, checkCompanyPrivilege } from '@/services/permission.service';

@Component({ template })
export class CompanySettingsList extends Vue {
	@Async
	get isDemo() {
		return isDemoUser();
	}

	@Async(false)
	get canUpdate() {
		return checkCompanyPrivilege('update');
	}
}

Vue.component('company-settings-list', CompanySettingsList);
