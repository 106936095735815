export default function piracyProgressTree(completion) {
	return [[
		'             ',
		'             ',
		'             ',
		'             ',
		'___________  ',
		'|_________/  ',
	], [
		'             ',
		'             ',
		'             ',
		'_____________',
		'|          / ',
		'|_________/  ',
	], [
		'             ',
		'             ',
		'_____        ',
		'|   |________',
		'|          / ',
		'|_________/  ',
	], [
		'         T   ',
		'         |)  ',
		'_____    |)  ',
		'|   |____|___',
		'|          / ',
		'|_________/  ',
	], [
		'         T   ',
		'  |)     |)  ',
		'__|__    |)  ',
		'|   |____|___',
		'|          / ',
		'|_________/  ',
	], [
		'         T   ',
		'  |)   __|)  ',
		'__|__ /____) ',
		'|   |____|___',
		'|          / ',
		'|_________/  ',
	], [
		'        _T_ _',
		'  |)   /____)',
		'__|__ /____) ',
		'|   |____|___',
		'|          / ',
		'|_________/  ',
	], [
		'  __    _T_ _',
		' /__)  /____)',
		'__|__ /____) ',
		'|   |____|___',
		'|          / ',
		'|_________/  ',
	], [
		'  __    _T__ ',
		' /__)  /____)',
		'__|__ /____) ',
		'|   |____|___',
		'| o o  o o / ',
		'|_________/  ',
	]][completion];
}
