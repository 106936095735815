import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "settings-core" */'./company-cloud-storage');
import { showFarmUpsell } from '@/services/permission.service';
import modalService from '@/services/modal.service';

export default {
	component,
	path : 'company-cloud-storage',
	name : 'company-cloud-storage',
	meta : {
		title  : `${dsl('Company')} ${dsl('Cloud Storage')}`,
		secure : true,
	},
	async beforeEnter(to, from, next) {
		if (await showFarmUpsell(true)) {
			modalService.launchModal('access-upsell', {})
				.catch(() => null);

			return next(from.name ? false : 'map');
		}

		return next(true);
	},
};
