import Vue from 'vue';
import Component from 'vue-class-component';
import template from './api-progress-bar.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import storage from '@/services/storage.service';

const frequency = 250; // update frequency
const hangTime = 2000; // how long to stick around after all calls are done
const duration = 3000; // expected api call duration

@Component({ template })
export class ApiProgressBar extends Vue {
	data() {
		return {
			currentTime  : 0, // current progress of the meter
			expectedTime : 0, // effective length of the meter
			callCount    : 0, // number of outstanding calls
			slowCount    : 0, // number of delayed calls

			timeout  : null,
			interval : null,

			list : [],
		};
	}

	mounted() {
		this.interval = setInterval(this.update.bind(this), frequency);
	}

	beforeDestroy() {
		clearInterval(this.interval);
	}

	get percentage() {
		if (this.callCount) return this.currentTime / this.expectedTime;
		if (this.timeout) return 1;
	}

	@Async
	get liveCount() {
		return storage.get('apiProgress')
			.catch(() => 0);
	}

	@Watch('liveCount')
	watchLiveCount(_, lastCount) {
		clearTimeout(this.timeout);

		this.timeout = null;

		if (this.liveCount > lastCount)
			this.increment(this.liveCount - lastCount);

		else if (this.liveCount)
			this.decrement(lastCount - this.liveCount);

		else if (this.callCount)
			this.reset();
	}

	increment(deltaCalls) {
		this.expectedTime += (duration + 1000) * deltaCalls;
		this.callCount += deltaCalls;

		Array(deltaCalls).fill()
			.map(() => this.list.push(setTimeout(() => {
				this.slowCount++;
			}, duration)));
	}

	decrement(deltaCalls) {
		Array(deltaCalls).fill()
			.map(() => clearTimeout(this.list.shift()));
	}

	reset() {
		this.timeout = setTimeout(() => {
			this.currentTime = 0;
			this.expectedTime = 0;
			this.callCount = 0;
			this.slowCount = 0;
			this.list.forEach(timeout => clearTimeout(timeout));
			this.list.splice(0, this.list.length);
			this.timeout = null;
		}, hangTime);
	}

	update() {
		this.currentTime += frequency * this.callCount;
		this.expectedTime += frequency * this.slowCount;
	}
}

Vue.component('api-progress-bar', ApiProgressBar);
