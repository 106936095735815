import Vue from 'vue';
import Component from 'vue-class-component';
import template from './select-company-input.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import { checkCompanyPrivilege } from '@/services/permission.service';

const props = {
	disabled : Boolean,
};

@Component({
	template,
	props,
})
export class SelectCompanyInput extends Vue {
	data() {
		return {
			creating : null,
		};
	}

	get companyList() {
		if (!this.companies) return;

		return this.companies.map(company => ({
			label : company.companyName,
			value : company,
		}));
	}

	get farmList() {
		if (!this.farms) return;

		return this.farms.map(farm => ({
			label : farm.farmName,
			value : farm,
		}));
	}

	@Async
	get company() {
		return api.company.get();
	}

	@Async
	get companies() {
		return api.company.list();
	}

	@Async(null, 'company')
	get farm() {
		return api.farm.get();
	}

	@Async(null, 'company')
	get farms() {
		if (!this.company) return;

		const { company_id } = this.company;

		this.farms = null;

		return api.farm.list({ company_id });
	}

	@Async(false)
	get canCreate() {
		if (!this.company) return false;

		return checkCompanyPrivilege('create', this.company);
	}

	@Watch(['company', 'companies'])
	watchCompany() {
		if (!this.company || !this.companies) return;

		this.creating = false;
		this.company = this.companies.find(({ company_id }) => company_id === this.company.company_id) || null;
	}

	@Watch(['farm', 'farms'])
	watchFarm() {
		if (!this.farms || this.creating) return;

		const selected_id = this.farm && this.farm.farm_id;

		this.farm = this.farms.length === 1
			? this.farms[0]
			: this.farms.find(({ farm_id }) => farm_id === selected_id) || null;
	}

	@Watch(['company', 'farm'])
	input() {
		const company = this.company;
		const farm = this.farm;
		const payload = {
			company,
			farm,
		};

		this.$emit('input', payload);
	}

	@Watch('creating')
	watchCreating() {
		this.$emit('create-farm', this.creating);
	}

	addFarm() {
		this.farm = new api.farm.Farm();
		this.creating = true;
	}
}

Vue.component('select-company-input', SelectCompanyInput);
