import Vue from 'vue';
import Component from 'vue-class-component';
import template from './close-button.html';

const props = {
	white : Boolean,
};

@Component({
	template,
	props,
})
export class CloseButton extends Vue {}

Vue.component('close-button', CloseButton);
