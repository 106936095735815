import Vue from 'vue';
import Component from 'vue-class-component';
import template from './conversation-browser.html';
import api from '@/api';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import { checkPrivilege, isDemoUser } from '@/services/permission.service';

const props = {
	query : {
		type     : Object,
		required : true,
	},
	readonly : Boolean,
};

@Component({
	template,
	props,
})
export class ConversationBrowser extends Vue {
	data() {
		return {
			conversation : null,
			loading      : null,
		};
	}

	@Async(false)
	get isDemoUser() {
		return isDemoUser();
	}

	@Async(false, 'conversation')
	get canDelete() {
		if (!this.conversation) return false;
		if (this.readonly) return false;

		return checkPrivilege('delete', this.conversation)
			.then(allowed => allowed && !this.isDemoUser);
	}

	@Watch('value')
	watchValue() {
		this.conversation = null;
	}

	removeConversation() {
		if (!this.conversation) return;

		const { conversation_id } = this.conversation;

		this.loading = true;

		return api.conversation.delete(conversation_id)
			.then(() => { this.conversation = null })
			.finally(() => { this.loading = false });
	}
}

Vue.component('conversation-browser', ConversationBrowser);
