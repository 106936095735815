import Vue from 'vue';
import Component from 'vue-class-component';
import template from './conversation-form.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

const props = {
	query : Object,
};

@Component({
	template,
	props,
})
export class ConversationForm extends Vue {
	data() {
		return {
			loading      : false,
			error        : null,
			conversation : {
				title   : '',
				content : '',
			},
		};
	}

	@Async
	get farm() {
		return api.farm.get();
	}

	@Async
	get user() {
		return api.user.get();
	}

	submit() {
		const { farm_id } = this.farm;
		const user = this.user;
		const conversation = Object.assign({}, this.conversation, { user }, this.query || { farm_id });

		this.loading = true;
		this.error = null;

		return api.conversation.save(conversation)
			.then(conversation => this.$emit('select', conversation))
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('conversation-form', ConversationForm);
