import Vue from 'vue';
import Component from 'vue-class-component';
import template from './test-list.html';

const props = {
	list : Array,
	skip : {
		type    : String,
		default : '',
	},
	sort : {
		type    : Function,
		default : (left, right) => (right.split('_').length - left.split('_').length) || left.localeCompare(right),
	},

	clickable : Boolean,
};

@Component({
	template,
	props,
})
export class TestList extends Vue {
	data() {
		return {
			selected : null,
		};
	}

	get columns() {
		return [].concat(...this.list.map(item => Object.entries(item)))
			.filter(([, value]) => value)
			.map(([column]) => column)
			.filter((column, index, array) => array.indexOf(column) === index)
			.filter(column => !/__/.test(column))
			.filter(column => !/At$/.test(column))
			.filter(column => !this.skip.split(/\W+/).includes(column))
			.filter(column => ![
			].includes(column))
			.sort(this.sort);
	}

	get rows() {
		return this.list;
	}

	click(row) {
		this.selected = row;
		this.$emit('click', row);
	}
}

Vue.component('test-list', TestList);
