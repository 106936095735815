import Vue from 'vue';
import Component from 'vue-class-component';
import template from './parcel-display.html';

const props = {
	parcel : Object,
};

@Component({
	template,
	props,
})
export class ParcelDisplay extends Vue {
	get properties() {
		if (!this.parcel) return;

		return [
			this.getProperty('Parcel Number', 'parcelNumber', 'number', 'name'),
			this.getProperty('Parcel Acreage', 'parcelAcreage', 'acreage'),
			this.getProperty('Parcel Owner', 'parcelOwner', 'owner'),
			this.getProperty('Physical Address', 'physicalAddress'),
			this.getProperty('Mailing Address', 'mailingAddress'),
			this.getProperty('Last Updated', 'lastUpdated'),
			this.getProperty('Parcel Municipality', 'parcelMunicipality'),
			this.getProperty('Land Use Class', 'landUseClass'),
			this.getProperty('Total Market Value', 'totalMarketValue'),
		]
			.filter(prop => prop.value);
	}

	getProperty(label, ...keys) {
		return {
			label,
			value : findInObject(this.parcel, ...keys),
		};
	}
}

Vue.component('parcel-display', ParcelDisplay);

function findInObject(obj, ...paths) {
	const selected = paths.find(key => obj.hasOwnProperty(key) && obj[key] !== null);

	if (selected)
		return obj[selected];
}
