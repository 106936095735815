import Vue from 'vue';

export default function toFixed(value, digits = 2) {
	const number = Number.parseFloat(`${value}`);

	if (!Number.isFinite(number)) return value;
	if (Number.isNaN(number)) return value;

	return addCommas(number.toFixed(digits));
}

Vue.filter('to-fixed', toFixed);

function addCommas(value) {
	// source:
	// https://stackoverflow.com/questions/16835754/regex-reads-from-right-to-left/44257645#44257645

	const [left, right = ''] = value.split('.');

	const withCommas = left.replace(/(?!^)(...(?=(...)*$))/g, ',$1');

	return `${withCommas}.${right}`;
}
