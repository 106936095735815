import Vue from 'vue';
import Component from 'vue-class-component';
import template from './unset-button.html';

const props = {
	value : Object,

	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
};

@Component({
	template,
	props,
})
export class UnsetButton extends Vue {}

Vue.component('unset-button', UnsetButton);
