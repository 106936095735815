import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-options.html';

const props = {
	map : {
		type     : Object,
		required : true,
	},
};

@Component({
	template,
	props,
})
export class MapOptions extends Vue {}

Vue.component('map-options', MapOptions);
