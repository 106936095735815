import Vue from 'vue';
import Component from 'vue-class-component';
import template from './field-list-summary.html';
import Async from '@/plugins/async-decorator';
import service from '@/services/map-filter.service';
import { farmableAcresRegExp } from '@/services/farmable-acres.service.js';

const props = {
	features : Array, // Array of GeoJSON objects
	filters  : Array, // [{ type, operation, value }]
};

@Component({
	template,
	props,
})
export class FieldListSummary extends Vue {
	@Async(null, 'features')
	get length() {
		if (!this.features) return 0;

		return this.features.filter(({ properties : { featureType } }) => featureType === 'field').length;
	}

	@Async(null, 'features')
	get parcelAcreage() {
		return this.getParcelAcres(this.features);
	}

	@Async(null, 'features')
	get farmableAcreage() {
		return this.getFarmableAcres(this.features);
	}

	@Async(null, ['filteredFeatures'])
	get filteredLength() {
		if (!this.filteredFeatures) return 0;

		return Promise.resolve(this.filteredFeatures.length)
			.then(length => length === this.length ? null : length);
	}

	@Async(null, ['filteredFeatures'])
	get filteredParcelAcreage() {
		return Promise.resolve(this.filteredFeatures)
			.then(list => this.getParcelAcres(list))
			.then(parcelAcreage => parcelAcreage === this.parcelAcreage ? null : parcelAcreage);
	}

	@Async(null, ['filteredFeatures'])
	get filteredFarmableAcreage() {
		return Promise.resolve(this.filteredFeatures)
			.then(list => this.getFarmableAcres(list))
			.then(farmableAcreage => farmableAcreage === this.farmableAcreage ? null : farmableAcreage);
	}

	@Async(null, ['features', 'filters'])
	get filteredFeatures() {
		return Promise.resolve(service.filterFeatures(this.features, this.filters));
	}

	getParcelAcres(list) {
		return this.getAcres(list, /(apn|parcel).*acre.*/i);
	}

	getFarmableAcres(list) {
		return this.getAcres(list, farmableAcresRegExp);
	}

	getAcres(list, regex) {
		if (!list) return list;

		return list.map(({ properties : { attributes } }) => {
			if (!attributes) return 0;

			const attribute = attributes.find(({ label }) => regex.test(label)) || {};

			return attribute.value || 0;
		})
			.reduce((total, acres) => total + (Number(acres) || 0), 0);
	}
}

Vue.component('field-list-summary', FieldListSummary);
