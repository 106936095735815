import Vue from 'vue';
import Component from 'vue-class-component';
import template from './billing-settings-list.html';
import Async from '@/plugins/async-decorator';
import { isDemoUser, checkCompanyPrivilege } from '@/services/permission.service';
import lockout from '@/services/billing-lockout.service';

@Component({ template })
export class BillingSettingsList extends Vue {
	data() {
		return {
			loading : false,
		};
	}

	@Async
	get isDemo() {
		return isDemoUser();
	}

	@Async(false)
	get showCompanySettings() {
		return checkCompanyPrivilege('delete');
	}

	@Async(false)
	get isPaidAccount() {
		return Promise.all([
			lockout.isAppEnabled(),
			lockout.isBillingEnabled(),
		])
			.then(results => results.every(Boolean));
	}

	get show() {
		return !this.isDemo && this.showCompanySettings;
	}
}

Vue.component('billing-settings-list', BillingSettingsList);
