import api from './api';
import farm from './farm';
import user from './user';

export default {
	list,
	count,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	Conversation : _extend,
};

function list(filters) {
	return filters
		? api.list('conversation', filters, { sortBy : '-createdAt' })
			.then(list => list.map(conversation => addUser(conversation)))
			.then(promises => Promise.all(promises))
			.then(list => list.map(conversation => _extend(conversation)))
		: farm.get()
			.then(farm => farm ? farm.farm_id : null)
			.then(farm_id => farm_id ? list({ farm_id }) : []);
}

function count(filters) {
	return filters
		? api.count('conversation', filters)
		: farm.get()
			.then(farm => farm ? farm.farm_id : null)
			.then(farm_id => farm_id ? count({ farm_id }) : { count : 0 });
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(conversation_id) {
	return api.read('conversation', conversation_id)
		.then(conversation => addUser(conversation))
		.then(promises => Promise.all(promises))
		.then(conversation => _extend(conversation));
}

function create(conversation) {
	return Promise.resolve(conversation.farm_id || farm.get().then(({ farm_id }) => farm_id))
		.then(farm_id => api.create('conversation', Object.assign(conversation, { farm_id })));
}

function update(conversation) {
	return api.update('conversation', conversation.conversation_id, conversation);
}

function save(conversation) {
	return conversation.conversation_id ? update(conversation) : create(conversation);
}

function _delete(conversation_id) {
	return api.delete('conversation', conversation_id);
}

function _extend(conversation = {}) {
	return Object.assign({

	}, conversation);
}

function addUser(conversation) {
	if (!conversation || conversation.user) return conversation;

	return Promise.resolve(conversation.user__conversations && conversation.user__conversations[0])
		.then(user => user ? user.user_id : null)
		.then(user_id => user_id ? user.read(user_id) : null)
		.then(user => Object.assign(conversation, { user }));
}
