import Vue from 'vue';
import Component from 'vue-class-component';
import template from './parcel-click-upsell-modal.html';
import permissions from '@/services/permission.service';
import Async from '@/plugins/async-decorator';

const props = {
};

@Component({
	template,
	props,
})
export class ParcelClickUpsellModal extends Vue {
	@Async
	get isAdmin() {
		return permissions.isAdmin();
	}

	get listeners() {
		return Object.assign({}, this.$listeners, {
			submit : () => null,
		});
	}

	submit() {
		if (this.isAdmin)
			this.$router.push({
				name : 'billing-settings-buy-package',
			});

		this.$emit('submit', null);
	}
}
