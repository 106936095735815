import Vue from 'vue';
import Component from 'vue-class-component';
import Async from '@/plugins/async-decorator';
import template from './user-chip-list.html';
import api from '@/api';

const props = {
	value            : Array,
	label            : String,
	readonly         : Boolean,
	showInvitations  : Boolean,
	isAdministrative : Boolean,
};

@Component({
	template,
	props,
})
export class UserChipList extends Vue {
	get users() {
		return (this.value || []).map(user => this.assignField(user));
	}

	@Async
	get list() {
		return Promise.all([
			api.user.list()
				.then(list => this.filterAdmins(list)),
			this.showInvitations ? api.invitation.list() : [],
		])
			.then(lists => [].concat(...lists))
			.then(list => list.map(user => this.assignField(user)));
	}

	assignField(user) {
		return assignUserLabel(user);
	}

	filterAdmins(list) {
		if (this.isAdministrative) return list;

		return getAdministrativeUsers()
			.then(admins => list.filter(({ user_id }) => !admins[user_id]));
	}
}

Vue.component('user-chip-list', UserChipList);

function getAdministrativeUsers() {
	return api.company.get()
		.then(({ company_id }) => api.team.find({
			company_id,
			teamType : 'administrative',
		}))
		.then(({ team_id }) => api.user.list({ team_id }))
		.then(list => list.reduce((admins, { user_id }) => Object.assign(admins, { [user_id] : true }), {}));
}


function assignUserLabel(user) {
	if (!user.name) return Object.assign({ field : user.email }, user);

	const field = `${user.name} (${user.email})`;

	return Object.assign({ field }, user);
}
