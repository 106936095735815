import component from './login';

export default {
	component,
	path : '/login',
	name : 'login',
	meta : {
		title : 'Login',
	},
};
