import api from '@/api';
import lockout from '@/services/billing-lockout.service';

export default {
	isDemoUser,
	isAdmin() {
		return checkCompanyPrivilege('delete');
	},
	checkBilling,
	checkPrivilege,
	checkFarmPrivilege,
	checkCompanyPrivilege,
	showFarmUpsell,
};

export async function isDemoUser(emailonly = false) {
	if (process.env.DEPLOYMENT === 'demo') return true;

	if (!emailonly)
		try {
			return await lockout.isDemo();
		}
		catch (_) {
			// This is intentional.
			// If lockout fails, we can check old behavior.
			// -AM Feb 05, 2022
			true; // eslint-disable-line
		}


	return api.user.get()
		.then(user => user || Promise.reject())
		.then(user => /demo@montage\.ag$/.test(user.email));
}

export function checkBilling(resource) {
	return lockout.isAddingOkay(resource);
}

async function checkBillingByAction(action) {
	if (['update', 'create'].includes(action))
		return checkBilling();

	return true;
}

export async function showFarmUpsell(secure = false) { // eslint-disable-line complexity
	if (!await checkCompanyPrivilege('delete')) return false;
	if (!await lockout.isBillingEnabled()) return false;
	if (await lockout.hasAllowance('farms')) return false;

	if (!secure && await lockout.isTrialing('access')) return false;

	return await lockout.isNotSubscribed() || await lockout.isSubscriptionType('clicks');
}

export function checkPrivilege(action, record) {
	return checkPermissions(action, record, checkFarmPrivilege)
		.then(async okay => okay && await checkBillingByAction(action));
}

export function checkFarmPrivilege(action, farm = null) {
	return checkPermissions(action, farm || api.farm.get(), checkCompanyPrivilege)
		.then(async okay => okay && await checkBillingByAction(action));
}

export function checkCompanyPrivilege(action, company = null) {
	return checkPermissions(action, company || api.company.get())
		.then(async okay => okay && await checkBillingByAction(action));
}

function checkPermissions(action, record, escalate = false) {
	return Promise.resolve(record)
		.then(record => record || {})
		.then(({ permissions }) => permissions || [])
		.then(permissions => permissions.length
			? permissions.some(({ privileges }) => privileges[action])
			: escalate && escalate(action));
}
