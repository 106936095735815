import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-clustering-controls.html';

const props = {
	map : {
		type     : Object,
		required : true,
	},
};

@Component({
	template,
	props,
})
export class MapClusteringControls extends Vue {
	data() {
		return {
			list : [{
				label : 'farthest',
				value : 4,
			}, {
				label : 'middle',
				value : 8,
			}, {
				label : 'closest',
				value : 12,
			}],
		};
	}

	get enabled() {
		return this.map.getClusteringEnabled();
	}

	set enabled(enabled) {
		return this.map.setClusteringEnabled(enabled);
	}

	get thresholdLabel() {
		return this.list.find(({ value }) => value === this.threshold).label;
	}

	get threshold() {
		return this.map.getClusteringThreshold();
	}

	set threshold(threshold) {
		return this.map.setClusteringThreshold(threshold);
	}
}

Vue.component('map-clustering-controls', MapClusteringControls);
