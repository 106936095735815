import Vue from 'vue';
import Component from 'vue-class-component';
import template from './test.html';

const props = {
	value    : [Object, String],
	blocking : Boolean,
};

@Component({
	template,
	props,
})
export class TestModal extends Vue {
	data() {
		return {
			cancellable     : !this.blocking,
			hasErrorButton  : null,
			hasCancelButton : null,
		};
	}

	get listeners() {
		return Object.assign({}, this.$listeners, {
			submit : () => null,
		});
	}

	get report() {
		return {
			value    : this.value,
			blocking : this.blocking,
		};
	}

	submit() {
		return Promise.resolve({
			payload : 'data',
			value   : this.value,
		})
			.then(data => { this.$emit('submit', data) });
	}

	error() {
		return this.$emit('error', new Error('my modal error'));
	}
}
