import Vue from 'vue';
import Component from 'vue-class-component';
import template from './ceres-imaging-integration-details.html';
import Popup from '@/classes/popup.class';
import api from '@/api';

const props = {
	value : {
		type     : Object, // CeresImaging integration record
		required : true,
	},
};

@Component({
	template,
	props,
})
export class CeresImagingIntegrationDetails extends Vue {
	data() {
		return {
			loading : null,
			error   : null,
		};
	}

	reauthorize() {
		if (this.loading) return;

		const popup = new Popup();

		const { ceresImaging_id } = this.value;

		this.loading = true;
		this.error = null;

		return api.ceresImaging.reauthorize(ceresImaging_id)
			.then(integration => this.awaitAuthorization(integration, popup))
			.catch(error => {
				this.error = error;

				popup.close();
			})
			.finally(() => { this.loading = false });
	}

	awaitAuthorization(integration, popup) {
		return Promise.resolve(popup.open(integration.url))
			.then(() => api.ceresImaging.read(integration.ceresImaging_id))
			.then(integration => { this.$emit('input', integration) });
	}
}

Vue.component('ceres-imaging-integration-details', CeresImagingIntegrationDetails);
