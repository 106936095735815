const component = () => import(/* webpackChunkName: "developer-test" */'./filters');

export default {
	component,
	path : 'filters',
	name : 'filters-test',
	meta : {
		title  : 'Filters',
		secure : false,
	},
};
