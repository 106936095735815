import markerTypes from '@/config/marker-types';
import dsl from '@/services/dsl.service';
import titleCase from '@/filters/title-case.filter';

export default [{
	label   : `${dsl('Marker')} Type`,
	type    : 'icon',
	options : markerTypes.map(({ type, icon : value }) => ({
		label : titleCase(type).replace(/-/g, ' '),
		value,
	})),
}, {
	label   : `Feature Type`,
	type    : 'type',
	options : [{
		label : `${dsl('Fields')}`,
		value : 'field',
	}, {
		label : `${dsl('Markers')}`,
		value : 'marker',
	}, {
		label : `${dsl('Lines')}`,
		value : 'line',
	}],
}, {
	label      : 'Acreage',
	type       : 'acre',
	units      : 'acres',
	operation  : '==',
	operations : [{
		label : 'less than',
		value : '<=',
	}, {
		label : 'equal to',
		value : '==',
	}, {
		label : 'more than',
		value : '>=',
	}],
}, {
	label : 'Name',
	type  : 'name',
}, {
	label   : 'Color',
	type    : 'color',
	options : [
		{
			label : 'Green',
			value : '#A8CA2E',
			color : '#A8CA2E',
		},
		{
			label : 'Teal',
			value : '#39BEA3',
			color : '#39BEA3',
		},
		{
			label : 'Blue',
			value : '#2090C9',
			color : '#2090C9',
		},
		{
			label : 'Purple',
			value : '#BC4474',
			color : '#BC4474',
		},
		{
			label : 'Red',
			value : '#DB4B4B',
			color : '#DB4B4B',
		},
		{
			label : 'Orange',
			value : '#FBB03B',
			color : '#FBB03B',
		},
	],
}, {
	label : 'Crop',
	type  : 'crop',
}, {
	label : 'Varietal',
	type  : 'varietal',
}, {
	label : `${dsl('Parcel Number')}`,
	type  : 'apn',
}, {
	label : 'Address',
	type  : 'address',
}]
	.map(filter => Object.assign({ operation : '=~' }, filter));
