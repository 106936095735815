import api from './api-storage-list';
import farm from './farm';
import service from '@/services/color.service';

export default {
	list,
	count,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	Field : _extend,
};

function list(filters) {
	return filters
		? api.list('field', filters)
			.then(list => list.map(field => _extend(field)))
		: farm.get()
			.then(farm => farm ? farm.farm_id : null)
			.then(farm_id => farm_id ? list({ farm_id }) : []);
}

function count(filters) {
	return filters
		? api.count('field', filters)
		: farm.get()
			.then(farm => farm ? farm.farm_id : null)
			.then(farm_id => farm_id ? count({ farm_id }) : { count : 0 });
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(field_id) {
	return api.read('field', field_id)
		.then(field => _extend(field));
}

function create(field) {
	return Promise.resolve(field.farm_id || farm.get().then(({ farm_id }) => farm_id))
		.then(farm_id => api.create('field', Object.assign(field, { farm_id })));
}

function update(field) {
	return api.update('field', field.field_id, field);
}

function save(field) {
	return field.field_id ? update(field) : create(field);
}

function _delete(field_id) {
	return api.delete('field', field_id);
}

function _extend(field = {}) {
	const color = field.color || '';

	return Object.assign({
		fieldName  : '',
		feature_id : field.field_id || null,
		hover      : null,
		attributes : null,
	}, field, {
		color : service.migrate(color),
	});
}
