import Vue from 'vue';
import Component from 'vue-class-component';
import template from './folder-picker.html';

const props = {
	parent : String,
};

@Component({
	template,
	props,
})
export class FolderPicker extends Vue {
	data() {
		return {
			source  : null,
			enabled : null,
		};
	}

	select({ path, name }) {
		const { cloudStorage_id, cloudFolder_id } = Object.assign({}, this.source);

		return this.$emit('select', {
			name,
			path,
			cloudStorage_id,
			cloudFolder_id,
		});
	}

	reset() {
		this.source = null;
		this.enabled = null;
	}
}

Vue.component('folder-picker', FolderPicker);
