import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-searchbar.html';
import Watch from '@/plugins/watch-decorator';
import Debounce from '@/plugins/debounce-decorator';
import { locationLookup } from '@/services/map.service';

const props = {
	map : null,
};

@Component({
	template,
	props,
})
export class MapSearchbar extends Vue {
	data() {
		return {
			address    : null,
			error      : null,
			loading    : false,
			resetAfter : null, // clear and blur input, after auto-search
		};
	}

	get element() {
		return this.$refs.input;
	}

	@Debounce
	@Watch('address')
	watchAddress() {
		this.search();
	}

	change() {
		this.resetAfter = true;
	}

	flyTo(geometry) {
		if (!this.map || !geometry) return;

		return this.map.flyToGeometry(geometry);
	}

	search() {
		if (this.loading) return;

		if (!this.address) return this.$refs.input.focus();

		const address = this.address;

		this.loading = true;

		return locationLookup({ address })
			.then(results => results[0])
			.then(result => result ? this.flyTo(result.geometry) : null)
			.then(() => this.resetAfter ? this.reset() : null)
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	submit() {
		this.resetAfter = true;

		return this.search();
	}

	reset() {
		this.address = null;
		this.resetAfter = null;
		this.element.blur();
	}
}

Vue.component('map-searchbar', MapSearchbar);
