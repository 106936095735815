import Vue from 'vue';
import Component from 'vue-class-component';
import template from './resource-edit-button.html';

const delay = 250;

const props = {
	value : {
		type     : Object,
		required : true,
	},
	resource : {
		type     : String,
		required : true,
	},
	editing : Boolean,
};

@Component({
	template,
	props,
})
export class ResourceEditButton extends Vue {
	data() {
		return {
			locked : true,
		};
	}

	created() {
		this.cycleLock();
	}

	click(event) {
		this.cycleLock();

		this.$emit('click', event);
	}

	cycleLock() {
		this.locked = true;

		setTimeout(() => {
			this.locked = false;
		}, delay);
	}

	get label() {
		return this.editing ? 'done' : 'edit';
	}

	get usage() {
		return this.value.resources[this.resource].usage;
	}

	get limit() {
		return this.value.resources[this.resource].limit;
	}

	get visible() {
		return this.editing || this.usage > this.limit;
	}
}

Vue.component('resource-edit-button', ResourceEditButton);
