import localStorage from '@/services/local-storage.service';

export default {
	namespaced : true,

	state : {
		farm : null,

		list : null,
		flag : null,
	},

	getters : {
		farm(state) {
			if (!state.farm)
				state.farm = localStorage.get('farm');

			return state.farm;
		},

		list(state) {
			return flag => !state.flag || state.flag === flag ? state.list : null;
		},
	},

	mutations : {
		set(state, { farm }) {
			state.farm = farm || null;

			localStorage.set('farm', state.farm);

			if (!state.farm) {
				state.list = null;
				state.flag = null;
			}

			return state.farm;
		},

		list(state, { list, flag }) {
			if (flag && !/^company_id=/.test(flag)) return list;

			state.list = list || null;
			state.flag = flag || null;

			return state.list;
		},

		clear(state) {
			state.farm = null;
			state.list = null;
			state.flag = null;

			localStorage.set('farm', null);

			return null;
		},
	},

	actions : {
		set({ commit }, payload) {
			return Promise.resolve(payload)
				.then(payload => commit('set', payload));
		},

		list({ commit }, payload) {
			return Promise.resolve(payload)
				.then(payload => commit('list', payload));
		},

		clear({ commit }) {
			return Promise.resolve(commit('clear'));
		},
	},
};
