import axios from 'axios';
import storage from '@/services/storage.service';

const queue = []; // queued requests
const limit = 5;

let count = 0; // number of running requests

axios.defaults.withCredentials = true;

export default {
	get,
	queueGet,
	post,
	put,
	delete : destroy,
	resetQueue,
};

function get(url, config) {
	count++;

	return incrementApiProgress()
		.then(() => addAuthorizationHeader(config))
		.then(config => axios.get(url, config))
		.then(({ data }) => data)
		.catch(processAxiosError)
		.finally(() => decrementApiProgress())
		.finally(() => count--)
		.finally(() => checkQueue());
}

function queueGet(url, config) {
	return incrementApiProgress()
		.then(() => addAuthorizationHeader(config))
		.then(config => new Promise((resolve, reject) => {
			queue.push({
				resolve,
				reject,
				url,
				config,
			});

			checkQueue();
		}))
		.finally(() => decrementApiProgress())
		.finally(() => checkQueue());
}

function post(url, data, config) {
	return incrementApiProgress()
		.then(() => addAuthorizationHeader(config))
		.then(config => axios.post(url, data, config))
		.then(({ data }) => data)
		.catch(processAxiosError)
		.finally(() => decrementApiProgress());
}

function put(url, data, config) {
	return incrementApiProgress()
		.then(() => addAuthorizationHeader(config))
		.then(config => axios.put(url, data, config))
		.then(({ data }) => data)
		.catch(processAxiosError)
		.finally(() => decrementApiProgress());
}

function destroy(url, config) {
	return incrementApiProgress()
		.then(() => addAuthorizationHeader(config))
		.then(config => axios.delete(url, config))
		.then(({ data }) => data)
		.catch(processAxiosError)
		.finally(() => decrementApiProgress());
}

export function resetQueue() {
	queue.forEach(({ reject }) => reject(null));
	queue.splice(0, queue.length);
}

//////

function processAxiosError(error) {
	if (error.response) return Promise.reject(error.response.data);

	if (error.request && error.request.status === 0)
		return Promise.reject({
			status  : -1,
			error   : true,
			message : 'Network Error',
			context : {},
		});

	return Promise.reject(error);
}

function addAuthorizationHeader(config = {}) {
	return storage.get('auth')
		.catch(() => '')
		.then(authorization => Object.assign(authorization ? {
			Authorization : authorization,
		} : {}, config.headers))
		.then(headers => Object.assign(config, { headers }));
}

function incrementApiProgress() {
	return storage.increment('apiProgress');
}

function decrementApiProgress() {
	return storage.decrement('apiProgress');
}

function checkQueue() {
	if (!queue.length || count >= limit) return;

	const {
		resolve,
		reject,
		url,
		config,
	} = queue.shift();

	count++;

	axios.get(url, config)
		.then(({ data }) => data)
		.catch(processAxiosError)
		.then(result => resolve(result))
		.catch(error => reject(error))
		.finally(() => count--)
		.finally(() => checkQueue());
}
