import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "pages-external" */'./accept-invitation');

export default {
	component,
	path : '/accept-invitation/:id',
	name : 'accept-invitation',
	meta : {
		title  : `Accept ${dsl('Invitation')}`,
		secure : false,
	},
};
