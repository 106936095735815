import Vue from 'vue';
import Component from 'vue-class-component';
import template from './draw-line-by-hand-panel.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

const props = {
	value : Object, // data passed through the panel-container
};

@Component({
	template,
	props,
})
export class DrawLineByHandPanel extends Vue {
	data() {
		return {
			error      : null,
			attributes : [],
			context    : null, // { cancel(), done(), setColor() }
			feature    : null,
			loading    : null,
			line       : {
				color    : '',
				lineName : '',
			},
		};
	}

	mounted() {
		this.draw();
	}

	beforeDestroy() {
		this.map.cancelDraw();
	}

	get map() {
		return this.value.map;
	}

	get features() {
		return this.value.features.map(({
			properties : {
				feature_id,
				featureType,
				fieldName,
				markerName,
				lineName,
			},
		}) => ({
			feature_id,
			featureType,
			featureName : fieldName || markerName || lineName,
		}));
	}

	@Watch('line.color')
	watchLineColor() {
		if (this.context) this.context.setColor(this.line.color);
	}

	draw() {
		this.feature = null;

		return this.map.draw('LineString')
			.then(feature => { this.feature = feature })
			.then(() => this.edit())
			.catch(() => this.draw());
	}

	edit() {
		return this.map.edit(this.feature, 'LineString')
			.then(context => {
				this.context = context;

				context.setColor(this.line.color);
			});
	}

	save() {
		this.error = null;
		this.loading = true;

		return this.context.done()
			.then(geometry => Object.assign(this.line, { geometry }))
			.then(line => api.line.create(line))
			.then(line => Promise.all(this.attributes.map((attribute, index) => api.attribute.create(Object.assign(attribute, {
				line_id : line.line_id,
				order   : index + 1,
			}))))
				.then(() => line))
			.then(({ lineName, line_id, geometry }) => {
				geometry.properties.featureType = 'line';
				geometry.properties.feature_id = line_id;
				geometry.properties.lineName = lineName;

				this.value.features.push(geometry);

				this.map.replaceFeatureCollection([this.feature], {
					type     : 'FeatureCollection',
					features : [geometry],
				});
			})
			.then(() => { this.$emit('close') })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	validateName(name) {
		const feature = this.features.find(({ featureName }) => featureName === name);

		if (feature) return `A ${this.$dsl(feature.featureType)} named '${name}' already exists.`;
	}
}

Vue.component('draw-line-by-hand-panel', DrawLineByHandPanel);
