import Vue from 'vue';
import Component from 'vue-class-component';
import template from './field-list.html';
import Async from '@/plugins/async-decorator';
import Debounce from '@/plugins/debounce-decorator';
import acreageService from '@/services/acreage.service';
import attributeService from '@/services/attribute.service';

const delay = 50;

const props = {
	fields  : Array,
	actions : Array,
};

@Component({
	template,
	props,
})
export class FieldList extends Vue {
	data() {
		return {
			focused : null,
		};
	}

	@Async(null, 'fields', { deep : false })
	get list() {
		return Promise.all(this.fields)
			.then(list => Promise.all(list.map(field => Promise.all([
				field,
				acreageService.getParcelAcreage(field),
				acreageService.getFarmableAcreage(field),
				field.field_id ? attributeService.getAttribute(/^apn.?$/i, field.attributes, { field_id : field.field_id }) : null,
			]))))
			.then(list => Promise.all(list.map(([
				field,
				parcelAcreage,
				farmableAcreage,
				apn,
			]) => Object.assign(field, {
				parcelAcreage,
				farmableAcreage,
				apn,
			}))));
	}

	@Debounce(delay)
	mouseenter(field) {
		if (this.focused === field) return;

		if (this.focused) this.$emit('mouseleave', this.focused);

		this.focused = field;

		this.$emit('mouseenter', this.focused);
	}

	@Debounce.immediate(delay)
	mouseleave(field) {
		if (field && field !== this.focused) return;

		if (this.focused) this.$emit('mouseleave', this.focused);

		this.focused = null;
	}
}

Vue.component('field-list', FieldList);
