import Vue from 'vue';
import Component from 'vue-class-component';
import template from './stripe-invoice-display.html';

const props = {
	value    : Object,
	disabled : Boolean,
	short    : Boolean,
};

@Component({
	template,
	props,
})
export class StripeInvoiceDisplay extends Vue {
	get invoice() {
		return this.value;
	}

	get id() {
		if (!this.invoice) return null;

		return this.invoice.id;
	}

	get paymentMethod() {
		if (!this.invoice) return null;

		return readprop(this.invoice, 'charge.payment_method_details');
	}

	get isAutomaticCollection() {
		if (!this.invoice) return null;

		return this.invoice.collection_method === 'charge_automatically';
	}

	get isSendInvoice() {
		if (!this.invoice) return null;

		return this.invoice.collection_method === 'send_invoice';
	}

	get isFuture() {
		if (!this.invoice) return false;

		return ['draft'].includes(this.invoice.status);
	}

	get lines() {
		if (!this.invoice) return null;

		return this.invoice.lines.data.filter(line => line.amount);
	}

	get appliedBalance() {
		if (!this.invoice) return null;

		return this.invoice.starting_balance - this.invoice.ending_balance;
	}

	get canPay() {
		if (!this.invoice) return false;

		return ['uncollectable', 'open'].includes(this.invoice.status);
	}

	pay() {
		this.$emit('pay', this.invoice);
	}

	getDescription(line) {
		return readprops(line, 'description');
	}
}

Vue.component('stripe-invoice-display', StripeInvoiceDisplay);

function readprops(source, ...props) {
	for (const prop of props) {
		const val = readprop(source, prop);

		if (val) return val;
	}
}

function readprop(source, prop, spliton = '.') {
	const path = prop.split(spliton);

	let cur = source;

	if (!path.length) return cur;

	while (path.length > 1) {
		const forward = cur[path[0]];

		if (!forward) return;

		cur = forward;
		path.shift();
	}

	return cur[path[0]];
}
