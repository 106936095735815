import storage from '@/services/storage.service';
import store from '@/storage';

export default {
	getCurrent,
	getData,
	push,
	pop,
	resolve,
	reject,
	clear,
	has,
};

function getCurrent() {
	return storage.get('flyover')
		.then(layer => layer.flyover)
		.catch(() => null);
}

function getData() {
	return storage.get('flyover')
		.then(layer => layer.data)
		.catch(() => null);
}

function push(flyover, data) {
	return storage.push('flyover', {
		flyover,
		data,
	});
}

function pop() {
	return storage.pop('flyover');
}

function resolve(value) {
	return storage.resolve('flyover', value);
}

function reject(value) {
	return storage.reject('flyover', value);
}

function clear() {
	return storage.clear('flyover');
}

function has(flyover) {
	const stack = store.getters['flyover/stack'];

	return stack.some(layer => layer.flyover === flyover);
}
