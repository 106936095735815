import Vue from 'vue';
import Component from 'vue-class-component';
import template from './cloud-source-picker.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

@Component({ template })
export class CloudSourcePicker extends Vue {
	@Async()
	get sources() {
		return api.cloudStorage.list();
	}

	select(source) {
		return this.$emit('select', source);
	}
}

Vue.component('cloud-source-picker', CloudSourcePicker);
