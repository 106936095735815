import Vue from 'vue';
import Component from 'vue-class-component';
import template from './content-tabs.html';
import Watch from '@/plugins/watch-decorator';

const props = {
	value : [Number, String], // the active tab
};

@Component({
	template,
	props,
})
export class ContentTabs extends Vue {
	data() {
		return {
			tabs   : [],
			tab_id : null,
			oldTab : null,
		};
	}

	mounted() {
		if (!this.tabs.length)
			throw new Error('[content-tabs] Content-tabs should contain content-tab children.');

		this.tab_id = this.value || 0;

		this.activate(this.tab);

		this.$emit('input', this.tab.tab_id);
	}

	get tab() {
		if (!this.tabs.length) return null;

		if (!this.tab_id) return this.tabs[0];

		const tab = this.tabs[this.tab_id] || this.tabs.find(({ tabId }) => tabId === this.tab_id);

		if (!tab) throw new Error(`[content-tabs] Failed to find tab '${this.tab_id}'.`);

		return tab;
	}

	@Watch('tab_id')
	watchTabId() {
		this.activate(this.tab);

		this.$emit('input', this.tab_id);
	}

	@Watch('value')
	watchValue() {
		this.tab_id = this.value;
	}

	activate(tab) {
		if (this.oldTab) this.oldTab.active = false;
		this.oldTab = tab;
		this.oldTab.active = true;
		this.tab_id = tab.tab_id;
	}

	inform(tab) {
		this.$emit('locked', tab);
	}
}

Vue.component('content-tabs', ContentTabs);
