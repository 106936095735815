import Vue from 'vue';
import Component from 'vue-class-component';
import template from './team-details.html';
import api from '@/api';
import Watch from '@/plugins/watch-decorator';

const props = {
	value : Object,
};

@Component({
	template,
	props,
})
export class TeamDetails extends Vue {
	data() {
		return {
			message   : null,
			teamNames : null,
		};
	}

	@Watch.once('value')
	getTeamNames() {
		const team_id = this.value.team_id;

		return api.team.list()
			.then(list => list.filter(team => team.team_id !== team_id))
			.then(list => list.map(({ teamName }) => teamName.toLowerCase()))
			.then(teamNames => { this.teamNames = teamNames });
	}

	get isAdministrative() {
		return this.value.teamType === 'administrative';
	}

	get type() {
		return this.message
			? 'is-danger'
			: null;
	}

	get noFarmsMessage() {
		if (!this.value) return;
		if (this.isAdministrative) return;

		const farms = this.value.farms;
		const hasAccess = farms.some(farm => farm.access !== 'none');

		if (hasAccess) return;

		return `This ${this.$dsl('Team')} has no access to ${this.$dsl('Farms')}`;
	}

	validateName(name) {
		if (!this.teamNames) return false;

		return !this.teamNames.includes(name.toLowerCase());
	}

	input(team) {
		const teamName = team.teamName || '';

		if (this.validateName(teamName)) {
			this.message = null;

			return this.$emit('input', Object.assign({}, this.value, team));
		}

		this.message = `${teamName} already exists`;
	}

	get users() {
		return [].concat(this.value.users, this.value.invitations);
	}

	set users(users) {
		return this.input({
			invitations : users.filter(({ invitation_id }) => invitation_id),
			users       : users.filter(({ invitation_id }) => !invitation_id),
		});
	}
}

Vue.component('team-details', TeamDetails);
