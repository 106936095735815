import Vue from 'vue';
import Component from 'vue-class-component';
import template from './stripe-next-invoice-display.html';
import api from '@/api';

const props = {
	value : Object,
};

@Component({
	template,
	props,
})
export class StripeNextInvoiceDisplay extends Vue {
	data() {
		return {
			error   : null,
			loading : null,
			invoice : null,
		};
	}

	mounted() {
		if (this.value)
			this.invoice = this.value;
		else
			this.loadUpcoming();
	}

	loadUpcoming() {
		if (this.loading) return;

		this.loading = true;

		return Promise.resolve(api.billingReplacement.getUpcomingInvoice())
			.then(response => { this.invoice = response })
			.catch(error => { this.error = error })
			.then(() => { this.loading = false });
	}

	get isAutomaticCollection() {
		if (!this.invoice) return null;

		return this.invoice.collection_method === 'charge_automatically';
	}

	get to() {
		return {
			name   : 'billing-settings-invoice-detail',
			params : { id : 'upcoming' },
		};
	}

	details() {
		return this.$router.push(this.to);
	}
}

Vue.component('stripe-next-invoice-display', StripeNextInvoiceDisplay);
