import Vue from 'vue';
import Component from 'vue-class-component';
import template from './card-header.html';
import cardService from '@/services/card.service';

const props = {
	cancelLabel : {
		default : 'Cancel',
		type    : String,
	},
};

@Component({
	template,
	props,
})
export class CardHeader extends Vue {
	cancel() {
		this.$emit('cancel');

		return cardService.reject();
	}
}

Vue.component('card-header', CardHeader);
