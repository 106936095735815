import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-overlay-controls.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import localStorage from '@/services/local-storage.service';

const props = {
	map : {
		type     : null,
		required : true,
	},
};

@Component({
	template,
	props,
})
export class MapOverlayControls extends Vue {
	data() {
		return {
			error           : null,
			baseLayer       : this.map.mapType,
			boundaryOverlay : '',
			imagingOverlay  : '',

			imagingOverlayData : {
				field  : null,
				flight : null,
				layer  : null,
				legend : null,
				url    : null,
			},
		};
	}

	created() {
		this.baseLayer = this.map.mapType;
	}

	setBaseLayer(value) {
		this.baseLayer = value;

		this.map.mapType = value;
		localStorage.set('montage.map.map-type', value);
	}

	setBoundaryOverlay(value) {
		this.boundaryOverlay = value;

		this.map.setOverlay(value);
	}

	setImagingOverlay(value) {
		this.imagingOverlay = value;

		if (!this.map.overlays[value]) return;

		this.map.setOverlay(value);
	}

	get layers() {
		return this.map.layers;
	}

	get boundaryOverlays() {
		return Object.keys(this.map.overlays).map(overlay => ({
			label : overlay,
			value : overlay,
		}));
	}

	@Async
	get ceresFields() {
		this.error = null;

		return api.ceresImagingField.list()
			.then(list => list.map(value => ({
				label : `${value.ceresImagingFieldName} (${value.ceresImagingFarmName})`,
				value,
			})))
			.catch(error => { this.error = error });
	}

	@Async(null, 'imagingOverlayData.field')
	get ceresFlights() {
		if (!this.imagingOverlayData.field) return;

		const { ceresImagingField_id } = this.imagingOverlayData.field;

		this.error = null;

		return api.ceresImagingField.flights(ceresImagingField_id)
			.then(list => list.map(value => ({
				label : value.date,
				value,
			})))
			.catch(error => { this.error = error });
	}

	@Async(null, 'imagingOverlayData.flight')
	get ceresLayers() {
		if (!this.imagingOverlayData.flight) return;

		return this.imagingOverlayData.flight.layers.map(value => ({
			label : value.label,
			value,
		}));
	}

	@Async(null, ['imagingOverlayData.field', 'imagingOverlayData.flight'])
	get ceresLegends() {
		if (!this.imagingOverlayData.flight) return;

		const { ceresImaging_id } = this.imagingOverlayData.field;
		const { uuid : flight_id } = this.imagingOverlayData.flight || {};

		return api.ceresImaging.legends({
			ceresImaging_id,
			flight_id,
		})
			.then(list => list.length ? list : api.ceresImaging.legends({
				ceresImaging_id,
			}));
	}

	@Async
	get farm() {
		return api.farm.get();
	}

	@Watch('farm')
	watchFarm() {
		this.map.clearOverlays();

		this.imagingOverlay = '';
		this.imagingOverlayData = {
			field  : null,
			flight : null,
			layer  : null,
			legend : null,
			url    : null,
		};
	}

	@Watch('imagingOverlayData.field')
	watchImagingOverlayField() {
		this.imagingOverlayData.flight = null;
	}

	@Watch('imagingOverlayData.flight')
	watchImagingOverlayFlight() {
		this.imagingOverlayData.layer = null;
	}

	@Watch('imagingOverlayData.layer')
	watchImagingOverlayLayer() {
		if (!this.imagingOverlayData.layer) return;

		this.imagingOverlayData.url = this.imagingOverlayData.layer.url;
		this.imagingOverlay = 'CeresImaging';

		this.map.addOverlay('CeresImaging', this.imagingOverlayData.url);
	}

	@Watch(['ceresLegends', 'imagingOverlayData.layer'])
	watchLegendsAndImagingOverlayLayer() {
		if (!this.ceresLegends || !this.imagingOverlayData.layer) return;

		const { type } = this.imagingOverlayData.layer;

		const legend = this.ceresLegends.find(legend => legend.type === type);

		if (!legend) return;

		this.imagingOverlayData.legend = legend;
	}

	@Watch('imagingOverlayData.legend')
	watchImagingOverlayLegend() {
		this.map.setLegend(this.imagingOverlayData.legend);
	}
}

Vue.component('map-overlay-controls', MapOverlayControls);
