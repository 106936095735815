import Vue from 'vue';
import Component from 'vue-class-component';
import template from './connect-ceres-imaging-field-modal.html';
import Async from '@/plugins/async-decorator';
import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';

const props = {
	integration : {
		type     : Object, // CeresImaging integration record
		required : true,
	},
};

@Component({
	template,
	props,
})
export class ConnectCeresImagingFieldModal extends Vue {
	data() {
		return {
			farm       : null,
			ceresFarm  : null,
			ceresField : null,
		};
	}

	get form() {
		if (!this.farm || !this.ceresFarm || !this.ceresField) return null;

		const { ceresImaging_id } = this.integration;
		const { farm_id } = this.farm;
		const { name : ceresImagingFarmName } = this.ceresFarm;
		const {
			name : ceresImagingFieldName,
			uuid : ceresImagingFieldUuid,
		} = this.ceresField;

		return {
			farm_id,
			ceresImaging_id,
			ceresImagingFarmName,
			ceresImagingFieldName,
			ceresImagingFieldUuid,
		};
	}

	get listeners() {
		return Object.assign({}, this.$listeners, {
			submit : () => null,
		});
	}

	@AsyncLoading
	get farms() {
		return api.farm.list()
			.then(list => list.map(value => ({
				label : value.farmName,
				value,
			})));
	}

	@AsyncLoading
	get ceresFarms() {
		const { ceresImaging_id } = this.integration;

		return api.ceresImaging.farms({ ceresImaging_id })
			.then(list => list.map(value => ({
				label : value.name,
				value,
			})));
	}

	@Async(null, 'ceresFarm')
	get ceresFields() {
		if (!this.ceresFarm) return null;

		const { ceresImaging_id } = this.integration;
		const { uuid } = this.ceresFarm;

		return api.ceresImaging.fields({
			ceresImaging_id,
			uuid,
		})
			.then(list => list.map(value => ({
				label : value.name,
				value,
			})));
	}
}
