import Vue from 'vue';
import Component from 'vue-class-component';
import template from './farm-settings-list.html';
import Async from '@/plugins/async-decorator';
import { checkFarmPrivilege } from '@/services/permission.service';

@Component({ template })
export class FarmSettingsList extends Vue {
	@Async(false)
	get showSettings() {
		return checkFarmPrivilege('update');
	}

	get isDevelopment() {
		return process.env.NODE_ENV === 'development';
	}
}

Vue.component('farm-settings-list', FarmSettingsList);
