const component = () => import(/* webpackChunkName: "developer-test" */'./paginated-table');

export default {
	component,
	path : 'paginated-table',
	name : 'paginated-table-test',
	meta : {
		title  : 'Paginated Table Test',
		secure : false,
	},
};
