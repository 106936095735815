import component from './onboarding-company-information';

export default {
	component,
	path  : 'company-info',
	name  : 'onboarding-company-information',
	props : true,
	meta  : {
		title  : 'Onboarding Company Information',
		secure : false,
	},
};
