import Vue from 'vue';
import Component from 'vue-class-component';
import template from './company-menu.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';
import service from '@/services/modal.service';
import { isDemoUser, checkCompanyPrivilege } from '@/services/permission.service';
import lockout from '@/services/billing-lockout.service';

@Component({ template })
export class CompanyMenu extends Vue {
	data() {
		return {
			show : null,
		};
	}

	@Async
	get company() {
		return api.company.get();
	}

	@Async
	get farm() {
		return api.farm.get();
	}

	get account() {
		return this.$store.getters['billingReplacement/account'];
	}

	get usage() {
		return this.$store.getters['billingReplacement/usage'];
	}

	@Async
	get isDemo() {
		return isDemoUser();
	}

	@Async(false)
	get isAdmin() {
		return checkCompanyPrivilege('delete');
	}

	@Async(false)
	get enabled() {
		return lockout.isAppEnabled();
	}

	@Async(null, 'account')
	get isBillingIssue() {
		return Promise.all([
			lockout.getBillingMessage(),
			lockout.isBillingOkay(),
		])
			.then(([message, okay]) => {
				if (message) return message;
				if (!okay) return 'There is an issue with your billing information';

				return null;
			});
	}

	@Async(false, 'usage', { immediate : true })
	get canAddFarm() {
		return lockout.isAddingOkay('farms');
	}

	addCompany() {
		this.$emit('error');

		return service.launchModal('add-company')
			.then(({ companyName, farmName }) => api.company.create({ companyName })
				.then(() => api.farm.create({ farmName })))
			.catch(error => { this.$emit('error', error) });
	}

	addFarm() {
		this.$emit('error');

		return service.launchModal('add-farm')
			.then(farm => api.farm.create(farm))
			.catch(error => { this.$emit('error', error) });
	}

	selectFarm(farm) {
		return api.company.set(this.company)
			.then(() => api.farm.set(farm));
	}
}

Vue.component('company-menu', CompanyMenu);
