/* eslint-disable id-match, id-length */

import api from './api';
import company from './company';
import lockout from '@/services/billing-lockout.service';

const queryParams = {
	client  : process.env.REPORT_ALL_CLIENT_KEY,
	v       : 5,
	si_srid : 4326,
};

export default {
	get,
	list,
	plist,
	status,

	ParcelQuery : _extend,
};

function get() {
	return company.get()
		.then(company => company ? company.company_id : null)
		.then(company_id => api.read('parcel-query', company_id))
		.then(record => _extend(record));
}

function list(params) {
	return Promise.resolve(company.get())
		.then(company => company ? company.company_id : null)
		.then(company_id => company_id || Promise.reject(new Error('Missing company_id')))
		.then(company_id => api.create('parcel-query', { company_id }))
		.then(() => getParcels(params))
		.then(results => reportUsage(results));
}

function plist(params) {
	// Paginated List, Return Pagination Information in addition to results

	return Promise.resolve(company.get())
		.then(company => company ? company.company_id : null)
		.then(company_id => company_id || Promise.reject(new Error('Missing company_id')))
		.then(company_id => api.create('parcel-query', { company_id }))
		.then(() => getParcelPagination(params))
		.then(results => reportUsage(results));
}

function status() {
	return api.read('parcel-query');
}

function getParcelPagination(params) {
	const url = getParcelUrl(params);

	return new Promise((resolve, reject) => {
		const okay = 200;
		const request = new XMLHttpRequest();

		request.open('GET', url);
		request.onload = () => request.status === okay
			? resolve(request.response)
			: reject(new Error(request.statusText));
		request.onerror = () => reject(new Error('Network error'));
		request.send();
	})
		.then(response => JSON.parse(response))
		.then(response => response.status === 'OK' ? response : Promise.reject(new Error(response.message)))
		.then(response => ({
			results : response.results.map(result => filterParcelProperties(result)),
			page    : response.page,
			pageCap : Math.ceil(response.count / response.rpp),
			total   : response.count,
			query   : params,
		}));
}

function getParcels(params) {
	return getParcelPagination(params)
		.then(response => response.results);
}

function getParcelUrl(options) {
	const baseUrl = 'https://reportallusa.com/api/parcels.php';
	const queryString = serialize(Object.assign({}, queryParams, options));

	return `${baseUrl}?${queryString}`;
}

function filterParcelProperties(apn) {
	const {
		parcel_id      : name,
		county_name    : parcelCounty = '',
		state_abbr     : parcelState = '',
		geom_as_wkt    : rawGeometry = '',
		owner          : parcelOwner = '',
		acreage_calc   : parcelAcreage = '0',
		acreage_deeded : acreageDeeded = '0',
		mkt_val_land   : landMarketValue = '',
		mkt_val_tot    : totalMarketValue = '',
		land_use_class : landUseClass = '',
		usps_residential : uspsResidential = '',
		last_updated   : lastUpdated = '',
		physcity       : parcelCity = '',
		muni_name      : parcelMunicipality = '',
		elevation = '',
		latitude = '',
		longitude = '',
		robust_id = '',
	} = apn || {};

	return {
		rawGeometry,
		properties : {
			name,
			parcelCounty,
			parcelCity,
			parcelOwner,
			parcelState,
			latitude,
			longitude,
			lastUpdated,
			parcelMunicipality,
			robust_id,
			parcelNumber     : name,
			landUseClass     : landUseClass || uspsResidential,
			elevation        : Number(elevation.replace(/[^0-9.]/g, '')),
			landMarketValue  : Number(landMarketValue.replace(/[^0-9.]/g, '')),
			totalMarketValue : Number(totalMarketValue.replace(/[^0-9.]/g, '')),
			acreage          : Number(parcelAcreage.replace(/[^0-9.]/g, '')),
			parcelAcreage    : Number(parcelAcreage.replace(/[^0-9.]/g, '')),
			acreageDeeded    : Number(acreageDeeded.replace(/[^0-9.]/g, '')),
			physicalAddress  : getPhysicalAddress(apn),
			mailingAddress   : getMailingAddress(apn),
			parcelRegion     : getRegion(apn),
		},
	};
}

function getPhysicalAddress(parcel) {
	const address = [
		parcel.addr_number,
		parcel.addr_street_prefix,
		parcel.addr_street_name,
		parcel.addr_street_suffix,
		parcel.addr_street_type,
	].filter(value => Boolean(value))
		.join(' ');

	const city = parcel.physcity;

	const state = [
		parcel.state_abbr,
		parcel.physzip,
	].filter(value => Boolean(value))
		.join(' ');

	return [
		address,
		city,
		state,
	].filter(value => Boolean(value))
		.join(', ');
}

function getMailingAddress(parcel) {
	return [
		parcel.mail_name,
		parcel.mail_address1,
		parcel.mail_address2,
		parcel.mail_address3,
	].filter(value => Boolean(value) && value !== 'None')
		.join(', ');
}

function getRegion(parcel) {
	return [
		parcel.physcity,
		parcel.state_abbr,
	].filter(value => Boolean(value)).join(', ');
}

function _extend(parcelQuery = {}) {
	return Object.assign({

	}, parcelQuery);
}

function serialize(obj) {
	return Object.keys(obj)
		.filter(key => {
			const type = typeof obj[key];

			return type === 'string' || type === 'number' || type === 'boolean';
		})
		.map(key => `${key}=${encodeURIComponent(obj[key])}`)
		.join('&');
}

function reportUsage(value) {
	return Promise.resolve(lockout.reportUsage('clicks'))
		.then(() => value);
}
