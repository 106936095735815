const component = () => import(/* webpackChunkName: "developer-test" */'./ordered-list-test');

export default {
	component,
	path : 'ordered-list',
	name : 'test_ordered-list-test',
	meta : {
		title  : 'Ordered List Test',
		secure : false,
	},
};
