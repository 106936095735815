const component = () => import(/* webpackChunkName: "pages-internal" */'./oauth2');

export default {
	component,
	path : '/oauth2',
	name : 'oauth2',
	meta : {
		title  : 'Oauth2',
		secure : true,
	},
};
