import '@/lib/fullstory.lib';

window.FS.shutdown();

export default {
	reset,
	identify,
};

function reset() {
	window.FS.identify(false);
	window.FS.shutdown();
}

function identify(user) {
	if (!user || !user.user_id) reset();

	window.FS.restart();
	window.FS.identify(user.user_id, {
		displayName : user.name || '',
		email       : user.email || '',
	});
}
