import moment from 'moment';
import pluralize from 'pluralize';
import acreageService from '@/services/acreage.service';
import attributeService from '@/services/attribute.service';
import avatarService from '@/services/avatar.service';
import colorService from '@/services/color.service';
import api from '@/api';
import dsl from '@/services/dsl.service';

////////

export default {
	convertUser,
	convertInvitation,
	convertTeam,
	convertFarm,
	convertField,
	convertMarker,
	convertLine,
};

////////
//  Exported Functions
//

function convertUser(user, actions) {
	if (user.invitation_id) return convertInvitation(user, actions);

	return {
		user,
		key  : user.user_id,
		type : 'user',

		get avatar() {
			return avatarService.generateAvatar({
				color : this.user.color || colorService.generate(this.user.user_id),
				label : this.user.name || this.user.email.toUpperCase(),
			});
		},

		get title() { return this.user.name || this.user.email },
		get subtitle() { return this.user.name && this.user.email },

		actions,
	};
}

function convertInvitation(invitation, actions) {
	return {
		invitation,
		key  : invitation.invitation_id,
		type : 'invitation',

		avatar : {
			color : '#00AEEF',
			icon  : 'user',
		},

		get title() { return this.invitation.email },

		get subtitle() {
			return this.invitation.invitationStatus === 'declined'
				? [
					dsl('User'),
					'declined',
					dsl('invitation'),
				].join(' ')
				: `${dsl('Invited')} ${this.invitation.lastAttemptAt ? moment(this.invitation.lastAttemptAt).fromNow() : 'just now'}`;
		},

		actions,
	};
}

function convertTeam(team, actions) {
	const result = {
		team,
		key  : team.team_id,
		type : 'team',

		avatar : {
			color : '#00AEEF',
			icon  : 'users',
		},

		meta : {
			loading : true,
		},

		get title() { return this.team.teamName },

		get subtitle() {
			if (this.meta.loading) return '...';

			return [
				this.meta.farmCount,
				pluralize(dsl('farms'), this.meta.farmCount),
				'-',
				this.meta.userCount,
				pluralize(dsl('users'), this.meta.userCount),
			].join(' ');
		},

		actions,
	};

	Promise.all([
		api.user.count({ team_id : team.team_id }),
		api.farm.count({ team_id : team.team_id }),
	])
		.then(([
			{ count : userCount },
			{ count : farmCount },
		]) => {
			result.meta = {
				farmCount,
				userCount,
			};
		});

	return Promise.resolve(result);
}

function convertFarm(farm, actions) {
	return Promise.all([
		api.field.count({ farm_id : farm.farm_id }),
		api.marker.count({ farm_id : farm.farm_id }),
		api.line.count({ farm_id : farm.farm_id }),
	])
		.then(([
			{ count : fieldCount },
			{ count : markerCount },
			{ count : lineCount },
		]) => ({
			farm,
			key  : farm.farm_id,
			type : 'farm',

			get title() { return this.farm.farmName },

			subtitle : [
				`${fieldCount} ${pluralize(dsl('fields'), fieldCount)}`,
				`${markerCount} ${pluralize(dsl('markers'), markerCount)}`,
				`${lineCount} ${pluralize(dsl('lines'), lineCount)}`,
			].join(', '),

			actions,
		}));
}

function convertField(field, actions) {
	return Promise.all([
		field.farmName,
		attributeService.getAttribute(/^(apn.?|parcel number)$/i, field.attributes, { field_id : field.field_id }),
		acreageService.getParcelAcreage(field),
		acreageService.getFarmableAcreage(field),
	])
		.then(([
			farmName,
			parcelNumber,
			parcelAcres,
			farmableAcres,
		]) => [{
			prefix : 'on',
			value  : farmName,
		}, {
			prefix : 'APN',
			value  : parcelNumber,
		}, {
			value  : parcelAcres,
			suffix : 'Parcel Acres',
		}, {
			value  : farmableAcres,
			suffix : dsl('Boundary Acres'),
		}].filter(({ value }) => value || value === 0)
			.map(({ prefix = '', value = '', suffix = '' }) => `${prefix} ${value} ${suffix}`.trim())
			.join(', '))
		.then(subtitle => ({
			field,
			key  : field.field_id,
			type : 'field',

			get avatar() {
				return {
					color : this.field.color,
				};
			},

			get title() { return this.field.fieldName },

			subtitle,

			actions,
		}));
}

function convertMarker(marker, actions) {
	if (marker.line_id) return convertLine(marker, actions);

	return {
		marker,
		key  : marker.marker_id,
		type : 'marker',

		get avatar() {
			return {
				color : this.marker.color,
				icon  : this.marker.icon,
			};
		},

		get title() { return this.marker.markerName },
		get subtitle() { return this.marker.farmName ? `${dsl('marker')} on ${this.marker.farmName}` : null },

		actions,
	};
}

function convertLine(line, actions) {
	return {
		line,
		key  : line.line_id,
		type : 'line',

		get avatar() {
			return {
				color : this.line.color,
			};
		},

		get title() { return this.line.lineName },
		get subtitle() { return this.line.farmName ? `${dsl('line')} on ${this.line.farmName}` : null },

		actions,
	};
}
