export default [{
	type       : 'Buried Treasure',
	icon       : 'gem', // treasure-chest
	attributes : [
		'Depth',
		'Value',
		'Location Accuracy',
	],
}, {
	type       : 'Message in a Bottle',
	icon       : 'wine-bottle',
	attributes : [
		'Bottle Volume',
		'Message Text',
	],
}, {
	type       : 'Shipwreck',
	icon       : 'ship',
	attributes : [
		'Depth',
		'Value',
		'Date Lost',
		'Date Recovered',
		'Location Accuracy',
	],
}, {
	type       : 'Pot o\' Gold',
	icon       : 'cloud-rainbow',
	attributes : [
		'Value',
		'Location Accuracy',
	],
}, {
	type       : 'Lost Treasure',
	icon       : 'map-marker-question',
	attributes : [
		'Name',
		'Origin',
		'Last Seen',
		'Location Accuracy',
	],
}, {
	type       : 'Food & Drink Cache',
	icon       : 'burger-soda',
	attributes : [
		'Type',
		'Quantity',
		'Expiration Date',
		'Alcohol',
	],
}, {
	type       : 'Ship Materials',
	icon       : 'pencil-ruler',
	attributes : [
		'Material Type',
		'Quantity',
	],
}, {
	type       : 'Trade Goods',
	icon       : 'sack-dollar',
	attributes : [
		'Type',
		'Quantity',
		'Expiration Date',
		'Total Value',
	],
}, {
	type       : 'Weapons & Tools',
	icon       : 'tools',
	attributes : [
		'Type',
		'Quantity',
		'Total Value',
	],
}, {
	type       : 'other',
	icon       : 'circle',
	attributes : [
		'Type',
	],
}];
