import Vue from 'vue';
import Component from 'vue-class-component';
import template from './daterange-picker-shortcuts.html';

const props = {
	value : {
		type     : Object, // { startDate, endDate }
		required : true,
	},
};

@Component({
	template,
	props,
})
export class DaterangePickerShortcuts extends Vue {
	get isToday() {
		const { endDate } = this.value;

		if (!endDate) return false;

		return this.today.valueOf() === endDate.valueOf();
	}

	get interval() {
		const { startDate, endDate } = this.value;
		const msPerDay = 86400000;

		if (!startDate || !endDate) return 0;

		return Math.round((endDate.getTime() - startDate.getTime()) / msPerDay);
	}

	get today() {
		const date = new Date();

		date.setHours(0, 0, 0, 0);

		return date;
	}

	shiftLeft() {
		const startDate = new Date(this.value.startDate);
		const endDate = new Date(this.value.startDate); // extract the current startDate as the new endDate.

		startDate.setDate(startDate.getDate() - this.interval); // move the startDate back.

		return this.updateDateRange(startDate, endDate);
	}

	shiftRight() {
		const startDate = new Date(this.value.endDate); // extract the current endDate as the new startDate.
		const endDate = new Date(this.value.endDate);

		endDate.setDate(endDate.getDate() + this.interval); // move the endDate forward.

		if (endDate.getTime() > this.today.getTime())
			return this.resetEndDate();

		return this.updateDateRange(startDate, endDate);
	}

	resetEndDate(interval) {
		const endDate = new Date(this.today);
		const startDate = new Date(this.today);

		startDate.setDate(startDate.getDate() - (interval || this.interval));

		return this.updateDateRange(startDate, endDate);
	}

	setInterval(interval) {
		const center = (this.value.endDate.getTime() + this.value.startDate.getTime()) / 2;

		const startDate = new Date(Math.floor(center));
		const endDate = new Date(Math.floor(center));

		startDate.setHours(0, 0, 0, 0);
		endDate.setHours(0, 0, 0, 0);

		startDate.setDate(startDate.getDate() - Math.floor(interval / 2));
		endDate.setDate(endDate.getDate() + Math.ceil(interval / 2));

		if (endDate.getTime() > this.today.getTime())
			return this.resetEndDate(interval);

		return this.updateDateRange(startDate, endDate);
	}

	updateDateRange(startDate, endDate) {
		return this.$emit('input', {
			startDate,
			endDate,
		});
	}
}

Vue.component('daterange-picker-shortcuts', DaterangePickerShortcuts);
