const component = () => import(/* webpackChunkName: "developer-test" */'./table');

export default {
	component,
	path : 'table',
	name : 'table-test',
	meta : {
		title  : 'Table Test',
		secure : false,
	},
};
