import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "developer-test" */'./marker-icon');

export default {
	component,
	path : 'marker-icon',
	name : 'marker-icon-test',
	meta : {
		title  : `${dsl('Marker')} Icon`,
		secure : false,
	},
};
