export default [{
	type       : 'well',
	icon       : 'hand-holding-water',
	attributes : [
		'Year Established',
		'Vendor',
		'Total Depth',
		'Pumping Level',
		'Expected GPM',
		'Horsepower',
	],
}, {
	type       : 'filtration-station',
	icon       : 'th',
	attributes : [
		'Year Established',
		'Vendor',
		'Capacity (GPM)',
		'Horsepower',
		'Hose Type',
		'Emitter Type',
	],
}, {
	type       : 'tree',
	icon       : 'tree-alt',
	attributes : [
		'Year Established',
		'Vendor',
		'Variety',
		'Spacing',
	],
}, {
	type       : 'row-crop',
	icon       : 'leaf',
	attributes : [
		'Year Established',
		'Vendor',
		'Variety',
		'Spacing',
	],
}, {
	type       : 'bird-control',
	icon       : 'dove',
	attributes : [
		'Vendor',
		'Advisor Name',
		'Date Installed',
		'Model Type',
		'Serial #',
		'Range',
	],
}, {
	type       : 'pest-control',
	icon       : 'spider',
	attributes : [
		'Vendor',
		'Advisor Name',
		'Date Installed',
		'Model Type',
		'Serial #',
		'Range',
	],
}, {
	type       : 'shop',
	icon       : 'wrench',
	attributes : [
		'Location',
		'Primary Contact',
	],
}, {
	type       : 'office',
	icon       : 'archive',
	attributes : [
		'Location',
		'Primary Contact',
	],
}, {
	type       : 'other',
	icon       : 'circle',
	attributes : [
		'Type',
	],
}];
