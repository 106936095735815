import Vue from 'vue';
import Component from 'vue-class-component';
import template from './card.html';

@Component({ template })
export class Card extends Vue {
	data() {
		return {
			collapsed : false,
		};
	}

	get label() {
		return this.collapsed ? 'Show More' : 'Show Less';
	}

	toggle() {
		this.collapsed = !this.collapsed;
	}
}

Vue.component('card', Card);
