const component = () => import(/* webpackChunkName: "developer-test" */'./map-marker');

export default {
	component,
	path : 'map-marker',
	name : 'map-marker-test',
	meta : {
		title  : 'Map Marker Test',
		secure : false,
	},
};
