import Vue from 'vue';
import Component from 'vue-class-component';
import template from './cloud-file-icon.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

const props = {
	value : Object,
};

@Component({
	template,
	props,
})
export class CloudFileIcon extends Vue {
	@Async
	get icon() {
		if (!this.value) return null;
		if (this.value._folder) return ['fas', 'fa-folder'];

		return api.fileType.get(this.value.name)
			.then(({ type }) => ({
				archive    : 'archive',
				audio      : 'audio',
				code       : 'code',
				csv        : 'csv',
				excel      : 'excel',
				image      : 'image',
				pdf        : 'pdf',
				powerpoint : 'powerpoint',
				text       : 'alt',
				video      : 'video',
				word       : 'word',
			}[type]))
			.catch(() => null)
			.then(type => ['fal', type ? `fa-file-${type}` : 'fa-file']);
	}
}

Vue.component('cloud-file-icon', CloudFileIcon);
