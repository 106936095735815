/* eslint-disable */
import api from '@/api';

const client = `${process.env.REPORT_ALL_CLIENT_KEY}`;

export default {
	getTileUrl,
	getFeaturesByApn,
	getFeaturesByOwner,
	getFeaturesByAddress,
	getFeaturesAtCoordinate,
	getPaginatedFeatures,
	getParcelsAtCoordinate,
};

export function getTileUrl() {
	return `https://reportallusa.com/dyn/tile.py?map=siteroot/Base_Layers.map&layer=Parcels&mode=tile&tilemode=gmap&tile={x}+{y}+{z}&client=${client}&palette_recolor=255%20255%200`;
}

export function getFeaturesByApn(apns, region, page) {
	if (!Array.isArray(apns)) apns = [apns];

	const queryGroups = groupQueries(apns);

	const promises = queryGroups.map(apns => getFeatures({
		parcel_id : apns,
		region,
		page,
	}));

	return Promise.all(promises)
		.then(featureGroups => [].concat(...featureGroups));
}

export function getFeaturesByOwner(owner, region, page) {
	if (!Array.isArray(owner)) owner = [owner];

	const queryGroups = groupQueries(owner);

	const promises = queryGroups.map(owner => getFeatures({
		owner,
		region,
		page,
	}));

	return Promise.all(promises)
		.then(featureGroups => [].concat(...featureGroups));
}

export function getFeaturesByAddress(address, region, page) {
	return getFeatures({
		address,
		region,
		page,
	});
}

export function getFeaturesAtCoordinate(coordinate) {
	return getFeatures({ spatial_intersect : getWktPoint(coordinate) });
}

export function getParcelsAtCoordinate(coordinate) {
	return getParcels({ spatial_intersect : getWktPoint(coordinate) });
}

function groupQueries(queries) {
	// NOTE: Report All limits their responses to 10 APNs. (Undocumented)
	// AM - 12-21-17
	const size = 10;
	const queryGroups = [];

	for (let index = 0; index < queries.length; index += size)
		queryGroups.push(queries.slice(index, index + size).join(';'));

	return queryGroups;
}

function getWktPoint(coordinate) {
	return `POINT(${coordinate[0]} ${coordinate[1]})`;
}

function getFeatures(params) {
	return getParcels(params)
		.then(parcels => buildFeatures(parcels));
}

export function getPaginatedFeatures(params) {
	return api.parcelQuery.plist(params)
		.then(response => Object.assign(response, { results : buildFeatures(response.results) }));
}

function getParcels(params) {
	return api.parcelQuery.list(params);
}

function buildFeatures(results) {
	return results.map(({
		rawGeometry,
		properties,
	}) => ({
		type       : 'Feature',
		geometry   : parseGeometry(rawGeometry),
		properties,
	}));
}

function parseGeometry(rawGeometry) {
	const sliceIndex = rawGeometry.indexOf('(');
	const rawType = rawGeometry.slice(0, sliceIndex).toLowerCase();
	const prefix = rawType.replace('polygon', '');
	const type = `${prefix}Polygon`.replace(/^\w/, letter => letter.toUpperCase());
	const coordinates = rawGeometry.slice(sliceIndex)
		.replace(/[(), ]/g, character => ({
			'(' : '[',
			')' : ']',
			',' : '],[',
			' ' : ',',
		}[character]));

	if (rawType !== 'multipolygon' && rawType !== 'polygon')
		throw new Error(`ParcelService, unknown type: ${rawType}`);

	return {
		type,
		coordinates : JSON.parse(`[${coordinates}]`),
	};
}
