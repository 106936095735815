const component = () => import(/* webpackChunkName: "developer-test" */'./style');

export default {
	component,
	path : 'style',
	name : 'style-test',
	meta : {
		title  : 'Style Test',
		secure : false,
	},
};
