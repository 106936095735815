const component = () => import(/* webpackChunkName: "settings-core" */'./legal-consent');

export default {
	component,
	path : 'legal-consent',
	name : 'legal-consent',
	meta : {
		title  : 'Legal Consent',
		secure : true,
	},
};
