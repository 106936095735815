import Vue from 'vue';
import Component from 'vue-class-component';
import template from './test-inputs.html';

const props = {
	loading  : Boolean,
	disabled : Boolean,
	required : Boolean,
};

@Component({
	template,
	props,
})
export class TestInputs extends Vue {
	data() {
		return {
			rangeValue : 50,
		};
	}

	get config() {
		return {
			disabled : this.disabled,
			loading  : this.loading,
			required : this.required,
		};
	}
}

Vue.component('test-inputs', TestInputs);
