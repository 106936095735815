import component from './map';

export default {
	component,
	path : '/map',
	name : 'map',
	meta : {
		title       : 'Map',
		secure      : true,
		requireFarm : true,
	},
};
