import Vue from 'vue';
import Component from 'vue-class-component';
import template from './conversation-list.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';
import { isDemoUser } from '@/services/permission.service';

const props = {
	query    : Object,
	readonly : Boolean,
};

@Component({
	template,
	props,
})
export class ConversationList extends Vue {
	data() {
		return {
			error : null,
		};
	}

	@Async
	get farm() {
		return api.farm.get();
	}

	@Async
	get user() {
		return api.user.get();
	}

	@Async(null, ['farm', 'query'])
	get conversations() {
		if (!this.farm || !this.query) return;

		this.error = null;

		return api.conversation.list(this.query)
			.catch(error => { this.error = error });
	}

	select(conversation) {
		return api.conversation.list(this.query)
			.then(conversations => { this.conversations = conversations })
			.catch(error => { this.error = error })
			.then(() => this.$emit('select', conversation));
	}

	@Async
	get isDemo() {
		return isDemoUser();
	}
}

Vue.component('conversation-list', ConversationList);
