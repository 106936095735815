import Vue from 'vue';
import Component from 'vue-class-component';
import template from './password-input.html';
import dsl from '@/services/dsl.service';

const props = {
	value : String,
	label : {
		type    : String,
		default : dsl('Password'),
	},
};

@Component({
	template,
	props,
})
export class PasswordInput extends Vue {}

Vue.component('password-input', PasswordInput);
