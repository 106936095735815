import Vue from 'vue';
import Component from 'vue-class-component';

const props = {
	value : Object,
};

@Component({
	template : '<span>{{ name }}</span>',
	props,
})
export class CloudFileLabel extends Vue {
	get name() {
		return this.value.name || 'Unnamed';
	}
}

Vue.component('cloud-file-label', CloudFileLabel);
