import component from './onboarding-account-information';

export default {
	component,
	path  : 'billing',
	name  : 'onboarding-account-information',
	props : true,
	meta  : {
		title  : 'Onboarding Account Information',
		secure : false,
	},
};
