import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-filters-top-panel.html';
import Watch from '@/plugins/watch-decorator';

const props = {
	value : Object, // { Map, features: [...], filters: [...] }
};

@Component({
	template,
	props,
})
export class MapFiltersTopPanel extends Vue {
	data() {
		return {
			loading : null,
			closing : null,
		};
	}

	@Watch(['loading', 'closing'])
	watchLoadingClosing() {
		if (this.loading) return;

		if (this.closing) this.$emit('close');
	}
}

Vue.component('map-filters-top-panel', MapFiltersTopPanel);
