export default {
	maxFileSize  : 1e9,
	maxFileCount : 1e3,

	ignorePatterns : [
		/^\./, // ignore dot files
		/^~/, // ignore temp files
		/^\$RECYCLE.BIN$/, // ignore trash
		/^s?bin$/, // ignore directories of executables
		/^tmp$/, // ignore directories of temp files
		/\.db$/, // ignore thumbnail caches
		/\.(cab|msix?|ms[mp])$/, // ignore installers
	],
};
