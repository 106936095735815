import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "settings-core" */'./user-settings');

const redirect = { name : 'user-profile' };

import changePassword from './change-password/change-password.route';
import deleteAccount from './delete-account/delete-account.route';
import exportAccount from './export-account/export-account.route';
import legalConsent from './legal-consent/legal-consent.route';
import userProfile from './user-profile/user-profile.route';
import myCompanies from './my-companies/my-companies.route';
import pendingInvitations from './pending-invitations/pending-invitations.route';

const children = [
	pendingInvitations,
	myCompanies,
	changePassword,
	deleteAccount,
	exportAccount,
	legalConsent,
	userProfile,
	{
		path : '*',
		redirect,
	},
];

export default {
	component,
	children,
	redirect,
	path : 'user-settings',
	meta : {
		title          : `${dsl('User')} Settings`,
		secure         : true,
		skipLegal      : true,
		skipOnboarding : true,
	},
};
