import Vue from 'vue';
import moment from 'moment';

export default function daysAgo(value) {
	if (!value) return value;

	return moment(value).fromNow();
}

Vue.filter('days-ago', daysAgo);
