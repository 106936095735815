import api from './api-storage-list';
import farm from './farm';
import service from '@/services/color.service';

export default {
	list,
	count,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	Line : _extend,
};

function list(filters) {
	return filters
		? api.list('line', filters)
			.then(list => list.map(line => _extend(line)))
		: farm.get()
			.then(farm => farm ? farm.farm_id : null)
			.then(farm_id => farm_id ? list({ farm_id }) : []);
}

function count(filters) {
	return filters
		? api.count('line', filters)
		: farm.get()
			.then(farm => farm ? farm.farm_id : null)
			.then(farm_id => farm_id ? count({ farm_id }) : { count : 0 });
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(line_id) {
	return api.read('line', line_id)
		.then(line => _extend(line));
}

function create(line) {
	return Promise.resolve(line.farm_id || farm.get().then(({ farm_id }) => farm_id))
		.then(farm_id => api.create('line', Object.assign(line, { farm_id })));
}

function update(line) {
	return api.update('line', line.line_id, line);
}

function save(line) {
	return line.line_id ? update(line) : create(line);
}

function _delete(line_id) {
	return api.delete('line', line_id);
}

function _extend(line = {}) {
	const color = line.color || '';

	return Object.assign({
		lineName   : '',
		feature_id : line.line_id || null,
	}, line, {
		color : service.migrate(color),
	});
}
