import storage from '@/services/storage.service';

export default {
	push,
	pop,
};

function push({ message, label }) {
	return storage.push('appNotification', {
		message,
		label,
	});
}

function pop() {
	return storage.pop('appNotification');
}
