import Vue from 'vue';
import Component from 'vue-class-component';
import template from './parcel-search-results-side-panel.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';
import events from '@/services/parcel-searchbar-communicator.service';
import AddToMapButton from './add-to-map-button/add-to-map-button';

const components = {
	AddToMapButton,
};

const props = {
	value : Object, // data passed through the panel-container
};

@Component({
	template,
	props,
	components,
})
export class ParcelSearchResultsSidePanel extends Vue {
	data() {
		return {
			selected  : null,
			listeners : null,
		};
	}

	mounted() {
		this.listeners = [
			this.map.on('feature-click', this.onFeatureClick),
		];
	}

	beforeDestroy() {
		this.$emit('clear-results', null);
		events.emit('parcel-searbar.clear-results');

		if (this.listeners) {
			this.listeners.forEach(fn => fn());
			this.listeners = null;
		}
	}

	get map() {
		return this.value.config.map;
	}

	get results() {
		return this.value.results;
	}

	onFeatureClick(click) {
		const selected_id = click.feature && click.feature.getProperty('robust_id');

		if (!selected_id) return;

		const selected = this.results.find(feature => feature.properties.robust_id === selected_id);

		if (selected)
			this.select(selected);
	}

	select(parcel) {
		this.selected = parcel;

		this.$nextTick()
			.then(() => {
				this.map.flyToGeometry(parcel);
			});
	}

	createFeature(parcel) {
		this.$emit('create', parcel);
	}
}

Vue.component('parcel-search-results-side-panel', ParcelSearchResultsSidePanel);
