/* eslint-disable max-lines */
/* eslint id-length: [warn, { exceptions: [x, y, z, _] } ] */

import Map from './classes/Map';
import Circle from './classes/Circle';
import Coordinate from './classes/Coordinate';
import Feature from './classes/Feature';
import Geometry from './classes/Geometry';
import Layer from './classes/Layer';

import copyFeature from './methods/copy-feature';
import emit from './methods/emit-event';
import enableEditingFeature from './methods/enable-editing-feature';
import getBoundsOfFeature from './methods/get-bounds-of-feature';
import getBoundsOfFeatures from './methods/get-bounds-of-features';
import updateFeatureGeometry from './methods/update-feature-geometry';

////

export default {
	Map,
	Circle,
	Coordinate,
	Feature,
	Geometry,
	Layer,

	copyFeature,
	emit,
	enableEditingFeature,
	getBoundsOfFeature,
	getBoundsOfFeatures,
	updateFeatureGeometry,
};

////
