const component = () => import(/* webpackChunkName: "settings-extra" */'./industry');

export default {
	component,
	path : 'industry',
	name : 'industry',
	meta : {
		title  : 'Industry',
		secure : true,
	},
};
