import Vue from 'vue';
import Component from 'vue-class-component';
import template from './select-company-modal.html';
import api from '@/api';

@Component({ template })
export class SelectCompanyModal extends Vue {
	data() {
		return {
			company      : null,
			farm         : null,
			loading      : null,
			shouldCreate : null,
		};
	}

	get listeners() {
		return Object.assign({}, this.$listeners, {
			submit : () => null,
		});
	}

	select({ company, farm }) {
		this.company = company;
		this.farm = farm;
	}

	submit() {
		const company = this.company;
		const farm = this.farm;

		if (!company || !farm || this.loading) return;

		this.loading = true;

		return api.company.set(company)
			.then(() => this.shouldCreate
				? api.farm.create(farm)
				: api.farm.set(farm))
			.then(() => { this.$emit('submit') })
			.finally(() => { this.loading = false });
	}
}
