export default [{
	type       : 'Panel',
	icon       : 'solar-panel',
	attributes : [
		'Vendor',
		'Date Installed',
		'Last Service Date',
	],
}, {
	type       : 'Meter',
	icon       : 'tachometer',
	attributes : [
		'Alias',
		'Meter #',
		'SAID',
		'Last Service Date',
		'Description',
	],
}, {
	type       : 'Equipment Box',
	icon       : 'container-storage',
	attributes : [
	],
}, {
	type       : 'other',
	icon       : 'circle',
	attributes : [
		'Type',
	],
}];
