const component = () => import(/* webpackChunkName: "settings-core" */'./billing-settings-unsubscribe');

export default {
	component,
	path : 'unsubscribe',
	name : 'billing-settings-unsubscribe',
	meta : {
		title           : 'Billing Settings Unsubscribe',
		secure          : true,
		onCompanyChange : { name : 'billing-settings' },
	},
};
