const component = () => import(/* webpackChunkName: "developer-test" */'./checkbox-list');

export default {
	component,
	path : 'checkbox-list',
	name : 'checkbox-list-test',
	meta : {
		title  : 'Checkbox List Test',
		secure : false,
	},
};
