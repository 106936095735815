const component = () => import(/* webpackChunkName: "settings-core" */'./settings');

const redirect = { name : 'user-profile' };

import billingSettings from './billing-settings/billing-settings.route';
import companySettings from './company-settings/company-settings.route';
import developerSettings from './developer-settings/developer-settings.route';
import farmSettings from './farm-settings/farm-settings.route';
import industry from './industry/industry.route';
import userSettings from './user-settings/user-settings.route';

const children = [
	billingSettings,
	companySettings,
	developerSettings,
	farmSettings,
	industry,
	userSettings,
	{
		path : '*',
		redirect,
	},
];

export default {
	children,
	component,
	redirect,
	path : '/settings',
	meta : {
		title       : 'Settings',
		secure      : true,
		demo        : false,
		skipBilling : true,
	},
};
