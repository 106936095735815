import Vue from 'vue';
import Component from 'vue-class-component';
import template from './indeterminate-checkbox-input.html';

const props = {
	value : {
		type : [Boolean, String],
		validator(value) {
			return [
				true,
				false,
				'all',
				'some',
				'mixed',
				'none',
			].includes(value);
		},
	},
};

@Component({
	template,
	props,
})
export class IndeterminateCheckboxInput extends Vue {
	get isAllSelected() {
		return [true, 'all'].includes(this.value);
	}

	get isSomeSelected() {
		return ['mixed', 'some'].includes(this.value);
	}

	input() {
		return this.isAllSelected
			? this.$emit('select-none')
			: this.$emit('select-all');
	}
}

Vue.component('indeterminate-checkbox-input', IndeterminateCheckboxInput);
