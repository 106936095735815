import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-access-key-settings.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';

@Component({ template })
export class WorkOrderAccessKeySettings extends Vue {
	data() {
		return {
			linking : null, // Boolean, whether user is linking to new account
		};
	}

	@AsyncLoading
	get accessKey() {
		return api.workOrder.accessKey.find();
	}

	deleteAccessKey() {
		if (this.loading) return;

		const { workOrderAccessKey_id } = this.accessKey;

		this.loading = true;
		this.error = null;

		api.workOrder.accessKey.delete(workOrderAccessKey_id)
			.then(() => { this.accessKey = null })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('work-order-access-key-settings', WorkOrderAccessKeySettings);
