import Vue from 'vue';
import Component from 'vue-class-component';
import template from './user-menu.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';
import service from '@/services/modal.service';
import { isDemoUser, checkCompanyPrivilege } from '@/services/permission.service';


@Component({ template })
export class UserMenu extends Vue {
	data() {
		return {
			show : null,
		};
	}

	@Async
	get user() {
		return api.user.get();
	}

	@Async
	get isDemo() {
		return isDemoUser();
	}

	@Async(false)
	get isAdmin() {
		return checkCompanyPrivilege('delete');
	}

	launchModal() {
		return service.launchModal('onboarding-progress')
			.then(route => route ? this.$router.push(route) : null)
			.catch(() => null)
			.then(() => { this.show = false });
	}
}

Vue.component('user-menu', UserMenu);
