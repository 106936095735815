import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "developer-test" */'./conversation');

export default {
	component,
	path : 'conversation',
	name : 'conversation-test',
	meta : {
		title  : `${dsl('Conversation')} Test`,
		secure : false,
	},
};
