import Vue from 'vue';
import Component from 'vue-class-component';
import template from './ceres-imaging-integration-list.html';
import Watch from '@/plugins/watch-decorator';

const props = {
	list : Array,
};

@Component({
	template,
	props,
})
export class CeresImagingIntegrationList extends Vue {
	data() {
		return {
			selected : null,
		};
	}

	@Watch('list')
	watchList() {
		this.selected = null;
	}
}

Vue.component('ceres-imaging-integration-list', CeresImagingIntegrationList);
