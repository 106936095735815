import Vue from 'vue';
import Component from 'vue-class-component';
import template from './file-upload.html';
import sort from '@/services/sorting.service';
import config from '@/config/file-upload';

@Component({ template })
export class FileUpload extends Vue {
	data() {
		return {
			files : [],
		};
	}

	get statusMessage() {
		return this.files.length ? [
			this.files[0].name,
			this.files.length > 1 ? `and {{ files.length - 1 }} more` : '',
			'selected.',
		].join(' ')
			: 'No files selected.';
	}

	onChange(input) {
		this.files = Array.from(input)
			.map(file => Object.assign(file, {
				path : `./${file.name}`,
			}))
			.sort((one, two) => sort(one, two, 'path'))
			.slice(0, config.maxFileCount);

		// clearing $refs.input.value also clears the content of input,
		// so it must be done after we call Array.from(input).
		// -CH 2018.12.20
		this.$refs.input.value = null;

		return this.$emit('input', this.files);
	}

	onDrop(files) {
		this.$refs.input.value = null;
		this.files = files;

		return this.$emit('input', files);
	}
}

Vue.component('file-upload', FileUpload);
