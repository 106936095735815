const component = () => import(/* webpackChunkName: "developer-test" */'./print');

export default {
	component,
	path : 'print',
	name : 'print-test',
	meta : {
		title  : 'Print Test',
		secure : false,
	},
};
