import Vue from 'vue';
import Component from 'vue-class-component';
import template from './top-panel-collapse-toggle.html';

const props = {
	collapsed : Boolean,
};

@Component({
	template,
	props,
})
export default class TopPanelCollapseToggle extends Vue {}
