import Vue from 'vue';
import Component from 'vue-class-component';
import template from './side-panel-container.html';
import Watch from '@/plugins/watch-decorator';

const props = {
	value    : null, // panel payload, if any
	panel    : String, // panel name, if any
	autoOpen : Boolean, // reopen when value changes
};

@Component({
	template,
	props,
})
export class SidePanelContainer extends Vue {
	data() {
		return {
			show : true,
		};
	}

	get component() {
		return this.show ? this.panel : null;
	}

	@Watch('value', { deep : false })
	watchValue() {
		if (this.autoOpen) this.show = true;
	}

	@Watch('value.selected', { deep : false })
	watchValueSelected() {
		if (this.value.selected) this.show = true;
	}
}

Vue.component('side-panel-container', SidePanelContainer);
