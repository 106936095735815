const geolocation = navigator.geolocation;

export default {
	get available() {
		return Boolean(geolocation);
	},
	getLocation,
};

export function getLocation() {
	return new Promise((resolve, reject) => {
		if (!geolocation) return reject(new Error(`GeoLocation is not available.`));

		geolocation.getCurrentPosition(resolve, reject, { maximumAge : 2500 });
	});
}
