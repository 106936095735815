import Vue from 'vue';
import Component from 'vue-class-component';
import template from './cloud-storage-browser.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

const props = {
	parent : String, // ['field', 'marker', 'line']
	query  : {
		type     : Object, // api.cloudFolder.list(query)
		required : true,
	},
	readonly : Boolean,
};

@Component({
	template,
	props,
})
export class CloudStorageBrowser extends Vue {
	data() {
		return {
			isLinking : null,
		};
	}

	@Async(null)
	get sources() {
		return api.cloudStorage.list();
	}

	@Async(null, 'query')
	get folders() {
		return api.cloudFolder.list(this.query);
	}

	connectFolder(folder) {
		const folders = this.folders;

		this.isLinking = false;
		this.folders = null;

		return api.cloudFolder.create(Object.assign({}, this.query, folder))
			.then(() => api.cloudFolder.list(this.query))
			.then(folders => { this.folders = folders })
			.catch(error => {
				this.error = error;
				this.folders = folders;
			});
	}
}

Vue.component('cloud-storage-browser', CloudStorageBrowser);
