import Vue from 'vue';
import Component from 'vue-class-component';
import template from './warning-display.html';

const props = {
	warning : [Object, String],
};

@Component({
	template,
	props,
})
export class WarningDisplay extends Vue {
	get message() {
		return typeof this.warning === 'string' ? this.warning : this.warning.message;
	}
}

Vue.component('warning-display', WarningDisplay);
