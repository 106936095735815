import Vue from 'vue';
import Component from 'vue-class-component';
import template from './user-location-button.html';
import geolocation from '@/services/geolocation.service';

const delay = 250;

@Component({ template })
export class UserLocationButton extends Vue {
	data() {
		return {
			locked   : true,
			loading  : false,
			disabled : !geolocation.available,
		};
	}

	created() {
		this.cycleLock();
	}

	click() {
		this.cycleLock();

		this.loading = true;

		return geolocation.getLocation()
			.then(position => {
				const { latitude, longitude, accuracy } = position.coords;
				const latlng = {
					lat : latitude,
					lng : longitude,
					accuracy, // in meters
				};

				return this.$emit('input', latlng);
			})
			.catch(() => { this.disabled = true })
			.finally(() => { this.loading = false });
	}

	cycleLock() {
		this.locked = true;

		setTimeout(() => {
			this.locked = false;
		}, delay);
	}
}

Vue.component('user-location-button', UserLocationButton);
