import Vue from 'vue';
import Component from 'vue-class-component';
import template from './parcel-disclosure.html';

@Component({ template })
export class ParcelDisclosure extends Vue {
	get url() {
		return process.env.DATA_AGGREGATION_DISCLOSURE_URL;
	}
}

Vue.component('parcel-disclosure', ParcelDisclosure);
