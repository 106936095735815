import { MapPrintPreviewRedux as component } from './map-print-preview-redux';

export default {
	component,
	path : '/map/print-preview',
	name : 'map-print-preview-redux',
	meta : {
		title  : false,
		secure : true,
	},
};
