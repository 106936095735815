const component = () => import(/* webpackChunkName: "developer-test" */'./headers');

export default {
	component,
	path : 'headers',
	name : 'headers-test',
	meta : {
		title  : 'Headers Test',
		secure : false,
	},
};
