export default {
	'farm'  : 'association',
	'field' : 'property',

	'crop map'  : 'real-estate map',
	'ranch map' : 'real-estate map',

	'farmable acres' : 'boundary acres',
	'boundary acres' : 'boundary acres',

	'email-placeholder' : 'user@montage.realty',

	'benefits-map-keyword-1' : 'owner',
	'benefits-map-keyword-2' : 'square feet',
	'benefits-map-keyword-3' : 'parcel number',

	'benefits-connection-keyword-1' : '',
	'benefits-connection-keyword-2' : '',

	'onboarding-progress-title'     : 'Onboarding Progress',
	'onboarding-progress-tagline'   : '',
	'onboarding-progress-style'     : 'default',
	'onboarding-progress-farm-icon' : 'home',
};
