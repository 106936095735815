/* eslint-disable max-lines */

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './timeseries-chart.html';
import Watch from '@/plugins/watch-decorator';
import generateConfig from './generate-config';
import service from '@/services/timeseries.service';

const props = {
	device : {
		type     : Object,
		required : true,
	},
};

@Component({
	template,
	props,
})
export class TimeseriesChart extends Vue {
	data() {
		return {
			hasMinMax : null,
			datasets  : null,
			axes      : null,
			chart     : null,
			sensors   : null,
			data      : null,
			dateRange : {
				startDate : null,
				endDate   : null,
			},
			load : {
				sensors : null,
				data    : null,
			},
		};
	}

	mounted() {
		if (this.device) this.loadSensors();
	}

	get id() {
		if (!this._id) this._id = `chart-container-${Date.now()}${Math.random()}`;

		return this._id;
	}

	get checkboxLabels() {
		if (!this.sensors) return [];

		return this.sensors.map(({ sensorName }) => sensorName);
	}

	get checkboxes() {
		if (!this.sensors) return [];

		return this.sensors.map(({ selected }) => selected);
	}

	set checkboxes(checkboxes) {
		checkboxes.forEach((selected, index) => {
			this.sensors[index].selected = selected;
		});
	}

	get loading() {
		return Object.values(this.load).some(loading => loading);
	}

	@Watch(['device'])
	loadSensors() {
		if (!this.device || this.load.sensors) return;

		this.$emit('error');
		this.load.sensors = true;
		this.sensors = null;

		return service.loadSensors(this.device)
			.then(sensors => { this.sensors = sensors })
			.catch(error => { this.$emit('error', error) })
			.finally(() => { this.load.sensors = false });
	}

	@Watch(['device', 'dateRange'])
	loadData() {
		if (!this.device || this.load.data) return;

		this.$emit('error');
		this.load.data = true;
		this.data = null;

		return service.loadData(this.device, this.dateRange)
			.then(data => { this.data = data })
			.catch(error => { this.$emit('error', error) })
			.finally(() => { this.load.data = false });
	}

	@Watch(['data', 'sensors'])
	processData() {
		if (!this.data || !this.sensors) return;

		const data = this.data.map(({ datetime, readings }) => ({
			datetime : roundTime(datetime),
			readings : nanify(readings),
		}))
			.reduce((result, { datetime, readings }) => {
				const last = result[result.length - 1];

				if (!last)
					result.push({
						datetime,
						readings : [readings],
					});

				else if (last.datetime.getTime() === datetime.getTime()) {
					this.hasMinMax = true;
					last.readings.push(readings);
				}

				else
					result.push({
						datetime,
						readings : [readings],
					});

				return result;
			}, [])
			.map(({ datetime, readings }) => ({
				datetime,
				readings : readings.reduce((result, readings) =>
					Object.entries(readings).reduce((result, [name, value]) => Object.assign(result, {
						[name] : typeof result[name] === 'undefined' ? {
							min : value,
							max : value,
							avg : value,
							num : 1,
						} : {
							min : Math.min(result[name].min, value),
							max : Math.max(result[name].max, value),
							avg : result[name].avg + ((value - result[name].avg) / (result[name].num + 1)),
							num : result[name].min + 1,
						},
					}), result), {}),
			}));

		function roundTime(datetime) {
			const date = new Date(datetime);
			const minuteInterval = 15;

			date.setMilliseconds(0);
			date.setSeconds(0);
			date.setMinutes(Math.floor(date.getMinutes() / minuteInterval) * minuteInterval);

			return date;
		}

		function nanify(readings) {
			return Object.entries(readings).reduce((result, [name, value]) => Object.assign(result, {
				[name] : typeof value === 'number' ? value : NaN,
			}), {});
		}

		const sensors = this.sensors.filter(({ selected }) => selected)
			.map(sensor => Object.assign({
				label   : sensor.sensorName,
				yAxisID : (({ prefix, suffix }) => prefix && suffix // eslint-disable-line id-match
					? `${prefix} / ${suffix}`
					: prefix || suffix || 'default')(sensor),
			}, sensor));

		this.datasets = sensors.reduce((result, sensor) => {
			if (this.hasMinMax) {
				result.push({ step : 'before', sensor }); /* upper bound */ // eslint-disable-line object-property-newline
				result.push({ sensor });
				result.push({ step : 'after', sensor }); /* lower bound */ // eslint-disable-line object-property-newline
			}

			else result.push({ sensor });

			return result;
		}, [])
			.map(({ step, sensor }) => Object.assign({
				step,
				data : data.map(({ datetime, readings }) => ({
					t : datetime, // eslint-disable-line id-length
					y : readings[sensor.sensor_id] ? (({ min, max, avg }) => step ? { // eslint-disable-line id-length
						before : max,
						after  : min,
					}[step] : avg)(readings[sensor.sensor_id]) : NaN,
				})),
			}, sensor))
			.map(sensor => Object.assign({
				fill : {
					before : '+2',
					after  : '-2',
				}[sensor.step] || false,
				backgroundColor : `rgba(${sensor.color}, 0.2)`,
				borderColor     : `rgba(${sensor.color}, 1.0)`,
				borderWidth     : 1,
				steppedLine     : sensor.step && 'middle',
			}, this.hasMinMax ? {
				lineTension : 1,

				cubicInterpolationMode : 'monotone',
			} : null, sensor));

		this.axes = sensors.map(({ yAxisID }) => yAxisID) // eslint-disable-line id-match
			.filter((value, index, array) => array.indexOf(value) === index);

		this.injectChart();
	}

	injectChart() {
		document.getElementById(this.id).innerText = ''; // remove existing chart

		const canvas = document.createElement('canvas');

		canvas.setAttribute('height', 1);
		canvas.setAttribute('width', 1);

		const canvasContainer = document.getElementById(this.id);

		canvasContainer.appendChild(canvas);

		const Chart = window.Chart;

		this.chart = new Chart(canvas.getContext('2d'), generateConfig(this));
	}

	clickLegend(event, item) {
		const index = item.datasetIndex;
		const metadata = this.chart.getDatasetMeta(index);
		const dataset = this.chart.data.datasets[index];
		const hidden = metadata.hidden === null ? !dataset.hidden : !metadata.hidden;

		if (this.hasMinMax) {
			this.chart.getDatasetMeta(index - 1).hidden = hidden;
			this.chart.getDatasetMeta(index + 0).hidden = hidden;
			this.chart.getDatasetMeta(index + 1).hidden = hidden;
		}

		else this.chart.getDatasetMeta(index).hidden = hidden;

		return this.chart.update();
	}

	getTooltipLabel(item, data) {
		const precision = 6;
		const value = item.yLabel.toPrecision(precision);
		const {
			label,
			step,
			prefix = '',
			suffix = '',
		} = data.datasets[item.datasetIndex];

		const parenthetical = this.hasMinMax ? {
			before : '(max)',
			after  : '(min)',
		}[step] || '(avg)' : '';

		return `${label} ${parenthetical}: ${prefix} ${value} ${suffix}`.replace(/ +( |:)/g, '$1');
	}
}

Vue.component('timeseries-chart', TimeseriesChart);
