import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "settings-core" */'./my-companies');

export default {
	component,
	path : 'my-companies',
	name : 'my-companies',
	meta : {
		title  : `My ${dsl('Companies')}`,
		secure : true,
	},
};
