import Vue from 'vue';
import Component from 'vue-class-component';
import template from './sidebar-layout.html';

const props = {
};

@Component({
	template,
	props,
})
export class SidebarLayout extends Vue {
	data() {
		return {
		};
	}
}

Vue.component('sidebar-layout', SidebarLayout);
