import localStorage from '@/services/local-storage.service';

export default {
	namespaced : true,

	state : {
		line : null,

		list : null,
		flag : null,
	},

	getters : {
		line(state) {
			if (!state.line)
				state.line = localStorage.get('line');

			return state.line;
		},

		list(state) {
			return flag => !state.flag || state.flag === flag ? state.list : null;
		},
	},

	mutations : {
		set(state, { line }) {
			state.line = line || null;

			localStorage.set('line', state.line);

			if (!state.line) {
				state.list = null;
				state.flag = null;
			}

			return state.line;
		},

		list(state, { list, flag }) {
			if (flag && !/^farm_id=/.test(flag)) return list;

			state.list = list || null;
			state.flag = flag || null;

			return state.list;
		},

		clear(state) {
			state.line = null;
			state.list = null;
			state.flag = null;

			localStorage.set('line', null);

			return null;
		},
	},

	actions : {
		set({ commit }, payload) {
			return Promise.resolve(payload)
				.then(payload => commit('set', payload));
		},

		list({ commit }, payload) {
			return Promise.resolve(payload)
				.then(payload => commit('list', payload));
		},

		clear({ commit }) {
			return Promise.resolve(commit('clear'));
		},
	},
};
