import Vue from 'vue';
import Component from 'vue-class-component';
import template from './color-picker-dropdown.html';

const props = {
	value    : String,
	readonly : Boolean,
};

@Component({
	template,
	props,
})
export class ColorPickerDropdown extends Vue {
	data() {
		return {
			open : false,
		};
	}
}

Vue.component('color-picker-dropdown', ColorPickerDropdown);
