import component from './select-company';

export default {
	component,
	path : '/select-company',
	name : 'select-company',
	meta : {
		title       : 'Select Company',
		secure      : true,
		skipBilling : true,
	},
};
