import Vue from 'vue';
import Component from 'vue-class-component';
import template from './add-marker-tools-list.html';
import markerTypes from '@/config/marker-types';
import properCase from '@/filters/proper-case.filter';

@Component({ template })
export class AddMarkerToolsList extends Vue {
	get types() {
		return markerTypes
			.map(({ icon, type }) => ({
				label : properCase(type.replace('-', ' ')),
				icon,
				type,
			}));
	}
}

Vue.component('add-marker-tools-list', AddMarkerToolsList);
