import api from './api';

export default {
	list,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	CloudFile : _extend,
};

function list(filters) {
	return api.list('cloud-file', filters)
		.then(list => list.map(cloudFile => _extend(cloudFile)));
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(cloudFile_id) {
	return api.read('cloud-file', cloudFile_id)
		.then(cloudFile => _extend(cloudFile));
}

function create(cloudFile) {
	return api.create('cloud-file', cloudFile);
}

function update(cloudFile) {
	return api.update('cloud-file', cloudFile.cloudFile_id, cloudFile);
}

function save(cloudFile) {
	return cloudFile.cloudFile_id ? update(cloudFile) : create(cloudFile);
}

function _delete(cloudFile_id) {
	return api.delete('cloud-file', cloudFile_id);
}

function _extend(cloudFile = {}) {
	return Object.assign({

	}, cloudFile);
}
