import Vue from 'vue';
import Component from 'vue-class-component';
import template from './import-parcels-top-panel.html';
import api from '@/api';
import Watch from '@/plugins/watch-decorator';
import { getFeaturesByApn } from '@/services/report-all-usa.service';


const props = {
	value : Object, // { Map, features }
};

@Component({
	template,
	props,
})
export class ImportParcelsTopPanel extends Vue {
	data() {
		return {
			error      : null,
			loading    : null,
			multiple   : true,
			geometries : null, // Array of GeoJSON object, { geometry, properties, type }
			parcels    : null, // [{ apn, found, selected, editing, loading }]
			collection : null, // Array of Google Map Data Features
			form       : {
				county : null,
				state  : null,
				apns   : null,
			},
		};
	}

	beforeDestroy() {
		if (this.collection) {
			this.map.removeFeatureCollection(this.collection);
			this.collection = null;
		}
	}

	get apn() {
		const apn = this.form.apns && splitApns(this.form.apns)[0];

		this.form.apns = apn;

		return apn;
	}

	set apn(apn) {
		this.form.apns = apn;
	}

	get map() {
		return this.value && this.value.map;
	}

	get selected() {
		if (!this.geometries || !this.parcels) return [];

		const filter = this.parcels.reduce((collection, parcel) => {
			const apn = parcel.apn;
			const selected = parcel.selected;

			collection[apn] = selected;

			return collection;
		}, {});

		return this.geometries.filter(geometry => {
			const apn = geometry.properties.name;

			return filter[apn];
		});
	}

	reset() {
		this.form = {
			county : null,
			state  : null,
			apns   : '',
		};
	}

	edit(parcel) {
		parcel.editing = true;
	}

	search() {
		if (this.loading) return;

		this.error = null;
		this.loading = true;

		const { county, state } = this.form;
		const apns = splitApns(this.form.apns);

		return getFeaturesByApn(apns, `${county}, ${state}`)
			.then(results => { this.geometries = results })
			.then(() => apns.map(apn => [apn, this.isFound(apn)]))
			.then(list => list.map(([apn, found]) => ({
				apn,
				found,
				selected : found,
				editing  : null,
				loading  : null,
			})))
			.then(parcels => { this.parcels = parcels })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	retry(parcel) {
		if (parcel.loading) return;

		this.error = null;

		parcel.loading = true;

		const { county, state } = this.form;
		const apn = parcel.apn;

		return getFeaturesByApn(apn, `${county}, ${state}`)
			.then(results => results[0])
			.then(result => result || Promise.reject())
			.then(geometry => {
				parcel.selected = true;
				parcel.found = true;

				this.geometries.push(geometry);
			})
			.catch(error => { this.error = error })
			.finally(() => {
				parcel.loading = false;
				parcel.editing = false;
			});
	}

	@Watch('geometries', { deep : false })
	@Watch('parcels')
	drawGeometries() {
		if (!this.geometries || !this.parcels) return;

		const features = this.selected;

		this.collection = this.map.replaceFeatureCollection(this.collection, {
			type : 'FeatureCollection',
			features,
		});

		if (features.length)
			this.map.flyToGeometry(this.collection);
	}

	save() {
		if (this.loading) return;

		this.error = null;
		this.loading = true;

		return Promise.resolve(this.selected)
			.then(geometries => geometries.map(geometry => ({
				geometry,
				color      : '#a7cc14',
				fieldName  : geometry.properties.name,
				attributes : [{
					label : 'Parcel Acres',
					value : geometry.properties.acreage,
				}, {
					label : 'Parcel Number',
					value : geometry.properties.name,
				}, {
					label : 'Parcel Owner',
					value : geometry.properties.parcelOwner,
				}, {
					label : 'Physical Address',
					value : geometry.properties.physicalAddress,
				}, {
					label : 'Mailing Address',
					value : geometry.properties.mailingAddress,
				}]
					.filter(({ value }) => value),
			})))
			.then(fields => Promise.all(fields.map(field => api.field.create(field))))
			.then(fields => fields.map(({ fieldName, color, field_id : feature_id, geometry }) => {
				Object.assign(geometry.properties, {
					color,
					feature_id,
					fieldName,
					featureType : 'field',
				});

				api.parcel.create(Object.assign({ field_id : feature_id }, geometry.properties)).catch(() => null);

				this.value.features.push(geometry);

				return geometry;
			}))
			.then(features => this.map.replaceFeatureCollection(this.collection, {
				type : 'FeatureCollection',
				features,
			}))
			.then(() => { this.$emit('close') })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	isFound(apn) {
		return this.geometries.some(geometry => geometry.properties.name === apn);
	}
}

Vue.component('import-parcels-top-panel', ImportParcelsTopPanel);


function splitApns(numbers) {
	return numbers.split(/[,\n]/)
		.map(line => line.trim())
		.filter(line => line)
		.filter((item, index, list) => list.indexOf(item) === index);
}
