import Vue from 'vue';
import Component from 'vue-class-component';
import template from './basic-select.html';
import Watch from '@/plugins/watch-decorator';

const ivalue = '#value';
const props = {
	value       : [String, Array, Number, Object],
	list        : Array,
	multiple    : Boolean,
	disabled    : Boolean,
	loading     : Boolean,
	required    : Boolean,
	placeholder : {
		type    : String,
		default : 'No Selection',
	},
};

@Component({
	template,
	props,
	inheritAttrs : false,
})
export class BasicSelect extends Vue {
	data() {
		return {
			[ivalue] : null,
			timeout  : null,
			dirty    : false,
			open     : false,
			staged   : [],
		};
	}

	get isMobile() {
		if (this.$attrs.native === '')
			return true;

		if ('$isMobile' in this)
			return this.$isMobile;

		return /mobile|android/i.test(navigator.userAgent || navigator.vendor || window.opera);
	}

	get listeners() {
		return Object.assign({}, this.$listeners, {
			input  : () => null,
			change : () => null,
		});
	}

	mounted() {
		this.watchValue();
	}

	@Watch('value')
	watchValue() {
		this[ivalue] = this.value || (this.multiple ? [] : null);
	}

	get ivalue() {
		return this[ivalue];
	}

	set ivalue(newValue) {
		this[ivalue] = newValue;

		this.dirty = true;

		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.$emit('input', newValue);
		}, 20);
	}

	get isEmpty() {
		return this.multiple && Array.isArray(this.ivalue)
			? this.ivalue.length === 0
			: !this.ivalue;
	}

	get emptyLabel() {
		return this.isEmpty
			? this.placeholder
			: 'Clear Selection';
	}

	get showEmpty() {
		return !this.multiple && (!this.required || (this.required && this.isEmpty));
	}

	get selectEl() {
		return this.$refs.select;
	}

	get selected() {
		if (!this.list || !this.ivalue || this.multiple) return null;

		return this.list.find(option => this.optionKey(option) === this.ivalue) || null;
	}

	input() {
		const selected = Array.from(this.selectEl.options)
			.filter(option => option.selected && option.value)
			.map(option => option.value);

		const newValue = this.multiple
			? selected
			: selected[0] || null;

		this.ivalue = newValue;
	}

	optionKey(option) {
		return typeof option === 'string'
			? option
			: option.value;
	}

	optionLabel(option) {
		return typeof option === 'string'
			? option
			: option.label || option.value;
	}

	get selectionLabel() {
		if (this.isEmpty) return null;

		if (this.multiple && Array.isArray(this.ivalue)) {
			if (this.ivalue.length > 1)
				return `${this.ivalue.length} Selected`;

			const key = this.ivalue[0];
			const selected = this.list.find(option => this.optionKey(option) === key);

			return this.optionLabel(selected);
		}

		return this.selected && this.optionLabel(this.selected);
	}

	stage() {
		this.staged = this.ivalue === null
			? []
			: [].concat(this.ivalue);
		this.open = true;
	}

	select(option) {
		const key = option
			? this.optionKey(option)
			: null;

		if (this.multiple)
			this.staged = arrayToggle(this.staged, key);
		else {
			this.staged = key ? [key] : [];
			this.commit();
		}
	}

	revert() {
		this.staged = [];
		this.open = false;
	}

	commit() {
		const selected = this.staged;
		const newValue = this.multiple
			? selected
			: selected[0] || null;

		this.ivalue = newValue;
		this.open = false;
		this.staged = [];
	}


	isStaged(option) {
		const key = this.optionKey(option);

		return this.staged.includes(key);
	}

	isSelected(option) {
		const key = this.optionKey(option);

		if (this.multiple && Array.isArray(this.ivalue))
			return this.ivalue.includes(key);

		return this.ivalue === key;
	}
}

Vue.component('basic-select', BasicSelect);

function arrayToggle(list, value) {
	const index = list.indexOf(value);

	if (index === -1)
		list.push(value);

	else
		list.splice(index, 1);


	return list;
}
