import Vue from 'vue';
import Component from 'vue-class-component';
import template from './add-employee-button.html';

const props = {
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
};

@Component({
	template,
	props,
})
export class AddEmployeeButton extends Vue {}

Vue.component('add-employee-button', AddEmployeeButton);
