import { loadFeatureAttributes } from '@/services/attribute.service';
import { farmableAcresRegExp } from '@/services/farmable-acres.service.js';

export default {
	updateLabels,
};

function test(labelType, value) {
	const re = {
		'APN'            : /^apn|parcel number$/i,
		'Crop'           : /crop/i,
		'Boundary Acres' : farmableAcresRegExp,
		'Owner'          : /owner/i,
		'Parcel Acres'   : /^(apn|parcel) acres$/i,
	}[labelType] || new RegExp(labelType || 'name', 'i');

	return re.test(value);
}

export function updateLabels(map, features, labelType = map.labelType) {
	return Promise.resolve(Object.values(features))
		.then(features => Promise.all(features.map(feature => loadFeatureAttributes(feature))))
		.then(features => features.forEach(feature => {
			const attributes = feature.properties.attributes;
			const attribute = attributes && attributes.find(attribute => test(labelType, attribute.label));
			const label = attribute && attribute.value;
			const mapFeature = map.getFeature(feature.properties.feature_id);

			if (mapFeature) mapFeature.setProperty('label', label);
		}));
}
