import mapService from '../google.lib';
import api from '@/api';

export default context => {
	const _setEvents = context._setEvents;

	const infoWindow = new window.google.maps.InfoWindow({});
	const anchor = new window.google.maps.MVCObject({});
	const infoWindowContent = document.createElement('div');

	infoWindowContent.classList.add('soil-details-info-window');

	return {
		_setEvents() {
			if (_setEvents) _setEvents.call(this);

			window.google.maps.event.addListener(this._map, 'click', event => {
				if (!this.isOverlayVisible('Soil')) return;

				const lat = event.latLng.lat();
				const lng = event.latLng.lng();

				infoWindow.setMap(null);

				api.soilDetails.get({
					lat,
					lng,
				})
					.then(content => {
						const position = new mapService.Coordinate([
							lng,
							lat,
						]);

						anchor.set('position', position);

						infoWindowContent.innerHTML = content.replace(/\s+class="[^"]+"/g, ''); // eslint-disable-line id-match

						const breaks = Array.from(infoWindowContent.querySelectorAll('br'));

						breaks.forEach(node => node.parentNode.removeChild(node));

						const anchors = Array.from(infoWindowContent.querySelectorAll('a'));

						anchors.forEach(node => {
							if (/^\?$|View Similar Data/.test(node.innerText))
								node.parentNode.removeChild(node);

							else {
								const span = document.createElement('span');

								span.innerText = node.innerText;
								node.parentNode.replaceChild(span, node);
							}
						});

						infoWindow.setContent(infoWindowContent);
						infoWindow.open(this._map, anchor);
					});
			});
		},
	};
};
