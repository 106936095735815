/* eslint-disable id-match */

export default {
	trackEvent,
	trackPage,
};

function trackEvent({ action, category, label }) {
	window.gtag('event', action, {
		event_category : category,
		event_label    : label,
	});
}

function trackPage(page) {
	window.gtag('config', process.env.GA_TRACKING_ID, { page_path : page.path });
}
