import Vue from 'vue';
import Component from 'vue-class-component';
import template from './close-menu-icon.html';

@Component({ template })
export class CloseMenuIcon extends Vue {
	get icon() {
		return this.$dsl('close-menu-icon');
	}
}

Vue.component('close-menu-icon', CloseMenuIcon);
