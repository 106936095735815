const component = () => import(/* webpackChunkName: "developer-test" */'./permission-control');

export default {
	component,
	path : 'permission-control',
	name : 'permission-control-test',
	meta : {
		title  : 'Permission Control Test',
		secure : false,
	},
};
