import Vue from 'vue';
import Component from 'vue-class-component';
import template from './numeric-input.html';
import Watch from '@/plugins/watch-decorator';

const props = {
	value : [Number, String],
	min   : [Number, String],
	max   : [Number, String],
};

@Component({
	template,
	props,
})
export class NumericInput extends Vue {
	data() {
		return {
			input    : null,
			minValue : null,
			maxValue : null,
		};
	}

	mounted() {
		if (typeof this.value === 'string') this.$emit('input', Number(this.value));
	}

	get listeners() {
		return Object.assign({}, this.$listeners, {
			input : () => null,
		});
	}

	@Watch.immediate('value')
	watchValue() {
		const value = typeof this.value === 'number' ? `${this.value}` : this.value;

		this.input = typeof value === 'string' ? `${this.value}` : null;
	}

	@Watch.immediate('min')
	watchMin() {
		this.minValue = !this.min && this.min !== 0 ? null : Number(this.min);
	}

	@Watch.immediate('max')
	watchMax() {
		this.maxValue = !this.max && this.max !== 0 ? null : Number(this.max);
	}

	update(input) {
		const value = typeof input === 'string' ? Number(input) : null;

		if (/^[+.-]$/.test(input)) return;

		if (isNaN(value) || !isFinite(value)) return this.reset();

		if (!this.isValid(value)) return;

		this.$emit('input', value);
	}

	isValid(value) {
		if (typeof this.minValue === 'number' && value < this.minValue) return false;

		if (typeof this.maxValue === 'number' && value > this.maxValue) return false;

		return true;
	}

	reset() {
		setTimeout(() => this.watchValue());
	}

	blur() {
		this.reset();
	}
}

Vue.component('numeric-input', NumericInput);
