import Vue from 'vue';
import Component from 'vue-class-component';
import template from './onboarding-account-information.html';
import api from '@/api';
import permissions from '@/services/permission.service';
import lockoutService from '@/services/billing-lockout.service';

const props = {
	next : Array,
};

@Component({
	template,
	props,
})
export default class OnboardingAccountInformation extends Vue {
	data() {
		return {
			loading   : false,
			saving    : false,
			error     : null,
			more_info : false,
			account   : {
				billing_name  : null,
				billing_email : null,
				billing_phone : null,
			},
			trial : null,
		};
	}

	async mounted() {
		this.loading = true;
		if (!await missingAccount())
			this.nextPage();

		this.getTrialInfo();
		this.setFormToUser();
		this.loading = false;
	}

	async getTrialInfo() {
		this.trial = await api.billingReplacement.isTrialEnabled().catch(() => null);
	}

	async setFormToUser() {
		const user = await api.user.get().catch(() => null);

		if (!user) return;

		this.account = {
			billing_name  : user.name,
			billing_email : user.email,
			billing_phone : user.phone,
		};
	}

	submit() {
		if (this.loading || this.saving) return;

		this.saving = true;
		this.error = null;

		return api.billingReplacement.createAccount(this.account)
			.then(() => this.exit())
			.catch(error => {
				this.error = error;
				this.more_info = true;
			})
			.finally(() => {
				this.saving = false;
			});
	}

	exit() {
		if (this.trial)
			return this.nextPage();

		return this.$router.push({
			name   : 'billing-settings-buy-subscription',
			params : {
				next : this.next,
			},
		});
	}

	nextPage() {
		if (!this.next || !this.next.length) return this.$router.push({ name : 'map' });

		return this.$router.push({
			name   : this.next[0],
			params : {
				next : this.next.slice(1),
			},
		});
	}
}

async function missingAccount() { // eslint-disable-line complexity
	try {
		if (!await api.company.get().catch(() => null)) return true;
		const enabled = await lockoutService.isAppEnabled();
		const isAdmin = await permissions.isAdmin();

		if (!enabled || !isAdmin) return false;

		const account = await api.billingReplacement.getAccount();

		return !account || !account.account_id || (account.account_type === 'paid' && !account.stripe_customer_id);
	}
	catch (error) {
		return true;
	}
}
