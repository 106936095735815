import Vue from 'vue';
import Component from 'vue-class-component';
import template from './add-marker-panel.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import { getMarkerIcon } from '@/config/marker-types';

const props = {
	value : Object, // data passed through the panel-container
};

@Component({
	template,
	props,
})
export class AddMarkerPanel extends Vue {
	data() {
		const markerType = this.value.markerType;

		return {
			error      : null,
			loading    : null,
			attributes : [],
			context    : null, // { cancel(), done(), setColor(), setIcon(), acres }
			feature    : null, // Google Map Data Feature
			marker     : {
				color      : '',
				markerName : null,
				icon       : getMarkerIcon({ markerType }),
				markerType,
			},
			formGeometry : {
				lat : null,
				lng : null,
			},
		};
	}

	mounted() {
		this.draw();
	}

	beforeDestroy() {
		this.map.cancelDraw();
	}

	get map() {
		return this.value.map;
	}

	get features() {
		return this.value.features.map(({
			properties : {
				feature_id,
				featureType,
				fieldName,
				markerName,
				lineName,
			},
		}) => ({
			feature_id,
			featureType,
			featureName : fieldName || markerName || lineName,
		}));
	}

	@Watch('marker.color')
	watchMarkerColor() {
		if (this.context)
			this.context.setIcon(this.marker.icon, this.marker.color);
	}

	draw() {
		this.feature = null;

		return this.map.draw('Point')
			.then(feature => { this.feature = feature })
			.then(() => this.edit())
			.catch(() => this.draw());
	}

	drawFromLatLng() {
		const lat = Number.parseFloat(this.formGeometry.lat, 10);
		const lng = Number.parseFloat(this.formGeometry.lng, 10);

		if (!(numBetween(lat, -90, 90) && numBetween(lng, -180, 180))) return;

		this.map.cancelDraw();

		this.feature = this.map.buildFeature({
			geometry : {
				lat,
				lng,
			},
		}, 'Point');

		this.edit();

		this.map.flyToFeature(this.feature);
	}

	edit() {
		return this.map.edit(this.feature, 'Point')
			.then(context => {
				this.context = context;

				context.setIcon(this.marker.icon, this.marker.color);
			});
	}

	save() {
		this.error = null;
		this.loading = true;

		return this.context.done()
			.then(geometry => Object.assign(this.marker, { geometry }))
			.then(marker => api.marker.create(marker))
			.then(marker => Promise.all(this.attributes.map((attribute, index) => api.attribute.create(Object.assign(attribute, {
				marker_id : marker.marker_id,
				order     : index + 1,
			}))))
				.then(() => marker))
			.then(({ markerName, marker_id, geometry }) => {
				geometry.properties.featureType = 'marker';
				geometry.properties.feature_id = marker_id;
				geometry.properties.markerName = markerName;

				this.value.features.push(geometry);

				this.map.replaceFeatureCollection([this.feature], {
					type     : 'FeatureCollection',
					features : [geometry],
				});
			})
			.then(() => { this.$emit('close') })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	validateName(name) {
		const feature = this.features.find(({ featureName }) => featureName === name);

		if (feature) return `A ${this.$dsl(feature.featureType)} named '${name}' already exists.`;
	}
}

Vue.component('add-marker-panel', AddMarkerPanel);

function numBetween(number, min, max) {
	return number >= min && number <= max;
}
