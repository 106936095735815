import api from './api';

export default {
	list,
	count,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	Geometry : _extend,
};

function list(filters) {
	return api.queueList('geometry', filters)
		.then(list => list.map(geometry => _extend(geometry)));
}

function count(filters) {
	return api.count('geometry', filters);
}

function find(filters) {
	return list(filters).then(list => list.length ? list.reduce((result, entry) =>
		new Date(result.updatedAt) > new Date(entry.updatedAt)
			? result
			: entry) : null);
}

function read(geometry_id) {
	return api.queueRead('geometry', geometry_id)
		.then(geometry => _extend(geometry));
}

function create(geometry) {
	return api.create('geometry', geometry);
}

function update(geometry) {
	return api.update('geometry', geometry.geometry_id, geometry);
}

function save(geometry) {
	return geometry.geometry_id ? update(geometry) : create(geometry);
}

function _delete(geometry_id) {
	return api.delete('geometry', geometry_id);
}

function _extend(geometry = {}) {
	return Object.assign({

	}, geometry);
}
