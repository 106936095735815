import Vue from 'vue';
import Component from 'vue-class-component';
import template from './onboarding-company-information.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

const props = {
	next : Array,
};

@Component({
	template,
	props,
})
export default class OnboardingCompanyInformation extends Vue {
	data() {
		return {
			companyName : null,
			farmName    : null,
			error       : null,
			loading     : null,
		};
	}

	@Async
	get user() {
		return api.user.get();
	}

	@Async
	get company() {
		return api.company.get();
	}

	@Async
	get farm() {
		return api.farm.get();
	}

	@Watch('user')
	watchUser() {
		if (!this.user || !this.user.user_id) return this.$router.push({ name : 'login' });
	}

	@Watch('company')
	watchCompany() {
		if (!this.company) return;

		this.companyName = this.company.companyName;
	}

	@Watch('farm')
	watchFarm() {
		if (!this.farm) return;

		this.farmName = this.farm.farmName;

		if (!this.loading)
			this.nextPage();
	}

	skip() {
		this.loading = true;

		return this.$router.push({ name : 'user-profile' });
	}

	submit() {
		const companyName = this.companyName;
		const farmName = this.farmName;

		this.error = null;
		this.loading = true;

		return Promise.resolve(this.company || api.company.create({ companyName }))
			.then(({ company_id }) => api.farm.create({
				company_id,
				farmName,
			}))
			.then(() => this.nextPage())
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	nextPage() {
		if (!this.next || !this.next.length) return this.$router.push({ name : 'onboarding-account-information' });

		return this.$router.push({
			name   : this.next[0],
			params : {
				next : this.next.slice(1),
			},
		});
	}
}
