import Vue from 'vue';
import Component from 'vue-class-component';
import template from './file-browser.html';
import api from '@/api';

const props = {
	sources : Array,
};

@Component({
	template,
	props,
})
export class FileBrowser extends Vue {
	openFile({ file, source, path } = {}) {
		const url = [
			process.env.SERVER_URL,
			'files',
			source.cloudFolder_id,
			...(path || '.').split('/').map(fragment => encodeURIComponent(fragment)),
			encodeURIComponent(file.name),
		].join('/');

		return window.open(url, '_blank');
	}

	deleteSource(source) {
		const index = this.sources.indexOf(source);

		if (index >= 0) this.sources.splice(index, 1);

		return api.cloudFolder.delete(source.cloudFolder_id)
			.catch(error => {
				this.error = error;

				this.sources.splice(index, 0, source);
			});
	}
}

Vue.component('file-browser', FileBrowser);
