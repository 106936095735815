const component = () => import(/* webpackChunkName: "developer-test" */'./icons');

export default {
	component,
	path : 'icons',
	name : 'icons-test',
	meta : {
		title  : 'Icons Test',
		secure : false,
	},
};
