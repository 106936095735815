export default [{
	label       : 'Building Number',
	placeholder : 'Science II',
}, {
	label       : 'Department',
	placeholder : 'Computer Science',
}, {
	label       : 'Department Chair',
	placeholder : 'Dr. Bob Bilson',
}, {
	label       : 'Graduate Coordinate',
	placeholder : 'Dr. Balex Bliu',
}, {
	label       : 'Administrative Coordinate',
	placeholder : 'Bisa Bennis',
}, {
	label       : 'Stupid Assistant',
	placeholder : 'Bad Batcher',
}];
