import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "developer-test" */'./marker-data');

export default {
	component,
	path : 'marker-data',
	name : 'marker-data-test',
	meta : {
		title  : `${dsl('Marker')} Data Test`,
		secure : false,
	},
};
