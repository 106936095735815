import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-filter-form.html';
import MapFilter from '../classes/map-filter.class';
import filterTypes from '../config/filter-types';

const props = {
	value : Object, // { type, operation, value }
};

@Component({
	template,
	props,
})
export default class MapFilterForm extends Vue {
	data() {
		return {
			filter     : this.value ? new MapFilter(this.value) : null,
			filterType : this.value ? filterTypes.find(({ type }) => this.value.type === type) : null,
		};
	}

	get filterTypes() {
		return filterTypes.map(value => ({
			label : value.label,
			value,
		}));
	}

	get filterValue() {
		const filterType = this.filterType;
		const { value } = this.filter;

		if (!filterType.options) return value;

		const { label } = filterType.options.find(option => option.value === value) || {};

		return label || value;
	}

	createMapFilter(filterType) {
		const { type, operation, operations } = filterType;

		this.filterType = filterType;
		this.filter = new MapFilter({
			type,
			operation,
			operations,
		});
	}

	addMapFilter() {
		this.$emit('input', this.filter);

		this.filter = null;
	}
}
