import Vue from 'vue';
import Component from 'vue-class-component';
import template from './pumpsight-source-form.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

const props = {
	value : Object, // a Timeseries Source record
};

@Component({
	template,
	props,
})
export class PumpsightSourceForm extends Vue {
	data() {
		return {
			loading : null,
			status  : null,
			source  : Object.assign({
				get timeseriesSourceName() {
					return this.credentials.username;
				},
				set timeseriesSourceName(name) {
					this.credentials.username = name;
				},
				credentials : {
					username : null,
					password : null,
				},
			}, this.value),
		};
	}

	@Watch('source')
	watchSource() {
		this.status = null;

		this.$emit('input', this.source);
	}

	checkCredentials() {
		this.loading = true;
		this.status = null;

		return api.timeseries.source.preview(this.source)
			.then(() => { this.status = 'success' })
			.catch(error => {
				const forbidden = 403;

				if (!error) return;

				if (error.status === forbidden) this.status = 'failure';

				else this.$emit('error', error);
			})
			.finally(() => { this.loading = false });
	}
}

Vue.component('pumpsight-source-form', PumpsightSourceForm);
