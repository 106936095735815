const component = () => import(/* webpackChunkName: "settings-core" */'./billing-settings-buy-package');

export default {
	component,
	path : 'buy/package',
	name : 'billing-settings-buy-package',
	meta : {
		title           : 'Billing Settings Buy Package',
		secure          : true,
		onCompanyChange : { name : 'billing-settings' },
	},
};
