export default {
	parseFile,
	parseCsv,
	read,
};

export function read(file) {
	return new Promise((resolve, reject) => {
		if (!FileReader)
			return reject(new Error('No window.FileReader found, reading files is unsupported.'));

		const reader = new FileReader();

		reader.onload = () => resolve(reader.result.replace(/\r\n?/g, '\n').replace(/\n+$/, ''));
		reader.onerror = () => reject(reader.error);

		reader.readAsText(file);
	});
}

export function parseFile(file) {
	return read(file)
		.then(content => parseCsv(content));
}

export function parseCsv(text) {
	const [first, ...remaining] = text.split('\n');
	const separator = getSeparator(first);
	const headers = first.split(separator)
		.map(header => unquote(header));

	return {
		headers,
		separator,
		rows : linesToRows(headers, separator, remaining),
	};
}

function getSeparator(line) {
	const tabs = line.match(/\t/g) || '';
	const commas = line.match(/,/g) || '';

	return tabs.length > commas.length
		? '\t'
		: ',';
}

function linesToRows(headers, separator, lines) { // eslint-disable-line max-statements, complexity
	const last = lines.length;
	const results = [];

	let index = 0;
	let line = null;

	while (index < last) {
		line = lines[index];
		index++;

		while (unterminatedString(line) && index < last) {
			line = `${line}\n${lines[index]}`;
			index++;
		}

		results.push(csvRow(headers, separator, line));
	}

	return results;
}

function csvRow(headers, separator, line) { // eslint-disable-line max-statements
	const storage = line.split(separator).reduce((storage, column) => {
		storage.hold.push(column);

		if (storage.isQuoted) {
			storage.parse();
			storage.hold.length = 0;
		}

		return storage;
	}, {
		row  : [],
		hold : [],
		parse() {
			return this.row.push(unquote(this.hold.join(separator)));
		},
		get isQuoted() {
			return !unterminatedString(this.hold.join(separator));
		},
	});

	storage.parse();

	return record(headers, storage.row);
}

function record(headers, line) {
	return headers.reduce((record, key, index) => {
		record[key] = line[index];

		return record;
	}, {});
}

function unquote(value) {
	return value.trim()
		.replace(/^"|"$/g, '')
		.replace(/""/g, '"');
}

function unterminatedString(line) {
	const quotes = line.match(/"/g) || '';

	return quotes.length % 2 !== 0;
}
