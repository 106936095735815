const component = () => import(/* webpackChunkName: "settings-core" */'./billing-settings-invoices');

export default {
	component,
	path : 'invoices',
	name : 'billing-settings-invoices',
	meta : {
		title           : 'Billing Settings Invoices',
		secure          : true,
		onCompanyChange : { name : 'billing-settings' },
	},
};
