import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-sync-location.html';
// import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';

const props = {
	location : {
		type     : Object,
		required : true,
	},
};

@Component({
	template,
	props,
})
export default class WorkOrderSyncLocation extends Vue {
	data() {
		return {
			error   : null,
			loading : null,
		};
	}

	syncLocation() {
		if (this.loading) return;

		this.error = null;
		this.loading = true;

		api.workOrder.sync.syncLocation(this.location.sync)
			.then(() => { this.$emit('reload') })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}
