import component from './session';

import dashboard from './dashboard/dashboard.route';
import map from './map/map.route';
import mapPrintPreviewRedux from './map-print-preview-redux/map-print-preview-redux.route';
import oauth2 from './oauth2/oauth2.route';
import selectCompany from './select-company/select-company.route';
import settings from './settings/settings.route';

const children = [
	dashboard,
	map,
	mapPrintPreviewRedux,
	oauth2,
	selectCompany,
	settings,
];

export default {
	children,
	component,
	path : '/session',
	name : 'session',
	meta : {
		title  : 'Session',
		secure : true,
	},
};
