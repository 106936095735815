export default [{
	label       : 'Parcel Number',
	placeholder : 'Enter the Parcel Number',
	isParcel    : true,
}, {
	label       : 'Property Owner',
	placeholder : 'John Smith',
}, {
	label       : 'Account Number',
	placeholder : '12-34567-89',
}, {
	label       : 'Panel Vendor',
	placeholder : 'Bob\'s Discount Solar Panels',
}, {
	label       : 'Installation Date',
	placeholder : 'Jan 1, 2016',
}];
