import Vue from 'vue';
import Component from 'vue-class-component';
import template from './onboarding-progress-tree.html';
import collegeProgressTree from './config/college';
import defaultProgressTree from './config/default';
import eldritchProgressTree from './config/eldritch';
import piracyProgressTree from './config/piracy';

const props = {
	value : {
		type      : Number, // Progress step, [0..8]
		default   : 0,
		validator : value => value >= 0 && value <= 8,
	},
};

@Component({
	template,
	props,
})
export class OnboardingProgressTree extends Vue {
	data() {
		return {
		};
	}

	get image() {
		return `/assets/images/onboarding-progress/${this.value}.svg`;
	}

	get style() {
		return this.$dsl('onboarding-progress-style');
	}

	get tree() {
		const generator = {
			college  : collegeProgressTree,
			eldritch : eldritchProgressTree,
			piracy   : piracyProgressTree,
		}[this.style] || defaultProgressTree;

		return generator(this.value);
	}
}

Vue.component('onboarding-progress-tree', OnboardingProgressTree);
