import { loadFeatureAttributes } from '@/services/attribute.service';

export default {
	filterFeatures,
	updateVisibleFeatures,
};

export function filterFeatures(features, filters) {
	return Promise.all([
		Object.values(features),
		filters.filter(({ active }) => active),
	])
		.then(([features, activeFilters]) => activeFilters.length
			? Promise.all(features.map(feature => loadFeatureAttributes(feature)))
			: features
		)
		.then(features => features.filter(feature => feature && visible(feature, filters)));
}

export function updateVisibleFeatures(map, features, filters) {
	return Promise.all([
		Object.values(features),
		filters.filter(({ active }) => active),
	])
		.then(([features, activeFilters]) => activeFilters.length
			? Promise.all(features.map(feature => loadFeatureAttributes(feature)))
			: features
		)
		.then(features => features.filter(feature => feature))
		.then(features => features.forEach(feature => {
			const shown = visible(feature, filters);
			const mapFeature = map && map.getFeature(feature.properties.feature_id);

			if (mapFeature)
				mapFeature.setProperty('visible', shown);
			 else
				feature.properties.visible = shown;
		}));
}

function visible(feature, filters) {
	const attributes = feature.properties.attributes || [];
	const type = feature.properties.featureType;
	const name = feature.properties[`${type}Name`];
	const icon = feature.properties.icon;
	const color = feature.properties.color;

	const result = filters.filter(({ active }) => active)
		.map(filter => ({
			type : filter.type,
			pass : [
				{
					label : 'type',
					value : type,
				},
				{
					label : 'name',
					value : name,
				},
				{
					label : 'icon',
					value : icon,
				},
				{
					label : 'color',
					value : color,
				},
				...attributes,
			].some(({
				label,
				value,
			}) => filter.test(label, value)),
		}))
		.reduce((result, { type, pass }) => Object.assign(result, {
			[type] : result[type] || pass,
		}), {});

	return Object.values(result).every(pass => pass);
}
