import Vue from 'vue';
import Component from 'vue-class-component';
import Async from '@/plugins/async-decorator';
import template from './company-list.html';
import api from '@/api';

@Component({
	template,
})
export class CompanyList extends Vue {
	data() {
		return {
			error   : null,
			loading : null,
		};
	}

	@Async
	get list() {
		this.loading = true;

		return Promise.all([api.company.list(), api.company.get()])
			.then(([list, selected]) => list.map(company => ({
				company,
				title      : company.companyName,
				isSelected : selected && selected.company_id === company.company_id,
			})))
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('company-list', CompanyList);
