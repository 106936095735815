export default function generateConfig(context) {
	return {
		type : 'line',
		data : {
			datasets : context.datasets,
		},
		options : {
			maintainAspectRatio : false,

			legend : {
				position : 'bottom',
				onClick  : context.clickLegend,
				labels   : {
					filter : (item, data) => !data.datasets[item.datasetIndex].steppedLine,
				},
			},
			tooltips : {
				mode : 'index',

				callbacks : {
					label : context.getTooltipLabel,
				},
			},
			scales : {
				xAxes : [{
					type : 'time',
				}],
				yAxes : context.axes.map((id, index) => ({
					id,
					// position  : index % 2 ? 'right' : 'left',
					gridLines : {
						drawOnChartArea : index === 0,
					},
					ticks : {
						// beginAtZero : true,
					},
					scaleLabel : {
						display     : true,
						labelString : id,
					},
				})),
			},
			elements : {
				line : {
					tension : 0,
				},
				point : {
					radius    : 0,
					hitRadius : 5,
				},
			},
			hover : {
				animationDuration : 0,
			},
			responsiveAnimationDuration : 0,
		},
	};
}
