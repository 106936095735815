import Vue from 'vue';
import Component from 'vue-class-component';
import template from './template.html';
import { farmableAcresRegExp } from '@/services/farmable-acres.service.js';

const props = {
	features     : Array,
	visibleAttrs : Array,
	farm         : Object,
	company      : Object,
	attribution  : Boolean,
};

@Component({
	template,
	props,
})
export default class PrintDetailsPage extends Vue {
	get fields() {
		if (!this.features) return null;

		return this.features.filter(feature => filterMatch(feature.properties, {
			featureType : 'field',
			visible     : true,
		}))
			.map(feature => {
				const properties = feature.properties;
				const attributes = properties.attributes || [];

				return Object.assign(
					{},
					objectFilter(properties, [
						['field_id', 'id'],
						['feature_id', 'id'],
						['fieldName', 'name'],
						'fieldName',
						'createdAt',
						'color',
					]),
					getAttributes(attributes, [
						{
							label : 'apn',
							re    : /(apn$|parcel number)/i,
						},
						{
							label : 'apnAcreage',
							re    : /(apn|parcel) acre/i,
						},
						{
							label : 'farmableAcreage',
							re    : farmableAcresRegExp,
						},
					]),
					{ attributes : unwindAttributes(attributes) }
				);
			});
	}

	get markers() {
		if (!this.features) return null;

		return this.features.filter(feature => filterMatch(feature.properties, {
			featureType : 'marker',
			visible     : true,
		}))
			.map(feature => {
				const properties = feature.properties;
				const attributes = properties.attributes || [];

				return Object.assign(
					{},
					objectFilter(properties, [
						['marker_id', 'id'],
						['feature_id', 'id'],
						['markerName', 'name'],
						'markerName',
						'markerType',
						'createdAt',
						'color',
						'icon',
					]),
					{ attributes : unwindAttributes(attributes) }
				);
			});
	}

	get lines() {
		if (!this.features) return null;

		return this.features.filter(feature => filterMatch(feature.properties, {
			featureType : 'line',
			visible     : true,
		}))
			.map(feature => {
				const properties = feature.properties;
				const attributes = properties.attributes || [];

				return Object.assign(
					{},
					objectFilter(properties, [
						['line_id', 'id'],
						['feature_id', 'id'],
						['lineName', 'name'],
						'lineName',
						'createdAt',
						'color',
					]),
					{ attributes : unwindAttributes(attributes) }
				);
			});
	}

	getTh(type) {
		let source = null;

		switch (type) {
			case 'markers':
				source = this.markers;
				break;
			case 'lines':
				source = this.lines;
				break;
			case 'fields':
			default:
				source = this.fields;
				break;
		}

		if (!source) return [];

		return this.visibleAttrs ? this.visibleAttrs : unique([].concat(...source.map(item => Object.keys(item.attributes))));
	}

	getTd(type, item) {
		return this.getTh(type).map(prop => item.attributes[prop] || item[prop]);
	}

	getTCol(type, plus = 1) {
		return this.getTh(type).length + plus;
	}

	get totalApnAcres() {
		return this.getTotalAcres('apnAcreage');
	}

	get totalFarmableAcres() {
		return this.getTotalAcres('farmableAcreage');
	}

	getTotalAcres(property) {
		if (!this.fields) return 0;

		return this.fields.reduce((total, field) => total + (Number(field[property]) || 0), 0);
	}
}


function getAttributes(attributes, filter) {
	return filter.reduce((acc, test) => {
		const attribute = attributes.find(attribute => test.re.test(attribute.label));

		if (attribute)
			acc[test.label || attribute.label] = attribute.value;

		return acc;
	}, {});
}

function unwindAttributes(attributes, settings = null) {
	const { labelfn = v => v, valuefn = v => v } = settings || {};

	return attributes.reduce((col, attr) => {
		col[labelfn(attr.label, attr)] = valuefn(attr.value, attr);

		return col;
	}, {});
}

function filterMatch(source, matches) {
	return Object.entries(matches).every(([key, value]) => source[key] === value);
}

function objectFilter(source, filter) {
	if (typeof source !== 'object') return {};
	if (!Array.isArray(filter) || filter.length < 1) return source;

	return filter.reduce((acc, key) => {
		const [from, to] = sett(key);

		if (from in source)
			acc[to] = source[from];

		return acc;
	}, {});

	function sett(key) {
		if (typeof key === 'string')
			return [key, key];

		if (Array.isArray(key) && key.length < 2)
			return [key[0], key[0]];

		return key;
	}
}

function unique(list) {
	const seen = {};

	return list.filter(item => {
		if (seen[item]) return false;

		return seen[item] = true; // eslint-disable-line no-return-assign
	}).sort();
}
