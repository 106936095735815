/* eslint-disable no-unused-vars */
export default class IComm {
	constructor() {
		this.listeners = [];
	}

	close() {
		delete this.listeners;
	}

	on(event, callee) {
		if (!this.listeners) return;
		const key = [event, callee];

		this.listeners.push(key);

		return () => this.off(callee);
	}
	once(event, callee) {
		if (!this.listeners) return;
		const off = () => this.off(callee);
		const key = [event, callee, off];

		this.listeners.push(key);

		return off;
	}
	off(callee) {
		if (!this.listeners) return;
		const index = this.listeners.findIndex(l => l[1] === callee);

		if (index > 0)
			this.listeners.splice(index, 1);
	}

	emit(event, value) {
		if (!this.listeners) return;
		const callers = this.listeners.filter(l => l[0] === event);

		callers.forEach(([_, ...calls]) => {
			calls.forEach(call => {
				call(value);
			});
		});
	}
}
