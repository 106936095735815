import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "pages-external" */'./forgot-password');

const redirect = { name : 'request-password-reset' };

import resetPassword from './reset-password/reset-password.route';
import requestPasswordReset from './request-password-reset/request-password-reset.route';

const children = [
	resetPassword,
	requestPasswordReset,
];

export default {
	component,
	children,
	redirect,
	path : '/forgot-password',
	name : 'forgot-password',
	meta : {
		title : `Forgot ${dsl('Password')}`,
	},
};
