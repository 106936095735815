import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "settings-core" */'./company-farms');

export default {
	component,
	path : 'company-farms',
	name : 'company-farms',
	meta : {
		title  : `${dsl('Company')} ${dsl('Farms')}`,
		secure : true,
	},
};
