const component = () => import(/* webpackChunkName: "settings-core" */'./farm-export');

export default {
	component,
	path : 'farm-export',
	name : 'farm-export',
	meta : {
		title  : 'Farm Export',
		secure : true,
	},
};
