import Vue from 'vue';
import Component from 'vue-class-component';
import template from './stripe-subscription-status-badge.html';

const props = {
	value     : String, // stripe subscription status
	badgeOnly : Boolean,
};

@Component({
	template,
	props,
})
export class StripeSubscriptionStatusBadge extends Vue {
	data() {
		return {
		};
	}

	get status() {
		return this.value || null;
	}

	get type() { // eslint-disable-line complexity
		switch (this.status) {
			case 'active':
			case 'trialing':
				return 'okay';
			case 'incomplete':
			case 'past_due':
				return 'warn';
			case 'unpaid':
			case 'incomplete_expired':
				return 'error';
			case 'canceled':
			case 'inactive':
			default:
				return 'none';
		}
	}

	get icon() {
		return {
			'far'                   : true,
			'fa-fw'                 : true,
			'fa-check-circle'       : this.type === 'okay',
			'fa-info-circle'        : this.type === 'warn',
			'fa-exclamation-circle' : this.type === 'error',
			'fa-times-circle'       : this.type === 'none',
		};
	}

	get iconkey() {
		return Object.entries(this.icon).filter(([icon, enabled]) => enabled).map(([icon]) => icon).join('.');
	}

	get classlist() {
		return [
			`ssb-type-${this.type}`,
			`ssb-status-${this.status}`
		];
	}
}

Vue.component('stripe-subscription-status-badge', StripeSubscriptionStatusBadge);
