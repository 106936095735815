import Vue from 'vue';
import Component from 'vue-class-component';

const props = {
	value : String,
};

@Component({
	template : '<status-bullet v-model="color"></status-bullet>',
	props,
})
export class IntegrationStatusBullet extends Vue {
	get color() {
		return {
			connected    : 'success',
			incomplete   : 'warning',
			disconnected : 'danger',
		}[this.value] || 'grey';
	}
}

Vue.component('integration-status-bullet', IntegrationStatusBullet);
