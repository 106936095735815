import Vue from 'vue';
import Component from 'vue-class-component';
import template from './sort-control.html';

const props = {
	disabled : Boolean,
};

@Component({
	template,
	props,
})
export class SortControl extends Vue {
	move(direction) {
		this.$emit('move', direction);
	}

	down(item, list) {
		const index = list.indexOf(item);

		if (index < 0 || index >= list.length - 1) return [];

		return swapNext(list, index);
	}

	up(item, list) {
		const index = list.indexOf(item);

		if (index <= 0) return [];

		return swapNext(list, index - 1);
	}
}

Vue.component('sort-control', SortControl);

function swapNext(list, index) {
	list[index].order++;
	list[index + 1].order--;

	return list.splice(index, 2, list[index + 1], list[index]);
}
