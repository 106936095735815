import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-overlay-dropdown.html';

const props = {
	map : {
		type     : null,
		required : true,
	},
	position : String, // ['is-top', 'is-bottom']
};

@Component({
	template,
	props,
})
export class MapOverlayDropdown extends Vue {
	data() {
		return {
			baseLayer : this.map.mapType,
			overlay   : '',
		};
	}

	created() {
		this.baseLayer = this.map.mapType;
	}

	setBaseLayer(value) {
		this.baseLayer = value;

		this.map.mapType = value;
	}

	setOverlay(value) {
		this.overlay = value;

		this.map.setOverlay(value);
	}

	get layers() {
		return this.map.layers;
	}

	get overlays() {
		return Object.keys(this.map.overlays).map(overlay => ({
			label : overlay,
			value : overlay,
		}));
	}
}

Vue.component('map-overlay-dropdown', MapOverlayDropdown);
