import Vue from 'vue';
import Component from 'vue-class-component';
import template from './domain-specific-language-dropdown.html';
import storage from '@/services/local-storage.service';

@Component({ template })
export class DomainSpecificLanguageDropdown extends Vue {
	data() {
		return {
			value : storage.get('domain-specific-language') || null,
		};
	}

	get list() {
		const list = [{
			label : 'Default',
			value : null,
		}, {
			label : 'Real Estate',
			value : 'Real Estate',
		}, {
			label : 'Solar',
			value : 'Solar',
		}];

		if (process.env.NODE_ENV === 'development')
			list.push({
				label : 'College',
				value : 'College',
			}, {
				label : 'Piracy',
				value : 'Piracy',
			}, {
				label : 'Eldritch',
				value : 'Eldritch',
			});

		return list;
	}

	changeLanguage(value) {
		storage.set('domain-specific-language', value);
		window.location.reload();
	}
}

Vue.component('domain-specific-language-dropdown', DomainSpecificLanguageDropdown);
