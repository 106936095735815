import Vue from 'vue';
import Component from 'vue-class-component';
import template from './stripe-invoice-status-display.html';

const props = {
	value : String,
};

@Component({
	template,
	props,
})
export class StripeInvoiceStatusDisplay extends Vue {
	get display() {
		return this.value;
	}

	get classlist() {
		return [`invoice-is-${this.display}`];
	}
}

Vue.component('stripe-invoice-status-display', StripeInvoiceStatusDisplay);
