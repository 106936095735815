import Vue from 'vue';
import Component from 'vue-class-component';
import template from './password-reset.html';
import api from '@/api';
import Async from '@/plugins/async-decorator';
import { minPasswordLength } from '@/services/password.service.js';

@Component({ template })
export class PasswordReset extends Vue {
	data() {
		return {
			loading   : false,
			error     : null,
			minLength : minPasswordLength,
			password  : {
				oldPassword : null,
				newPassword : null,
			},
		};
	}

	@Async()
	get user() {
		return api.user.get();
	}

	get disabled() {
		return !this.password.oldPassword && !this.password.newPassword;
	}

	clearError() {
		this.error = null;
	}

	submit() {
		this.loading = true;
		this.error = null;

		this.password.user_id = this.user.user_id;

		return api.password.update(this.password)
			.then(() => {
				this.password = {
					oldPassword : null,
					newPassword : null,
				};
			})
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('password-reset', PasswordReset);
