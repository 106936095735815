const component = () => import(/* webpackChunkName: "developer-test" */'./status-bullet');

export default {
	component,
	path : 'status-bullet',
	name : 'status-bullet-test',
	meta : {
		title  : 'Status Bullet Test',
		secure : false,
	},
};
