const component = () => import(/* webpackChunkName: "developer-test" */'./map-label-dropdown');

export default {
	component,
	path : 'map-label-dropdown',
	name : 'map-label-dropdown-test',
	meta : {
		title  : 'Map Label Dropdown Test',
		secure : false,
	},
};
