const component = () => import(/* webpackChunkName: "settings-core" */'./company-ceres-imaging');
import { showFarmUpsell } from '@/services/permission.service';
import modalService from '@/services/modal.service';

export default {
	component,
	path : 'company-ceres-imaging',
	name : 'company-ceres-imaging',
	meta : {
		title  : 'Company Ceres Imaging',
		secure : true,
	},
	async beforeEnter(to, from, next) {
		if (await showFarmUpsell(true)) {
			modalService.launchModal('access-upsell', {})
				.catch(() => null);

			return next(from.name ? false : 'map');
		}

		return next(true);
	},
};
