import Vue from 'vue';
import Component from 'vue-class-component';
import template from './add-farm-modal.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

@Component({ template })
export class AddFarmModal extends Vue {
	data() {
		return {
			loading : null,
			farm    : {
				farmName : '',
			},
			messages : {
				noSlashes : `${this.$dsl('Farm')} name cannot contain slashes.`,
				duplicate : `A ${this.$dsl('farm')} with that name already exists.`,
			},
		};
	}

	get listeners() {
		return Object.assign({}, this.$listeners, {
			submit : () => null,
		});
	}

	@Async([])
	get farms() {
		this.loading = true;

		return api.farm.list()
			.finally(() => { this.loading = false });
	}

	get names() {
		return this.farms.map(({ farmName }) => farmName);
	}

	validate(value) {
		if (/[/\\]/.test(value)) return this.messages.noSlashes;

		if (this.names.some(name => name.toLowerCase() === value.toLowerCase()))
			return this.messages.duplicate;
	}
}
