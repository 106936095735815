import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-sync-status.html';

const props = {
	value : {
		type     : Object, // { date, sync }
		required : true,
	},
};

@Component({
	template,
	props,
})
export class WorkOrderSyncStatus extends Vue {}

Vue.component('work-order-sync-status', WorkOrderSyncStatus);
