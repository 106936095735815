import Vue from 'vue';
import Component from 'vue-class-component';
import template from './team-folder-list.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

const props = {
	value : Object, // team
};

@Component({
	template,
	props,
})
export class TeamFolderList extends Vue {
	@Async(null, 'value')
	get cloudFolders() {
		return api.cloudFolder.list({ team_id : this.value.team_id })
			.catch(error => { this.error = error });
	}

	linkFolder(folder) {
		const team_id = this.value.team_id;

		return api.cloudFolder.create(Object.assign(folder, { team_id }))
			.then(() => api.cloudFolder.list({ team_id : this.value.team_id }))
			.then(cloudFolders => { this.cloudFolders = cloudFolders })
			.catch(error => { this.error = error });
	}

	unlinkFolder(folder) {
		const index = this.cloudFolders.indexOf(folder);

		return api.cloudFolder.delete(folder.cloudFolder_id)
			.then(() => { this.cloudFolders.splice(index, 1) })
			.catch(error => { this.error = error });
	}
}

Vue.component('team-folder-list', TeamFolderList);
