import Vue from 'vue';
import Component from 'vue-class-component';
import template from './login-form.html';
import api from '@/api';
import sentry from '@/services/sentry.service';

const isDemo = /^demo\./i.test(location.hostname) || process.env.DEPLOYMENT === 'demo';
const delay = 250;

const props = {
	email    : String,
	readonly : Boolean,
};

@Component({
	template,
	props,
})
export class LoginForm extends Vue {
	data() {
		const { context = '' } = this.$route.query;

		return {
			error   : null,
			loading : null,
			form    : isDemo ? {
				email    : `${context}-${process.env.DEMO_EMAIL}`.replace(/^-/, ''),
				password : process.env.DEMO_PASSWORD,
			} : {
				email    : this.email,
				password : null,
			},
		};
	}

	mounted() {
		if (this.isDemo) this.submit();
	}

	get isDemo() {
		return isDemo;
	}

	submit() {
		if (this.loading) return;

		this.error = null;
		this.loading = true;

		return this.login()
			.catch(() => this.login()) // retry once
			.then(() => this.$emit('login'))
			.catch(error => {
				const unauthorized = 401;
				const notFound = 404;

				this.error = processError(error);

				if (error.status === unauthorized || error.status === notFound) return;

				sentry.captureException(error);
			})
			.finally(() => { this.loading = false });
	}

	login() {
		clearTimeout(this.timeout);

		return new Promise(resolve => {
			this.timeout = setTimeout(resolve, delay);
		})
			.then(() => api.session.login(this.form));
	}
}

Vue.component('login-form', LoginForm);

function processError(error) {
	const customStatus = 999;
	const status = error.status || customStatus;
	const tryLater = `Please try again in a few minutes.`;
	const code = `[Code: LF.SF-63${status}]`;
	const unavailableMessage = `Montage is currently unavailable. ${tryLater} ${code}`;
	const defaultMessage = `An unknown error has occurred. ${tryLater} ${code}`;
	const notFoundMessage = 'Invalid credentials. Please try again.';

	return {
		'-1'  : unavailableMessage,
		'401' : notFoundMessage,
		'404' : notFoundMessage,
		'500' : defaultMessage,
	}[status] || defaultMessage;
}
