import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "developer-test" */'./cloud-storage');

export default {
	component,
	path : 'cloud-storage',
	name : 'cloud-storage-test',
	meta : {
		title  : `${dsl('Cloud Storage')} Test`,
		secure : false,
	},
};
