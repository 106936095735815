import Vue from 'vue';
import Component from 'vue-class-component';
import template from './conversation-comment-form.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

const props = {
	conversation : Object,
};

@Component({
	template,
	props,
})
export class ConversationCommentForm extends Vue {
	data() {
		return {
			content : '',
			loading : false,
			error   : null,
		};
	}

	@Async
	get user() {
		return api.user.get();
	}

	submit() {
		const conversation_id = this.conversation.conversation_id;
		const content = this.content;
		const comment = {
			conversation_id,
			content,
			user      : this.user,
			createdAt : Date.now(),
		};

		if (!/\S/.test(content)) {
			this.error = 'Please input a message.';

			return;
		}

		this.content = '';
		this.loading = true;

		return api.comment.save(comment)
			.then(comment => this.$emit('created', comment))
			.catch(() => { this.content = content })
			.finally(() => { this.loading = false });
	}
}

Vue.component('conversation-comment-form', ConversationCommentForm);
