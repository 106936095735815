import Vue from 'vue';
import Component from 'vue-class-component';
import template from './line-list.html';
import Async from '@/plugins/async-decorator';

const props = {
	lines   : Array,
	actions : Array,
};

@Component({
	template,
	props,
})
export class LineList extends Vue {
	@Async(null, 'lines')
	get list() {
		return this.lines.map(value => ({
			value,

			avatar : {
				color : value.color || '#000',
			},

			title : value.lineName,

			actions : this.actions,
		}));
	}
}

Vue.component('line-list', LineList);
