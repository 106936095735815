import Vue from 'vue';
import Component from 'vue-class-component';
import template from './permission-control.html';

const props = {
	value : {
		type     : String,
		required : true,
	},
	allowEdit : Boolean,
};

@Component({
	template,
	props,
})
export class PermissionControl extends Vue {
	get list() {
		return [{
			label : 'No access',
			value : 'none',
		}, {
			label : 'Can view only',
			value : 'view',
		}, {
			label : 'Can add and edit',
			value : 'edit',
		}];
	}

	get icon() {
		return {
			none : 'fa-eye-slash',
			view : 'fa-eye',
			edit : 'fa-pen',
		}[this.value];
	}

	select(access = 'none') {
		this.$emit('input', access);
		this.$emit(access, access);
	}
}

Vue.component('permission-control', PermissionControl);
