import Vue from 'vue';
import Component from 'vue-class-component';
import template from './conversation-comment.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import service from '@/services/color.service';

const props = {
	value : {
		type     : Object,
		required : true,
	},
	compact : Boolean,
};

@Component({
	template,
	props,
})
export class ConversationComment extends Vue {
	data() {
		return {
			style : null,
		};
	}

	mounted() {
		this.style = document.createElement('style');

		this.$el.appendChild(this.style);
	}

	get comment_id() {
		return `comment-${this.value.comment_id}`;
	}

	@Async
	get user() {
		return api.user.get();
	}

	get author() {
		return this.value.user || {};
	}

	get name() {
		return this.author.name || this.author.email;
	}

	get date() {
		return this.value.createdAt || Date.now();
	}

	get title() {
		return this.value.title;
	}

	get message() {
		return this.value.content;
	}

	get content() {
		return this.message.split('\n');
	}

	get isCurrentUser() {
		if (!this.user || !this.author) return;

		return this.user.user_id === this.author.user_id;
	}

	get background() {
		if (this.isCurrentUser) return null;

		const color = this.author.color || service.generate(this.author.user_id);

		return service.lighten(color);
	}

	@Watch(['author', 'user'])
	setStyle() {
		if (!this.user) return;

		const background = this.background;

		if (background) this.style.innerText = `#${this.comment_id}::after { border-bottom-color : ${background} }`;
	}
}

Vue.component('conversation-comment', ConversationComment);
