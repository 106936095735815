import Vue from 'vue';

import toFixed from './to-fixed.filter';

export default function currency(value) {
	if (typeof value !== 'number') return value;
	if (!isFinite(value)) return value;
	if (value < 0) return `-${currency(-1 * value)}`;

	return `$${toFixed(value, 2)}`;
}

Vue.filter('currency', currency);
