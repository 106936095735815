import Vue from 'vue';
import Component from 'vue-class-component';
import template from './onboarding-progress-tiles.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import service from '@/services/onboarding.service';

@Component({ template })
export class OnboardingProgressTiles extends Vue {
	data() {
		return {
			completed : new service.Progress(),
			loading   : null,
		};
	}

	get total() {
		return this.tasks.length;
	}

	get tasks() {
		return service.tasks;
	}

	@Async
	get farm() {
		this.loading = true;

		return api.farm.get()
			.catch(() => ({}));
	}

	@Watch('farm')
	watchFarm() {
		this.loading = true;

		service.updateProgress(this.completed)
			.finally(() => { this.loading = false });
	}

	routeTo(task) {
		const route = this.link(task);
		const tour_id = service.tours[task];

		if (this.$router) this.$router.push(route);

		else this.$emit('route', route);

		if (tour_id) window.Intercom('startTour', tour_id); // eslint-disable-line new-cap
	}

	icon(task) {
		return [
			this.completed[task] ? 'fas' : 'far',
			`${service.icons[task]}`,
		];
	}

	link(task) {
		return service.links[task];
	}

	label(task) {
		return service.labels[task];
	}

	style(task) {
		return {
			completed : this.completed[task],
		};
	}
}

Vue.component('onboarding-progress-tiles', OnboardingProgressTiles);
