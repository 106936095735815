import Vue from 'vue';
import Component from 'vue-class-component';
import template from './editable-header.html';
import Watch from '@/plugins/watch-decorator';

const props = {
	value    : String,
	readonly : Boolean,
	validate : Function,
};

@Component({
	template,
	props,
})
export class EditableHeader extends Vue {
	data() {
		return {
			editing : false,
			input   : this.value,
		};
	}

	@Watch('editing')
	watchEditing() {
		if (this.editing) this.$refs.input.focus();
	}

	edit() {
		if (this.readonly) return;

		this.input = this.value;
		this.editing = true;
	}

	save() {
		this.editing = false;
		this.$emit('input', this.input);
	}

	cancel() {
		this.editing = false;
	}
}

Vue.component('editable-header', EditableHeader);
