import Vue from 'vue';
import Component from 'vue-class-component';
import template from './ghetto-table.html';

const props = {
	value : {
		type     : Object, // the object to turn into a stupid table
		required : true,
	},
};

@Component({
	template,
	props,
})
export class GhettoTable extends Vue {
	data() {
		return {
			list : Object.entries(this.value),
		};
	}
}

Vue.component('ghetto-table', GhettoTable);
