import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-sync-button.html';

const props = {
	value : {
		type     : Object, // { date, sync }
		required : true,
	},
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
};

@Component({
	template,
	props,
})
export class WorkOrderSyncButton extends Vue {}

Vue.component('work-order-sync-button', WorkOrderSyncButton);
