import api from './api';
import farm from './farm';
import user from './user';

export default {
	list,
	count,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	Comment : _extend,
};

function list(filters) {
	return filters
		? api.list('comment', filters)
			.then(list => list.map(comment => addUser(comment)))
			.then(promises => Promise.all(promises))
			.then(list => list.map(comment => _extend(comment)))
		: farm.get()
			.then(farm => farm ? farm.farm_id : null)
			.then(farm_id => farm_id ? list({ farm_id }) : []);
}

function count(filters) {
	return filters
		? api.count('comment', filters)
		: farm.get()
			.then(farm => farm ? farm.farm_id : null)
			.then(farm_id => farm_id ? count({ farm_id }) : { count : 0 });
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(comment_id) {
	return api.read('comment', comment_id)
		.then(comment => addUser(comment))
		.then(promises => Promise.all(promises))
		.then(comment => _extend(comment));
}

function create(comment) {
	return Promise.resolve(comment.farm_id || farm.get().then(({ farm_id }) => farm_id))
		.then(farm_id => api.create('comment', Object.assign(comment, { farm_id })));
}

function update(comment) {
	return api.update('comment', comment.comment_id, comment);
}

function save(comment) {
	return comment.comment_id ? update(comment) : create(comment);
}

function _delete(comment_id) {
	return api.delete('comment', comment_id);
}

function _extend(comment = {}) {
	return Object.assign({

	}, comment);
}

function addUser(comment) {
	if (!comment || comment.user) return comment;

	return Promise.resolve(comment.user__comments && comment.user__comments[0])
		.then(user => user ? user.user_id : null)
		.then(user_id => user_id ? user.read(user_id) : null)
		.then(user => Object.assign(comment, { user }));
}
