import Vue from 'vue';
import Component from 'vue-class-component';
import template from './field-default-attributes.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import fieldAttributes from '@/config/field-attributes';
import { farmableAcresLabel, farmableAcresRegExp } from '@/services/farmable-acres.service.js';

const props = {
	value       : Object, // the field record
	acreage     : [String, Number],
	readonly    : Boolean,
	showParcel  : Boolean, // Show parcel attributes with other defaults
	showDefault : Boolean,
};

@Component({
	template,
	props,
})
export class FieldDefaultAttributes extends Vue {
	data() {
		return {
			error       : null,
			attributes  : null,
			deleteQueue : [],
		};
	}

	mounted() {
		if (!this.value) this.populateAttributes();
	}

	@Async([])
	get list() {
		if (!this.value) return;

		const { field_id } = this.value;

		return api.attribute.list({ field_id })
			.catch(error => { this.error = error });
	}

	@Watch('list')
	populateAttributes() {
		if (this.attributes || !this.list) return;

		if (this.showDefault) {
			const labels = this.list.map(({ label }) => label);

			this.attributes = [
				...this.list,
				...this.defaults.filter(label => !labels.includes(label))
					.map(label => ({
						label,
						value : '',
					})),
			];
		}
		else
			this.attributes = [
				...this.list,
			];
	}

	@Watch('acreage')
	updateFarmableAcres() {
		const attributes = this.attributes || [];
		const attribute = attributes.find(({ label }) => farmableAcresRegExp.test(label));
		const value = this.acreage;

		if (attribute && value)
			return Object.assign(attribute, {
				value,
				label : farmableAcresLabel,
			});
	}

	@Watch('attributes')
	watchAttributes() {
		this.$emit('input', this.attributes.filter(({ label, value }) => label && value));
	}

	get placeholder() {
		return {
			label : 'Label (e.g. Crop)',
			value : 'Description (e.g. Corn)',
		};
	}

	get defaults() {
		return fieldAttributes
			.filter(({ isParcel }) => this.showParcel || !isParcel)
			.map(({ label }) => label);
	}

	create(attribute) {
		return this.attributes.push(attribute);
	}

	remove(attribute) {
		const index = this.attributes.findIndex(item => item === attribute);

		if (index === -1) return;

		this.deleteQueue.splice(-1, 0, ...this.attributes.splice(index, 1));
	}
}

Vue.component('field-default-attributes', FieldDefaultAttributes);
