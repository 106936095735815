import api from '@/api';
import geojsonService from '@/services/geojson.service';
import geometryService from '@/services/geometry.service';
import attrService from '@/services/attribute.service';

export default async function fetchFeatures(emitter) {
	let stop = false;
	let total = null;
	let completed = 0;

	listen(emitter, 'stop', () => {
		stop = true;
	});

	try {
		return taskList(
			() => stop,
			collectArray(() => fetch_features('field')),
			collectArray(() => fetch_features('marker')),
			collectArray(() => fetch_features('line')),
			lists => [].concat(...lists),
			list => {
				total = list.length;
				emit(emitter, 'progress', [0, total]);

				return list;
			},
			list => asyncForChunks(list, feature => taskList(
				() => stop,
				feature,
				feature => geometryService.assignGeometry(feature),
				feature => geojsonService.getGeoJson(feature),
				feature => attrService.loadFeatureAttributes(feature),
				feature => {
					if (!('visible' in feature.properties))
						feature.properties.visible = true;

					return feature;
				},
				feature => {
					emit(emitter, 'progress', [++completed, total]);

					return feature;
				}
			))
		);
	}
	catch (error) {
		if (error)
			throw error;
	}
}

function emit(emitter, event, payload) {
	if (!emitter) return;
	if ('emit' in emitter) return emitter.emit(event, payload);
	if (typeof emitter === 'function') return emitter(event, payload);
}

function listen(emitter, event, caller) {
	if (typeof emitter === 'object' && 'once' in emitter)
		return emitter.once(event, caller);
}

/* Tasks */

async function fetch_features(type) {
	return api[type].list()
		.then(fs => fs.map(f => Object.assign({
			featureType : type,
		}, f)));
}

function collectArray(caller) {
	return async arr => {
		if (!arr) arr = [];

		arr.push(await caller());

		return arr;
	};
}

/* Helpers */

async function asyncForChunks(source, onEach = null) {
	const list = await source;
	const chunkCount = 6;

	let result = [];
	let i = 0;

	while (i < list.length) {
		const group = new Array(Math.min(chunkCount, list.length - i))
			.fill(null)
			.map((_, j) => [list[i + j], i + j]); // eslint-disable-line no-loop-func

		result = result.concat(await Promise.all(group.map(([item, i]) => onEach(item, i, list))));

		i += chunkCount;
	}

	return result;
}

async function taskList(stopSignal, ...list) {
	const tasks = list.length === 1 && Array.isArray(list[0])
		? list[0]
		: list;

	let value = null;

	for (const task of tasks) {
		if (await stopSignal()) throw null; // eslint-disable-line no-throw-literal
		if (typeof task === 'function')
			value = await task(value);
		else
			value = await task;
	}

	return value;
}
