const component = () => import(/* webpackChunkName: "settings-core" */'./billing-settings-wallet');

export default {
	component,
	path : 'wallet',
	name : 'billing-settings-wallet',
	meta : {
		title           : 'Billing Settings Wallet',
		secure          : true,
		onCompanyChange : { name : 'billing-settings' },
	},
};
