import Vue from 'vue';
import Component from 'vue-class-component';
import template from './side-panel-layout.html';
import SidePanelHeader from './side-panel-header/side-panel-header';

const components = {
	SidePanelHeader,
};

const props = {
	value : null, // panel payload, if any
	panel : String, // panel name, if any
};

@Component({
	template,
	components,
	props,
})
export class SidePanelLayout extends Vue {
	get listeners() {
		return Object.assign({}, this.$listeners, {
			close : () => null,
		});
	}
}

Vue.component('side-panel-layout', SidePanelLayout);
