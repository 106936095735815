import Vue from 'vue';

export default function stripePrice(value, currency = 'usd', style = 'minus') {
	if (!value && value !== 0) return value;
	const number = Number.parseInt(value, 10) / 100;
	const formatted = Intl.NumberFormat('en-us', { style: 'currency', currency: currency || 'usd' }).format(Math.abs(number)); // eslint-disable-line

	return stylePrice(formatted, style || 'minus', number < 0);
}

Vue.filter('stripe-price', stripePrice);


function stylePrice(price, style, neg) {
	switch (`${style}-${neg ? 'neg' : 'pos'}`) {
		case 'paren-neg':
			return `(${price})`;
		case 'minus-neg':
			return `-${price}`;
		default:
			return price;
	}
}
