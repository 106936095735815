import Vue from 'vue';
import Component from 'vue-class-component';
import template from './dropdown-layout.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

const props = {
	value    : Boolean, // true if open
	isAbove  : Boolean, // dropdown should open above the trigger instead of below
	isCenter : Boolean, // dropdown should open centered above/below the trigger
	isRight  : Boolean, // dropdown should align to the right instead of left
};

@Component({
	template,
	props,
})
export class DropdownLayout extends Vue {
	data() {
		return {
			show : this.value || null,
		};
	}

	created() {
		this.unlisten = this.$router.afterEach(() => {
			this.show = false;
		});
	}

	destroy() {
		if (this.unlisten) this.unlisten();
	}

	get className() {
		return {
			'dropdown-above'  : this.isAbove,
			'dropdown-center' : this.isCenter,
			'dropdown-right'  : this.isRight,
		};
	}

	@Async
	get farm() {
		return api.farm.get();
	}

	@Watch('farm')
	watchFarm() {
		this.show = false;
	}

	@Watch('value')
	watchValue() {
		this.show = this.value;
	}

	@Watch('show')
	watchShow() {
		this.$emit('input', this.show);
	}
}

Vue.component('dropdown-layout', DropdownLayout);
