import Vue from 'vue';
import Component from 'vue-class-component';
import template from './ghetto-checklist.html';

const props = {
	value : Array,

	keyProp : [String, Function],
	name    : [String, Function],
};

@Component({
	template,
	props,
})
export class GhettoChecklist extends Vue {
	click(item) {
		item.selected = !item.selected;

		this.$emit('input', this.value);
	}

	key(item) {
		return typeof this.keyProp === 'function' ? this.keyProp(item) : item[this.keyProp] || item.key;
	}

	label(item) {
		return typeof this.name === 'function' ? this.name(item) : item[this.name] || item.name || item.label;
	}
}

Vue.component('ghetto-checklist', GhettoChecklist);
