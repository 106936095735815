import Vue from 'vue';
import Component from 'vue-class-component';
import template from './nav-header.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';
import { isDemoUser, checkFarmPrivilege } from '@/services/permission.service';


@Component({ template })
export class NavHeader extends Vue {
	@Async
	get hasFarm() {
		return api.farm.get();
	}

	@Async
	get isDemoUser() {
		return isDemoUser();
	}

	@Async(false)
	get canCreate() {
		return checkFarmPrivilege('create')
			.then(okay => okay && !this.isDemoUser);
	}

	routeIs(snippet) {
		return this.$route.matched.some(({ name = '' }) => name.indexOf(snippet) >= 0);
	}
}

Vue.component('nav-header', NavHeader);
