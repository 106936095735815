import api from './api';

export default {
	getLocation,
};

const expirationDuration = 3600000; // 3600000 is one hour

let cache = {};

export function getLocation() {
	if (!cache.promise || cache.expires < Date.now()) cache = {
		promise : api.read('ip-location'),
		expires : Date.now() + expirationDuration,
	};

	return cache.promise;
}
