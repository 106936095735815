import Vue from 'vue';
import Component from 'vue-class-component';
import template from './email-input.html';

const props = {
	value : String,
};

@Component({
	template,
	props,
})
export class EmailInput extends Vue {}

Vue.component('email-input', EmailInput);
