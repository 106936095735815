import Vue from 'vue';
import Component from 'vue-class-component';
import template from './link-data-device-button.html';
import Async from '@/plugins/async-decorator';
import { checkFarmPrivilege, isDemoUser } from '@/services/permission.service';

const props = {
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
	compact  : Boolean, // show just the button and not the well and text
};

@Component({
	template,
	props,
})
export class LinkDataDeviceButton extends Vue {
	@Async(false)
	get isDemoUser() {
		return isDemoUser();
	}

	@Async(false)
	get isAllowed() {
		return checkFarmPrivilege('delete')
			.then(allowed => allowed && !this.isDemoUser);
	}
}

Vue.component('link-data-device-button', LinkDataDeviceButton);
