import Vue from 'vue';
import Component from 'vue-class-component';
import template from './edit-marker-top-panel.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

const props = {
	value : Object, // data passed through the panel-container
};

@Component({
	template,
	props,
})
export class EditMarkerTopPanel extends Vue {
	data() {
		return {
			error   : null,
			loading : null,
			context : null, // { cancel(), done(), setColor(), setIcon(), acres }
			marker  : Object.assign({}, this.value.marker),
		};
	}

	mounted() {
		const { feature_id } = this.marker;

		this.map.edit(feature_id, 'Point')
			.then(context => { this.context = context });
	}

	beforeDestroy() {
		this.context.cancel();
	}

	get map() {
		return this.value.map;
	}

	get feature_id() {
		return this.marker.feature_id;
	}

	get feature() {
		return this.map.getFeature(this.feature_id);
	}

	get features() {
		return this.value.features.map(({
			properties : {
				feature_id,
				featureType,
				fieldName,
				markerName,
				lineName,
			},
		}) => ({
			feature_id,
			featureType,
			featureName : fieldName || markerName || lineName,
		}));
	}

	@Watch('marker.color')
	watchFieldColor() {
		if (this.context) this.context.setColor(this.marker.color);
	}

	save() {
		this.error = null;
		this.loading = true;

		return this.context.done()
			.then(geometry => Object.assign(this.marker, { geometry }))
			.then(marker => api.marker.update(marker))
			.then(marker => Object.assign(this.value.marker, marker))
			.then(({ markerName }) => this.feature.setProperty('markerName', markerName))
			.then(() => { this.$emit('close') })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	validateName(name) {
		const feature = this.features.find(({ featureName }) => featureName === name);

		if (feature) return `A ${this.$dsl(feature.featureType)} named '${name}' already exists.`;
	}
}

Vue.component('edit-marker-top-panel', EditMarkerTopPanel);
