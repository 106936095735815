import Vue from 'vue';
import Component from 'vue-class-component';
import template from './connect-ceres-imaging-farm-modal.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

const props = {
	integration : {
		type     : Object, // Ceres Imaging integration record
		required : true,
	},
};

@Component({
	template,
	props,
})
export class ConnectCeresImagingFarmModal extends Vue {
	data() {
		return {
			farm      : null,
			ceresFarm : null,
		};
	}

	get form() {
		if (!this.farm || !this.ceresFarm) return null;

		const { ceresImaging_id } = this.integration;
		const { farm_id } = this.farm;
		const { name, uuid } = this.ceresFarm;

		return {
			farm_id,
			ceresImaging_id,
			ceresImagingFarmName : name,
			ceresImagingFarmUuid : uuid,
		};
	}

	get listeners() {
		return Object.assign({}, this.$listeners, {
			submit : () => null,
		});
	}

	@Async
	get farms() {
		return api.farm.list()
			.then(list => list.map(value => ({
				label : value.farmName,
				value,
			})));
	}

	@Async
	get ceresFarms() {
		const { ceresImaging_id } = this.integration;

		return api.ceresImaging.farms({ ceresImaging_id })
			.then(list => list.map(value => ({
				label : value.name,
				value,
			})));
	}
}
