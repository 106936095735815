import api from '@/api';

////////

export default {
	assignGeometries,
	assignGeometry,
	getCenter,
};

////////

function assignGeometries(list) {
	return Promise.all(list.map(feature => assignGeometry(feature)));
}

function assignGeometry(feature) {
	const key = Object.keys(feature).find(key => /_geometries$/.test(key));
	const geometry = feature[key] && feature[key][0];

	if (!geometry) return Promise.reject(new Error(`No geometry found`));

	return Promise.resolve(geometry)
		.then(({ geometry_id, geometry }) => geometry || api.geometry.read(geometry_id))
		.then(geometry => Object.assign(feature, { geometry }));
}

function getCenter(feature) {
	const { geometry } = feature.geometry;
	const { coordinates, type } = geometry;

	const method = {
		Polygon,
		MultiPolygon,
	}[type] || (() => null);

	return method(coordinates);

	function Polygon(polygon) {
		return reduce(polygon);
	}

	function MultiPolygon(multiPolygon) {
		return reduce(multiPolygon.map(polygon => [reduce(polygon)]));
	}

	function reduce(polygon) {
		const [
			lngSine,
			lngCosine,
			latSine,
			latCosine,
		] = polygon[0].map(([lng, lat]) => [
			Math.sin(toRadians(lng)),
			Math.cos(toRadians(lng)),
			Math.sin(toRadians(lat)),
			Math.cos(toRadians(lat)),
		])
			.reduce(([
				totalLngSine,
				totalLngCosine,
				totalLatSine,
				totalLatCosine,
			], [
				lngSine,
				lngCosine,
				latSine,
				latCosine,
			]) => [
				totalLngSine + lngSine,
				totalLngCosine + lngCosine,
				totalLatSine + latSine,
				totalLatCosine + latCosine,
			]);

		const lngCorrection = correction(lngCosine);
		const latCorrection = correction(latCosine);

		return [
			toDegrees(Math.atan(lngSine / lngCosine) - lngCorrection),
			toDegrees(Math.atan(latSine / latCosine) - latCorrection),
		];


		function correction(angle) {
			return angle < 0 ? Math.PI : 0;
		}

		function toRadians(angle) {
			const degrees = 180;

			return angle * Math.PI / degrees;
		}

		function toDegrees(angle) {
			const degrees = 180;

			return angle * degrees / Math.PI;
		}
	}
}
