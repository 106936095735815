import Vue from 'vue';
import { createDecorator } from 'vue-class-component';

const defaultDelay = 1000;

export default DebounceDecorator;

function DebounceDecorator(model, key) {
	const isVue = model instanceof Vue;

	return isVue
		? decorator(defaultDelay, false)(model, key)
		: decorator(model, key);
}

DebounceDecorator.immediate = function immediate(model, key) {
	const isVue = model instanceof Vue;

	return isVue
		? decorator(defaultDelay, true)(model, key)
		: decorator(model, true);
};

function decorator(delay, immediate) {
	return createDecorator((options, name) => {
		const method = options.methods[name];

		let timeout = null;

		options.methods[name] = function value(...args) {
			if (immediate && !timeout) method.apply(this, args);

			cancel();

			timeout = setTimeout(() => {
				cancel();

				if (!immediate) method.apply(this, args);
			}, delay || defaultDelay);
		};

		options.methods[name].cancel = cancel;

		function cancel() {
			timeout = clearTimeout(timeout);
		}
	});
}
