import component from './onboarding-features';

export default {
	component,
	path  : 'features',
	name  : 'onboarding-features',
	props : true,
	meta  : {
		title  : 'Onboarding Features',
		secure : false,
	},
};
