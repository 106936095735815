import Vue from 'vue';
import Component from 'vue-class-component';
import template from './edit-attribute-row.html';
import Debounce from '@/plugins/debounce-decorator';
import Watch from '@/plugins/watch-decorator';

const props = {
	value : Object, // the attribute record
};

@Component({
	template,
	props,
})
export default class EditAttributeRow extends Vue {
	data() {
		return {
			original : Object.assign({}, this.value),
		};
	}

	beforeDestroy() {
		if (this.disabled) this.restore();
	}

	get dirty() {
		return this.original.label !== this.value.label || this.original.value !== this.value.value;
	}

	get disabled() {
		return !this.value.label || !this.value.value;
	}

	move(modifier) {
		return this.$emit('move', {
			modifier,
			attribute : this.value,
		});
	}

	remove() {
		return this.$emit('delete', this.value);
	}

	@Debounce
	@Watch('value')
	input() {
		if (!this.disabled) return this.$emit('input', this.value);
	}

	restore() {
		return this.$emit('input', Object.assign(this.value, {
			label : this.original.label,
			value : this.original.value,
		}));
	}
}
