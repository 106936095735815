const component = () => import(/* webpackChunkName: "pages-external" */'./unsubscribe');

export default {
	component,
	path : '/unsubscribe',
	name : 'unsubscribe',
	meta : {
		title  : 'Unsubscribe',
		secure : false,
	},
};
