import api from '../api';

export default {
	preview,
	list,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	TimeseriesSource : _extend,
};

function preview(query) {
	return api.create('timeseries-source/preview', query);
}

function list(filters) {
	return api.list('timeseries-source', filters, { sortBy : 'timeseriesSourceName' })
		.then(list => list.map(timeseries => _extend(timeseries)));
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(timeseriesSource_id) {
	return api.read('timeseries-source', timeseriesSource_id)
		.then(timeseries => _extend(timeseries));
}

function create(timeseries) {
	return api.create('timeseries-source', timeseries);
}

function update(timeseries) {
	return api.update('timeseries-source', timeseries.timeseriesSource_id, timeseries);
}

function save(timeseries) {
	return timeseries.timeseriesSource_id ? update(timeseries) : create(timeseries);
}

function _delete(timeseriesSource_id) {
	return api.delete('timeseries-source', timeseriesSource_id);
}

function _extend(timeseries = {}) {
	return Object.assign({

	}, timeseries);
}
