export default [{
	label       : 'Parcel Number',
	placeholder : 'Enter the Parcel Number',
	isParcel    : true,
}, {
	label       : 'Property Owner',
	placeholder : 'John Smith',
}, {
	label       : 'Account Number',
	placeholder : '12-34567-89',
}, {
	label       : 'Listing Price',
	placeholder : '$319,200',
}, {
	label       : 'Sales Price',
	placeholder : '$321,000',
}, {
	label       : 'Last Date of Sale',
	placeholder : 'Jan 1, 2018',
}, {
	label       : 'Year Built',
	placeholder : '1980',
}];
