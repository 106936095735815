export default {
	'company' : null,
	'farm'    : 'map',
	'field'   : 'location',
	'marker'  : null,
	'line'    : null,

	'team' : null,
	'user' : null,

	'details-tab' : 'Details',
	'files-tab'   : 'Files',
	'notes-tab'   : 'Notes',
	'charts-tab'  : 'Charts',

	'data integration' : 'integration',
	'data source'      : 'source',
	'data device'      : 'device',

	'cloud storage'             : null,
	'cloud storage integration' : null,

	'file'         : null,
	'note'         : null,
	'conversation' : null,

	'email address' : 'email',
	'phone number'  : 'phone',

	'invitation' : null,
	'invited'    : null,
	'invite'     : null,

	'parcel'         : null,
	'parcels'        : null,
	'parcel number'  : null,
	'parcel acres'   : null,
	'farmable acres' : null,
	'boundary acres' : null,

	'crop map'  : 'map',
	'ranch map' : 'map',

	'open-menu-icon'  : 'fas fa-chevron-down',
	'close-menu-icon' : 'fal fa-lg fa-times',

	'email-placeholder' : 'user@montage.ag',

	'benefits-map-keyword-1' : 'APN',
	'benefits-map-keyword-2' : 'crop type',
	'benefits-map-keyword-3' : 'pesticide permit #',

	'benefits-connection-keyword-1' : 'locations',
	'benefits-connection-keyword-2' : 'devices',

	'onboarding-progress-title'     : 'Onboarding Progress',
	'onboarding-progress-tagline'   : 'Complete Tasks.',
	'onboarding-progress-style'     : 'default',
	'onboarding-progress-farm-icon' : 'map',
};
