import Vue from 'vue';
import Component from 'vue-class-component';
import template from './file-picker.html';
import api from '@/api';
import Watch from '@/plugins/watch-decorator';

const props = {
	sources  : Array,
	readonly : Boolean,
};

@Component({
	template,
	props,
})
export class FilePicker extends Vue {
	data() {
		return {
			error       : null,
			source      : null,
			list        : [],
			uploadFiles : [],
			uploading   : null,
			folders     : null,
			files       : null,
		};
	}

	get path() {
		return this.list.map(({ name }) => name).join('/');
	}

	@Watch('path')
	loadFolders() {
		this.folders = null;

		if (!this.source) return;

		const path = this.path;
		const { cloudFolder_id } = this.source;

		this.error = null;

		return api.folder.list({
			path,
			cloudFolder_id,
		})
			.then(folders => { this.folders = folders })
			.catch(error => { this.error = handleError(error) });
	}

	@Watch('path')
	loadFiles() {
		this.files = null;

		if (!this.source) return;

		const path = this.path;
		const { cloudFolder_id } = this.source;

		this.error = null;

		return api.file.list({
			path,
			cloudFolder_id,
		})
			.then(files => { this.files = files })
			.catch(error => { this.error = handleError(error) });
	}

	@Watch('source')
	reset() {
		this.list = [];
	}

	select(folder) {
		if (folder) this.list.push(folder);

		else this.list.pop();
	}

	pickFile(file) {
		return this.$emit('select', {
			source : this.source,
			path   : this.path,
			file,
		});
	}

	refresh() {
		this.loadFolders();
		this.loadFiles();
	}

	upload() {
		const files = this.uploadFiles;
		const cloudFolder_id = this.source.cloudFolder_id;

		if (this.uploading) return;

		this.uploading = true;

		return Promise.all(files.map(data => api.file.create({
			data,
			cloudFolder_id,
			path : this.path,
		})))
			.then(() => this.refresh())
			.then(() => { this.uploadFiles = [] })
			.catch(error => { this.error = error })
			.finally(() => { this.uploading = false });
	}
}

Vue.component('file-picker', FilePicker);


function handleError(error) {
	const unauthorized = 401;

	if (error.status === unauthorized)
		return 'You no longer have access to this folder.';

	return error;
}
