import Vue from 'vue';
import Component from 'vue-class-component';

const props = {
	value : Object,
};

@Component({
	template : '<span>{{ name }}</span>',
	props,
})
export class CloudFolderLabel extends Vue {
	get name() {
		const {
			owner,
			name,
		} = this.value;

		return name || owner || 'Unknown';
	}
}

Vue.component('cloud-folder-label', CloudFolderLabel);
