import Vue from 'vue';
import Component from 'vue-class-component';
import template from './decline-invitation-modal.html';
import api from '@/api';

const props = {
	invitation : Object,
};

@Component({
	template,
	props,
})
export class DeclineInvitationModal extends Vue {
	get listeners() {
		return Object.assign({}, this.$listeners, {
			submit : () => null,
		});
	}

	decline() {
		return Promise.resolve(this.invitation)
			.then(({ invitation_id }) => api.invitation.decline(invitation_id))
			.then(invitation => { this.$emit('submit', invitation) });
	}
}
