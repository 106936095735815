const component = () => import(/* webpackChunkName: "settings-extra" */'./developer-settings');

export default {
	component,
	path : 'developer-settings',
	name : 'developer-settings',
	meta : {
		title  : 'Developer Settings',
		secure : true,
	},
};
