import Vue from 'vue';
import Component from 'vue-class-component';
import template from './user-settings-list.html';
import Async from '@/plugins/async-decorator';
import { isDemoUser } from '@/services/permission.service';

@Component({ template })
export class UserSettingsList extends Vue {
	@Async
	get isDemo() {
		return isDemoUser();
	}
}

Vue.component('user-settings-list', UserSettingsList);
