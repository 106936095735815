export default {
	'company' : 'cult',
	'farm'    : 'enclave',
	'field'   : 'temple',
	'marker'  : 'eye',
	'line'    : 'tentacle',

	'team'     : 'sect',
	'user'     : 'cultist',
	'password' : 'incantation',

	'details-tab' : 'Dread',
	'files-tab'   : 'Fear',
	'notes-tab'   : 'Nothing',
	'charts-tab'  : 'Doom',

	'data integration' : 'integration',
	'data source'      : 'source',
	'data device'      : 'device',

	'cloud storage'             : 'summoning circles',
	'cloud storage integration' : 'summoning ritual',

	'file'         : 'lunatic rant',
	'note'         : 'diary entry',
	'conversation' : 'dialog',

	'email address' : 'beholder',
	'phone number'  : 'gibbering',

	'invitation' : 'conversion',
	'invited'    : 'brainwashed',
	'invite'     : 'brainwash',

	'parcel'         : 'dark mass',
	'parcels'        : 'dark masses',
	'parcel number'  : 'configuration',
	'parcel acres'   : 'writhing surface',
	'farmable acres' : 'woven surface',
	'boundary acres' : 'woven surface',

	'crop map'  : 'psychosis',
	'ranch map' : 'psychosis',

	'open-menu-icon'  : 'fas fa-lg fa-eye-evil',
	'close-menu-icon' : 'far fa-lg fa-eye-evil',

	'email-placeholder' : 'gustaf.johansen@r\'lyeh.nope',

	'benefits-map-keyword-1' : 'hallucination',
	'benefits-map-keyword-2' : 'Elder God',
	'benefits-map-keyword-3' : 'Great Old One',

	'benefits-connection-keyword-1' : 'locations',
	'benefits-connection-keyword-2' : 'devices',

	'onboarding-progress-title'     : 'Iä! Iä! Cthulhu fhtagn!',
	'onboarding-progress-tagline'   : 'The world is thinner in some places.',
	'onboarding-progress-style'     : 'eldritch',
	'onboarding-progress-farm-icon' : 'place-of-worship',
};
