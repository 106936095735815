const listeners = [];
const eBuffer = {};

export default {
	// Listen for event(s)
	on(event, callback = null, immediate = false) {
		return addListener(event, callback, immediate);
	},
	once(event, caller = null, immediate = false) {
		return addListener(event, caller, immediate, true);
	},
	// Emit event to listeners
	emit(event, payload) {
		emit(event, payload);
		storeEvent(event, payload);
	},
	// Clear Event Storage
	clear(event = null) {
		removeEvent(event);
	},
};

function addListener(event = 'all', listener = null, immediate = false, once = false) {
	if (!listener) return;

	const key = [event || 'all', listener, once];

	listeners.push(key);

	if (immediate)
		replayEvent(event, key);

	return () => removeListener(key);
}
function removeListener(key) {
	const i = typeof key === 'function'
		? listeners.findIndex(key)
		: listeners.indexOf(key);

	if (i < 0) return;

	listeners.splice(i, 1);
}
function getListeners(event, filter = null) {
	const okay = [
		event,
		'all',
		null,
	];

	const selected = listeners.filter(i => okay.includes(i[0]))
		.filter(i => {
			if (filter === null) return true;
			if (typeof filter === 'function') filter(i);

			return i === filter;
		});

	// Some events are set to destruct on first call. Remove them here.
	selected
		.filter(i => i[2])
		.forEach(key => removeListener(key));

	return selected.map(i => i[1]);
}
function emit(event, payload) {
	return getListeners(event)
		.forEach(listener => {
			listener(payload, event);
		});
}
function storeEvent(event, payload) {
	eBuffer[event] = { payload };
}
function removeEvent(event = null) {
	if (typeof event === 'string')
		delete eBuffer[event];

	Object.keys(eBuffer)
		.forEach(key => {
			eBuffer[key] = null;
		});
}
function replayEvent(event, key) {
	if (!eBuffer[event]) return;

	const { payload } = eBuffer[event];

	return getListeners(event, key)
		.forEach(listener => {
			listener(payload, event);
		});
}
