import Vue from 'vue';
import Component from 'vue-class-component';
import template from './add-company-button.html';
import Async from '@/plugins/async-decorator';
import { isDemoUser } from '@/services/permission.service';

const props = {
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
};

@Component({
	template,
	props,
})
export class AddCompanyButton extends Vue {
	@Async(false)
	get allowed() {
		return !this.isDemo;
	}

	@Async
	get isDemo() {
		return isDemoUser();
	}
}

Vue.component('add-company-button', AddCompanyButton);
