import Vue from 'vue';
import Component from 'vue-class-component';
import template from './status-bullet.html';

const props = {
	value : {
		type    : String,
		default : 'grey',
	},
};

@Component({
	template,
	props,
})
export class StatusBullet extends Vue {}

Vue.component('status-bullet', StatusBullet);
