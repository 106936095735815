const component = () => import(/* webpackChunkName: "settings-core" */'./billing-settings-buy');

export default {
	component,
	path : 'buy',
	name : 'billing-settings-buy',
	meta : {
		title           : 'Billing Settings Buy',
		secure          : true,
		onCompanyChange : { name : 'billing-settings' },
	},
};
