import Vue from 'vue';
import Component from 'vue-class-component';
import template from './folder-file-list.html';

const duration = 250;

const props = {
	folders : Array,
	files   : Array,
};

@Component({
	template,
	props,
})
export class FolderFileList extends Vue {
	get list() {
		return [
			...this.folders,
			...this.files,
		];
	}

	select(item) {
		clearTimeout(this.timeout);

		return new Promise(resolve => {
			this.timeout = setTimeout(resolve, duration);
		})
			.then(() => {
				if (this.isFolder(item)) return this.$emit('select-folder', item);

				if (this.isFile(item)) return this.$emit('select-file', item);
			});
	}

	isFolder({ type }) {
		return type === 'folder';
	}

	isFile({ type }) {
		return type === 'file';
	}
}

Vue.component('folder-file-list', FolderFileList);
