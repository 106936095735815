import Vue from 'vue';
import Component from 'vue-class-component';
import template from './template.html';
import sessionStorage from '@/services/session-storage.service';
import mapLabelService from '@/services/map-label.service';

const props = {
	farm       : Object,
	collection : {
		type     : Object,
		required : true,
		validator(value) {
			return value.type === 'FeatureCollection';
		},
	},
};

@Component({
	template,
	props,
})
export default class PrintMapPage extends Vue {
	data() {
		return {
			loadedOnce : false,
		};
	}

	get options() {
		const { center, zoom, mapTypeId = 'hybrid' } = sessionStorage.get('map-view') || {};
		const isLocked = Boolean(center && zoom);

		return {
			center,
			zoom,
			mapTypeId,
			isLocked,
			fullscreenControl : false,
			allowRecenter     : false,
		};
	}

	async onLoad(event) {
		await mapLabelService.updateLabels(event.map, this.collection.features);

		if (!this.loadedOnce)
			this.$emit('loaded', event);
		this.loadedOnce = true;
	}
}
