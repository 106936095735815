export default {
	namespaced : true,

	state : {
		queue : [],
	},

	getters : {
		get(state) {
			return state.queue[0] || null;
		},
	},

	mutations : {
		push({ queue }, { appNotification }) {
			queue.push(appNotification);
		},

		pop({ queue }) {
			queue.shift();
		},
	},

	actions : {
		push({ commit }, payload) {
			commit('push', payload);
		},

		pop({ commit, getters: { get: result } }) {
			commit('pop');

			return result || null;
		},
	},
};
