import Vue from 'vue';
import Component from 'vue-class-component';
import template from './print-settings-modal.html';

const props = {
	features   : Array,
	hasFilters : Boolean,
};

@Component({
	template,
	props,
})
export class PrintSettingsModal extends Vue {
	data() {
		return {
			form : {
				showMap     : true,
				allAttr     : true,
				selAttr     : [],
				featureType : null,
				names       : null,
				keepFilters : Boolean(this.hasFilters),
			},
		};
	}

	get listeners() {
		return Object.assign({}, this.$listeners, {
			submit : () => null,
		});
	}

	get selectedFeatures() {
		if (!this.features) return null;

		const allowedTypes = Array.isArray(this.form.featureType)
			? this.form.featureType
			: [this.form.featureType].filter(Boolean);

		let match = true;

		if (this.form.keepFilters)
			match = feature => feature.properties.visible !== false;
		else if (allowedTypes.length)
			match = feature => allowedTypes.includes(feature.properties.featureType);

		return this.features
			.filter(feature => typeof match === 'function' ? match(feature) : match);
	}

	get attributes() {
		if (!this.features) return null;

		const attributeList = this.selectedFeatures
			.map(feature => feature.properties
				? getPropertySafe(feature.properties.attributes, 'label')
				: []);

		return sort(unique([].concat(...attributeList)));
	}

	get featureTypes() {
		return [
			{
				label : `${this.$dsl('Fields')}`,
				value : 'field',
			},
			{
				label : `${this.$dsl('Markers')}`,
				value : 'marker',
			},
			{
				label : `${this.$dsl('Lines')}`,
				value : 'line',
			},
		];
	}

	get featureNames() {
		if (!this.features) return null;

		const names = this.selectedFeatures
			.map(feature => {
				const type = feature.properties.featureType;

				return feature.properties[`${type}Name`];
			});

		return sort(names);
	}

	get filters() {
		const types  = Array.isArray(this.form.featureType) ? this.form.featureType : [this.form.featureType].filter(Boolean);
		const names = Array.isArray(this.form.names) ? this.form.names : [this.form.names].filter(Boolean);

		return [].concat(
			types.map(value => ({
				type      : 'type',
				operation : '=~',
				value,
			})),
			names.map(value => ({
				type      : 'name',
				operation : '=~',
				value,
			}))
		);
	}

	onSubmit() {
		this.$emit('submit', Object.assign({
			filters : this.filters,
		}, this.form));
	}
}

function getPropertySafe(list, property) {
	if (!Array.isArray(list)) return null;

	return list.map(item => item && item[property]);
}
function unique(list) {
	const seen = {};

	return list.filter(item => {
		if (seen[item]) return false;

		return seen[item] = true; // eslint-disable-line no-return-assign
	});
}
function sort(list) {
	return list.sort();
}
