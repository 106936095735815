import Vue from 'vue';
import Component from 'vue-class-component';
import template from './farm-access-list.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

const props = {
	value : {
		type     : Array,
		required : true,
	},
	readonly : Boolean,
};

@Component({
	template,
	props,
})
export class FarmChipList extends Vue {
	@Async
	get list() {
		return api.farm.list();
	}

	selectView({ farm_id }) {
		const farms = [{
			access : 'view',
			farm_id,
		}]
			.concat(this.value)
			.filter(({ farm_id }, index, list) => list.findIndex(({ farm_id:item_id }) => item_id === farm_id) === index);

		return this.$emit('input', farms);
	}

	selectEdit({ farm_id }) {
		const farms = [{
			access : 'edit',
			farm_id,
		}]
			.concat(this.value)
			.filter(({ farm_id }, index, list) => list.findIndex(({ farm_id:item_id }) => item_id === farm_id) === index);

		return this.$emit('input', farms);
	}

	deselect({ farm_id }) {
		const farms = this.value
			.filter(({ farm_id: item_id }) => item_id !== farm_id)
			.filter(({ farm_id }, index, list) => list.findIndex(({ farm_id:item_id }) => item_id === farm_id) === index);

		return this.$emit('input', farms);
	}

	getAccess({ farm_id }) {
		const { access = 'none' } = this.value.find(access => access.farm_id === farm_id) || {};

		return access;
	}
}

Vue.component('farm-access-list', FarmChipList);
