import Vue from 'vue';
import Component from 'vue-class-component';
import template from './copy-input.html';

const props = {
	value : String,
	label : String,
};

@Component({
	template,
	props,
})
export class CopyInput extends Vue {
	data() {
		return {
			status : 0,
		};
	}

	get element() {
		return this.$refs.input.$refs.input;
	}

	get copyLabel() {
		if (this.status === 2)
			return 'Copied';
		if (this.status === 1)
			return 'Working';

		return 'Copy';
	}

	copy() {
		this.status = 1;

		return copyText(this.value, this.element)
			.then(() => {
				this.status = 2;

				setTimeout(() => {
					this.status = 0;
				}, 3000);
			})
			.catch(() => { this.status = 0 });
	}
}

Vue.component('copy-input', CopyInput);


function copyText(text, el) {
	return clipboard(text)
		.catch(() => execCopy(el));
}

function execCopy(element) {
	return new Promise((resolve, reject) => {
		try {
			const max = 99999;

			element.select();
			element.setSelectionRange(0, max);
			if (document.execCommand('copy'))
				resolve();
			else
				reject();


			element.setSelectionRange(0, 0);
			element.blur();
		}
		catch (error) {
			reject(error);
		}
	});
}


function clipboard(text) {
	if (navigator.clipboard)
		return navigator.clipboard.writeText(text);

	return Promise.reject();
}
