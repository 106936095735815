import Vue from 'vue';
import Component from 'vue-class-component';
import template from './stripe-payment-selector.html';
import api from '@/api';

const props = {
	payAfterSelect : {
		type    : Boolean,
		default : true,
	},
	disabledPay : Boolean,
};

@Component({
	template,
	props,
})
export class StripePaymentSelector extends Vue {
	data() {
		return {
			selected   : null,
			adding     : false,
			cards      : null,
			loading    : false,
			saving     : false,
			cardSecret : '',
			ierror     : null,
		};
	}

	get card() {
		if (!this.selected) return;

		return this.cards.find(card => card.id === this.selected);
	}

	get error() {
		return this.ierror;
	}

	set error(error) {
		this.$emit('error', error);
		this.ierror = error;
	}

	mounted() {
		this.load();
	}


	load(params) {
		if (this.loading) return;

		this.loading = true;

		return Promise.resolve(api.billingReplacement.listCards(params))
			.then(cards => { this.cards = cards })
			.catch(error => { this.error = error })
			.then(() => { this.loading = false });
	}

	get submitDisabled() {
		return !this.selected || this.loading || this.disabledPay;
	}

	submit() {
		this.$emit('submit', this.selected);
	}

	addCard() {
		if (this.loading) return;

		this.adding = true;
		this.loading = true;

		return Promise.resolve(api.billingReplacement.addCard())
			.then(secret => { this.cardSecret = secret })
			.catch(error => { this.error = error })
			.then(() => { this.loading = false });
	}

	finishAdd(intent) {
		this.selected = (intent && intent.payment_method) || null;
		this.adding = false;

		return this.load({ iat : Date.now() });
	}
}

Vue.component('stripe-payment-selector', StripePaymentSelector);
