import Vue from 'vue';
import Component from 'vue-class-component';
import template from './registration.html';
import api from '@/api';

@Component({ template })
export default class Registration extends Vue {
	mounted() {
		api.session.logout();
	}

	login() {
		this.$router.push({ name : 'onboarding' });
	}
}
