/* eslint-disable max-statements */

import Vue from 'vue';
import { createDecorator } from 'vue-class-component';

export default AsyncDecorator;

/*
Usage:
class MyComponent extends Vue {
	...

	@Async
	get property1() {
		return Promise.resolve('value');

		// this.property1 will be initialized to null,
		// and set to 'value' when the promise resolves.
	}

	@Async('initial')
	get property2() {
		return Promise.resolve('value');

		// this.property2 will be initialized to 'initial',
		// and set to 'value' when the promise resolves.
	}

	@Async('initial', 'otherProperty')
	get property3() {
		return Promise.resolve(Date.now());

		// this.property3 will be initialized to 'initial',
		// and set to current time when 'otherProperty' changes.
	}
}
*/

function AsyncDecorator(model, key, { deep = true, immediate = false } = {}) {
	const defaultValue = model instanceof Vue ? null : model;
	const watchers = model instanceof Vue || !key ? [] : [].concat(key);

	const decorator = createDecorator((options, key) => {
		const asyncGetter = `_async$${key}Getter`;

		const computed = options.computed || {};
		const optionsData = options.data;
		const optionsCreated = options.created;

		if (!computed[key] || !computed[key].get) throw new Error('@Async must be attached to a getter');

		if (computed[key].set) throw new Error('@Async cannot have a setter');

		const getter = computed[key].get;

		options.computed[asyncGetter] = getter;

		delete computed[key];

		options.data = function data() {
			const dataObject = optionsData ? optionsData.call(this) : {};

			dataObject[key] = defaultValue;

			return dataObject;
		};

		options.created = function created() {
			if (optionsCreated) optionsCreated.call(this);

			const timeout = {};

			const setValue = value => {
				this[key] = value;
			};

			const reactToPropertyChange = property => () => {
				clearTimeout(timeout[property]);
				timeout[property] = setTimeout(() => {
					this[key] = defaultValue;

					Promise.resolve(getter.call(this))
						.then(setValue);
				});
			};

			const watchProperty = property => {
				if (property === key) this.$watch(asyncGetter, reactToPropertyChange(property), {
					immediate : true,
				});

				else this.$watch(property, reactToPropertyChange(property), {
					deep,
					immediate : immediate || Boolean(this[property]),
				});
			};

			if (watchers.length)
				watchers.forEach(watchProperty);

			else this.$watch(asyncGetter, promise => {
				// this[key] = defaultValue; // we don't do this here, because it triggers an excessive number of watchers.

				Promise.resolve(promise)
					.then(setValue);
			}, {
				immediate : true,
			});
		};
	});

	return model instanceof Vue ? decorator(model, key) : decorator;
}
