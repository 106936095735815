import Vue from 'vue';
import Component from 'vue-class-component';
import template from './ceres-imaging-field-list.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';
import service from '@/services/modal.service';

const props = {
	integration : {
		type     : Object, // CeresImaging integration record
		required : true,
	},
};

@Component({
	template,
	props,
})
export class CeresImagingFieldList extends Vue {
	data() {
		return {
			error   : null,
			loading : null,
		};
	}

	@Async
	get list() {
		const { ceresImaging_id } = this.integration;

		return api.ceresImagingField.list({ ceresImaging_id });
	}

	removeCeresField(ceresField) {
		if (this.loading) return;

		const index = this.list.indexOf(ceresField);

		this.error = null;
		this.loading = true;

		return api.ceresImagingField.delete(ceresField.ceresImagingField_id)
			.then(() => { this.list.splice(index, 1) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	destroyCeresField(ceresField) {
		if (this.loading) return;

		const index = this.list.indexOf(ceresField);

		this.error = null;
		this.loading = true;

		return api.ceresImagingField.destroy(ceresField.ceresImagingField_id)
			.then(() => { this.list.splice(index, 1) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	launch() {
		if (this.loading) return;

		this.error = null;
		this.loading = true;

		return service.launchModal('connect-ceres-imaging-field', {
			farm        : this.farm,
			ceresFarm   : this.ceresFarm,
			integration : this.integration,
		})
			.then(record => api.ceresImagingField.create(record))
			.then(record => { this.list.push(record) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('ceres-imaging-field-list', CeresImagingFieldList);
