import Vue from 'vue';
import Component from 'vue-class-component';
import template from './content-tab.html';

const props = {
	label : {
		type     : String,
		required : true,
	},
	tabId   : String,
	destroy : Boolean, // Destroy contents of this tab when not active
	locked  : Boolean, // This tab is unavailable currently
};

@Component({
	template,
	props,
})
export class ContentTab extends Vue {
	data() {
		return {
			active : false,
		};
	}

	created() {
		if (!this.$parent.tabs) {
			this.$destroy();
			throw new Error('[content-tab] Content-tab should be an immediate child of content-tabs');
		}

		this.$parent.tabs.push(this);
	}

	beforeDestroy() {
		const index = this.$parent.tabs.indexOf(this);

		if (index >= 0)
			this.$parent.tabs.splice(index, 1);
	}

	get tab_id() {
		return this.tabId || this.$parent.tabs.indexOf(this);
	}
}

Vue.component('content-tab', ContentTab);
