import Vue from 'vue';
import Component from 'vue-class-component';
import Async from '@/plugins/async-decorator';
import service from '@/services/app-notification.service';

const duration = 5000;
const tolerance = 250;

@Component({
	template : '<span></span>',
})
export class AppNotificationContainer extends Vue {
	data() {
		return {
			close : null,
		};
	}

	@Async
	get action() {
		return service.pop()
			.then(response => response ? this.open(response) : null);
	}

	open({ message, label : actionText, resolve, reject }) {
		if (!message) return;

		if (this.close) this.close();

		const queue = false;

		const onAction = () => {
			// User clicked the button (to undo or to retry).

			clearTimeout(this.timeout);
			this.close = null;
			reject(actionText);
		};

		const close = actionText ? this.$snackbar.open({
			duration,
			message,
			actionText,
			onAction,
			queue,
		}).close : this.$toast.open({
			duration,
			message,
			queue,
		}).close;

		this.close = () => {
			// User caused the message to close before timer expired.

			close();
			clearTimeout(this.timeout);
			resolve('dismiss');
			this.close = null;
		};

		this.timeout = setTimeout(() => {
			// User allowed the timer to expire.

			resolve('timeout');
			this.close = null;
		}, duration + tolerance);
	}
}

Vue.component('app-notification-container', AppNotificationContainer);
