import Vue from 'vue';
import Component from 'vue-class-component';
import template from './add-field-tools-list.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';
import modalService from '@/services/modal.service';

const FIELD_LIMIT = Number(process.env.FIELD_LIMIT) || Infinity;

@Component({ template })
export class AddFieldToolsList extends Vue {
	@Async({})
	get status() {
		return api.parcelQuery.get();
	}

	@Async(0)
	get remaining() {
		if (!Number.isFinite(FIELD_LIMIT))
			return Infinity;

		return api.field.count()
			.then(({ count }) => FIELD_LIMIT - count);
	}

	get plusOne() {
		return this.remaining >= 1;
	}

	get plusMany() {
		return this.remaining > 1;
	}

	useParcelTool(tool) {
		if (this.status.limitReached)
			return modalService.launchModal('parcel-click-upsell', {})
				.catch(() => null);

		this.$emit('select', tool);
	}
}

Vue.component('add-field-tools-list', AddFieldToolsList);
