import Vue from 'vue';
import Component from 'vue-class-component';
import template from './autocomplete.html';
import Debounce from '@/plugins/debounce-decorator';

const props = {
	value   : String,
	options : {
		type     : Array,
		required : true,
	},
	label       : String,
	placeholder : String,
	required    : Boolean,
};

@Component({
	template,
	props,
})
export class Autocomplete extends Vue {
	data() {
		return {
			message : null,
			type    : null,
		};
	}

	get key() {
		if (!this.label) return `autocomplete-${Date.now()}`;

		return `autocomplete-${this.label.replace(/\s+/g, '-')}`;
	}

	onBlur(event) {
		this.checkHtml5Validity();

		return this.$emit('blur', event);
	}

	onFocus(event) {
		this.checkHtml5Validity();

		return this.$emit('focus', event);
	}

	checkHtml5Validity() {
		const element = this.$refs.input;

		let type = null;
		let message = null;

		if (!element.checkValidity()) {
			type = 'is-danger';
			message = element.validationMessage;
		}

		this.type = type;
		this.message = message;
	}

	@Debounce(550)
	onInput(event) {
		this.$emit('input', event.target.value);
	}
}

Vue.component('autocomplete', Autocomplete);
