import Vue from 'vue';
import Component from 'vue-class-component';
import template from './navigation-button.html';

const props = {
	route : {
		type     : String,
		required : true,
	},
};

@Component({
	template,
	props,
})
export class NavigationButton extends Vue {
	data() {
		return {
			loading : false,
		};
	}

	created() {
		if (!this.$router) return;

		this.removeHook = this.$router.afterEach(() => {
			this.loading = false;
		});
	}

	beforeDestroy() {
		if (this.removeHook) this.removeHook();
	}

	get disabled() {
		return this.$route.matched.some(({ name = '' }) => name.indexOf(this.route) >= 0);
	}

	click() {
		const duration = 3000;

		this.loading = true;

		this.$router.push({ name : this.route });

		setTimeout(() => {
			this.loading = false;
		}, duration);
	}
}

Vue.component('navigation-button', NavigationButton);
