import Vue from 'vue';
import config from '@/config/file-upload';
import sort from '@/services/sorting.service';

class DragDrop {
	constructor(element) {
		this.element = element;
		this.callback = null;
		this.listeners = {
			drag      : prevent(),
			dragenter : prevent(() => { this.element.classList.add('is-dragging') }),
			dragstart : prevent(() => { this.element.classList.add('is-dragging') }),
			dragover  : prevent(() => { this.element.classList.add('is-dragging') }),
			dragleave : prevent(() => { this.element.classList.remove('is-dragging') }),
			dragend   : prevent(() => { this.element.classList.remove('is-dragging') }),
			drop      : prevent(event => this.onDrop(event.dataTransfer.items)),
		};
	}

	destruct() {
		removeEventListeners(this.element, this.listeners);

		this.element.classList.remove('drag-enabled');
	}

	setCallback(callback) {
		this.callback = callback;

		if (callback) {
			addEventListeners(this.element, this.listeners);

			this.element.classList.add('drag-enabled');
		}

		else {
			removeEventListeners(this.element, this.listeners);

			this.element.classList.remove('drag-enabled');
		}
	}

	onDrop(items) {
		this.element.classList.remove('is-dragging');

		const entryList = Array.from(items)
			.map(item => item.webkitGetAsEntry())
			.filter(file => file); // webkitGetAsEntry will return null if item is not a file - AM 12/26/18

		return processEntryList(entryList)
			.then(files => files.sort((one, two) => sort(one, two, 'path')))
			.then(files => files.slice(0, config.maxFileCount))
			.then(files => this.callback(files));
	}
}

const drop = {
	bind(element, { value }) {
		element.DragDrop = new DragDrop(element);
		element.DragDrop.setCallback(value);
	},

	unbind(element) {
		element.DragDrop.destruct();
		element.DragDrop = null;
	},

	update(element, { value }) {
		element.DragDrop.setCallback(value);
	},
};

export const directive = Vue.directive('drop', drop);


function addEventListeners(element, listeners) {
	Object.entries(listeners).forEach(([event, listener]) => element.addEventListener(event, listener));
}

function removeEventListeners(element, listeners) {
	Object.entries(listeners).forEach(([event, listener]) => element.removeEventListener(event, listener));
}

function prevent(next) {
	return event => {
		event.preventDefault();
		event.stopPropagation();

		return next && next(event);
	};
}

function processEntryList(list, path = '.') {
	return Promise.all(list.map(entry => new Promise(resolve => {
		if (config.ignorePatterns.some(regex => regex.test(entry.name))) return resolve(null);

		if (entry.isDirectory)
			readEntries(entry.createReader(), `${path}/${entry.name}`)
				.then(files => resolve({
					name : entry.name,
					path : `${path}/${entry.name}`,
					files,
				}));


		else entry.file(file => {
			if (file.size > config.maxFileSize) return resolve(null);

			resolve(Object.assign(file, {
				path : `${path}/${entry.name}`,
			}));
		});
	})))
		.then(files => files.filter(file => Boolean(file)))
		.then(list => list.reduce((result, entry) => entry.files ? result.concat(entry.files) : result.concat([entry]), []));
}

function readEntries(reader, path, result = []) {
	return new Promise(resolve => {
		reader.readEntries(list => {
			if (!list.length) return resolve([]);

			setTimeout(() => processEntryList(list, path)
				.then(list => readEntries(reader, path, list))
				.then(list => resolve(list)), 10);
		});
	})
		.then(list => result.concat(list));
}
