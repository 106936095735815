import MapFilter from '@/components/map-filters/classes/map-filter.class';
import modalService from '@/services/modal.service';

export default async function launchModal(props = {}) {
	const modal = modalService.launchModal('print-settings', props);

	const options = await modal;

	let filters = null;
	let attrs = null;

	if (!options.keepFilters && options.filters)
		filters = options.filters.map(filter => new MapFilter(filter));

	if (options.allAttr !== true)
		attrs = options.selAttr;

	return {
		attrs,
		filters,
		map : options.showMap !== false,
	};
}
