import api from '@/api';
import dsl from '@/services/dsl.service';

////////
// Local Data
//

const defaultTours = {
	profile : null,
	farm    : null,
	field   : null,
	marker  : null,
	files   : null,
	print   : null,
	teams   : null,
	users   : null,
};

////////
// Exported Data
//

const tasks = [
	'profile',
	'farm',
	'field',
	'marker',
	'files',
	'print',
	'teams',
	'users',
];

const tours = {
	cuvx444s : { // production
		profile : null,
		farm    : null,
		field   : '50416',
		marker  : '50695',
		files   : '50698',
		print   : '63835',
		teams   : '50701',
		users   : '50702',
	},

	jzotl2rf : { // testing
		profile : '50831',
		farm    : null,
		field   : null,
		marker  : null,
		files   : null,
		print   : null,
		teams   : null,
		users   : null,
	},
}[process.env.INTERCOM_APP_ID] || defaultTours;

const icons = {
	profile : 'fa-user-check',
	farm    : `fa-${dsl('onboarding-progress-farm-icon')}`,
	field   : 'fa-vector-square',
	marker  : 'fa-map-marker-check',
	files   : 'fa-file-check',
	print   : 'fa-print',
	teams   : 'fa-users',
	users   : 'fa-envelope-open-text',
};

const links = {
	profile : { name : 'user-profile' },
	farm    : { name : 'company-farms' },
	field   : { name : 'map' },
	marker  : { name : 'map' },
	files   : { name : 'company-cloud-storage' },
	print   : { name : 'map' },
	teams   : { name : 'company-teams' },
	users   : { name : 'company-users' },
};

const labels = {
	profile : `${dsl('User')} profile`,
	farm    : `Add a ${dsl('farm')}`,
	field   : `Add a ${dsl('field')}`,
	marker  : `Add a ${dsl('marker')}`,
	files   : `Link to ${dsl('files')}`,
	print   : `Print a ${dsl('ranch map')}`,
	teams   : `Add a ${dsl('team')}`,
	users   : `${dsl('Invite')} a ${dsl('user')}`,
};

const blurbs = {
	profile : `Complete your ${dsl('user')} profile`,
	farm    : `Create a ${dsl('farm')} for your ${dsl('company')}`,
	field   : `Create a ${dsl('field')} for your ${dsl('farm')}`,
	marker  : `Create a ${dsl('marker')} for your ${dsl('farm')}`,
	files   : `Integrate your ${dsl('company')} ${dsl('files')}`,
	print   : `Print a ${dsl('ranch map')} of your ${dsl('farm')}`,
	teams   : `Create a ${dsl('team')} for your ${dsl('company')}`,
	users   : `${dsl('Invite')} someone to your ${dsl('company')}`,
};

////////

export default {
	tasks,
	tours,
	icons,
	links,
	labels,
	blurbs,

	updateProgress,

	Progress() {
		return tasks.reduce((progress, task) => Object.assign(progress, {
			[task] : false,
		}), {});
	},
};

////////
// Exported Functions
//

function updateProgress(progress) {
	return Promise.all([
		api.user.get()
			.then(user => user ? user.user_id : null)
			.then(user_id => [
				Boolean(user_id),
			])
			.catch(() => [
				false,
			]),

		api.config.read()
			.then(({ printMap }) => [
				Boolean(printMap),
			])
			.catch(() => [
				false,
			]),

		api.company.get()
			.then(() => Promise.all([
				api.cloudStorage.list()
					.then(list => Boolean(list.length))
					.catch(() => false),
				api.team.list()
					.then(list => Boolean(list.length > 1))
					.catch(() => false),
				api.user.list()
					.then(list => Boolean(list.length > 1))
					.catch(() => false),
			]))
			.catch(() => [
				false,
				false,
				false,
			]),

		api.farm.get()
			.then(({ farm_id }) => Promise.all([
				Boolean(farm_id),
				api.field.list()
					.then(list => Boolean(list.length))
					.catch(() => false),
				api.marker.list()
					.then(list => Boolean(list.length > 1))
					.catch(() => false),
			]))
			.catch(() => [
				false,
				false,
				false,
			]),
	])
		.then(([[
			profile,
		], [
			print,
		], [
			files,
			teams,
			users,
		], [
			farm,
			field,
			marker,
		]]) => Object.assign(progress, {
			profile,
			print,
			files,
			teams,
			users,
			farm,
			field,
			marker,
		}));
}
