export default {
	'company' : 'captain\'s fleet',
	'farm'    : 'pirate ship',
	'field'   : 'port town',
	'marker'  : 'treasure',
	'line'    : 'shipping lane',

	'team' : 'boarding party',
	'user' : 'buccaneer',

	'details-tab' : 'Details',
	'files-tab'   : 'Letters',
	'notes-tab'   : 'Captain\'s Log',
	'charts-tab'  : 'Graphs',

	'data integration' : 'integration',
	'data source'      : 'source',
	'data device'      : 'device',

	'cloud storage'             : 'mail bags',
	'cloud storage integration' : 'mail bag',

	'file'         : 'letter',
	'note'         : 'log entry',
	'conversation' : 'parley session',

	'email address' : 'parrot\'s name',
	'phone number'  : 'seashell #',

	'invitation' : 'shanghaiing',
	'invited'    : 'shanghaiied',
	'invite'     : 'shanghai',

	'parcel'         : 'paper trail',
	'parcels'        : 'paper trails',
	'parcel number'  : 'paper trail ID',
	'parcel acres'   : 'documented acres',
	'farmable acres' : 'undocumented acres',
	'boundary acres' : 'undocumented acres',

	'crop map'  : 'pirate map',
	'ranch map' : 'pirate map',

	'open-menu-icon'  : 'fas fa-lg fa-skull',
	'close-menu-icon' : 'fas fa-lg fa-skull-crossbones',

	'email-placeholder' : 'matey@davy-joness.locker',

	'benefits-map-keyword-1' : 'ship type',
	'benefits-map-keyword-2' : 'ship ID',
	'benefits-map-keyword-3' : 'pirate ID',

	'benefits-connection-keyword-1' : 'islands',
	'benefits-connection-keyword-2' : 'parrots',

	'onboarding-progress-title'     : 'Dash-o-matic',
	'onboarding-progress-tagline'   : 'Big Money! Big Prizes! I Love It!',
	'onboarding-progress-style'     : 'piracy',
	'onboarding-progress-farm-icon' : 'ship',
};
