import Vue from 'vue';
import Component from 'vue-class-component';
import template from './user-details.html';
import api from '@/api';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import colorService from '@/services/color.service';

const props = {
	readonly : Boolean,
};

@Component({
	template,
	props,
})
export class UserDetails extends Vue {
	data() {
		return {
			disabled : true,
			loading  : false,
			error    : null,
		};
	}

	get extraColor() {
		return colorService.generate(this.user.user_id);
	}

	@Async()
	get user() {
		return api.user.get();
	}

	@Watch('user')
	setDirty(_, old) {
		if (old) this.disabled = false;
	}

	clearError() {
		this.error = null;
	}

	submit() {
		this.loading = true;
		this.error = null;

		return api.user.update(this.user)
			.then(() => { this.disabled = true })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('user-details', UserDetails);
