const flyover = {
	namespaced : true,

	state : {
		stack : [],
	},

	getters : {
		flyover(state) {
			return state.stack[0] || null;
		},

		stack(state) {
			return state.stack || [];
		},
	},

	mutations : {
		push({ stack }, { flyover }) {
			const current = stack[0] || {};

			if (current.flyover === flyover) {
				current.data = flyover.data;
				flyover.reject();
			}

			else stack.unshift(flyover);
		},

		pop({ stack }) {
			const layer = stack.shift();

			if (layer.resolve) layer.resolve(layer.data);
		},

		resolve({ stack }, { value }) {
			const layer = stack.shift() || {};

			if (layer.resolve) layer.resolve(value);
		},

		reject({ stack }, { value }) {
			const layer = stack.shift() || {};

			if (layer.reject) layer.reject(value);
		},

		clear({ stack }, { error }) {
			while (stack.length) {
				const layer = stack.shift();

				if (layer.reject) layer.reject(error);
			}
		},
	},

	actions : {
		push({ commit }, payload) {
			return Promise.resolve(commit('push', payload));
		},

		pop({ commit }) {
			return Promise.resolve(commit('pop'));
		},

		resolve({ commit }, payload) {
			return Promise.resolve(commit('resolve', payload));
		},

		reject({ commit }, payload) {
			return Promise.resolve(commit('reject', payload));
		},

		clear({ commit }, payload) {
			return Promise.resolve(commit('clear', payload));
		},
	},
};

export default flyover;
