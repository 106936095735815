import api from './api';

export default {
	list,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	Oauth : _extend,
};

function list(filters) {
	return api.list('oauth', filters)
		.then(list => list.map(oauth => _extend(oauth)));
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(oauth_id) {
	return api.read('oauth', oauth_id)
		.then(oauth => _extend(oauth));
}

function create(oauth) {
	return api.create('oauth', oauth);
}

function update(oauth) {
	return api.update('oauth', oauth.oauth_id, oauth);
}

function save(oauth) {
	return oauth.oauth_id ? update(oauth) : create(oauth);
}

function _delete(oauth_id) {
	return api.delete('oauth', oauth_id);
}

function _extend(oauth = {}) {
	return Object.assign({

	}, oauth);
}
