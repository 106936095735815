import Vue from 'vue';
import Component from 'vue-class-component';
import template from './checkbox-list.html';

const props = {
	value : {
		type     : Array, // [Boolean, ...]
		required : true,
	},
	labels : {
		type     : Array, // [String, ...]
		required : true,
	},
	icons : {
		type    : Array, // [<icon>, ...]
		default : () => [],
	},
	indeterminate : Boolean,
};

@Component({
	template,
	props,
})
export class CheckboxList extends Vue {
	get isAllChecked() {
		return this.value.every(value => value);
	}

	get isIndeterminate() {
		return this.value.some(value => value) && !this.isAllChecked;
	}

	toggle(index, entry) {
		this.value.splice(index, 1, entry);

		this.$emit('input', this.value);
	}

	checkAll(checked) {
		return this.$emit('input', Array(this.value.length).fill(checked));
	}
}

Vue.component('checkbox-list', CheckboxList);
