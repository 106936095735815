import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-legend-panel.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import attributeService from '@/services/attribute.service';
import { checkFarmPrivilege, isDemoUser } from '@/services/permission.service';
import localStorage from '@/services/local-storage.service';

const props = {
	value : Object, // { Map, features: [...], filters }
};

@Component({
	template,
	props,
})
export class MapLegendPanel extends Vue {
	data() {
		return {
			panel      : null, // the type of side-panel to open
			loading    : null, // applying map filters
			results    : null, // search results
			currentTab : lsMapPanelTab(),
		};
	}

	beforeDestroy() {
		this.$emit('select', null);
	}

	get isControlsShown() {
		return this.$attrs['show-controls'] === '' || this.$attrs['show-controls'] === true;
	}

	get isSearchEnabled() {
		return process.env.DISABLE_PARCEL_SEARCH !== 'true';
	}

	get features() {
		return this.value.features;
	}

	get filters() {
		return this.value.filters;
	}

	get map() {
		return this.value.map;
	}

	get childPanelValue() {
		if (this.panel === 'parcel-search-results-side-panel')
			return {
				config  : this.value,
				results : this.results,
			};

		return this.value.selected;
	}

	@Watch('currentTab')
	onCurrentTabChange() {
		if (this.currentTab >= 2)
			lsMapPanelTab(1);
		 else
			lsMapPanelTab(this.currentTab);
	}

	@Async(false)
	get isDemoUser() {
		return isDemoUser();
	}

	@Async(false)
	get canCreate() {
		return checkFarmPrivilege('create')
			.then(okay => okay && !this.isDemoUser);
	}

	getFeature(feature_id) {
		return this.value.map.getFeature(feature_id);
	}

	showSearchResults(results) {
		this.results = results;
		if (results)
			this.panel = 'parcel-search-results-side-panel';
		else
			this.panel = null;
	}

	select(selected) {
		if (!this.value) return;

		this.currentTab = 'legend';
		this.value.selected = selected;
	}

	edit(selected) {
		const feature = this.getFeature(selected.feature_id);

		this.$emit('panel', {
			value : selected,
			panel : {
				field  : 'edit-field',
				marker : 'edit-marker',
				line   : 'edit-line',
			}[feature.getProperty('featureType')],
		});
	}

	addFeature(value) {
		this.$emit('panel', {
			panel : 'add-map-feature',
			value,
		});
	}

	createFromJson(value) {
		this.$emit('panel', {
			panel : 'create-field-from-json',
			value,
		});
	}

	updateSelected(selected) {
		const geoJson = this.features.find(({ properties : { feature_id } }) => feature_id === selected.feature_id);
		const feature = this.getFeature(selected.feature_id);
		const featureType = feature.getProperty('featureType');

		const updateProperty = {
			field  : ({ fieldName }) => feature.setProperty('fieldName', fieldName),
			marker : ({ markerName }) => feature.setProperty('markerName', markerName),
			line   : ({ lineName }) => feature.setProperty('lineName', lineName),
		}[featureType];

		const loadAttributes = {
			field  : field => attributeService.loadFieldAttributes(field),
			marker : marker => attributeService.loadMarkerAttributes(marker),
			line   : line => attributeService.loadLineAttributes(line),
		}[featureType];

		return loadAttributes(selected)
			.then(attributes => { geoJson.properties.attributes = attributes.map(attribute => Object.assign({}, attribute)) })
			.then(() => { this.features.splice(this.features.indexOf(geoJson), 1, geoJson) })
			.then(() => updateProperty(selected));
	}
}

Vue.component('map-legend-panel', MapLegendPanel);

function lsMapPanelTab(value = null) {
	const key = 'map-legend.current.panel';

	if (value)
		return localStorage.set(key, value);


	if (value === false)
		return localStorage.clear(key);


	return localStorage.get(key);
}
