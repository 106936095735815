const legendPosition = window.google.maps.ControlPosition.RIGHT_TOP;

////

export default {
	legendIndex : null,

	setLegend(config) {
		this.clearLegend();

		if (!config) return;

		const legend = createCeresImagingMapLegend(config);

		this.legendIndex = this._map.controls[legendPosition].push(legend) - 1;
	},

	clearLegend() {
		if (this.legendIndex === null) return;

		this._map.controls[legendPosition].removeAt(this.legendIndex);

		this.legendIndex = null;
	},
};


function createCeresImagingMapLegend({ labels, colors }) {
	const legend = document.createElement('div');

	legend.classList.add('embedded-map-legend');

	const list = document.createElement('ul');

	labels.forEach((label, index) => {
		const color = colors[index];

		const entry = document.createElement('li');

		const colorSpan = document.createElement('span');
		const labelSpan = document.createElement('span');

		colorSpan.classList.add('icon');
		colorSpan.style.background = color;

		labelSpan.innerText = label;

		entry.append(colorSpan, labelSpan);

		list.append(entry);
	});

	legend.append(list);

	return legend;
}
