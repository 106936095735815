import Vue from 'vue';
import Component from 'vue-class-component';
import template from './team-list.html';
import Async from '@/plugins/async-decorator';
import service from '@/services/ordered-list.service';

const props = {
	teams : Array,
};

@Component({
	template,
	props,
})
export class TeamList extends Vue {
	@Async(null, 'teams')
	get list() {
		return Promise.all(this.teams.map(team => service.convertTeam(team, [{
			event   : 'delete',
			icon    : 'trash-alt',
			tooltip : `Remove ${this.$dsl('team')}`,
			hidden  : team.teamType === 'administrative',
		}])));
	}
}

Vue.component('team-list', TeamList);
