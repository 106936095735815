import Vue from 'vue';
import Component from 'vue-class-component';
import template from './company-menu-trigger-button.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

const props = {
	show    : Boolean, // whether the menu is open
	warning : String, // show $danger exclamation with this title on hover
};

@Component({
	template,
	props,
})
export class CompanyMenuTriggerButton extends Vue {
	@Async
	get company() {
		return api.company.get();
	}

	@Async
	get farm() {
		return api.farm.get();
	}
}

Vue.component('company-menu-trigger-button', CompanyMenuTriggerButton);
