import Vue from 'vue';
import Component from 'vue-class-component';
import template from './tile-list.html';

const props = {
	list : Array,
};

@Component({
	template,
	props,
})
export class TileList extends Vue {
	data() {
		return {
			getImagePath,
		};
	}

	emit(event, data) {
		this.$emit('event', {
			event,
			data,
		});
	}
}

Vue.component('tile-list', TileList);

function getImagePath(image) {
	if (/^https?/.test(image)) return image;

	return `/assets/images/${image}`;
}
