const baseUrl = 'https://geo.montageforag.com:3001';
const convertUrl = 'https://geo.montageforag.com:3001/convert';

export default {
	status,
	convertToGeoJson,
};

function status() {
	return new Promise((resolve, reject) => {
		const okay = 200;
		const request = new XMLHttpRequest();

		request.open('GET', baseUrl);
		request.onload = () => request.status === okay
			? resolve()
			: reject();
		request.onerror = () => reject(new Error('Could not connect to the network.'));
		request.send();
	})
		.then(() => true)
		.catch(() => false);
}

function convertToGeoJson(form = {}) {
	const data = createFormData(form);

	return new Promise((resolve, reject) => {
		const okay = 200;
		const request = new XMLHttpRequest();

		request.open('POST', convertUrl);
		request.onload = () => request.status === okay
			? resolve(request.response)
			: reject(getError(JSON.parse(request.response)));
		request.onerror = () => reject(new Error('Could not connect to the network.'));
		request.send(data);
	})
		.then(response => JSON.parse(response))
		.then(collections => collectFeatures(collections));
}

function createFormData(form) {
	return Object.entries(form)
		.reduce((data, [key, value]) => {
			data.append(key, value);

			return data;
		}, new FormData());
}

function collectFeatures(collections) {
	const features = collections.reduce((features, collection) => features.concat(collection.features), []);

	return {
		type : 'FeatureCollection',
		features,
	};
}

function getError(response) {
	const details = (response.error === true && response.msg)
		|| (Array.isArray(response.errors) && response.errors.join('\n'));

	if (!details) return null;

	const message = 'The provided file could not be opened or has been corrupted, please try a different file or format.';
	const error = new Error(message);

	error.details = details;

	return error;
}
