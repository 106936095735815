import { getTileUrl as getReportAllUrl } from '@/services/report-all-usa.service';
import defaultColors from '@/config/colors.json';

const defaultColor = defaultColors[0];
const mapMarker = window.mapMarkerAwesomeFactory(true);

export default {
	zoom   : 4,
	center : {
		lat : 37.0902,
		lng : -95.7129,
	},
	restriction : {
		latLngBounds : {
			north : 85,
			east  : 180,
			south : -85,
			west  : -180,
		},
		strictBounds : true,
	},
	featureStyle   : defaultStyle,
	drawingOptions : null,
	layers         : [{
		label : 'Map',
		value : 'roadmap',
	}, {
		label : 'Satellite',
		value : 'hybrid',
	}],
	overlays : {
		Parcels : { xyzUrl : getReportAllUrl() },
		Soil    : { xyzUrl : 'https://casoilresource.lawr.ucdavis.edu/cgi-bin/mapserv?map=/soilmap2/website/gmap/mapunit_wms.map&layers=ssurgo&layers=ssa&mode=tile&tilemode=gmap&tile={x}+{y}+{z}' }, // eslint-disable-line max-len
	},

	// Google's icons and points of interest
	clickableIcons : false,
	styles         : [{
		featureType : 'poi',
		stylers     : [{ visibility : 'off' }],
	}, {
		featureType : 'transit',
		stylers     : [{ visibility : 'off' }],
	}],

	// Controls
	disableDefaultUI  : true, // eslint-disable-line id-match
	scaleControl      : true,
	fullscreenControl : false,
	mapTypeControl    : false,
	rotateControl     : false,
	streetViewControl : false,
	zoomControl       : false,
	tilt              : 0,
};

function defaultStyle(feature) {
	const yellow = '#ffff00';
	const color = feature.getProperty('hover')
		? yellow
		: feature.getProperty('color');

	return {
		clickable     : !feature.getProperty('disable-click'),
		strokeColor   : color || defaultColor,
		fillColor     : color || defaultColor,
		strokeWidth   : feature.getProperty('stroke-width'),
		strokeOpacity : feature.getProperty('stroke-opacity'),
		fillOpacity   : feature.getProperty('fill-opacity'),
		visible       : feature.getProperty('visible') !== false,
		icon          : {
			url : mapMarker(feature.getProperty('icon'), {
				fill : feature.getProperty('color') || defaultColor,
			}),
		},
	};
}
