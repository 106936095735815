class BillingReplacementMeta {
	constructor() {
		this.account_stale_at = null;
		this.account_flags = null;
		this.usage_stale_at = null;
		this.usage_flags = null;
	}

	clear() {
		this.account_stale_at = null;
		this.account_flags = null;
		this.usage_stale_at = null;
		this.usage_flags = null;
	}

	expire(type = null) {
		const keys = this._refkeys(type);

		if (keys)
			keys.forEach(key => {
				this[key] = null;
			});
		 else
			this.clear();
	}

	expired(type, flags = null) {
		const keys = this._refkeys(type);

		if (!keys) return true;

		const stampcheck = this[keys[0]] > new Date().getTime();
		const flagcheck = !keys[1] || (typeof flags === 'function' ? flags(this[keys[1]]) : this[keys[1]] === flags);

		return !(stampcheck && flagcheck);
	}

	set(type, time = null, flags = null) {
		const keys = this._refkeys(type);

		if (!keys) return;

		this[keys[0]] = time;

		if (keys[1])
			this[keys[1]] = flags;
	}

	_refkeys(key) {
		switch (key) {
			case 'usage':
				return ['usage_stale_at', 'usage_flags'];
			case 'account':
				return ['account_stale_at', 'account_flags'];
			default:
		}
	}

	toJSON() {
		return JSON.stringify({
			account_stale_at : this.account_stale_at,
			account_flags    : this.account_flags,
			usage_stale_at   : this.usage_stale_at,
			usage_flags      : this.usage_flags,
		});
	}
}

export default {
	namespaced : true,

	state : {
		account : null,
		usage   : null,
		meta    : new BillingReplacementMeta(),
	},

	getters : {
		account(state) {
			return state.account;
		},
		isAccountStale(state, getters, rootState, rootGetters) { // eslint-disable-line
			const company = rootGetters['company/company'];

			return state.meta.expired('account', company && company.company_id);
		},
		usage(state) {
			return state.usage;
		},
		isUsageStale(state, getters, rootState, rootGetters) { // eslint-disable-line
			const company = rootGetters['company/company'];

			return state.meta.expired('usage', company && company.company_id);
		},
	},

	mutations : {
		setAccount(state, { account, company_id } = {}) {
			const expireAt = account
				? stamp(10, 'minutes')
				: stamp(30, 'seconds');

			state.account = null;
			state.account = account || null;
			state.usage = null;
			state.meta.expire();
			state.meta.set('account', expireAt, company_id);

			return state.account;
		},

		setUsage(state, { usage, company_id } = {}) {
			state.usage = null;
			state.usage = usage || null;

			const expireAt = usage
				? stamp(10, 'minutes')
				: stamp(30, 'seconds');

			state.meta.set('usage', expireAt, company_id);

			return state.usage;
		},

		expire(state, { type } = {}) {
			state.meta.expire(type);

			return state.meta;
		},

		clear(state) {
			state.account = null;
			state.cards = null;
			state.usage = null;
			state.meta.clear();

			return null;
		},
	},

	actions : {
		setAccount({ commit }, payload) {
			return Promise.resolve(commit('setAccount', payload));
		},
		setUsage({ commit }, payload) {
			return Promise.resolve(commit('setUsage', payload));
		},
		isExpired({ state, getters }, { type, company_id } = {}) {
			return state.meta.expired(type, company_id)
				? Promise.reject()
				: Promise.resolve(getters[type]);
		},
		expire({ commit }, payload) {
			return Promise.resolve(commit('expire', payload));
		},
		clear({ commit }) {
			return Promise.resolve(commit('clear'));
		},
	},
};


function stamp(plus = 0, units = 'ms', base = new Date()) {
	const mult = {
		ms      : 1,
		second  : 1000,
		seconds : 1000,
		minute  : 60000,
		minutes : 60000,
	}[units] || 0;
	const value = base.getTime() + (plus * mult);

	return value ? value : 0;
}
