const component = () => import(/* webpackChunkName: "settings-core" */'./billing-settings');

const redirect = { name : 'billing-settings-dashboard' };

import billingSettingsBuy from './billing-settings-buy/billing-settings-buy.route';
import billingSettingsBuyPackage from './billing-settings-buy-package/billing-settings-buy-package.route';
import billingSettingsBuySubscription from './billing-settings-buy-subscription/billing-settings-buy-subscription.route';
import billingSettingsDashboard from './billing-settings-dashboard/billing-settings-dashboard.route';
import billingSettingsInvoiceDetail from './billing-settings-invoice-detail/billing-settings-invoice-detail.route';
import billingSettingsInvoices from './billing-settings-invoices/billing-settings-invoices.route';
import billingSettingsNewAccount from './billing-settings-new-account/billing-settings-new-account.route';
import billingSettingsUnsubscribe from './billing-settings-unsubscribe/billing-settings-unsubscribe.route';
import billingSettingsWallet from './billing-settings-wallet/billing-settings-wallet.route';

const children = [
	billingSettingsBuy,
	billingSettingsBuyPackage,
	billingSettingsBuySubscription,
	billingSettingsDashboard,
	billingSettingsInvoiceDetail,
	billingSettingsInvoices,
	billingSettingsNewAccount,
	billingSettingsUnsubscribe,
	billingSettingsWallet,
	{
		path : '*',
		redirect,
	},
];

export default {
	children,
	component,
	redirect,
	path : 'billing-settings',
	name : 'billing-settings',
	meta : {
		title          : 'Billing Settings',
		secure         : true,
		skipBilling    : true,
		skipLegal      : true,
		skipOnboarding : true,
		allowance      : 'delete',
	},
};
