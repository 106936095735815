import api from './api';
import company from './company';

export default {
	list,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	CloudStorage : _extend,
};

function list(filters) {
	return filters
		? api.list('cloud-storage', filters)
			.then(list => list.map(cloudStorage => _extend(cloudStorage)))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? list({ company_id }) : []);
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(cloudStorage_id) {
	return api.read('cloud-storage', cloudStorage_id)
		.then(cloudStorage => _extend(cloudStorage));
}

function create(cloudStorage) {
	return api.create('cloud-storage', cloudStorage);
}

function update(cloudStorage) {
	return api.update('cloud-storage', cloudStorage.cloudStorage_id, cloudStorage);
}

function save(cloudStorage) {
	return cloudStorage.cloudStorage_id ? update(cloudStorage) : create(cloudStorage);
}

function _delete(cloudStorage_id) {
	return api.delete('cloud-storage', cloudStorage_id);
}

function _extend(cloudStorage = {}) {
	return Object.assign({

	}, cloudStorage);
}
