const component = () => import(/* webpackChunkName: "settings-core" */'./export-account');

export default {
	component,
	path : 'export-account',
	name : 'export-account',
	meta : {
		title  : 'Export User Details',
		secure : true,
	},
};
