import defaultColors from '@/config/colors.json';

const defaultColor = defaultColors[0];
const mapMarker = window.mapMarkerAwesomeFactory(true);

export default {
	drawingMode           : null,
	drawingControl        : false,
	drawingControlOptions : { drawingModes : ['marker', 'polyline', 'polygon'] },
	markerOptions         : {
		draggable : true,
		icon      : {
			url : mapMarker('', {
				fill : defaultColor,
			}),
		},
	},
	polygonOptions : {
		strokeColor : defaultColor,
		fillColor   : defaultColor,
		draggable   : false,
		editable    : true,
	},
	polylineOptions : {
		draggable   : true,
		editable    : true,
		strokeColor : defaultColor,
	},
};
