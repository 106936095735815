import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "developer-test" */'./user-location-button');

export default {
	component,
	path : 'user-location-button',
	name : 'user-location-button-test',
	meta : {
		title  : `${dsl('User')} Location Button Test`,
		secure : false,
	},
};
