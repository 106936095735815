export default {
	'company' : 'customer',
	'farm'    : 'property',
	'field'   : 'array',
	'user'    : 'user',

	'crop map'  : 'property map',
	'ranch map' : 'property map',

	'farmable acres' : 'boundary acres',
	'boundary acres' : 'boundary acres',

	'email-placeholder' : 'user@montage.solar',

	'benefits-map-keyword-1' : 'owner',
	'benefits-map-keyword-2' : 'square footage',
	'benefits-map-keyword-3' : 'parcel number',

	'benefits-connection-keyword-1' : '',
	'benefits-connection-keyword-2' : '',

	'onboarding-progress-title'     : 'Onboarding Progress',
	'onboarding-progress-tagline'   : '',
	'onboarding-progress-style'     : 'default',
	'onboarding-progress-farm-icon' : 'eclipse',
};
