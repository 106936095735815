import Vue from 'vue';
import Buefy from 'buefy';
import Component from 'vue-class-component';

Vue.use(Buefy, {
	defaultIconPack : 'far',
});

// Hooks from https://router.vuejs.org/en/advanced/navigation-guards.html
Component.registerHooks([
	'beforeRouteEnter',
	'beforeRouteUpdate',
	'beforeRouteLeave',
]);

import './base-components';
import './buttons';
import './cards';
import './components';
import './directives';
import './filters';
import './flyovers';
import './fragments';
import './icons';
import './inputs';
import './layouts';
import './lists';
import './panels';
import './tabs';
import './lib/outdated-browser.lib';

import dslService from '@/services/dsl.service';
import router from './views';
import store from './storage';
import brandConfig from '@/config/brand';

Vue.prototype.$dsl = dslService; // eslint-disable-line id-match
Vue.prototype.$BRAND = brandConfig;
Vue.prototype.$BRAND_NAME = brandConfig.BRAND_NAME;

export const app = new Vue({
	router,
	store,
	el : '#app',
});
