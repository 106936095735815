import Vue from 'vue';
import Component from 'vue-class-component';
import Async from '@/plugins/async-decorator';
import api from '@/api';

const props = {
	value : Object,
};

@Component({
	template : '<span>{{ type }}</span>',
	props,
})
export class CloudFileType extends Vue {
	@Async
	get type() {
		if (!this.value) return '';
		if (this.value._folder) return 'Folder';

		return api.fileType.get(this.value.name)
			.then(({ type, label }) => label || type)
			.catch(() => 'Unknown');
	}
}

Vue.component('cloud-file-type', CloudFileType);
