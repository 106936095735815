import api from './api-storage-list';
import company from './company';

export default {
	list,
	count,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	Team : _extend,
};

function list(filters) {
	return filters
		? api.list('team', filters, {
			sortBy : ['teamType', 'teamName'],
		})
			.then(list => list.map(team => _extend(team)))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? list({ company_id }) : []);
}

function count(filters) {
	return filters
		? api.count('team', filters)
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? count({ company_id }) : { count : 0 });
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(team_id) {
	return api.read('team', team_id)
		.then(team => _extend(team));
}

function create(team) {
	return Promise.resolve(team.company_id ? team : company.get())
		.then(({ company_id }) => api.create('team', Object.assign({ company_id }, team)));
}

function update(team) {
	return api.update('team', team.team_id, team);
}

function save(team) {
	return team.team_id ? update(team) : create(team);
}

function _delete(team_id) {
	return api.delete('team', team_id);
}

function _extend(team = {}) {
	return Object.assign({
		teamName   : '',
		teamAccess : {},
	}, team);
}
