import Vue from 'vue';
import Component from 'vue-class-component';
import template from './select-company.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';
import service from '@/services/modal.service';
import { isDemoUser, checkFarmPrivilege } from '@/services/permission.service';

@Component({ template })
export default class SelectCompany extends Vue {
	data() {
		return {
			jump_id      : null,
			company      : null,
			farm         : null,
			error        : null,
			loading      : null,
			shouldCreate : null,
		};
	}

	created() {
		this.loading = true;

		this.jump_id = this.$route.query.j || null;

		return this.shouldRedirect()
			.then(redirect => redirect && this.$router.push({ name : process.env.LANDING_PAGE }))
			.finally(() => { this.loading = false });
	}

	@Async
	get isDemo() {
		return isDemoUser();
	}

	@Async(false)
	get canCreate() {
		return checkFarmPrivilege('create')
			.then(okay => okay && !this.isDemo);
	}

	shouldRedirect() {
		return Promise.all([
			api.company.list(),
			api.farm.list(),
		])
			.then(([companies, farms]) => {
				const onlyPossible = companies.length < 1 || (companies.length === 1 && farms.length === 1);
				const selected = this.jump_id ? companies.find(company => company.company_id === this.jump_id) : null;

				if (selected)
					api.company.set(selected);

				return onlyPossible || selected;
			});
	}

	select({ company, farm }) {
		this.company = company;
		this.farm = farm;
	}

	skip() {
		this.loading = true;

		return this.$router.push({ name : 'user-profile' });
	}

	addCompany() {
		if (this.loading) return;

		this.loading = true;
		this.error = null;

		return service.launchModal('add-company')
			.then(({ companyName, farmName }) => api.company.create({ companyName })
				.then(() => api.farm.create({ farmName })))
			.then(() => this.$router.push({ name : 'map' }))
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	submit() {
		const company = this.company;
		const farm = this.farm;

		if (!company || !farm || this.loading) return;

		this.loading = true;

		return api.company.set(company)
			.then(() => this.shouldCreate
				? api.farm.create(farm)
				: api.farm.set(farm))
			.then(() => this.$router.push({ name : 'map' }))
			.finally(() => { this.loading = false });
	}
}
