import api from '../api';
import company from '../company';

export default {
	list,
	find,
	read,
	register,
	create,
	update,
	save,
	delete : _delete,

	AccessKey : _extend,
};

function list(filters) {
	return company.get()
		.then(({ company_id }) => Object.assign({ company_id }, filters))
		.then(filters => api.list('work-order-access-key', filters))
		.then(list => list.map(accessKey => _extend(accessKey)));
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(accessKey_id) {
	return api.read('work-order-access-key', accessKey_id)
		.then(accessKey => _extend(accessKey));
}

function register() {
	return company.get()
		.then(({ company_id }) => api.create('work-order-access-key/registration', { company_id }));
}

function create(accessKey) {
	return company.get()
		.then(({ company_id }) => Object.assign({ company_id }, accessKey))
		.then(accessKey => api.create('work-order-access-key', accessKey));
}

function update(accessKey) {
	return api.update('work-order-access-key', accessKey.accessKey_id, accessKey);
}

function save(accessKey) {
	return accessKey.accessKey_id ? update(accessKey) : create(accessKey);
}

function _delete(accessKey_id) {
	return api.delete('work-order-access-key', accessKey_id);
}

function _extend(accessKey = {}) {
	return Object.assign({
		accessKey : null,
	}, accessKey);
}
