export default function collegeProgressTree(completion) {
	return [[
		'             ',
		'             ',
		'             ',
		'     |       ',
		'     |       ',
		'     |       ',
	], [
		'    /        ',
		'    \\        ',
		'     \\       ',
		'     |       ',
		'     |       ',
		'     |       ',
	], [
		'    /  |     ',
		'    \\  \\     ',
		'     \\_/     ',
		'     |       ',
		'     |       ',
		'     |       ',
	], [
		'  | /  |     ',
		'  \\ \\  \\     ',
		'   \\_\\_/     ',
		'     |       ',
		'     |       ',
		'     |       ',
	], [
		'  | /  | /   ',
		'  \\ \\  \\/    ',
		'   \\_\\_/     ',
		'     |       ',
		'     |       ',
		'     |       ',
	], [
		'  | /  | /   ',
		'\\ \\ \\  \\/    ',
		' \\ \\_\\_/     ',
		'  \\__|       ',
		'     |       ',
		'     |       ',
	], [
		'  | /  | /   ',
		'\\ \\ \\  \\/  / ',
		' \\ \\_\\_/  /  ',
		'  \\__|   /   ',
		'     |__/    ',
		'     |       ',
	], [
		'  | /  | /   ',
		'\\ \\ \\  \\/  / ',
		' \\ \\_\\_/  / /',
		'  \\__|   /_/ ',
		'     |__/    ',
		'     |       ',
	], [
		'  | /  | /   ',
		'\\ \\ \\  \\/  / ',
		' \\ \\_\\_/  / /',
		'\\_\\__|   /_/ ',
		'     |__/    ',
		'     |       ',
	]][completion];
}
