import storage from '@/services/storage.service';
import { AcceptInvitationModal } from '@/modals/accept-invitation-modal/accept-invitation-modal';
import { AccessUpsellModal } from '@/modals/access-upsell-modal/access-upsell-modal';
import { AddCompanyModal } from '@/modals/add-company-modal/add-company-modal';
import { AddFarmModal } from '@/modals/add-farm-modal/add-farm-modal';
import { AddUserModal } from '@/modals/add-user-modal/add-user-modal';
import { ConnectCeresImagingFarmModal } from '@/modals/connect-ceres-imaging-farm-modal/connect-ceres-imaging-farm-modal';
import { ConnectCeresImagingFieldModal } from '@/modals/connect-ceres-imaging-field-modal/connect-ceres-imaging-field-modal';
import { DeclineInvitationModal } from '@/modals/decline-invitation-modal/decline-invitation-modal';
import { ModalProgrammatic } from 'buefy/dist/components/modal';
import { ModalVerification } from '@/modals/modal-verification/modal-verification';
import { OnboardingProgressModal } from '@/modals/onboarding-progress-modal/onboarding-progress-modal';
import { ParcelClickUpsellModal } from '@/modals/parcel-click-upsell-modal/parcel-click-upsell-modal';
import { PrintSettingsModal } from '@/modals/print-settings-modal/print-settings-modal';
import { SelectCompanyModal } from '@/modals/select-company-modal/select-company-modal';
import { TestModal } from '@/modals/test/test';
import { VerifyDeletionModal } from '@/modals/verify-deletion-modal/verify-deletion-modal';

const modalTypes = {
	'accept-invitation'           : AcceptInvitationModal,
	'access-upsell'               : AccessUpsellModal,
	'add-company'                 : AddCompanyModal,
	'add-farm'                    : AddFarmModal,
	'add-user'                    : AddUserModal,
	'connect-ceres-imaging-farm'  : ConnectCeresImagingFarmModal,
	'connect-ceres-imaging-field' : ConnectCeresImagingFieldModal,
	'decline-invitation'          : DeclineInvitationModal,
	'onboarding-progress'         : OnboardingProgressModal,
	'parcel-click-upsell'         : ParcelClickUpsellModal,
	'print-settings'              : PrintSettingsModal,
	'select-company'              : SelectCompanyModal,
	'test'                        : TestModal,
	'verify-deletion'             : VerifyDeletionModal,
};

export default {
	modalTypes,

	launchModal,
	launchVerification,
};

const activeModals = {}; // { <modal_id> : Boolean }

function launch({ modal_id, component, canCancel = true, onSubmit, props }) {
	return new Promise((resolve, reject) => {
		if (modal_id && activeModals[modal_id]) return reject();

		activeModals[modal_id] = true;

		const modal = ModalProgrammatic.open({
			component,
			canCancel,

			onCancel : () => {
				reject();
				activeModals[modal_id] = false;
			},

			props : Object.assign({}, props, {
				submit : data => Promise.resolve(onSubmit ? onSubmit(data) : data)
					.then(data => {
						resolve(data);
						modal.close();
						activeModals[modal_id] = false;
					}),
				cancel : () => {
					reject();
					modal.cancel('button');
					activeModals[modal_id] = false;
				},
			}),
		});
	});
}

function launchModal(modal_id, props = {}) {
	const component = modalTypes[modal_id];

	if (!component) throw new Error(`Unknown modal: '${modal_id}'`);

	const buefyLauncher = props => launch({
		component,
		props,
		canCancel : props.canCancel,
		modal_id  : props.modal_id || modal_id,
		onSubmit  : props.onSubmit || (data => data),
	});

	const montageLauncher = props => storage.push('modal', {
		component,
		props,
	});

	const launcher = {
		'accept-invitation'           : montageLauncher,
		'access-upsell'               : montageLauncher,
		'add-company'                 : montageLauncher,
		'add-farm'                    : montageLauncher,
		'add-user'                    : buefyLauncher,
		'connect-ceres-imaging-farm'  : montageLauncher,
		'connect-ceres-imaging-field' : montageLauncher,
		'decline-invitation'          : montageLauncher,
		'onboarding-progress'         : montageLauncher,
		'parcel-click-upsell'         : montageLauncher,
		'print-settings'              : montageLauncher,
		'select-company'              : montageLauncher,
		'test'                        : montageLauncher,
		'verify-deletion'             : montageLauncher,
	}[modal_id] || buefyLauncher;

	return launcher(props);
}

function launchVerification(props) {
	const component = ModalVerification;

	return launch({
		component,
		props,
		modal_id : props.modal_id,
	});
}
