const component = () => import(/* webpackChunkName: "developer-test" */'./app-notification');

export default {
	component,
	path : 'app-notification',
	name : 'app-notification-test',
	meta : {
		title  : 'App Notification',
		secure : false,
	},
};
