import component from './logout';

export default {
	component,
	path : '/logout',
	name : 'logout',
	meta : {
		title : 'Logout',
	},
};
