const component = () => import(/* webpackChunkName: "settings-core" */'./billing-settings-invoice-detail');

export default {
	component,
	path  : 'invoices/:id',
	props : true,
	name  : 'billing-settings-invoice-detail',
	meta  : {
		title           : 'Billing Settings Invoice Detail',
		secure          : true,
		onCompanyChange : { name : 'billing-settings' },
	},
};
