const component = () => import(/* webpackChunkName: "developer-test" */'./async-loading-decorator');

export default {
	component,
	path : 'async-loading-decorator',
	name : 'async-loading-decorator-test',
	meta : {
		title  : 'Async Loading Decorator Test',
		secure : false,
	},
};
