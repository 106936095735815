const delay = 100;

export default {
	openDialog,
};

function openDialog() {
	return new Promise(resolve => {
		window.print();
		setTimeout(resolve, delay);
	});
}
