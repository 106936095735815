import Vue from 'vue';
import Component from 'vue-class-component';
import template from './access-upsell-modal.html';
import permissions from '@/services/permission.service';
import Async from '@/plugins/async-decorator';

const props = {
};

@Component({
	template,
	props,
})
export class AccessUpsellModal extends Vue {
	@Async
	get isAdmin() {
		return permissions.isAdmin();
	}

	get listeners() {
		return Object.assign({}, this.$listeners, {
			submit : () => null,
		});
	}

	submit() {
		if (this.isAdmin)
			this.$router.push({
				name  : 'billing-settings-buy-subscription',
				query : { farms : 1 },
			});

		this.$emit('submit', null);
	}
}
