import Vue from 'vue';
import Component from 'vue-class-component';
import template from './farm-tile-list.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import pluralize from 'pluralize';
import { checkFarmPrivilege, checkCompanyPrivilege } from '@/services/permission.service';
import dslService from '@/services/dsl.service';

const props = {
	list : Array,
};

@Component({
	template,
	props,
})
export class FarmTileList extends Vue {
	data() {
		return {
			subtitles   : {}, // { <farm_id> : Promise || String }
			permissions : {}, // { <farm_id> : Promise || { canUpdate, canDelete } }
		};
	}

	get farmNameColumn() {
		return dslService('Farm');
	}

	@Async(false)
	get canCreate() {
		return checkCompanyPrivilege('create');
	}

	@Watch.immediate('list')
	watchList() {
		if (!this.list) return;

		const subtitles = {};
		const permissions = {};

		this.list.forEach(farm => {
			const { farm_id } = farm;

			subtitles[farm_id] = this.loadSubtitle(farm);
			permissions[farm_id] = this.loadPermissions(farm);
		});

		this.subtitles = subtitles;
		this.permissions = permissions;
	}

	loadSubtitle(farm) {
		const { farm_id } = farm;

		return this.subtitles[farm_id] || Promise.all([
			api.field.count({ farm_id }),
			api.marker.count({ farm_id }),
		])
			.then(([{
				count : fieldCount,
			}, {
				count : markerCount,
			}]) => {
				this.subtitles[farm_id] = [
					`${fieldCount} ${pluralize(this.$dsl('fields'), fieldCount)}`,
					`${markerCount} ${pluralize(this.$dsl('markers'), markerCount)}`,
				].join(', ');
			});
	}

	loadPermissions(farm) {
		const { farm_id } = farm;

		return this.permissions[farm_id] || Promise.all([
			checkFarmPrivilege('update', farm),
			checkFarmPrivilege('delete', farm),
		])
			.then(([canUpdate, canDelete]) => {
				this.permissions[farm_id] = {
					update : canUpdate,
					delete : canDelete,
				};
			});
	}

	getSubtitle({ farm_id }) {
		if (typeof this.subtitles[farm_id] === 'string') return this.subtitles[farm_id];

		return 'Loading...';
	}

	hasPermission({ farm_id }, action) {
		if (!this.permissions[farm_id]) return false;

		return this.permissions[farm_id][action] || false;
	}
}

Vue.component('farm-tile-list', FarmTileList);
