import Vue from 'vue';
import Component from 'vue-class-component';
import template from './timeseries-vendor-dropdown.html';

const props = {
	value : String,
};

@Component({
	template,
	props,
})
export class TimeseriesVendorDropdown extends Vue {
	get list() {
		return [{
			label : 'PumpSight',
			value : 'pumpsight',
		}];
	}
}

Vue.component('timeseries-vendor-dropdown', TimeseriesVendorDropdown);
