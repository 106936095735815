import Vue from 'vue';
import Component from 'vue-class-component';
import template from './parcel-searchbar-type-selector.html';

const props = {
	value : String,
};

@Component({
	template,
	props,
	watch : {
		value : 'matchValue',
	},
})
export default class ParcelSearchbarTypeSelector extends Vue {
	data() {
		return {
			itype : null,
		};
	}

	get type() {
		return this.itype;
	}

	set type(value) {
		this.itype = value;

		this.$emit('input', value);
	}

	matchValue(value) {
		if (this.itype !== value)
			this.itype = value;
	}
}
