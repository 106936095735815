import Vue from 'vue';
import Component from 'vue-class-component';
import template from './add-user-modal.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

const props = {
	submit : Function,
	cancel : Function,
};

@Component({
	template,
	props,
})
export class AddUserModal extends Vue {
	data() {
		return {
			teamLabel : `Assign to which ${this.$dsl('team')}?`,

			user : {
				email   : '',
				message : null,
				team    : {},
				get team_id() {
					return this.team.team_id || null;
				},
			},

			error    : null,
			loading  : null,
			multiple : null, // invite multiple users at once
		};
	}

	@Async
	get users() {
		this._loading = this._loading || 0;
		this.loading = ++this._loading;

		return api.user.list()
			.finally(() => { this.loading = --this._loading });
	}

	@Async
	get invitations() {
		this._loading = this._loading || 0;
		this.loading = ++this._loading;

		return api.invitation.list()
			.finally(() => { this.loading = --this._loading });
	}

	@Async
	get teams() {
		this._loading = this._loading || 0;
		this.loading = ++this._loading;

		return api.team.list()
			.finally(() => { this.loading = --this._loading });
	}

	validateEmail(email) {
		if (this.users && this.users.some(user => user.email === email))
			return `${email} is already a ${this.$dsl('user')} of this ${this.$dsl('company')}`;

		if (this.invitations && this.invitations.some(invite => invite.email === email))
			return `${email} has already been ${this.$dsl('invited')}`;
	}

	onSubmit() {
		if (this.loading) return;

		this.error = null;
		this.loading = true;

		if (this.multiple)
			return Promise.resolve(this.user.email.split('\n'))
				.then(list => list.filter(email => email))
				.then(list => list.map(email => [
					this.validateEmail(email),
					email,
				]))
				.then(list => Promise.all(list.map(([error, email]) => error ? Promise.reject(error) : email)))
				.then(list => list.map(email => Object.assign({}, this.user, { email })))
				.then(list => Promise.all(list.map(invitation => api.invitation.create(invitation))))
				.then(list => this.submit(list))
				.catch(error => { this.error = error })
				.finally(() => { this.loading = false });

		return api.invitation.create(this.user)
			.then(invitation => this.submit(invitation))
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}
