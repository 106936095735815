import './vendors';

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './timeseries-source-form.html';
import api from '@/api';

const props = {
	value : Object, // existing Timeseries Source record to edit.
};

@Component({
	template,
	props,
})
export class TimeseriesSourceForm extends Vue {
	data() {
		return {
			loading : null,
			source  : Object.assign({
				vendor : 'pumpsight',
			}, this.value),
		};
	}

	get subcomponent() {
		return `${this.source.vendor}-source-form`;
	}

	saveSource() {
		this.$emit('error');

		this.loading = true;

		return api.company.get()
			.then(({ company_id }) => api.timeseries.source.save(Object.assign({ company_id }, this.source)))
			.then(source => { this.$emit('input', source) })
			.catch(error => { this.$emit('error', error) })
			.finally(() => { this.loading = false });
	}
}

Vue.component('timeseries-source-form', TimeseriesSourceForm);
