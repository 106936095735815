import Vue from 'vue';
import Vuex from 'vuex';
import localStorage from '../services/local-storage.service';
import sessionStorage from '../services/session-storage.service';

import apiProgress from './api-progress.storage';
import appNotification from './app-notification.storage';
import auth from './auth.storage';
import billingReplacement from './billing-replacement.storage';
import card from './card.storage';
import company from './company.storage';
import config from './config.storage';
import farm from './farm.storage';
import field from './field.storage';
import flyover from './flyover.storage';
import line from './line.storage';
import marker from './marker.storage';
import modal from './modal.storage';
import route from './route.storage';
import team from './team.storage';
import test from './test.storage';
import user from './user.storage';

Vue.use(Vuex);

export default new Vuex.Store({
	state : {
		group : null,
	},

	getters : {
	},

	mutations : {
	},

	actions : {
		reset({ commit }) { // eslint-disable-line max-statements
			localStorage.clear();
			sessionStorage.clear();

			commit({ type : 'user/set' });
			commit({ type : 'auth/set' });
			commit({ type : 'company/set' });
			commit({ type : 'config/set' });
			commit({ type : 'farm/set' });
			commit({ type : 'flyover/clear' });
			commit({ type : 'route/clear' });
			commit({ type : 'billingReplacement/clear' });
		},
	},

	modules : {
		apiProgress,
		appNotification,
		auth,
		billingReplacement,
		card,
		company,
		config,
		farm,
		field,
		flyover,
		line,
		marker,
		modal,
		route,
		team,
		test,
		user,
	},
});
