import Vue from 'vue';
import Component from 'vue-class-component';
import template from './daterange-picker.html';

const props = {
	value : {
		type     : Object, // { startDate, endDate }
		required : true,
	},
};

@Component({
	template,
	props,
})
export class DaterangePicker extends Vue {
	mounted() {
		const { startDate, endDate } = this.value;

		if (!startDate || !endDate) {
			const range = -30;
			const date = new Date();

			date.setHours(0, 0, 0, 0);
			date.setDate(date.getDate() + range);

			return this.updateDateRange(startDate || date, endDate || this.maxDate);
		}
	}

	get maxDate() {
		const date =  new Date();

		date.setDate(date.getDate() + 1);
		date.setHours(0, 0, 0, 0);

		return date;
	}

	updateStartDate(startDate) {
		return this.updateDateRange(startDate, this.value.endDate);
	}

	updateEndDate(endDate) {
		return this.updateDateRange(this.value.startDate, endDate);
	}

	updateDateRange(startDate, endDate) {
		return this.$emit('input', Object.assign(this.value || {}, {
			startDate,
			endDate,
		}));
	}
}

Vue.component('daterange-picker', DaterangePicker);
