import Vue from 'vue';
import Component from 'vue-class-component';
import template from './field-details-panel.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import { checkPrivilege, isDemoUser, showFarmUpsell } from '@/services/permission.service';
import modalService from '@/services/modal.service';

const props = {
	value : Object, // Field record
};

@Component({
	template,
	props,
})
export class FieldDetailsPanel extends Vue {
	data() {
		return {
			tab_id : null,
			field  : this.value,
		};
	}

	get fieldName() {
		return this.value.fieldName;
	}

	set fieldName(fieldName) {
		this.$emit('error');
		this.$emit('loading', true);

		return api.field.update(Object.assign(this.value, { fieldName }))
			.then(field => { this.$emit('input', field) })
			.catch(error => { this.$emit('error', error) })
			.finally(() => { this.$emit('loading', false) });
	}

	@Async(false)
	get isDemoUser() {
		return isDemoUser();
	}

	@Async(true)
	get readonly() {
		return Promise.resolve(checkPrivilege('update', this.value))
			.then(result => !result || this.isDemoUser);
	}

	@Async(false)
	get showFarmUpsell() {
		return showFarmUpsell(true);
	}

	@Watch('value')
	watchValue() {
		this.field = this.value;
	}

	updateAttributes(attributes) {
		this.$emit('input', Object.assign(this.field, { attributes }));
	}

	showUpsell() {
		return modalService.launchModal('access-upsell', {})
			.catch(() => null);
	}
}

Vue.component('field-details-panel', FieldDetailsPanel);
