import Vue from 'vue';
import Component from 'vue-class-component';
import template from './editable-input.html';

const props = {
	value : String,
};

@Component({
	template,
	props,
})
export class EditableInput extends Vue {
	data() {
		return {
			editing : false,
			input   : this.value,
		};
	}

	edit() {
		this.input = this.value;
		this.editing = true;
	}

	save() {
		this.editing = false;
		this.$emit('input', this.input);
	}

	cancel() {
		this.editing = false;
	}
}

Vue.component('editable-input', EditableInput);
