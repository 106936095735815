import Vue from 'vue';
import Component from 'vue-class-component';
import template from './ghetto-list.html';

const props = {
	list : Array,
};

@Component({
	template,
	props,
})
export class GhettoList extends Vue {
	get columns() {
		const entry = this.list[0];
		const blacklist = [
			'createdAt',
			'createdBy',
			'updatedAt',
			'updatedBy',
			'deletedAt',
			'version',
			'permissions',
		];

		return Object.keys(entry)
			.filter(key => typeof entry[key] !== 'object')
			.filter(key => !blacklist.includes(key))
			.filter(key => !/__|_id$/.test(key));
	}
}

Vue.component('ghetto-list', GhettoList);
