export default function locationLookup(request) {
	const { OK, ZERO_RESULTS } = window.google.maps.GeocoderStatus;
	const geocoder = new window.google.maps.Geocoder();

	return new Promise((resolve, reject) => {
		geocoder.geocode(request, (results, status) => {
			if (status === OK) return resolve(results);
			if (status === ZERO_RESULTS) return resolve(results || []);

			return reject(status);
		});
	});
}
