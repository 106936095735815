import Vue from 'vue';
import Component from 'vue-class-component';
import template from './basic-input.html';

const props = {
	value       : String,
	label       : String,
	type        : String,
	message     : String,
	messageType : String, // ['is-danger', 'is-info', etc]
};

@Component({
	template,
	props,
})
export class BasicInput extends Vue {
	get element() {
		return this.$refs.input.$refs.input;
	}

	setCustomValidity(message) {
		this.element.setCustomValidity(message);
	}
}

Vue.component('basic-input', BasicInput);
