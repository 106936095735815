import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-controls.html';

const props = {
	value : Object,
};

@Component({
	template,
	props,
})
export class MapControls extends Vue {
	get map() {
		return this.value && this.value.map;
	}
}

Vue.component('map-controls', MapControls);
