import Vue from 'vue';
import Component from 'vue-class-component';
import template from './add-map-feature-panel.html';

const props = {
	value : {
		type     : Object, // { Map, features : [] }
		required : true,
	},
};

@Component({
	template,
	props,
})
export class AddMapFeaturePanel extends Vue {
	data() {
		return {
		};
	}

	get map() {
		return this.value.map;
	}

	get type() {
		return this.value.type || null;
	}

	select(panel, value) {
		return this.$emit('panel', {
			panel,
			value,
		});
	}
}

Vue.component('add-map-feature-panel', AddMapFeaturePanel);
