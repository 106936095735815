import api from './api';

export default {
	list,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	File : _extend,
};

function list(filters) {
	return api.list('file', filters)
		.then(list => list.map(file => _extend(file)));
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(file_id) {
	return api.read('file', file_id)
		.then(file => _extend(file));
}

function create({ cloudFolder_id, path, data }) {
	const url = [
		'file',
		cloudFolder_id,
		...(path || '.').split('/').map(fragment => encodeURIComponent(fragment)),
		path.indexOf(data.name) === path.length - data.name.length
			? null
			: encodeURIComponent(data.name),
	].filter(part => part).join('/');

	return api.create(url, data, {
		params : {
			size : data.size,
		},
		headers : {
			'Content-Type' : 'application/octet-stream',
		},
	});
}

function update(file) {
	return api.update('file', file.file_id, file);
}

function save(file) {
	return file.file_id ? update(file) : create(file);
}

function _delete(file_id) {
	return api.delete('file', file_id);
}

function _extend(file = {}) {
	return Object.assign({
		type : file._folder ? 'folder' : 'file',

	}, file);
}
