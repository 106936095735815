import Vue from 'vue';
import Component from 'vue-class-component';
import template from './attributes.html';
import CreateAttributeRow from './create-attribute-row/create-attribute-row';
import EditAttributeRow from './edit-attribute-row/edit-attribute-row';
import ReadonlyAttributeRow from './readonly-attribute-row/readonly-attribute-row';

const components = {
	CreateAttributeRow,
	EditAttributeRow,
	ReadonlyAttributeRow,
};

const props = {
	value       : Array, // list of attribute records
	placeholder : Object, // {label, value} of placeholder strings
	readonly    : Boolean,
	editonly    : Boolean,
	defaults    : Array,
};

@Component({
	template,
	components,
	props,
})
export class Attributes extends Vue {
	get attributes() {
		return this.value.map(attribute => ({
			key : attribute.attribute_id || Date.now() + Math.random(),
			attribute,
		}));
	}

	get labels() {
		const defaults = this.defaults || [];
		const attributes = this.value || [];

		return [
			...defaults.map(attribute => attribute.label || attribute),
			...attributes.map(({ label }) => label),
		].filter((label, index, list) => list.indexOf(label) === index);
	}

	move({ modifier, attribute }) {
		const attributes = modifier(attribute, this.value);

		return attributes.forEach(attribute => this.$emit('update', attribute));
	}
}

Vue.component('attributes', Attributes);
