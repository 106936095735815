const component = () => import(/* webpackChunkName: "settings-core" */'./pending-invitations');

export default {
	component,
	path : 'pending-invitations',
	name : 'pending-invitations',
	meta : {
		title  : 'Pending Invitations',
		secure : true,
	},
};
