import mapService from '../google.lib';

////

const defaultZoom = 17;

////

export default (_, options) => ({
	get isLocked() {
		return !options.allowRecenter && options.isLocked;
	},

	get defaultZoom() {
		return defaultZoom;
	},

	flyToFeature(feature_id) {
		return Promise.resolve(typeof feature_id.getGeometry === 'function'
			? feature_id
			: this._map.data.getFeatureById(feature_id))
			.then(feature => feature && feature.getGeometry())
			.then(geometry => geometry && this._flyToFeature(geometry));
	},

	flyToGeometry(geometry) {
		return [
			geometry.viewport ? () => this._fitBounds(geometry.viewport) : null,
			geometry.bounds ? () => this._fitBounds(geometry.bounds) : null,
			geometry.getBounds ? () => this._fitBounds(geometry.getBounds()) : null,
			geometry.geometry ? () => this._fitToFeature(geometry) : null,
			Array.isArray(geometry) ? () => this._fitToFeatures(geometry) : null,
			() => this._panTo(geometry.location),
		].find(handler => handler)();
	},

	recenter() {
		this._fitToFeatures(this._map.data);
	},

	_flyToFeature(geometry) {
		const bounds = new window.google.maps.LatLngBounds();

		geometry.forEachLatLng(latLng => {
			bounds.extend(latLng);
		});

		return this._fitBounds(bounds);
	},

	_fitBounds(bounds) {
		if (!bounds) return;
		if (bounds.isEmpty && bounds.isEmpty()) return;

		return this._map.fitBounds(bounds);
	},

	_fitToFeatures(features = []) {
		if (this.isLocked) return;

		const array = [];

		this._map.data.forEach(feature => {
			array.push(feature);
		});

		const bounds = mapService.getBoundsOfFeatures(features.length ? features : array);

		return this._fitBounds(bounds);
	},

	_fitToFeature(feature) {
		const array = [];

		this._map.data.forEach(feature => {
			array.push(feature);
		});

		const bounds = mapService.getBoundsOfFeature(feature);

		return this._fitBounds(bounds);
	},

	_panTo(location) {
		this._map.setZoom(this.defaultZoom);

		return this._map.panTo(location);
	},
});
