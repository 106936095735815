const component = () => import(/* webpackChunkName: "developer-test" */'./left-right-dropdown');

export default {
	component,
	path : 'left-right-dropdown',
	name : 'left-right-dropdown-test',
	meta : {
		title  : 'Left Right Dropdown Test',
		secure : false,
	},
};
