import Vue from 'vue';
import Component from 'vue-class-component';
import template from './ceres-imaging-integration-list-item.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';

const props = {
	value : {
		type     : Object, // CeresImaging integration record
		required : true,
	},
};

@Component({
	template,
	props,
})
export class CeresImagingIntegrationListItem extends Vue {
	@AsyncLoading
	get list() {
		const { ceresImaging_id } = this.value;

		return api.ceresImagingField.list({ ceresImaging_id });
	}
}

Vue.component('ceres-imaging-integration-list-item', CeresImagingIntegrationListItem);
