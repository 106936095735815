import api from '@/api';
import permissions from '@/services/permission.service';
import lockoutService from '@/services/billing-lockout.service';

import component from './onboarding';

import onboardingCompanyInformation from './onboarding-company-information/onboarding-company-information.route.js';
import onboardingAccountInformation from './onboarding-account-information/onboarding-account-information.route';
import onboardingFeatures from './onboarding-features/onboarding-features.route';

const children = [
	onboardingCompanyInformation,
	onboardingAccountInformation,
	onboardingFeatures,
];

export default {
	children,
	component,
	path : '/onboarding',
	name : 'onboarding',
	meta : {
		title  : 'Onboarding',
		secure : false,
	},
	async beforeEnter(to, from, next) { // eslint-disable-line
		console.log(`onboarding.route.js:27 to ${to && to.name} from ${from && from.name}`);
		if (to.name !== 'onboarding') return next();

		const showFeatures = Boolean(process.env.ONBOARDING_FEATURES_IFRAME || process.env.ONBOARDING_FEATURES_VIDEO);
		const landing = process.env.LANDING_PAGE || 'map';

		let showCompany = false;
		let showAccount = false;

		try {
			showCompany = await missingCompanyOrFarm();
			showAccount = await missingAccount();
		}
		catch (error) {
			// This is intentional
			// If the above fails, default to showing 'public' onboaring pages.
			// -AM Mar 17, 2022
			true; // eslint-disable-line no-unused-expressions
		}

		const routes = [
			showFeatures ? onboardingFeatures.name : null,
			showCompany ? onboardingCompanyInformation.name : null,
			showAccount ? onboardingAccountInformation.name : null,
			landing,
		].filter(Boolean);

		return next({
			name   : routes[0],
			params : {
				next : routes.slice(1),
			},
		});
	},
};

async function missingCompanyOrFarm() {
	const hasCompany = await api.company.get().catch(() => null);

	if (!hasCompany) return true;

	const hasFarm = await api.farm.get().catch(() => null);
	const canCreate = await permissions.checkFarmPrivilege('create');

	return canCreate && !hasFarm;
}

async function missingAccount() { // eslint-disable-line complexity
	try {
		if (!await api.company.get().catch(() => null)) return true;
		const enabled = await lockoutService.isAppEnabled();
		const isAdmin = await permissions.isAdmin();

		if (!enabled || !isAdmin) return false;

		const account = await api.billingReplacement.getAccount();

		return !account || !account.account_id || (account.account_type === 'paid' && !account.stripe_customer_id);
	}
	catch (error) {
		return true;
	}
}
