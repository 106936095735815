import api from './api';
import company from './company';

export default {
	list,
	find,
	read,
	create,
	update,
	accept,
	decline,
	delete : _delete,
	reject : _delete,

	Invitation : _extend,
};

function list(filters) {
	return filters
		? api.list('invitation', filters, {
			sortBy : 'email',
		})
			.then(list => list.map(invitation => _extend(invitation)))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? list({ company_id }) : null);
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(invitation_id) {
	return api.read('invitation', invitation_id);
}

function create(invitation) {
	return company.get()
		.then(company => company ? company.company_id : null)
		.then(company_id => api.create('invitation', Object.assign({ company_id }, invitation)));
}

function update(invitation) {
	return api.update('invitation', invitation.invitation_id, invitation);
}

function accept(invitation_id) {
	return update({
		invitation_id,
		invitationStatus : 'accepted',
	});
}

function decline(invitation_id) {
	return update({
		invitation_id,
		invitationStatus : 'declined',
	});
}

function _delete(invitation_id) {
	return api.delete('invitation', invitation_id);
}

function _extend(invitation = {}) {
	return Object.assign({

	}, invitation);
}
