/* eslint-disable max-lines */

import mapService from '../google.lib';
import pluralize from 'pluralize';
import polyLabel from '@mapbox/polylabel';
import sort from '@/services/sorting.service.js';

////

const clusteringThreshold = 12; // google maps zoom level
const clusterMarkerSize = 12; // pixel radius of cluster markers
const debounceDuration = 100; // debounced updates

////

export default (context, config) => {
	const _setEvents = context._setEvents;

	const markerList = []; // [<google.maps.Marker>, ...]
	const markerDict = {}; // { <cluster_id> : google.maps.Marker, ... }
	const infoWindow = new window.google.maps.InfoWindow({});

	let timeout = null;

	return {
		_clusteringThreshold : clusteringThreshold,
		_clusteringEnabled   : !config.disableClustering,
		_activeClustering    : null, // whether clustering is being applied
		_clusteringData      : null,

		getProjection() {
			return this._map.getProjection();
		},

		getClusteringThreshold() {
			return this._clusteringThreshold;
		},

		getClusteringEnabled() {
			return this._clusteringEnabled;
		},

		setClusteringThreshold(clusteringThreshold) {
			this._clusteringThreshold = clusteringThreshold;

			this._map.set('clustering-threshold', this._clusteringThreshold);

			this.updateClustering();
		},

		setClusteringEnabled(clusteringEnabled) {
			this._clusteringEnabled = clusteringEnabled;

			this._map.set('clustering-enabled', this._clusteringEnabled);

			this.updateClustering();
		},

		updateClustering() {
			clearTimeout(timeout);

			timeout = setTimeout(() => this._updateClustering(), debounceDuration);
		},

		_updateClustering() {
			if (!this._clusteringEnabled || this.zoom > this._clusteringThreshold)
				this.stopClustering();

			else if (this.zoom <= this._clusteringThreshold)
				this.startClustering();

			if (!this._activeClustering) return;

			markerList.forEach(marker => {
				marker.setLabel({
					text : '',
				});
				marker.setMap(this._map);
			});

			const projection = this.getProjection();

			if (!projection) return this.updateClustering();

			const scale = 1 << this.zoom; // eslint-disable-line no-bitwise
			const distanceTable = getDistanceTable(markerList, projection, scale);

			this._clusteringData = getClusteringData(markerList, distanceTable);

			Object.values(this._clusteringData)
				.forEach(list => {
					if (!list.length) return;

					list.forEach((marker, index) => {
						marker.setLabel({
							text       : `${list.length}`,
							color      : 'white',
							fontWeight : 'bold',
						});

						// hide collapsed cluster markers
						if (index !== 0) marker.setMap(null);
					});
				});
		},

		startClustering() {
			if (this._activeClustering) return;

			this._activeClustering = true;

			// hide the normal map features
			this._map.data.setMap(null);

			markerList.forEach(marker => {
				marker.setMap(this._map);
			});
		},

		stopClustering() {
			if (!this._activeClustering) return;

			this._activeClustering = false;

			this.forceLabelRefresh();

			this._map.data.setMap(this._map);

			markerList.forEach(marker => {
				marker.setMap(null);
			});
		},

		_setEvents() {
			if (_setEvents) _setEvents.call(this);

			this._map.set('clustering-enabled', this._clusteringEnabled);
			this._map.set('clustering-threshold', this._clusteringThreshold);

			window.google.maps.event.addListener(this._map, 'click', () => {
				infoWindow.setMap(null);
			});

			window.google.maps.event.addListener(this._map, 'zoom_changed', () => {
				if (!this.zoom) return;

				infoWindow.setMap(null);

				this.updateClustering();
			});

			this._map.data.addListener('addfeature', ({ feature }) => {
				feature.setProperty('cluster_id', generateClusterId(feature));

				this._createClusterMarker(feature);
			});

			this._map.data.addListener('removefeature', ({ feature }) => {
				this._removeClusterMarker(feature.getProperty('cluster_id'));
			});

			this._map.data.addListener('setproperty', ({ feature, name, newValue }) => {
				if (name === 'visible') {
					const cluster_id = feature.getProperty('cluster_id');
					const marker = markerDict[cluster_id];

					marker.setVisible(newValue);

					infoWindow.setMap(null);

					this.updateClustering();
				}
			});
		},

		_createClusterMarker(feature) {
			const cluster_id = feature.getProperty('cluster_id');

			const marker = createClusterMarker(feature);

			markerList.push(marker);
			markerDict[cluster_id] = marker;

			marker.set('clickListener', marker.addListener('click', () => {
				infoWindow.setContent(getInfoWindowContent(this._clusteringData[cluster_id]));
				infoWindow.open(this._map, marker);
			}));
		},

		_removeClusterMarker(cluster_id) {
			const marker = markerDict[cluster_id];
			const index = markerList.indexOf(marker);

			if (!marker) return;

			const listener = marker.get('clickListener');

			listener.remove();

			markerList.splice(index, 1);
			markerDict[cluster_id] = null;

			marker.setMap(null);
			infoWindow.setMap(null);

			this.updateClustering();
		},
	};
};

function generateClusterId(feature) {
	return feature.getProperty('cluster_id') || feature.getProperty('feature_id') || `clustering-${Date.now()}${Math.random()}`;
}

// eslint-disable-next-line max-statements
function createClusterMarker(feature) {
	// const feature_id = feature.getId(); // TODO: research getId() vs getProperty()
	const feature_id = feature.getProperty('feature_id');
	const cluster_id = feature.getProperty('cluster_id');
	const featureType = feature.getProperty('featureType');
	const featureName = feature.getProperty(`${featureType}Name`);
	// const fillColor = feature.getProperty('color');
	const geometry = feature.getGeometry();
	const position = new mapService.Coordinate(getCenter(geometry));

	const marker = new window.google.maps.Marker({
		position,
		icon : {
			path         : window.google.maps.SymbolPath.CIRCLE,
			scale        : clusterMarkerSize, // TODO: more than 9 -> 15, more than 99 -> 20
			fillColor    : '#70AA49', // TODO: grab feature color
			fillOpacity  : 1,
			strokeColor  : 'white',
			strokeWeight : 2,
		},
	});

	marker.set('feature_id', feature_id);
	marker.set('cluster_id', cluster_id);
	marker.set('featureType', featureType);
	marker.set('featureName', featureName);

	return marker;
}

function getCenter(geometry) {
	const type = geometry.getType();

	const handler = {
		Point(geometry) { return point(geometry) },
		LineString(geometry) { return polyLabel([linestring(geometry)]) },
		Polygon(geometry) { return polyLabel(polygon(geometry)) },
		MultiPolygon(geometry) { return polyLabel(multipolygon(geometry)) },
	}[type];

	return handler && handler(geometry);

	function point(geometry) {
		if (geometry.get) return point(geometry.get());

		return [geometry.lng(), geometry.lat()];
	}

	function linestring(geometry) {
		return geometry.getArray().map(coord => point(coord));
	}

	function polygon(geometry) {
		return geometry.getArray().map(ring => linestring(ring));
	}

	function multipolygon(geometry) {
		return polygon(geometry.getAt(0));
	}
}

function getDistanceTable(markers, projection, scale) {
	const distanceTable = markers.reduce((table, marker) => {
		const pixel = getPixel(marker, projection, scale);

		const row = markers.reduce((row, marker) => {
			const distance = getDistance(pixel, getPixel(marker, projection, scale));

			row[marker.get('cluster_id')] = distance;

			return row;
		}, {});

		table[marker.get('cluster_id')] = row;

		return table;
	}, {});

	return distanceTable;
}

function getPixel(marker, projection, scale) {
	const point = projection.fromLatLngToPoint(marker.getPosition());

	return new window.google.maps.Point(
		Math.floor(point.x * scale),
		Math.floor(point.y * scale)
	);
}

function getDistance(one, two) {
	const dx = one.x - two.x;
	const dy = one.y - two.y;

	const ddx = dx * dx;
	const ddy = dy * dy;

	return Math.sqrt(ddx + ddy);
}

function getClusteringData(markerList, distanceTable) {
	const list = markerList.map(marker => marker.get('cluster_id')).sort();

	const clustering = list.reduce((clustering, cluster_id) => Object.assign(clustering, {
		[cluster_id] : [],
	}), {}); // { <cluster_id> : [google.maps.Marker, ...] }

	list.forEach(cluster_id => {
		list.forEach(feature_id => {
			const distance = distanceTable[cluster_id][feature_id];

			if (distance <= clusterMarkerSize * 2)
				clustering[feature_id] = clustering[cluster_id];
		});
	});

	markerList.forEach(marker => {
		const cluster_id = marker.get('cluster_id');

		if (marker.getVisible())
			clustering[cluster_id].push(marker);
	});

	return clustering;
}

function getInfoWindowContent(list) {
	const content = document.createElement('div');

	const {
		field  : fields = [], // Array of google.maps.Marker for Fields
		marker : markers = [],
		line   : lines = [],
	} = sortAndGroupMarkers(list);

	content.classList.add('map-clustering-info-window');

	content.append(
		generateInfoWindowTypeGroup(fields, pluralize('Fields', fields.length)),
		generateInfoWindowTypeGroup(markers, pluralize('Markers', markers.length)),
		generateInfoWindowTypeGroup(lines, pluralize('Lines', lines.length))
	);

	return content;
}

function sortAndGroupMarkers(list) {
	return list.sort((one, two) => sort(one, two, [{
		extract : marker => marker.get('featureType'),
		compare : (typeOne, typeTwo) => ({
			'field-marker' : -1,
			'field-line'   : -1,
			'marker-line'  : -1,

			'marker-field' : 1,
			'line-field'   : 1,
			'line-marker'  : 1,
		})[`${typeOne}-${typeTwo}`],
	}, {
		extract : marker => marker.get('featureName'),
	}]))
		.reduce((types, marker) => {
			// Note: `marker` here is a google.maps.Marker

			const type = marker.get('featureType');

			if (!types[type]) types[type] = [];

			types[type].push(marker);

			return types;
		}, {});
}

function generateInfoWindowTypeGroup(list, label) {
	if (!list.length) return '';

	const div = document.createElement('div');
	const h4 = document.createElement('h4');
	const span = document.createElement('span');
	const ul = document.createElement('ul');

	span.innerText = label;

	div.append(h4, ul);
	h4.append(span);
	ul.append(...list.map(marker => {
		const li = document.createElement('li');

		const name = document.createElement('span');

		name.innerText = marker.get('featureName');

		li.append(name);

		return li;
	}));

	return div;
}
