const component = () => import(/* webpackChunkName: "developer-test" */'./spinner');

export default {
	component,
	path : 'spinner',
	name : 'spinner-test',
	meta : {
		title  : 'Spinner',
		secure : false,
	},
};
