import Vue from 'vue';
import Component from 'vue-class-component';
import template from './file-path.html';

const props = {
	value  : Array,
	source : Object,
};

@Component({
	template,
	props,
})
export class FilePath extends Vue {
	select(folder) {
		const index = this.value.indexOf(folder);

		this.$emit('input', this.value.slice(0, index + 1));
	}

	reset() {
		if (this.source) this.$emit('reset');

		else this.$emit('input', []);
	}

	get list() {
		return this.value.slice().reverse();
	}
}

Vue.component('file-path', FilePath);
