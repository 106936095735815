import defaultMapOptions from './map-options.js';
import mapService from './google.lib';
import featureMethods from './methods/feature.methods';
import drawingMethods from './methods/drawing.methods';
import overlayMethods from './methods/overlay.methods';
import panAndZoomMethods from './methods/pan-and-zoom.methods';
import labelMethods from './methods/label.methods';
import legendMethods from './methods/legend.methods';
import clusteringMethods from './methods/clustering.methods';
import soilMethods from './methods/soil.methods';

const extensions = [
	featureMethods,
	drawingMethods,
	overlayMethods,
	panAndZoomMethods,
	labelMethods,
	legendMethods,
	clusteringMethods,
	soilMethods,
];

export default class Map {
	constructor(options = {}) { // eslint-disable-line max-statements
		const config = Object.assign({}, defaultMapOptions, options);

		this._map = new mapService.Map(config);

		extensions.forEach(extension => Object.assign(this, typeof extension === 'function'
			? extension(this, options)
			: extension));

		if (config.isLocked)
			this._disableInterface();

		if (config.featureStyle)
			this._setStyle(config.featureStyle);

		if (config.features)
			this._addFeatureCollection(config.features);
		else this._fitToFeatures();

		this.enableDrawing();

		this.layers = config.layers;
		this.overlays = config.overlays;

		this._setEvents();
	}

	get center() {
		return this._map.getCenter().toJSON();
	}

	get zoom() {
		return this._map.getZoom();
	}

	get mapType() {
		return this._map.getMapTypeId();
	}

	set mapType(type) {
		return this._map.setMapTypeId(type);
	}

	has(id) {
		return this._map.data.getFeatureById(id);
	}

	on(eventName, handler) {
		const [scope, event] = eventName.split('-');
		const context = {
			map     : this._map,
			feature : this._map.data,
		}[scope];

		if (!context)
			throw new Error(`Unknown event type '${scope}' in '${eventName}', expected 'map' or 'feature'`);

		const listener = context.addListener(event, handler);

		return () => listener.remove();
	}

	once(eventName, handler) {
		const listener = this.on(eventName, event => {
			listener();

			return handler(event);
		});

		return listener;
	}

	emit(eventName, ...args) {
		return mapService.emit(this._map, eventName, ...args);
	}

	_setStyle(styleConfig = {}) {
		return this._map.data.setStyle(styleConfig);
	}

	_disableInterface() {
		return this._map.setOptions({
			disableDefaultUI  : true, // eslint-disable-line id-match
			gestureHandling   : 'none',
			keyboardShortcuts : false,
			streetViewControl : false,
			zoomControl       : false,
			mapTypeControl    : false,
			fullscreenControl : false,
		});
	}
}
