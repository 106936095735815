import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-filter-entry.html';
import MapFilterForm from '../map-filter-form/map-filter-form';
import filterTypes from '../config/filter-types';

const components = {
	MapFilterForm,
};

const props = {
	value : {
		type     : Object, // { type, operation, value, active }
		required : true,
	},
	editing : null, // The filter being edited (possibly this one)
};

@Component({
	template,
	components,
	props,
})
export default class MapFilterEntry extends Vue {
	data() {
		return {
			edit_id : this.value.mapFilter_id || `${Date.now()}${Math.random()}`,
		};
	}

	get filterType() {
		return filterTypes.find(({ type }) => type === this.value.type) || {};
	}

	get filterValue() {
		const filterType = this.filterType;
		const { value } = this.value;

		if (!filterType.options) return value;

		const { label } = filterType.options.find(option => option.value === value) || {};

		return label || value;
	}

	edit() {
		this.$emit('editing', this.edit_id);
	}

	save(filter) {
		this.$emit('input', filter);
	}
}
