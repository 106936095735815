import Vue from 'vue';
import Component from 'vue-class-component';
import template from './marker-timeseries-data.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import { checkFarmPrivilege, isDemoUser } from '@/services/permission.service';

const props = {
	marker : {
		type     : Object,
		required : true,
	},
};

@Component({
	template,
	props,
})
export class MarkerTimeseriesData extends Vue {
	data() {
		return {
			connecting : null,
			device     : null,
			error      : null,
			loading    : null,
			devices    : null,
		};
	}

	mounted() {
		this.loadDevices();
	}

	@Async(false)
	get isDemoUser() {
		return isDemoUser();
	}

	@Async(false)
	get isAllowed() {
		return checkFarmPrivilege('delete')
			.then(allowed => allowed && !this.isDemoUser);
	}

	@Watch('connecting')
	watchConnecting() {
		if (this.connecting) return;

		this.loadDevices();
	}

	loadDevices() {
		const { marker_id } = this.marker;

		this.devices = null;
		this.error = null;
		this.loading = true;

		return api.timeseries.device.list({ marker_id })
			.then(devices => { this.devices = devices })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	connectDevice(device) {
		const { marker_id } = this.marker;

		this.error = null;
		this.loading = true;

		return api.timeseries.device.create(Object.assign({ marker_id }, device))
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false })
			.finally(() => { this.connecting = false });
	}

	disconnectDevice(device) {
		this.error = null;
		this.loading = true;

		return api.timeseries.device.delete(device.timeseriesDevice_id)
			.then(() => { this.devices.splice(this.devices.indexOf(device), 1) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('marker-timeseries-data', MarkerTimeseriesData);
