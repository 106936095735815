/* eslint-disable id-match, new-cap */

export default {
	reset,
	identify,
	update,
	trackEvent,
	trackPage,
};

////

function reset() {
	window.Intercom('shutdown');
}

function identify(user) {
	if (!user || !process.env.INTERCOM_APP_ID) return;

	window.Intercom('boot', {
		app_id     : process.env.INTERCOM_APP_ID,
		created_at : user.createdAt,
		email      : user.email,
		name       : user.name,
		phone      : user.phone,
		user_hash  : user.user_hash,
		user_id    : user.user_id,
	});
}

function update({ user, company }) {
	if (!user || !process.env.INTERCOM_APP_ID) return;

	window.Intercom('update', Object.assign({
		app_id    : process.env.INTERCOM_APP_ID,
		email     : user.email,
		name      : user.name,
		phone     : user.phone,
		user_hash : user.user_hash,
		user_id   : user.user_id,
	}, company ? {
		company : {
			company_id : company.company_id,
			name       : company.companyName,
		},
	} : null));
}

function trackEvent({ action, category, label }) {
	const prefix = category ? `${category}_` : '';
	const suffix = label ? `_${label}` : '';

	window.Intercom('trackEvent', `${prefix}${action}${suffix}`);
}

function trackPage() {
	window.Intercom('update');
}
