/* eslint-disable max-lines */
/* eslint id-length: [warn, { exceptions: [x, y, z, _] } ] */

import Map from './classes/map';
import locationLookup from './methods/location-lookup';

////

export default {
	Map,
	locationLookup,
};

export { Map };
export { locationLookup };

////
