export default [{
	label       : 'Crop',
	placeholder : 'E.g., Corn',
}, {
	label       : 'Varietal',
	placeholder : 'E.g., Sweet',
}, {
	label       : 'Parcel Number',
	placeholder : 'Enter the Parcel Number',
	isParcel    : true,
}, {
	label       : 'Parcel Acres',
	placeholder : 'E.g., 40',
	isParcel    : true,
}, {
	label       : 'Row Spacing',
	placeholder : 'Enter a distance',
}, {
	label       : 'Plant Spacing',
	placeholder : 'Enter a distance',
}, {
	label       : 'Pesticide Permit',
	placeholder : 'Enter your number',
}];
