import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-employee-settings.html';
import api from '@/api';

const props = {
};

@Component({
	template,
	props,
})
export class WorkOrderEmployeeSettings extends Vue {
	data() {
		return {
			employee : null,
		};
	}

	addEmployee() {
		this.employee = new api.workOrder.employee.Employee();
	}
}

Vue.component('work-order-employee-settings', WorkOrderEmployeeSettings);
