import Vue from 'vue';
import Component from 'vue-class-component';
import template from './marker-default-attributes.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import { getAttributes } from '@/config/marker-types';

const props = {
	value    : Object, // the marker record
	readonly : Boolean,
};

@Component({
	template,
	props,
})
export class MarkerDefaultAttributes extends Vue {
	data() {
		return {
			error       : null,
			attributes  : null,
			deleteQueue : [],
		};
	}

	mounted() {
		if (!this.value) this.populateAttributes();
	}

	@Async([])
	get list() {
		if (!this.value) return;

		const { marker_id } = this.value;

		return api.attribute.list({ marker_id })
			.catch(error => { this.error = error });
	}

	@Watch('list')
	populateAttributes() {
		if (this.attributes || !this.list) return;

		const labels = this.list.map(({ label }) => label);

		this.attributes = [
			...this.list,
			...this.defaults.filter(label => !labels.includes(label))
				.map(label => ({
					label,
					value : '',
				})),
		];
	}

	@Watch('attributes')
	watchAttributes() {
		this.$emit('input', this.attributes.filter(({ label, value }) => label && value));
	}

	get placeholder() {
		return {
			label : 'e.g., Year Installed',
			value : 'e.g., 2018',
		};
	}

	get defaults() {
		return getAttributes(this.value);
	}

	create(attribute) {
		return this.attributes.push(attribute);
	}

	remove(attribute) {
		const index = this.attributes.findIndex(item => item === attribute);

		if (index === -1) return;

		this.deleteQueue.splice(-1, 0, ...this.attributes.splice(index, 1));
	}
}

Vue.component('marker-default-attributes', MarkerDefaultAttributes);
