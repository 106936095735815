import api from './api-storage-list';
import farm from './farm';
import service from '@/services/color.service';

export default {
	list,
	count,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	Marker : _extend,
};

function list(filters) {
	return filters
		? api.list('marker', filters)
			.then(list => list.map(marker => _extend(marker)))
		: farm.get()
			.then(farm => farm ? farm.farm_id : null)
			.then(farm_id => farm_id ? list({ farm_id }) : []);
}

function count(filters) {
	return filters
		? api.count('marker', filters)
		: farm.get()
			.then(farm => farm ? farm.farm_id : null)
			.then(farm_id => farm_id ? count({ farm_id }) : { count : 0 });
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(marker_id) {
	return api.read('marker', marker_id)
		.then(marker => _extend(marker));
}

function create(marker) {
	return Promise.resolve(marker.farm_id || farm.get().then(({ farm_id }) => farm_id))
		.then(farm_id => api.create('marker', Object.assign(marker, { farm_id })));
}

function update(marker) {
	return api.update('marker', marker.marker_id, marker);
}

function save(marker) {
	return marker.marker_id ? update(marker) : create(marker);
}

function _delete(marker_id) {
	return api.delete('marker', marker_id);
}

function _extend(marker = {}) {
	const color = marker.color || '';

	return Object.assign({
		markerName : '',
		feature_id : marker.marker_id || null,
	}, marker, {
		color : service.migrate(color),
	});
}
