import Vue from 'vue';
import Component from 'vue-class-component';
import template from './parcel-number.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';
import geometryService from '@/services/geometry.service';
import { getParcelsAtCoordinate } from '@/services/report-all-usa.service';
import modalService from '@/services/modal.service';

const props = {
	field : {
		type     : Object,
		required : true,
	},
};

@Component({
	template,
	props,
})
export class ParcelNumber extends Vue {
	data() {
		return {
			loading  : null,
			disabled : null,
		};
	}

	@Async
	get parcels() {
		const {
			parcels,
			field__parcels : joins = [], // eslint-disable-line id-match
			field_id,
		} = this.field;

		return Promise.all(parcels || joins.map(({ parcel }) => parcel))
			.then(list => list.filter(parcel => parcel))
			.then(list => list.length ? list : api.parcel.list({ field_id }));
	}

	@Async({})
	get status() {
		return api.parcelQuery.get();
	}

	showUpsellModal() {
		return modalService.launchModal('parcel-click-upsell', {})
			.catch(() => null);
	}

	retrieveParcels() {
		if (this.loading) return;

		if (this.status.limitReached)
			return this.showUpsellModal();

		this.loading = true;
		this.disabled = true;

		const { field_id } = this.field;

		return api.geometry.find({ field_id })
			.then(geometry => geometryService.getCenter({ geometry }))
			.then(center => getParcelsAtCoordinate(center))
			.then(list => Promise.all(list.map(({ properties }) => Object.assign({ field_id }, properties))))
			.then(list => Promise.all(list.map(parcel => api.parcel.create(parcel))))
			.then(parcels => { this.parcels = parcels })
			.finally(() => { this.loading = false });
	}
}

Vue.component('parcel-number', ParcelNumber);
