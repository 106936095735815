import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "pages-external" */'./decline-invitation');

export default {
	component,
	path : '/decline-invitation/:id',
	name : 'decline-invitation',
	meta : {
		title  : `Decline ${dsl('Invitation')}`,
		secure : false,
	},
};
