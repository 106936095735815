/* eslint-disable max-lines */

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './date-input.html';
import Watch from '@/plugins/watch-decorator';

const daysPerWeek = 7; // In case it ever changes again (thanks a lot, Constantine).

const props = {
	value : [Date, String],
	label : String,
	min   : Date,
	max   : Date,

	position : {
		type    : String, // ['left', 'right'], which side to appear on
		default : 'left',
	},
};

@Component({
	template,
	props,
})
export class DateInput extends Vue {
	data() {
		const date = this.value ? new Date(this.value) : new Date();

		if (date) date.setHours(0, 0, 0, 0);

		return {
			calendar : timestamp(this.value),
			show     : null,
			date,
		};
	}

	mounted() {
		if (typeof this.value === 'string') this.$emit('input', new Date(this.value));
	}

	get displayValue() {
		if (!this.value) return this.value;

		const date = typeof this.value === 'string' ? new Date(this.value) : this.value;

		return date.toLocaleDateString();
	}

	get element() {
		return this.$refs.input;
	}

	get calendarDate() {
		const date = new Date(this.calendar);

		return date;
	}

	get day() {
		return this.calendarDate.getDate();
	}

	get month() {
		return this.calendarDate.getMonth();
	}

	get monthName() {
		const language = navigator.userLanguage || navigator.language;

		return this.calendarDate.toLocaleString(language, { month : 'long' });
	}

	get year() {
		return this.calendarDate.getFullYear();
	}

	get firstCalendarDay() {
		const date = new Date(this.calendar);

		date.setDate(1);

		return date.getDay();
	}

	get lastCalendarDay() {
		const date = new Date(this.calendar);

		date.setMonth(date.getMonth() + 1, 0);

		return date.getDate();
	}

	get prevMonth() {
		const date = new Date(this.calendar);

		date.setMonth(date.getMonth() - 1, 1);

		return date;
	}

	get prevMonthEnd() {
		const date = new Date(this.calendar);

		date.setDate(0);

		return date;
	}

	get nextMonth() {
		const date = new Date(this.calendar);

		date.setMonth(date.getMonth() + 1, 1);

		return date;
	}

	get weeks() {
		const weekRange = [
			daysPerWeek - this.firstCalendarDay,
			this.lastCalendarDay - this.nextMonth.getDay(),
		];

		const weekCount = (weekRange[1] - weekRange[0]) / daysPerWeek;

		const weeks = new Array(weekCount).fill()
			.map((_, index) => new Array(daysPerWeek).fill(index * daysPerWeek)
				.map((week, index) => ({
					day   : week + index + 1 + weekRange[0],
					month : this.month,
					year  : this.year,
				})));

		return [
			this.firstCalendarWeek,
			...weeks,
			this.lastCalendarWeek,
		];
	}

	get firstCalendarWeek() {
		const prefix = new Array(this.firstCalendarDay).fill()
			.map((_, index) => ({
				day   : this.prevMonthEnd.getDate() + index + 1 - this.firstCalendarDay,
				month : this.prevMonthEnd.getMonth(),
				year  : this.prevMonthEnd.getFullYear(),
			}));

		const firstWeek = new Array(daysPerWeek - this.firstCalendarDay).fill()
			.map((_, index) => ({
				day   : index + 1,
				month : this.month,
				year  : this.year,
			}));

		return [].concat(prefix, firstWeek);
	}

	get lastCalendarWeek() {
		const lastDay = this.nextMonth.getDay();

		const lastWeek = new Array(lastDay).fill()
			.map((_, index) => ({
				day   : this.lastCalendarDay + index + 1 - lastDay,
				month : this.month,
				year  : this.year,
			}));

		const suffix = lastDay ? new Array(daysPerWeek - lastDay).fill()
			.map((_, index) => ({
				day   : index + 1,
				month : this.nextMonth.getMonth(),
				year  : this.nextMonth.getFullYear(),
			})) : [];

		return [].concat(lastWeek, suffix);
	}

	@Watch('value')
	watchValue() {
		if (isNaN(this.value)) {
			this.element.focus();
			this.element.setCustomValidity('Please enter a valid date.');
		}

		else {
			this.element.blur();
			this.element.setCustomValidity('');
			this.element.value = this.value ? this.value.toJSON().replace(/T.*/, '') : null;
			this.calendar = timestamp(this.value);
			this.date = this.value;
		}
	}

	dateClass(date) {
		const { day, month, year } = this.date ? {
			day   : this.date.getDate(),
			month : this.date.getMonth(),
			year  : this.date.getFullYear(),
		} : {};

		return {
			dim    : date.month !== this.month,
			active : [
				date.day === day,
				date.month === month,
				date.year === year,
			].every(value => value),
		};
	}

	input(value) {
		const date = parse(value);

		if (isNaN(date)) return;

		this.calendar = timestamp(date);
		this.date = date;

		this.$emit('input', parse(value));
	}

	change(value) {
		this.$emit('input', parse(value));
	}

	focus(event) {
		if (this.timeout) clearTimeout(this.timeout);

		else this.$emit('focus', event);

		this.timeout = null;
		this.show = true;
	}

	blur(event) {
		const delay = 250;

		this.timeout = setTimeout(() => {
			this.$emit('blur', event);
			this.timeout = null;
			this.show = false;
		}, delay);
	}

	select({ year, month, day }) {
		this.$emit('input', new Date(year, month, day));
	}

	showPrevMonth() {
		this.calendar = this.prevMonth.getTime();

		this.element.focus();
	}

	showNextMonth() {
		this.calendar = this.nextMonth.getTime();

		this.element.focus();
	}
}

Vue.component('date-input', DateInput);

////////

function parse(value) {
	let date = new Date(value);

	// try a little bit to recover date strings
	if (isNaN(date))
		date = new Date(value.replace(/-/g, '/'));

	if (isNaN(date))
		date = new Date(value.replace(/\D+/g, '/'));

	return date;
}

function timestamp(value) {
	const date = value ? new Date(value) : new Date();

	date.setHours(0, 0, 0, 0);

	return date.getTime();
}
