import api from './api-storage-list';
import storage from '@/services/storage.service';
import colorService from '@/services/color.service';
import company from './company';
import analytics from './../services/analytics.service';

export default {
	get,
	list,
	count,
	find,
	read,
	download,
	create,
	update,
	save,
	delete : _delete,
	unsubscribe,

	User : _extend,
};

function get() {
	return storage.get('user')
		.catch(() => api.read('session')
			.then(user => {
				if (user) {
					storage.set('user', user);
					storage.set('auth', user.auth);
				}

				return user;
			}))
		.then(user => _extend(user));
}

function list(filters) {
	return filters
		? api.list('user', filters, {
			sortBy : 'name',
		})
			.then(list => list.map(user => _extend(user)))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? list({ company_id }) : []);
}

function count(filters) {
	return filters
		? api.count('user', filters)
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? count({ company_id }) : { count : 0 });
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(user_id) {
	return api.read('user', user_id)
		.then(user => _extend(user));
}

function download() {
	return get().then(({ user_id, email }) => api.download('user', user_id, { filename : email }));
}

function create(user) {
	return api.create('user', user);
}

function update(user) {
	return api.update('user', user.user_id, user)
		.then(user => {
			storage.set('user', user);
			analytics.update({ user });

			return user;
		});
}

function save(user) {
	return user.user_id ? update(user) : create(user);
}

function _delete(user_id) {
	return company.get()
		.then(company => company ? company.company_id : null)
		.then(company_id => api.delete('user', user_id, { company_id }));
}

async function unsubscribe(where) {
	await removeSelfFromAllCompanies();

	where.action = 'delete';

	return update(where);
}

async function removeSelfFromAllCompanies() {
	const list = await company.list();

	const ids = list.map(item => item.company_id);

	return Promise.all(ids.map(id => company.destroy(id)));
}

function _extend(user = {}) {
	return Object.assign({

	}, user, user && {
		color : user.color || colorService.generate(user.user_id),
	});
}
