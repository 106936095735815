import Vue from 'vue';
import Component from 'vue-class-component';
import template from './top-panel-layout.html';
import TopPanelHeader from './top-panel-header/top-panel-header';
import TopPanelCollapseToggle from './top-panel-collapse-toggle/top-panel-collapse-toggle';

const components = {
	TopPanelHeader,
	TopPanelCollapseToggle,
};

const props = {
	value : Object, // panel payload, if any
	panel : String, // panel name, if any
	error : null,
};

@Component({
	template,
	components,
	props,
})
export class TopPanelLayout extends Vue {
	data() {
		return {
			collapsed : null,
		};
	}
}

Vue.component('top-panel-layout', TopPanelLayout);
