import Vue from 'vue';
import Component from 'vue-class-component';
import template from './add-cloud-storage-dropdown-button.html';

const props = {
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
	isRight  : Boolean, // display dropdown to the right.
};

@Component({
	template,
	props,
})
export class AddCloudStorageDropdownButton extends Vue {
	get list() {
		return [{
			label : 'Dropbox',
			value : 'Dropbox',
		}, {
			label : 'Google Drive',
			value : 'GoogleDrive',
		}, {
			label : 'OneDrive',
			value : 'OneDrive',
		}, {
			label : 'OneDrive Business',
			value : 'OneDriveBusiness',
		}, {
			label : 'Office 365 Sharepoint Sites',
			value : 'MicrosoftSites',
		}];
	}
}

Vue.component('add-cloud-storage-dropdown-button', AddCloudStorageDropdownButton);
