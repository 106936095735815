export default [{
	label       : 'Mayor',
	placeholder : 'John Smith',
}, {
	label       : 'Security',
	placeholder : 'Computer Science',
}, {
	label       : 'Department Chair',
	placeholder : 'Dr. Bob Bilson',
}, {
	label       : 'Graduate Coordinate',
	placeholder : 'Dr. Balex Bliu',
}, {
	label       : 'Administrative Coordinate',
	placeholder : 'Bisa Bennis',
}, {
	label       : 'Stupid Assistant',
	placeholder : 'Bad Batcher',
}];
