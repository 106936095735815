import Vue from 'vue';
import Component from 'vue-class-component';
import template from './open-menu-icon.html';

@Component({ template })
export class OpenMenuIcon extends Vue {
	get icon() {
		return this.$dsl('open-menu-icon');
	}
}

Vue.component('open-menu-icon', OpenMenuIcon);
