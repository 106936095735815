const component = () => import(/* webpackChunkName: "settings-core" */'./work-orders');

export default {
	component,
	path : 'work-orders',
	name : 'company-work-orders',
	meta : {
		title  : 'Work Orders',
		secure : true,
	},
};
