import Vue from 'vue';
import Component from 'vue-class-component';
import Async from '@/plugins/async-decorator';
import template from './team-details-flyover.html';
import flyoverService from '@/services/flyover.service';
import api from '@/api';

@Component({ template })
export class TeamDetailsFlyover extends Vue {
	data() {
		return {
			error : null,
		};
	}

	@Async
	get team() {
		return flyoverService.getData()
			.then(team => team
				? Promise.all([
					team,
					team.team_id ? api.user.list({ team_id : team.team_id }) : [],
					team.team_id ? getTeamFarms(team) : [],
					team.team_id ? api.invitation.list({ team_id : team.team_id }) : [],
				])
				: Promise.reject(null))
			.then(([team, users, farms, invitations]) => Object.assign(team, {
				users,
				farms,
				invitations,
			}))
			.catch(() => null);

		function getTeamFarms({ team_id, teamAccess }) {
			const { farms: farmAccess = {} } = teamAccess;

			return api.farm.list({ team_id })
				.then(farms => farms.map(farm => {
					const access = farmAccess[farm.farm_id] || 'view';

					return Object.assign({}, farm, { access });
				}));
		}
	}

	save() {
		this.error = null;

		const invitations = this.team.invitations || [];

		return api.team.save(this.team)
			.then(team => Promise.all(invitations.map(invite => api.invitation.update(Object.assign(invite, { team_id : team.team_id }))))
				.then(() => team))
			.then(team => flyoverService.resolve(team))
			.catch(error => { this.error = error });
	}
}

Vue.component('team-details-flyover', TeamDetailsFlyover);
