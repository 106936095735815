import api from '../api';

export default {
	list,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	TimeseriesSensor : _extend,
};

function list(filters) {
	return api.list('timeseries-sensor', filters)
		.then(list => list.map(timeseries => _extend(timeseries)));
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(timeseriesSensor_id) {
	return api.read('timeseries-sensor', timeseriesSensor_id)
		.then(timeseries => _extend(timeseries));
}

function create(timeseries) {
	return api.create('timeseries-sensor', timeseries);
}

function update(timeseries) {
	return api.update('timeseries-sensor', timeseries.timeseriesSensor_id, timeseries);
}

function save(timeseries) {
	return timeseries.timeseriesSensor_id ? update(timeseries) : create(timeseries);
}

function _delete(timeseriesSensor_id) {
	return api.delete('timeseries-sensor', timeseriesSensor_id);
}

function _extend(timeseries = {}) {
	return Object.assign({

	}, timeseries);
}
