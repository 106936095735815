import Vue from 'vue';
import Component from 'vue-class-component';
import template from './csv-file-upload.html';
import service from '@/services/csv-parser.service';

@Component({ template })
export class CsvFileUpload extends Vue {
	data() {
		return {
			error   : null,
			loading : false,
		};
	}

	parseFile([file]) {
		if (!file || this.loading) return;

		this.error = null;
		this.loading = true;

		return service.parseFile(file)
			.then(csv => this.$emit('input', csv))
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('csv-file-upload', CsvFileUpload);
