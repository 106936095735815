import Vue from 'vue';
import Component from 'vue-class-component';
import template from './brand-logo.html';

@Component({ template })
export class BrandLogo extends Vue {
	get mobileSrc() {
		if (this.$BRAND && this.$BRAND.src.mobile)
			return this.$BRAND.src.mobile;

		return '/assets/images/m4a-mark.svg';
	}
	get desktopSrc() {
		if (this.$BRAND && this.$BRAND.src.desktop)
			return this.$BRAND.src.desktop;

		return '/assets/images/montage/branding-green.svg';
	}
	get altText() {
		if (this.$BRAND && this.$BRAND.BRAND_NAME)
			return this.$BRAND.BRAND_NAME;

		return 'Montage';
	}
}

Vue.component('brand-logo', BrandLogo);
