import Vue from 'vue';
import Component from 'vue-class-component';
import template from './stripe-payment-method-button.html';

const props = {
	value : Object, // Stripe Card Object
	type  : {
		type    : String,
		default : 'button',
	},
};

@Component({
	template,
	props,
})
export class StripePaymentMethodButton extends Vue {
	get isCard() {
		if (!this.value) return null;

		return this.value.type === 'card';
	}

	get card() {
		if (!this.value || this.value.type !== 'card') return null;

		return this.value.card;
	}

	get brand() {
		if (!this.value || this.value.type !== 'card') return null;

		return this.value.card.brand;
	}

	get icon() { // eslint-disable-line complexity
		switch (this.brand) {
			case 'visa':
				return 'fab fa-cc-visa';
			case 'discover':
				return 'fab fa-cc-discover';
			case 'mastercard':
				return 'fab fa-cc-mastercard';
			case 'amex':
				return 'fab fa-cc-amex';
			case 'jcb':
				return 'fab fa-cc-jcb';
			case 'diners':
				return 'fab fa-cc-diners-club';
			case 'unionpay':
			default:
				return 'far fa-credit-card';
		}
	}

	get exp() {
		if (!this.value || this.value.type !== 'card') return null;

		const month = monthToString(this.card.exp_month);
		const year = this.card.exp_year;

		return {
			month,
			year,
		};
	}

	onClick(event) {
		this.$emit('click', event);
	}
}

Vue.component('stripe-payment-method-button', StripePaymentMethodButton);

function monthToString(month) {
	return [
		null,
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	][Number.parseInt(month, 10)];
}
