import api from '@/api';

const standardMessage = {
	incomplete         : 'We were unable to charge your payment method to start your subscription',
	incomplete_expired : 'Due to lack of payment, you have been unsubscribed',
	inactive           : 'Please sign up for a Subscription',
	past_due           : 'There was an issue with your latest invoice',
	unpaid             : 'You have unpaid invoices',
	canceled           : 'Please restart your subscription',
	disabled           : 'Billing is currently unavailable',
};

export default {
	isAppEnabled,
	isDemo,
	isBillingEnabled,
	isBillingOkay,
	isAddingOkay,
	isTrialing,
	isNotSubscribed,
	isSubscriptionType,
	getBillingMessage,
	hasAllowance,
	atAllowanceLimit,
	reportUsage,
};
function isType(...types) {
	return Promise.resolve(api.billingReplacement.getAccount())
		.then(account => account && types.includes(account.account_type))
		.catch(() => false);
}

export function isSubscriptionType(...types) {
	return Promise.resolve(api.billingReplacement.getAccount())
		.then(account => account && types.includes(account.subscription_type));
}

export async function isAppEnabled() {
	// Is this user locked out for some reason. Should Everything be disabled?
	return Promise.resolve(api.billingReplacement.isEnabled())
		.catch(() => false);
}

export async function isDemo() {
	return isType('demo');
}

export function isBillingEnabled() {
	return isType('paid');
}

export async function isBillingOkay() {
	if (await isType('free', 'managed', 'demo')) return true;
	if (!await isBillingEnabled()) return false;

	return Promise.resolve(api.billingReplacement.getAccount())
		.then(account => account.status)
		.then(status => ['active', 'trialing', 'canceled'].includes(status));
}

export async function isAddingOkay(resource) {
	if (await isType('free', 'managed')) return true;
	if (!await isBillingEnabled()) return false;

	return Promise.resolve(api.billingReplacement.getAccount())
		.then(account => account.status)
		.then(status => ['active', 'disabled', 'trialing'].includes(status))
		.then(okay => resource
			? okay && hasAllowance(resource, 1)
			: okay);
}

export async function isTrialing(mode = '') {
	if (await isType('free', 'managed')) return false;
	if (!await isBillingEnabled()) return false;

	const account = await api.billingReplacement.getAccount();
	const trial = await api.billingReplacement.isTrialEnabled();

	if (!trial) return false;
	if (trial.type !== mode) return false;

	const status = account.status;

	return status === 'trialing';
}

export async function isNotSubscribed() {
	if (await isType('free', 'managed')) return false;
	if (!await isBillingEnabled()) return false;

	return Promise.resolve(api.billingReplacement.getAccount())
		.then(account => account.status)
		.then(status => ['canceled', 'trialing'].includes(status));
}

export function getBillingMessage() {
	return Promise.resolve(api.billingReplacement.getAccount())
		.then(account => typeof account.status_reason === 'string'
			? account.status_reason
			: standardMessage[account.status] || '');
}

export async function hasAllowance(resource, amount) {
	if (await isType('free', 'managed')) return true;
	if (!await isBillingEnabled()) return false;

	return api.billingReplacement.canUse(resource, amount);
}

export async function atAllowanceLimit(resource, moreThan = 1) {
	if (await isType('free', 'managed')) return false;
	if (!await isBillingEnabled()) return false;

	return api.billingReplacement.getUsage()
		.then(usage => {
			if (!usage[resource]) return true;
			if (usage[resource].limit < 1) return true;

			return usage[resource].remaining < moreThan;
		});
}

export async function reportUsage(resource) {
	if (await isType('free', 'managed', 'demo', 'missing')) return;
	if (!isBillingEnabled()) return;

	return Promise.resolve(api.billingReplacement.expireUsage())
		.then(() => Promise.all([
			api.billingReplacement.getUsage(),
			!resource || resource === 'farms' ? api.billingReplacement.reportUsage() : null,
		]))
		.then(([usage]) => usage);
}
