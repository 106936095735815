import api from './api';

export default {
	list,
	count,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	Attribute : _extend,
};

function list(filters) {
	return api.queueList('attribute', filters)
		.then(list => list.map(attribute => _extend(attribute)));
}

function count(filters) {
	return api.count('attribute', filters);
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(attribute_id) {
	return api.queueRead('attribute', attribute_id)
		.then(attribute => _extend(attribute));
}

function create(attribute) {
	return api.create('attribute', attribute);
}

function update(attribute) {
	return api.update('attribute', attribute.attribute_id, attribute);
}

function save(attribute) {
	return attribute.attribute_id ? update(attribute) : create(attribute);
}

function _delete(attribute_id) {
	return api.delete('attribute', attribute_id);
}

function _extend(attribute = {}) {
	return Object.assign({
		label : null,
		value : null,
	}, attribute);
}
