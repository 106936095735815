import Vue from 'vue';
import Component from 'vue-class-component';
import template from './verify-deletion-modal.html';

const props = {
	collection : { // what type of record is getting deleted
		type     : String,
		requried : true,
	},
	name : String, // the name of the record, e.g. farm name, user email
};

@Component({
	template,
	props,
})
export class VerifyDeletionModal extends Vue {}
