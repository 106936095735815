import Vue from 'vue';
import Component from 'vue-class-component';
import template from './company-menu-farm-list.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

@Component({
	template,
})
export class CompanyMenuFarmList extends Vue {
	@Async
	get farms() {
		return api.farm.list();
	}

	@Async
	get activeFarm() {
		return api.farm.get();
	}
}

Vue.component('company-menu-farm-list', CompanyMenuFarmList);
