import filterTypes from '../config/filter-types';

export default function MapFilter({ type, operation, value, active } = {}) {
	const filter = {
		type      : type || null,
		operation : operation || '=~',
		value     : value || null,
		active    : active !== false,

		get key() {
			return `${this.type}:${this.operation}:${this.value}`;
		},

		get comparison() {
			const operation = this.operations ? this.operations.find(({ value }) => value === this.operation) : null;

			if (operation)
				return `is ${operation.label}`;

			if (this.filterType && this.filterType.options)
				return 'is';

			return 'includes';
		},

		get units() {
			const filterType = this.filterType;

			return filterType ? filterType.units : null;
		},

		get options() {
			const filterType = this.filterType;

			return filterType ? filterType.options : null;
		},

		get operations() {
			const filterType = this.filterType;

			return filterType ? filterType.operations : null;
		},

		get filterType() {
			return filterTypes.find(({ type }) => type === this.type);
		},

		toJSON() { // eslint-disable-line id-match
			const { type, operation, value, active } = this;

			return {
				type,
				operation,
				value,
				active,
			};
		},
	};

	filter.test = generateTest(filter);

	return filter;
}

function generateTest(filter) {
	const toleranceRange = 0.02;

	return (label, value) => {
		const { type, value : comparator, operation, active } = filter;

		if (!active) return true;

		const labelTest = label => regex(type).test(label);
		const valueTest = {
			'=~' : value => regex(comparator).test(value),
			'==' : value => Math.abs(Number(value) - Number(comparator)) < Math.abs(Number(comparator) * toleranceRange),
			'<=' : value => Number(value) <= Number(comparator),
			'>=' : value => Number(value) >= Number(comparator),
		}[operation];

		return labelTest(label) && valueTest(value);
	};
}

function regex(string) {
	if (!string) return /^$/;

	const source = string.replace(/([\\[\]()^$.|{}?+])/g, '\\$1')
		.replace(/\*+/g, '.*');

	return new RegExp(source, 'i');
}
