const component = () => import(/* webpackChunkName: "developer-test" */'./modal-verification');

export default {
	component,
	path : 'modal-verification',
	name : 'modal-verification-test',
	meta : {
		title  : 'Modal Verification',
		secure : false,
	},
};
