import * as api from '.';

export default api;
export { api };
// Because the test pages hijack this to provide the original API.
// - CH 2019-09-04

export { default as attribute } from './attribute';
export { default as billingReplacement } from './billing-replacement';
export { default as ceresImaging } from './ceres-imaging';
export { default as ceresImagingField } from './ceres-imaging-field';
export { default as cloudFile } from './cloud-file';
export { default as cloudFolder } from './cloud-folder';
export { default as cloudStorage } from './cloud-storage';
export { default as comment } from './comment';
export { default as company } from './company';
export { default as config } from './config';
export { default as conversation } from './conversation';
export { default as farm } from './farm';
export { default as field } from './field';
export { default as file } from './file';
export { default as fileType } from './file-type';
export { default as folder } from './folder';
export { default as geometry } from './geometry';
export { default as geoserver } from './geoserver';
export { default as group } from './group';
export { default as invitation } from './invitation';
export { default as ipLocation } from './ip-location';
export { default as legalConsent } from './legal-consent';
export { default as line } from './line';
export { default as marker } from './marker';
export { default as oauth } from './oauth';
export { default as parcel } from './parcel';
export { default as parcelQuery } from './parcel-query';
export { default as password } from './password';
export { default as passwordReset } from './password-reset';
export { default as session } from './session';
export { default as soilDetails } from './soil-details';
export { default as team } from './team';
export { default as test } from './test';
export { default as timeseries } from './timeseries';
export { default as unsubscribe } from './unsubscribe';
export { default as user } from './user';
export { default as workOrder } from './work-order';
export { resetQueue } from './http';
