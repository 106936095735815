import Vue from 'vue';
import Component from 'vue-class-component';
import template from './acreage-display.html';

const props = {
	value : Number, // Acres
};

@Component({
	template,
	props,
})
export class AcreageDisplay extends Vue {}

Vue.component('acreage-display', AcreageDisplay);
