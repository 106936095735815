import Vue from 'vue';
import Component from 'vue-class-component';
import template from './external-link.html';

const props = {
	href : String,
};

@Component({
	template,
	props,
})
export class ExternalLink extends Vue {}

Vue.component('external-link', ExternalLink);
