import Vue from 'vue';
import Component from 'vue-class-component';
import template from './timeseries-device-list.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

@Component({ template })
export class TimeseriesDeviceList extends Vue {
	data() {
		return {
			loading : null,
			source  : null,
		};
	}

	@Async
	get devices() {
		if (!this.source) return null;

		const { timeseriesSource_id } = this.source;

		this.$emit('error');

		this.devices = null;
		this.loading = true;

		return api.timeseries.device.preview({ timeseriesSource_id })
			.then(list => list.map(device => Object.assign({ timeseriesSource_id }, device)))
			.catch(error => { this.$emit('error', error) })
			.finally(() => { this.loading = false });
	}
}

Vue.component('timeseries-device-list', TimeseriesDeviceList);
