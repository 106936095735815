import agricultureAttributes from './domains/agriculture';
import collegeAttributes from './domains/college';
import defaultAttributes from './domains/default';
import eldritchAttributes from './domains/eldritch';
import foodSafetyAttributes from './domains/food-safety';
import groundwaterAttributes from './domains/groundwater';
import piracyAttributes from './domains/piracy';
import solarAttributes from './domains/solar';
import realEstateAttributes from './domains/real-estate';
import testingAttributes from './domains/testing';
import { getCurrentDomain } from '@/services/dsl.service';

const domains = {
	// Standard
	'Default' : defaultAttributes,
	'Testing' : testingAttributes,

	// Custom
	'Agriculture' : agricultureAttributes, // The basic package
	'Food Safety' : foodSafetyAttributes, // FDA Food Safety Modernization Act
	'Groundwater' : groundwaterAttributes, // California Sustainable Groundwater Management Act
	'Real Estate' : realEstateAttributes,
	'Solar'       : solarAttributes,

	// Fun
	'College'  : collegeAttributes,
	'Piracy'   : piracyAttributes,
	'Eldritch' : eldritchAttributes,
};

export default domains[getCurrentDomain()] || defaultAttributes;
