import Vue from 'vue';
import Component from 'vue-class-component';
import template from './add-to-map-button.html';
import Async from '@/plugins/async-decorator';
import { checkFarmPrivilege, isDemoUser, showFarmUpsell } from '@/services/permission.service';
import modalService from '@/services/modal.service';

const props = {};

@Component({
	template,
	props,
})
export default class AddToMapButton extends Vue {
	@Async(false)
	get isDemoUser() {
		return isDemoUser();
	}

	@Async(false)
	get canCreate() {
		return checkFarmPrivilege('create')
			.then(okay => okay && !this.isDemoUser);
	}

	@Async(false)
	get showFarmUpsell() {
		return showFarmUpsell();
	}

	onClick(event) {
		if (this.showFarmUpsell)
			return modalService.launchModal('access-upsell', {})
				.catch(() => null);

		this.$emit('click', event);
	}
}
