import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-sync.html';
import Watch from '@/plugins/watch-decorator';
import WorkOrderSyncZone from './work-order-sync-zone/work-order-sync-zone';
import api from '@/api';

const components = {
	WorkOrderSyncZone,
};

const props = {
};

@Component({
	template,
	components,
	props,
})
export class WorkOrderSync extends Vue {
	data() {
		return {
			error   : null,
			loading : null,
			preview : null,
			reload  : true,
		};
	}

	get accessKey() {
		if (!this.preview) return null;

		return this.preview.accessKey;
	}

	@Watch.immediate('reload')
	loadPreview() {
		if (this.loading || !this.reload) return;

		this.error = null;
		this.loading = true;

		api.workOrder.sync.read()
			.then(preview => { this.preview = preview })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false })
			.finally(() => { this.reload = false });
	}
}

Vue.component('work-order-sync', WorkOrderSync);
