export default {
	'company' : 'university',
	'farm'    : 'campus',
	'field'   : 'building',
	'marker'  : 'campus hobo',
	'line'    : 'path',

	'team' : 'club',
	'user' : 'student',

	'notes-tab'  : 'Blog',
	'charts-tab' : 'Grades',

	'file'         : 'assignment',
	'note'         : 'blog post',
	'conversation' : 'jibba jabba',

	'farmable acres' : 'lawn area',
	'boundary acres' : 'lawn area',

	'crop map'  : 'campus map',
	'ranch map' : 'campus map',

	'open-menu-icon'  : 'fas fa-lg fa-graduation-cap',
	'close-menu-icon' : 'fas fa-lg fa-books',

	'email-placeholder' : 'bright-eyed@bushy-tailed.edu',

	'benefits-map-keyword-1' : 'student ID',
	'benefits-map-keyword-2' : 'major',
	'benefits-map-keyword-3' : 'course #',

	'benefits-connection-keyword-1' : 'courses',
	'benefits-connection-keyword-2' : 'classrooms',

	'onboarding-progress-title'     : 'Dash-o-matic',
	'onboarding-progress-tagline'   : 'Big Money! Big Prizes! I Love It!',
	'onboarding-progress-style'     : 'college',
	'onboarding-progress-farm-icon' : 'bell-school',
};
