export default function eldritchProgressTree(completion) {
	return [[
		'         ',
		'm        ',
		'         ',
		'         ',
		'         ',
		'         ',
	], [
		'    n    ',
		'm       h',
		'         ',
		'         ',
		'       g ',
		'         ',
	], [
		'    n  u ',
		'm     a h',
		'  t      ',
		'         ',
		'w      g ',
		'         ',
	], [
		'  h n  u ',
		'm   \' a h',
		'  t    u ',
		'      y  ',
		'w      gl',
		'    a    ',
	], [
		' Ph n  u ',
		'm l \' a h',
		'  th   u ',
		'      y h',
		'w  h   gl',
		'  h a    ',
	], [
		' Ph n  ui',
		'm l \' afh',
		'  th l u ',
		'    \' y h',
		'w  h\'  gl',
		'  h a n  ',
	], [
		' Ph ng ui',
		'm lw\' afh',
		'  th lhu ',
		'   R\' y h',
		'wg h\' agl',
		' fh a n  ',
	], [
		' Ph\'ng ui',
		'm lw\'nafh',
		'  thulhu ',
		'   R\' yeh',
		'wg h\'nagl',
		' fh agn  ',
	], [
		' Ph\'nglui',
		'mglw\'nafh',
		' Cthulhu ',
		'   R\'lyeh',
		'wgah\'nagl',
		' fhtagn  ',
	]][completion];
}
