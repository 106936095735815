import Vue from 'vue';
import Component from 'vue-class-component';
import template from './parcel-searchbar.html';
import ParcelSearchbarTypeSelector from './parcel-searchbar-type-selector/parcel-searchbar-type-selector';
import events from '@/services/parcel-searchbar-communicator.service';
import { getFeaturesAtCoordinate, getFeaturesByOwner, getFeaturesByApn, getFeaturesByAddress } from '@/services/report-all-usa.service';
import Async from '@/plugins/async-decorator';
import api from '@/api';
import modalService from '@/services/modal.service';

const minZoom = 14;
const error_messages = {
	only_numbers   : 'This address does not seem correct. Please check your input.',
	missing_spaces : 'This address seems to be incorrectly formatted. Please check your input.',
};

const components = {
	ParcelSearchbarTypeSelector,
};

const props = {
	config   : Object,
	readonly : Boolean,
};

@Component({
	template,
	components,
	props,
})
export class ParcelSearchbar extends Vue {
	data() {
		return {
			loading : false,
			error   : null,
			itype   : null,
			form    : {
				line   : '',
				region : '',
			},
			listeners  : null,
			results    : null,
			collection : null,
			observers  : null,
		};
	}

	mounted() {
		this.observers = [
			events.on('parcel-searbar.clear-results', () => this.clearResults()),
		];
	}

	beforeDestroy() {
		if (this.collection) {
			this.map.removeFeatureCollection(this.collection);
			this.collection = null;
		}

		if (this.listeners) {
			this.listeners.forEach(fn => fn());
			this.listeners = null;
		}

		if (this.observers) {
			this.observers.forEach(fn => fn());
			this.observers = null;
		}
	}

	resetForm() {
		this.form = {
			line   : '',
			region : '',
		};
	}

	@Async({})
	get status() {
		return api.parcelQuery.get();
	}

	get map() {
		return this.config.map;
	}

	get zoom() {
		return this.map.zoom;
	}

	get minZoom() {
		/*
			This zoom is representative of reportallusa's zoom requirement.
			The tiles will appear between zoom 15 to 21.
			https://reportallusa.com/solutions/overlay/documentation.php

			- AM 05/29/2019
		*/
		return minZoom;
	}

	get type() {
		return this.itype;
	}

	set type(type) {
		if (this.itype !== type) {
			this.itype = type;
			this.onType(type);
		}
	}

	onType(type) {
		if (this.listeners) {
			this.listeners.forEach(fn => fn());
			this.listeners = null;
		}

		this.resetForm();

		if (type === 'boundary')
			this.listeners = this.listenMapClick();
	}

	onBlur() {
		this.form.line = `${this.form.line}`.trim();
		this.form.region = `${this.form.region}`.trim();
	}

	onMapClick(click) {
		this.search([click.latLng.lng(), click.latLng.lat()]);
	}

	listenMapClick() {
		if (this.listeners) return this.listeners;

		this.map.setOverlay('Parcels');
		this.map.disableFeatureClick();
		const listener = this.map.on('map-click', this.onMapClick);

		return [
			() => {
				this.map.clearOverlays();
				this.map.enableFeatureClick();
			},
			listener,
		];
	}

	showSearchResults(results) {
		if (results) {
			this.draw(results);
			this.$emit('results', results);
			events.emit('parcel-searchbar.results', results);
		}
	}

	clearResults() {
		if (this.collection) {
			this.map.removeFeatureCollection(this.collection);
			this.collection = null;
		}

		this.$emit('results', null);
	}

	draw(results) {
		this.collection = this.map.replaceFeatureCollection(this.collection, {
			type     : 'FeatureCollection',
			features : results,
		});

		if (results.length)
			this.map.flyToGeometry(this.collection);
	}

	async search(...params) { // eslint-disable-line max-statements
		try {
			this.loading = true;
			this.error = null;

			if (this.status.limitReached)
				return this.showUpsellModal();

			const notValid = this.validateSearch();

			if (notValid) {
				this.error = notValid;

				return this.showSearchResults([]);
			}

			const handler = {
				address  : this.searchByAddress,
				owner    : this.searchByOwner,
				parcel   : this.searchByApn,
				boundary : this.searchByClick,
			}[this.type];

			if (typeof handler === 'function')
				return this.showSearchResults(await handler(...params));
		}
		catch (error) {
			this.error = error;
		}
		finally {
			this.loading = false;
		}
	}

	searchByAddress() {
		if (!this.form.line || !this.form.region) return null;

		return getFeaturesByAddress(this.form.line, this.form.region);
	}

	searchByOwner() {
		if (!this.form.line || !this.form.region) return null;

		return getFeaturesByOwner(this.form.line, this.form.region);
	}

	searchByApn() {
		if (!this.form.line || !this.form.region) return null;

		return getFeaturesByApn(this.form.line, this.form.region);
	}

	searchByClick(lngLat) {
		return getFeaturesAtCoordinate(lngLat);
	}

	validateSearch() {
		const line = `${this.form.line}`.trim();

		switch (this.type) {
			case 'address':
				if (/^[0-9]+$/i.test(line))
					return new Error(error_messages.only_numbers);
				if (/[0-9][a-z]/i.test(line))
					return new Error(error_messages.missing_spaces);

				return false;
			default:
		}
	}

	showUpsellModal() {
		return modalService.launchModal('parcel-click-upsell', {})
			.catch(() => null);
	}
}

Vue.component('parcel-searchbar', ParcelSearchbar);
