import Vue from 'vue';
import Component from 'vue-class-component';
import template from './test-top-panel.html';

@Component({ template })
export class TestTopPanel extends Vue {
	data() {
		return {
			content   : null, // has a lot of content
			collapsed : null, // has collapsed content
			footer    : null, // has a footer
		};
	}

	get gibberish() {
		const lots = 200;
		const some = 10;
		const space = 26;

		return Array(this.content ? lots : some).fill()
			.map(() => Math.random()
				.toString(space)
				.replace(/\d/g, digit => ['q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'][digit])
				.substring(2))
			.join(' ');
	}
}

Vue.component('test-top-panel', TestTopPanel);
