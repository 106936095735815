import api from './api';

export default {
	list,
	count,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	Parcel : _extend,
};

function list(filters) {
	return api.list('parcel', filters)
		.then(list => list.map(parcel => _extend(parcel)));
}

function count(filters) {
	return api.count('parcel', filters);
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(parcel_id) {
	return api.read('parcel', parcel_id)
		.then(parcel => _extend(parcel));
}

function create(parcel) {
	return api.create('parcel', parcel);
}

function update(parcel) {
	return api.update('parcel', parcel.parcel_id, parcel);
}

function save(parcel) {
	return parcel.parcel_id ? update(parcel) : create(parcel);
}

function _delete(parcel_id) {
	return api.delete('parcel', parcel_id);
}

function _extend(parcel = {}) {
	return Object.assign({

	}, parcel);
}
