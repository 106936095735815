import Vue from 'vue';
import Component from 'vue-class-component';
import template from './submit-button.html';

const delay = 250;

const props = {
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
	inverted : Boolean,
};

@Component({
	template,
	props,
})
export class SubmitButton extends Vue {
	data() {
		return {
			locked : true,
		};
	}

	created() {
		this.cycleLock();
	}

	cycleLock() {
		this.locked = true;

		setTimeout(() => {
			this.locked = false;
		}, delay);
	}
}

Vue.component('submit-button', SubmitButton);
