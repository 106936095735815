import Vue from 'vue';
import Component from 'vue-class-component';

const props = {
	value : String,
};

@Component({
	template : '<span class="marker-icon" :alt="value" :key="value" v-html="svg"></span>',
	props,
})
export class MarkerIcon extends Vue {
	get svg() {
		return require(`../assets/images/markers/${this.value}/icon.svg`); // eslint-disable-line global-require
	}
}

Vue.component('marker-icon', MarkerIcon);
