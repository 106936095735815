import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-container.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import geojsonService from '@/services/geojson.service';
import geolocation from '@/services/geolocation.service';
import geometryService from '@/services/geometry.service';
import sessionStorage from '@/services/session-storage.service';
import { Map } from '@/services/map.service';

const props = {
	config : {
		type : Object,
		default() {
			return {
				mapOptions : {},
			};
		},
	},
};

@Component({
	template,
	props,
})
export class MapContainer extends Vue {
	data() {
		return {
			map     : null,
			timeout : null,
			loaded  : {
				tiles    : null,
				filters  : null,
				features : null,
			},
		};
	}

	mounted() {
		const config = Object.assign({}, this.config.mapOptions, { noClear : true });

		this.map = new Map(config);

		this.map.once('map-tilesloaded', () => {
			this.loaded.tiles = true;
		});

		this.map.on('feature-addfeature', () => {
			this.loaded.features = [
				this.fields,
				this.markers,
				this.lines,
			].every(loaded => loaded);
		});

		this.map.on('feature-click', ({ feature }) => {
			const featureType = feature.getProperty('featureType');
			const feature_id  = feature.getProperty('feature_id');

			this.$emit('select', {
				featureType,
				feature_id,
			});
		});
	}

	get features() {
		let features = [];

		if (this.fields)
			features.push(...this.fields);

		if (this.markers)
			features.push(...this.markers);

		if (this.lines)
			features.push(...this.lines);

		features = features.map(feature => geojsonService.getGeoJson(feature))
			.filter(geojson => Boolean(geojson));

		return features;
	}

	get collection() {
		return {
			type     : 'FeatureCollection',
			features : this.features,
		};
	}

	get loading() {
		return [
			!this.fields,
			!this.markers,
			!this.lines,
			!this.loaded.tiles,
			!this.loaded.filters,
			this.features.length && !this.loaded.features,
		].some(loading => loading);
	}

	@Async
	get filters() {
		return Promise.resolve(sessionStorage.get('map-filters') || [])
			.finally(() => { this.loaded.filters = true });
	}

	@Async(null)
	get company() {
		return api.company.get()
			.catch(() => {
				this.$emit('error', 'Failed to load company info');
			});
	}

	@Async(null)
	get farm() {
		return api.farm.get()
			.catch(() => {
				this.$emit('error', 'Failed to load farm info');
			});
	}

	@Async(null, 'farm')
	get fields() {
		return api.field.list()
			.then(fields => fields.map(field => Object.assign({
				featureType : 'field',
			}, field)))
			.then(fields => geometryService.assignGeometries(fields))
			.catch(() => {
				this.$emit('error', 'Failed to load field info');
			});
	}

	@Async(null, 'farm')
	get markers() {
		return api.marker.list()
			.then(markers => markers.map(marker => Object.assign({
				featureType : 'marker',
			}, marker)))
			.then(markers => geometryService.assignGeometries(markers))
			.catch(() => {
				this.$emit('error', 'Failed to load marker info');
			});
	}

	@Async(null, 'farm')
	get lines() {
		return api.line.list()
			.then(lines => lines.map(line => Object.assign({
				featureType : 'line',
			}, line)))
			.then(lines => geometryService.assignGeometries(lines))
			.catch(() => {
				this.$emit('error', 'Failed to load line info');
			});
	}

	@Watch('features', { deep : false })
	watchFeatures() {
		this.map.setFeatureCollection(this.collection);

		this.map.recenter();
	}

	@Watch('loading')
	watchLoading() {
		if (this.loading) return this.$emit('loaded', null);

		this.recenter();

		this.$emit('loaded', {
			map      : this.map,
			features : this.features,
			filters  : this.filters,
			selected : null,
		});
	}

	recenter() {
		if (this.loading) return;

		if (this.features.length)
			return this.map.recenter();

		return this.centerOnUser();
	}

	centerOnUser() {
		if (!geolocation.available) return;

		geolocation.getLocation()
			.then(position => position.coords)
			.then(({ latitude: lat, longitude: lng, accuracy }) => ({
				lat,
				lng,
				accuracy,
			}))
			.then(location => {
				const user = this.map.highlightLocation(location, location.accuracy);

				return this.map.flyToGeometry(user);
			})
			.catch(() => null);
	}
}

Vue.component('map-container', MapContainer);
