import Vue from 'vue';
import Component from 'vue-class-component';
import template from './draw-field-by-hand-panel.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

const props = {
	value : Object, // data passed through the panel-container
};

@Component({
	template,
	props,
})
export class DrawFieldByHandFlyover extends Vue {
	data() {
		return {
			error      : null,
			loading    : null,
			attributes : [],
			context    : null, // { cancel(), done(), setColor(), setIcon(), acres }
			feature    : null, // Google Map Data Feature
			field      : {
				color     : '',
				fieldName : null,
			},
		};
	}

	mounted() {
		this.draw();
	}

	beforeDestroy() {
		this.map.cancelDraw();
	}

	get map() {
		return this.value.map;
	}

	get features() {
		return this.value.features.map(({
			properties : {
				feature_id,
				featureType,
				fieldName,
				markerName,
				lineName,
			},
		}) => ({
			feature_id,
			featureType,
			featureName : fieldName || markerName || lineName,
		}));
	}

	@Watch('field.color')
	watchFieldColor() {
		if (this.context) this.context.setColor(this.field.color);
	}

	draw() {
		return this.map.draw('Polygon')
			.then(feature => { this.feature = feature })
			.then(() => this.edit())
			.catch(() => this.draw());
	}

	edit() {
		return this.map.edit(this.feature, 'Polygon')
			.then(context => { this.context = context });
	}

	save() {
		this.error = null;
		this.loading = true;

		return this.context.done()
			.then(geometry => Object.assign(this.field, { geometry }))
			.then(field => api.field.create(field))
			.then(field => Promise.all(this.attributes.map((attribute, index) => api.attribute.create(Object.assign(attribute, {
				field_id : field.field_id,
				order    : index + 1,
			}))))
				.then(() => field))
			.then(({ fieldName, field_id, geometry }) => {
				geometry.properties.featureType = 'field';
				geometry.properties.feature_id = field_id;
				geometry.properties.fieldName = fieldName;

				this.value.features.push(geometry);

				this.map.replaceFeatureCollection([this.feature], {
					type     : 'FeatureCollection',
					features : [geometry],
				});
			})
			.then(() => { this.$emit('close') })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	validateName(name) {
		const feature = this.features.find(({ featureName }) => featureName === name);

		if (feature) return `A ${this.$dsl(feature.featureType)} named '${name}' already exists.`;
	}
}

Vue.component('draw-field-by-hand-panel', DrawFieldByHandFlyover);
