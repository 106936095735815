import Vue from 'vue';
import Component from 'vue-class-component';
import template from './cloud-folder-icon.html';

const props = {
	value : Object,
};

@Component({
	template,
	props,
})
export class CloudFolderIcon extends Vue {
	get vendor() {
		return this.value.vendor;
	}

	get icon() {
		return {
			Dropbox          : ['fab', 'fa-dropbox'],
			GoogleDrive      : ['fab', 'fa-google-drive'],
			OneDrive         : ['fab', 'fa-microsoft'],
			OneDriveBusiness : ['fab', 'fa-microsoft'],
			MicrosoftSites   : ['fab', 'fa-microsoft'],
		}[this.vendor] || ['fas', 'fa-question'];
	}

	get multilayered() {
		return {
			OneDrive         : true,
			OneDriveBusiness : false,
		}[this.vendor];
	}

	get layers() {
		return {
			OneDrive : [{
				icon      : 'fas fa-cloud fa-flip-horizontal fa-inverse',
				transform : 'shrink-8 down-1 left-2.25',
			}, {
				icon      : 'fas fa-cloud fa-inverse',
				transform : 'shrink-9.25 down-2 left-0.5',
			}, {
				icon      : 'fal fa-cloud',
				transform : 'shrink-9 down-2 left-0.5',
			}],
		}[this.vendor];
	}
}

Vue.component('cloud-folder-icon', CloudFolderIcon);
