import localStorage from '@/services/local-storage.service';

export default {
	namespaced : true,

	state : {
		marker : null,

		list : null,
		flag : null,
	},

	getters : {
		marker(state) {
			if (!state.marker)
				state.marker = localStorage.get('marker');

			return state.marker;
		},

		list(state) {
			return flag => !state.flag || state.flag === flag ? state.list : null;
		},
	},

	mutations : {
		set(state, { marker }) {
			state.marker = marker || null;

			localStorage.set('marker', state.marker);

			if (!state.marker) {
				state.list = null;
				state.flag = null;
			}

			return state.marker;
		},

		list(state, { list, flag }) {
			if (flag && !/^farm_id=/.test(flag)) return list;

			state.list = list || null;
			state.flag = flag || null;

			return state.list;
		},

		clear(state) {
			state.marker = null;
			state.list = null;
			state.flag = null;

			localStorage.set('marker', null);

			return null;
		},
	},

	actions : {
		set({ commit }, payload) {
			return Promise.resolve(payload)
				.then(payload => commit('set', payload));
		},

		list({ commit }, payload) {
			return Promise.resolve(payload)
				.then(payload => commit('list', payload));
		},

		clear({ commit }) {
			return Promise.resolve(commit('clear'));
		},
	},
};
