import api from '../api';
import company from '../company';

export default {
	// list,
	// find,
	read,
	// create,
	// update,
	// save,
	// delete : _delete,

	syncZone,
	syncLocation,

	// WorkOrderSync : _extend,
};

// function list(filters) {
// 	return api.list('work-order-sync', filters)
// 		.then(list => list.map(workOrderSync => _extend(workOrderSync)));
// }

// function find(filters) {
// 	return list(filters).then(list => list.length === 1 ? list[0] : null);
// }

function read() {
	return company.get()
		.then(company => company ? company.company_id : null)
		.then(company_id => company_id && api.read('work-order-sync/preview', company_id));
}

function syncZone(payload) {
	return company.get()
		.then(company => company ? company.company_id : null)
		.then(company_id => company_id && api.create('work-order-sync/zone', Object.assign({
			company_id,
		}, payload)));
}

function syncLocation(payload) {
	return company.get()
		.then(company => company ? company.company_id : null)
		.then(company_id => company_id && api.create('work-order-sync/location', Object.assign({
			company_id,
		}, payload)));
}
