import Vue from 'vue';
import Component from 'vue-class-component';
import template from './timeseries-source-list.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

@Component({
	template,
})
export class TimeseriesSourceList extends Vue {
	data() {
		return {
			connecting : null,
			loading    : null,
			source     : null,
		};
	}

	@Async
	get sources() {
		this.$emit('error');

		this.loading = true;

		return api.company.get()
			.then(({ company_id }) => api.timeseries.source.list({ company_id }))
			.catch(error => { this.$emit('error', error) })
			.finally(() => { this.loading = false });
	}

	addSource(source) {
		this.connecting = null;

		this.sources.push(source);

		this.$emit('input', source);
	}

	selectSource(source) {
		this.source = source;

		this.$emit('input', source);
	}

	editSource(source) {
		this.source = source;
	}

	deleteSource(source) {
		this.$emit('error');

		this.loading = true;

		return api.timeseries.source.delete(source.timeseriesSource_id)
			.then(() => { this.sources.splice(this.sources.indexOf(source), 1) })
			.catch(error => { this.$emit('error', error) })
			.finally(() => { this.loading = false });
	}
}

Vue.component('timeseries-source-list', TimeseriesSourceList);
