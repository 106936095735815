import api from './api';

export default {
	list,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	Folder : _extend,
};

function list(filters) {
	return api.list('folder', filters)
		.then(list => list.map(folder => _extend(folder)));
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(folder_id) {
	return api.read('folder', folder_id)
		.then(folder => _extend(folder));
}

function create(folder) {
	return api.create('folder', folder);
}

function update(folder) {
	return api.update('folder', folder.folder_id, folder);
}

function save(folder) {
	return folder.folder_id ? update(folder) : create(folder);
}

function _delete(folder_id) {
	return api.delete('folder', folder_id);
}

function _extend(folder = {}) {
	return Object.assign({
		type : 'folder',

	}, folder);
}
