import api from './api';
import company from './company';

export default {
	list,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	reauthorize,
	farms,
	fields,
	legends,

	CeresImaging : _extend,
};

function list(filters) {
	return filters
		? api.list('ceres-imaging', filters)
			.then(list => list.map(ceresImaging => _extend(ceresImaging)))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? list({ company_id }) : []);
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(ceresImaging_id) {
	return api.read('ceres-imaging', ceresImaging_id)
		.then(ceresImaging => _extend(ceresImaging));
}

function create(ceresImaging) {
	return api.create('ceres-imaging', ceresImaging);
}

function update(ceresImaging) {
	return api.update('ceres-imaging', ceresImaging.ceresImaging_id, ceresImaging);
}

function save(ceresImaging) {
	return ceresImaging.ceresImaging_id ? update(ceresImaging) : create(ceresImaging);
}

function _delete(ceresImaging_id) {
	return api.delete('ceres-imaging', ceresImaging_id);
}

function reauthorize(ceresImaging_id) {
	return api.read('ceres-imaging/reauthorize', ceresImaging_id);
}

function farms({ ceresImaging_id }) {
	return api.read('ceres-imaging/farms', ceresImaging_id);
}

function fields({ ceresImaging_id, uuid }) {
	return api.read('ceres-imaging/fields', `${ceresImaging_id}/${uuid}`);
}

function legends({ ceresImaging_id, flight_id = '' }) {
	return api.read('ceres-imaging/legends', `${ceresImaging_id}/${flight_id}`);
}

function _extend(ceresImaging = {}) {
	return Object.assign({

	}, ceresImaging);
}
