import MapLabel from '../../map-label';
import mapService from '../google.lib';
import polyLabel from '@mapbox/polylabel';
import sessionStorage from '@/services/session-storage.service';

////

export default () => {
	const labels = [];

	return {
		labelType : sessionStorage.get('map-label') || null,

		forceLabelRefresh() {
			labels.forEach(label => label.setMap(null));
			labels.forEach(label => label.setMap(this._map));
		},

		_addLabel(feature) {
			const map = this._map;
			const feature_id = feature.getId();
			const geometry = feature.getGeometry();
			const position = new mapService.Coordinate(getCenter(geometry));

			const label = new MapLabel({
				map,
				feature,
				geometry,
				feature_id,
				position,
				visible : feature.getProperty('visible') !== false,
				text    : getLabel(feature),
			});

			feature.label = label;
			labels.push(label);
			this.emit('addlabel', { label });

			return label;
		},

		_removeLabel(feature) {
			const feature_id = feature.getId() || null;
			const index = labels.findIndex(label => label === feature.label || label.feature_id === feature_id);

			if (index >= 0) {
				this.emit('removelabel', { label : labels[index] });
				labels[index].setMap(null);
				labels.splice(index, 1);
			}
		},

		_setEvents() {
			const data = this._map.data;

			data.addListener('addfeature', ({ feature }) => this._addLabel(feature));
			data.addListener('removefeature', ({ feature }) => this._removeLabel(feature));
			data.addListener('setproperty', event => {
				updateLabel(event);
				updateVisibility(event);
			});
			data.addListener('removeproperty', event => {
				updateLabel(event);
				updateVisibility(event);
			});
			data.addListener('setgeometry', ({ feature, newGeometry : geometry }) => {
				const label = feature.label;
				const position = new mapService.Coordinate(getCenter(geometry));

				if (label && position)
					label.set('position', position);
			});
		},
	};
};

////////

function updateLabel({ feature, name : property }) {
	const label = feature.label;
	const nameLike = /label|name/i.test(property);

	if (label && nameLike)
		label.set('text', getLabel(feature));
}

function updateVisibility({ feature, name : property, newValue : value }) {
	const label = feature.label;

	if (label && property === 'visible')
		label.set(property, value);
}

function getCenter(geometry) {
	const type = geometry.getType();

	const handler = {
		Point(geometry) { return point(geometry) },
		LineString(geometry) { return polyLabel([linestring(geometry)]) },
		Polygon(geometry) { return polyLabel(polygon(geometry)) },
		MultiPolygon(geometry) { return polyLabel(multipolygon(geometry)) },
	}[type];

	return handler && handler(geometry);

	function point(geometry) {
		if (geometry.get) return point(geometry.get());

		return [geometry.lng(), geometry.lat()];
	}

	function linestring(geometry) {
		return geometry.getArray().map(coord => point(coord));
	}

	function polygon(geometry) {
		return geometry.getArray().map(ring => linestring(ring));
	}

	function multipolygon(geometry) {
		return polygon(geometry.getAt(0));
	}
}

function getLabel(feature) {
	return feature.getProperty('label')
		|| feature.getProperty('fieldName')
		|| feature.getProperty('markerName')
		|| feature.getProperty('lineName')
		|| feature.getProperty('name');
}
