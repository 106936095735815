import api from '@/api';

////////

export default {
	getAttribute,
	assignAttributes,
	loadFieldAttributes,
	loadMarkerAttributes,
	loadLineAttributes,
	loadFeatureAttributes,
};

////////
//  Exported Functions
//

function getAttribute(pattern, attributes, query) {
	return Promise.resolve(attributes || api.attribute.list(query))
		.then(list => list.find(({ label }) => pattern.test(label)))
		.then(attribute => attribute ? attribute.value : null);
}

export function assignAttributes(feature) {
	const key = Object.keys(feature).find(key => /_attributes$/.test(key));
	const featureType = determineFeatureType(feature);

	return Promise.resolve(feature[key] || [])
		.then(list => list.map(({ attribute }) => attribute))
		.then(list => list.filter(attribute => attribute))
		.then(list => list.length ? list : api.attribute.list({
			[`${featureType}_id`] : feature.feature_id,
		}))
		.then(attributes => Object.assign(feature, { attributes }));
}

function determineFeatureType(feature) {
	if (feature.featureType) return feature.featureType;
	if (!feature.feature_id) return;
	if (feature.field_id === feature.feature_id) return 'field';
	if (feature.marker_id === feature.feature_id) return 'marker';
	if (feature.line_id === feature.feature_id) return 'line';
}

export function loadFieldAttributes(field) {
	const { field_id } = field;

	return loadAttributes(field, { field_id });
}

export function loadMarkerAttributes(marker) {
	const { marker_id } = marker;

	return loadAttributes(marker, { marker_id });
}

export function loadLineAttributes(line) {
	const { line_id } = line;

	return loadAttributes(line, { line_id });
}

export function loadFeatureAttributes(feature) {
	return Promise.all([
		feature,
		feature.properties,
		feature.properties.attributes || api.attribute.list({
			[`${feature.properties.featureType}_id`] : feature.properties.feature_id,
		}),
	])
		.then(([feature, properties, attributes]) => [
			feature,
			Object.assign(properties, { attributes }),
		])
		.then(([feature]) => feature);
}

////

function loadAttributes(feature, query) {
	return Promise.resolve(feature.attributes || api.attribute.list(query))
		.then(attributes => Object.assign(feature, { attributes }))
		.then(({ attributes }) => attributes);
}
