import Vue from 'vue';
import Component from 'vue-class-component';
import template from './logout.html';
import api from '@/api';

const LOGOUT_URL = process.env.BRAND_LOGOUT_URL;
const urlRe = /^https:\/\//;

/*
urlRe is intentionally short, we are in a limited scope,
don't need to take an exceptionally long time to figure out
if the variable that we control is correct.

-AM Aug 22, 2022
*/

@Component({ template })
export default class Logout extends Vue {
	created() {
		this.logout();
	}

	async logout() {
		/* This must be await if external logout is enabled. We navigate away and the request is cancelled */
		await api.session.logout()
			.catch(() => null);

		if (LOGOUT_URL && urlRe.test(LOGOUT_URL))
			return location.href = LOGOUT_URL; // eslint-disable-line no-return-assign

		return this.$router.replace('login');
	}
}
