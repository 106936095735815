import api from '../api';

export default {
	preview,
	list,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	TimeseriesDevice : _extend,
};

function preview(filters) {
	return api.list('timeseries-device/preview', filters, { sortBy : 'deviceName' })
		.then(list => list.map(timeseries => _extend(timeseries)));
}

function list(filters) {
	return api.list('timeseries-device', filters, { sortBy : 'deviceName' })
		.then(list => list.map(timeseries => _extend(timeseries)));
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(timeseriesDevice_id) {
	return api.read('timeseries-device', timeseriesDevice_id)
		.then(timeseries => _extend(timeseries));
}

function create(timeseries) {
	return api.create('timeseries-device', timeseries);
}

function update(timeseries) {
	return api.update('timeseries-device', timeseries.timeseriesDevice_id, timeseries);
}

function save(timeseries) {
	return timeseries.timeseriesDevice_id ? update(timeseries) : create(timeseries);
}

function _delete(timeseriesDevice_id) {
	return api.delete('timeseries-device', timeseriesDevice_id);
}

function _extend(timeseries = {}) {
	return Object.assign({

	}, timeseries);
}
