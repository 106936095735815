import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "settings-core" */'./company-settings');

const redirect = { name : 'company-details' };

import companyCeresImaging from './company-ceres-imaging/company-ceres-imaging.route';
import companyCloudStorage from './company-cloud-storage/company-cloud-storage.route';
import companyDetails from './company-details/company-details.route';
import companyFarms from './company-farms/company-farms.route';
import companyPumpsight from './company-pumpsight/company-pumpsight.route';
import companyTeams from './company-teams/company-teams.route';
import companyUsers from './company-users/company-users.route';
import workOrders from './work-orders/work-orders.route';

const children = [
	companyCeresImaging,
	companyCloudStorage,
	companyDetails,
	companyFarms,
	companyPumpsight,
	companyTeams,
	companyUsers,
	workOrders,
	{
		path : '*',
		redirect,
	},
];

export default {
	children,
	component,
	redirect,
	path : 'company-settings',
	meta : {
		title          : `${dsl('Company')} Settings`,
		secure         : true,
		requireCompany : true,
	},
};
