import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-filters.html';
import service from '@/services/map-filter.service';
import MapFilter from './classes/map-filter.class';
import MapFilterEntry from './map-filter-entry/map-filter-entry';
import MapFilterForm from './map-filter-form/map-filter-form';
import sessionStorage from '@/services/session-storage.service';
import Watch from '@/plugins/watch-decorator';

const components = {
	MapFilterEntry,
	MapFilterForm,
};

const props = {
	map      : Object, // Google Map object
	features : Array, // Array of GeoJSON objects
	filters  : {
		type     : Array, // [{ type, operation, value }]
		required : true,
	},
};

@Component({
	template,
	components,
	props,
})
export class MapFilters extends Vue {
	data() {
		return {
			loading : null,
			editing : null, // the component being edited
		};
	}

	get hasActiveFilters() {
		return this.filters.some(({ active }) => active);
	}

	create(filter) {
		this.filters.push(filter);
	}

	update(filter, index) {
		this.filters.splice(index, 1, filter);
		this.editing = false;
	}

	remove(index) {
		this.filters.splice(index, 1);
	}

	@Watch('loading')
	watchLoading() {
		this.editing = null;

		this.$emit('loading', this.loading);
	}

	@Watch('filters', { immediate : true })
	updateFilters() {
		if (this.loading) return;

		this.loading = true;

		const list = this.filters.filter(({ key }) => !key);

		return Promise.resolve(list.length > 0)
			.then(updating => updating
				? list.forEach(filter => {
					const index = this.filters.indexOf(filter);

					this.filters.splice(index, 1, new MapFilter(filter));
				})
				: this.updateVisibleFeatures()
			)
			.finally(() => {
				this.loading = false;
				sessionStorage.set('map-filters', this.filters);
			});
	}

	updateVisibleFeatures() {
		return service.updateVisibleFeatures(this.map, this.features, this.filters);
	}
}

Vue.component('map-filters', MapFilters);
