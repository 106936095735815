import agricultureDomain from './domains/agriculture';
import collegeDomain from './domains/college';
import defaultDomain from './domains/default';
import eldritchDomain from './domains/eldritch';
import foodSafetyDomain from './domains/food-safety';
import groundwaterDomain from './domains/groundwater';
import piracyDomain from './domains/piracy';
import solarDomain from './domains/solar';
import pluralize from 'pluralize';
import realEstateDomain from './domains/real-estate';
import storage from '@/services/local-storage.service';
import testingDomain from './domains/testing';

const domains = {
	// Standard
	'Default' : defaultDomain,
	'Testing' : testingDomain,

	// Custom
	'Agriculture' : agricultureDomain, // The basic package
	'Food Safety' : foodSafetyDomain, // FDA Food Safety Modernization Act
	'Groundwater' : groundwaterDomain, // California Sustainable Groundwater Management Act
	'Real Estate' : realEstateDomain,
	'Solar'       : solarDomain,

	// Fun
	'College'  : collegeDomain,
	'Piracy'   : piracyDomain,
	'Eldritch' : eldritchDomain,
};
const transcribe = {};

setDomain(getCurrentDomain());

const patterns = [{
	name  : 'capitalized',
	test  : (phrase, { casing : allowed }) => allowed && /^[A-Z]/.test(phrase),
	apply : phrase => phrase.replace(/^./, match => match.toUpperCase()),
}, {
	name  : 'proper cased',
	test  : (phrase, { casing : allowed }) => allowed && phrase.split(' ').every(word => /^[A-Z]/.test(word)),
	apply : phrase => phrase.replace(/(^| )./g, match => match.toUpperCase()),
}, {
	name  : 'uppercased',
	test  : (phrase, { casing : allowed }) => allowed && !/[a-z]/.test(phrase),
	apply : phrase => phrase.toUpperCase(),
}, {
	name  : 'pluralized',
	test  : (phrase, { plural : allowed }) => allowed && phrase === pluralize(phrase),
	apply : phrase => pluralize(phrase),
}];

export function getDomains() {
	return Object.keys(domains);
}

export function getCurrentDomain() { // eslint-disable-line complexity
	const defaultDomain = [
		/fsma/.test(location.hostname) ? 'Food Safety' : null,
		/sgma/.test(location.hostname) ? 'Groundwater' : null,
		/real-?estate/.test(location.hostname) ? 'Real Estate' : null,
		/solar/.test(location.hostname) ? 'Solar' : null,
		/montage.ag$/.test(location.hostname) ? 'Agriculture' : null,
		process.env.DOMAIN_SPECIFIC_LANGUAGE || null,
	].find(name => name);

	return storage.get('domain-specific-language') || defaultDomain;
}

export function setDomain(name) {
	storage.set('domain-specific-language', name);

	Object.assign(transcribe, domains.Default, domains[name]);
}

export default function dsl(phrase) {
	if (!phrase) return phrase;

	const transcription = [
		'literal',
		'plural',
		'single',
		'lowercase literal',
		'lowercase plural',
		'lowercase single',
	]
		.map(name => Object.assign({ name }, {
			lookup : getTranscriptionLookup(name, phrase),
		}))
		.map(check => Object.assign({
			result : transcribe[check.lookup],
		}, check))
		.find(check => typeof check.result === 'string');

	if (!transcription) return phrase;

	return applyPatterns(transcription.result, filterPatterns(phrase, getAllowedPatterns(transcription)));
}

function getTranscriptionLookup(name, phrase) {
	return {
		'literal'           : phrase,
		'plural'            : pluralize(phrase),
		'single'            : pluralize(phrase, 1),
		'lowercase literal' : phrase.toLowerCase(),
		'lowercase plural'  : pluralize(phrase).toLowerCase(),
		'lowercase single'  : pluralize(phrase, 1).toLowerCase(),
	}[name] || phrase;
}

function getAllowedPatterns({ name }) {
	return {
		'literal' : {
			casing : false,
			plural : false,
		},
		'plural' : {
			casing : false,
			plural : false,
		},
		'single' : {
			casing : false,
			plural : true,
		},
		'lowercase literal' : {
			casing : true,
			plural : false,
		},
		'lowercase plural' : {
			casing : true,
			plural : false,
		},
		'lowercase single' : {
			casing : true,
			plural : true,
		},
	}[name];
}

function filterPatterns(phrase, allowed) {
	return patterns.filter(({ test }) => test(phrase, allowed));
}

function applyPatterns(phrase, patterns) {
	return patterns.reduce((phrase, { apply }) => apply(phrase), phrase);
}
