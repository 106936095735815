import api from '../api';
import company from '../company';

export default {
	list,
	// find,
	// read,
	create,
	update,
	save,
	delete : _delete,

	Employee : _extend,
};

function list(filters) {
	return company.get()
		.then(({ company_id }) => Object.assign({ company_id }, filters))
		.then(filters => api.list('work-order-sync/employee', filters))
		.then(list => list.map(employee => _extend(employee)));
}

// function find(filters) {
// 	return list(filters).then(list => list.length === 1 ? list[0] : null);
// }

// function read(employee_id) {
// 	return api.read('work-order-sync/employee', employee_id)
// 		.then(employee => _extend(employee));
// }

function create(employee) {
	return company.get()
		.then(({ company_id }) => Object.assign({ company_id }, employee))
		.then(employee => api.create('work-order-sync/employee', employee));
}

function update(employee) {
	return api.update('work-order-sync/employee', employee.employee_id, employee);
}

function save(employee) {
	return employee.employee_id ? update(employee) : create(employee);
}

function _delete(employee_id) {
	return company.get()
		.then(({ company_id }) => api.delete('work-order-sync/employee', employee_id, { company_id }));
}

function _extend(employee = {}) {
	return Object.assign({
	}, employee);
}
