import Vue from 'vue';
import Component from 'vue-class-component';
import template from './enter-parcel-number-panel.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import { getFeaturesByApn } from '@/services/report-all-usa.service';

const props = {
	value : Object, // data passed through the panel-container
};

@Component({
	template,
	props,
})
export class EnterParcelNumberPanel extends Vue {
	data() {
		return {
			error   : null,
			loading : null,
			query   : {
				apn    : null,
				county : null,
				state  : null,
			},
			attributes : [],
			collection : null, // Array of Google Map Data Features
			geometry   : null, // GeoJSON object, { geometry, properties, type }
			parcel     : {
				acreage         : null,
				number          : null,
				owner           : null,
				physicalAddress : null,
				mailingAddress  : null,
			},
			field : {
				color     : '',
				fieldName : null,
			},
		};
	}

	beforeDestroy() {
		if (this.collection) {
			this.map.removeFeatureCollection(this.collection);
			this.collection = null;
		}
	}

	get acreage() {
		if (!this.parcel) return 0;

		return this.parcel.acreage;
	}

	get feature() { // Google Map Data Feature
		if (this.collection) return this.collection[0];
	}

	get features() {
		// returns a list of existing features (fields, markers, lines)
		// for validating the field name.
		return this.value.features.map(({
			properties : {
				feature_id,
				featureType,
				fieldName,
				markerName,
				lineName,
			},
		}) => ({
			feature_id,
			featureType,
			featureName : fieldName || markerName || lineName,
		}));
	}

	get map() {
		return this.value.map;
	}

	@Watch('geometry', { deep : false })
	watchGeometry() {
		if (!this.geometry) return;

		const { properties } = this.geometry;

		this.geometry.properties.color = this.field.color;

		this.collection = this.map.replaceFeatureCollection(this.collection, {
			type     : 'FeatureCollection',
			features : [this.geometry],
		});

		this.parcel = properties;

		this.map.flyToGeometry(this.collection);
	}

	@Watch('field.color')
	watchColor() {
		if (this.collection)
			this.collection.forEach(feature => feature.setProperty('color', this.field.color));
	}

	save() {
		this.error = null;
		this.loading = true;

		return Promise.resolve(this.geometry)
			.then(geometry => Object.assign(this.field, { geometry }))
			.then(field => api.field.create(field))
			.then(field => Promise.all([
				field,
				[{
					label : 'Parcel Acres',
					value : this.parcel.acreage,
				}, {
					label : 'Parcel Number',
					value : this.parcel.parcelNumber,
				}, {
					label : 'Parcel Owner',
					value : this.parcel.parcelOwner,
				}, {
					label : 'Physical Address',
					value : this.parcel.physicalAddress,
				}, {
					label : 'Mailing Address',
					value : this.parcel.mailingAddress,
				}]
					.filter(({ value }) => value)
					.concat(this.attributes),
			]))
			.then(([field, attributes]) => Promise.all([
				field,
				Promise.all(attributes.map((attribute, index) => api.attribute.create(Object.assign(attribute, {
					field_id : field.field_id,
					order    : index + 1,
				})))),
			]))
			.then(([{ fieldName, field_id, geometry }]) => {
				geometry.properties.featureType = 'field';
				geometry.properties.feature_id = field_id;
				geometry.properties.fieldName = fieldName;

				api.parcel.create(Object.assign({ field_id }, this.parcel)).catch(() => null);

				this.value.features.push(geometry);

				this.map.replaceFeatureCollection(this.collection, {
					type     : 'FeatureCollection',
					features : [geometry],
				});
			})
			.then(() => { this.$emit('close') })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	search() {
		if (this.loading) return;

		this.error = null;
		this.loading = true;

		const { apn, county, state } = this.query;

		return getFeaturesByApn(apn, `${county}, ${state}`)
			.then(list => list[0])
			.then(geometry => geometry || Promise.reject(new Error('Parcel not found')))
			.then(geometry => { this.geometry = geometry })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	validateName(name) {
		const feature = this.features.find(({ featureName }) => featureName === name);

		if (feature) return `A ${this.$dsl(feature.featureType)} named '${name}' already exists.`;
	}
}

Vue.component('enter-parcel-number-panel', EnterParcelNumberPanel);
