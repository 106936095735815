import Vue from 'vue';
import Component from 'vue-class-component';
import template from './conversation-details.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import Async from '@/plugins/async-decorator';
import { isDemoUser } from '@/services/permission.service';

const props = {
	value    : Object,
	readonly : Boolean,
};

@Component({
	template,
	props,
})
export class ConversationDetails extends Vue {
	data() {
		return {
			error    : null,
			comments : null,
		};
	}

	@Watch('value', { immediate : true })
	refresh() {
		if (!this.value) return null;

		const conversation_id = this.value.conversation_id;

		return api.comment.list({ conversation_id })
			.then(comments => { this.comments = comments })
			.catch(error => { this.error = error });
	}

	@Async
	get isDemo() {
		return isDemoUser();
	}
}

Vue.component('conversation-details', ConversationDetails);
