import Vue from 'vue';
import Component from 'vue-class-component';
import template from './marker-attributes.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import { getAttributes } from '@/config/marker-types';

const props = {
	value    : Object, // the Marker record
	readonly : Boolean,
};

@Component({
	template,
	props,
})
export class MarkerAttributes extends Vue {
	data() {
		return {
			error          : null,
			editing        : false,
			removalQueue   : [],
			removalPromise : Promise.resolve(),
		};
	}

	@Async(null, 'value')
	get attributes() {
		const { marker_id } = this.value;

		return Promise.resolve(this.value.attributes || api.attribute.list({ marker_id }))
			.catch(error => { this.error = error });
	}

	get placeholder() {
		return {
			label : 'e.g., Year Installed',
			value : 'e.g., 2018',
		};
	}

	get defaults() {
		return getAttributes(this.value);
	}

	@Watch('removalQueue')
	processRemoval() {
		if (!this.removalQueue.length) return;

		this.removalPromise = this.removalPromise.then(() => this.removalQueue[0])
			.then(attribute_id => Promise.all([attribute_id, api.attribute.delete(attribute_id)]))
			.then(([attribute_id]) => this.attributes.findIndex(attribute => attribute_id === attribute.attribute_id))
			.then(index => index >= 0 ? this.attributes.splice(index, 1) : null)
			.catch(error => { this.error = error })
			.finally(() => { this.removalQueue.shift() });
	}

	create(attribute) {
		const { marker_id } = this.value;

		this.error = null;

		return api.attribute.create(Object.assign({ marker_id }, attribute))
			.then(attribute => { this.attributes.push(attribute) })
			.catch(error => { this.error = error });
	}

	update(attribute) {
		this.error = null;

		return api.attribute.update(attribute)
			.then(() => this.attributes.findIndex(({ attribute_id }) => attribute_id === attribute.attribute_id))
			.then(index => index >= 0 ? this.attributes.splice(index, 1, attribute) : null)
			.catch(error => { this.error = error });
	}

	remove({ attribute_id }) {
		this.removalQueue.push(attribute_id);
	}
}

Vue.component('marker-attributes', MarkerAttributes);
