const component = () => import(/* webpackChunkName: "developer-test" */'./csv-parser');

export default {
	component,
	path : 'csv-parser',
	name : 'csv-parser-test',
	meta : {
		title  : 'Csv Parser Test',
		secure : false,
	},
};
