export default function updateFeatureGeometry(feature, editable) {
	const geometry = feature.getGeometry();
	const type = geometry.getType();

	const converter = {
		Point : ([editable]) => {
			feature.setGeometry(editable.getPosition());
		},

		LineString : ([editable]) => {
			const latlngArray = editable.getPath().getArray();
			const linestring = new window.google.maps.Data.LineString(latlngArray);

			feature.setGeometry(linestring);
		},

		Polygon : ([editable]) => {
			const paths = editable.getPaths()
				.getArray()
				.map(ring => ring.getArray());
			const polygon = new window.google.maps.Data.Polygon(paths);

			feature.setGeometry(polygon);
		},

		MultiPolygon : editables => {
			const polygons = editables.map(polygon => {
				const paths = polygon.getPaths()
					.getArray()
					.map(ring => ring.getArray());

				return new window.google.maps.Data.Polygon(paths);
			});
			const multiPolygon = new window.google.maps.Data.MultiPolygon(polygons);

			feature.setGeometry(multiPolygon);
		},
	}[type];

	if (!converter) throw new Error(`Unsupported Type ${type}. Cannot convert to editable.`);

	return converter(editable);
}
