import api from '@/api';

////////

let colorIndex = 0;

////////

export default {
	loadSensors,
	loadData,
};

////////
// Exported Functions

function loadSensors(device) {
	const { timeseriesDevice_id } = device;

	return api.timeseries.sensor.list({ timeseriesDevice_id })
		.then(sensors => sensors.map((sensor, index) => Object.assign(sensor, {
			selected : index === 0,
			color    : nextColor(),
		})));
}

function loadData(device, dateRange) {
	const { timeseriesDevice_id } = device;
	const query = {
		timeseriesDevice_id,
		startDate : dateRange.startDate ? dateRange.startDate.getTime() : null,
		endDate   : dateRange.endDate ? dateRange.endDate.getTime() : null,
	};

	return api.timeseries.data.list(query);
}

////////
// Local Functions


function nextColor() {
	const colors = [
		'5, 76, 124', // montage-blue
		'141, 197, 74', // montage-green
		'204, 61, 10', // brick-red
		'198, 179, 3', // wheat-yellow

		'112, 170, 73', // grass-green
		'80, 200, 244', // misty-blue
		'237, 72, 0', // barn-red
		'226, 209, 0', // corn-yellow

		'187, 218, 142', // leaf-green
		'0, 174, 239', // sky-blue
	];

	return colors[colorIndex++ % colors.length];
}
