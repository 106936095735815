import Vue from 'vue';
import Component from 'vue-class-component';
import template from './invite-user-button.html';

const props = {
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
};

@Component({
	template,
	props,
})
export class InviteUserButton extends Vue {
	get tooltip() {
		return [
			this.$dsl('Invite'),
			'someone to this',
			this.$dsl('company'),
		].join(' ');
	}
}

Vue.component('invite-user-button', InviteUserButton);
