import Vue from 'vue';
import Component from 'vue-class-component';
import template from './ceres-imaging-field-list-item.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

const props = {
	ceresField : {
		type     : Object, // CeresImagingField record
		required : true,
	},
};

@Component({
	template,
	props,
})
export class CeresImagingFieldListItem extends Vue {
	@Async
	get farm() {
		const { ceresImagingField_id } = this.ceresField;

		return api.farm.find({ ceresImagingField_id })
			.then(farm => farm || this.$emit('destroy', this.ceresField));
	}
}

Vue.component('ceres-imaging-field-list-item', CeresImagingFieldListItem);
