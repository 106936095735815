import Vue from 'vue';
import Component from 'vue-class-component';
import template from './create-field-from-json-top-panel.html';
import api from '@/api';
import Watch from '@/plugins/watch-decorator';

const props = {
	value : Object, // data passed through the panel-container
};

@Component({
	template,
	props,
})
export class CreateFieldFromJsonTopPanel extends Vue {
	data() {
		return {
			error      : null,
			loading    : null,
			attributes : [],
			collection : null,
			parcel     : {
				acreage         : null,
				number          : null,
				owner           : null,
				physicalAddress : null,
				mailingAddress  : null,
			},
			field : {
				color     : '',
				fieldName : null,
			},
		};
	}

	mounted() {
		if (!this.geometry) return;

		const { properties } = this.geometry;

		this.geometry.properties.color = this.field.color;

		this.collection = this.map.replaceFeatureCollection(this.collection, {
			type     : 'FeatureCollection',
			features : [this.geometry],
		});

		this.parcel = properties;

		this.map.flyToGeometry(this.collection);
	}

	beforeDestroy() {
		if (this.collection) {
			this.map.removeFeatureCollection(this.collection);
			this.collection = null;
		}
	}

	get map() {
		return this.value.map;
	}

	get geometry() {
		return this.value.createJson;
	}

	get acreage() {
		if (!this.parcel) return 0;

		return this.parcel.acreage;
	}

	@Watch('field.color')
	watchColor() {
		if (this.collection)
			this.collection.forEach(feature => feature.setProperty('color', this.field.color));
	}

	get features() {
		// returns a list of existing features (fields, markers, lines)
		// for validating the field name.
		return this.value.features.map(({
			properties : {
				feature_id,
				featureType,
				fieldName,
				markerName,
				lineName,
			},
		}) => ({
			feature_id,
			featureType,
			featureName : fieldName || markerName || lineName,
		}));
	}

	validateName(name) {
		const feature = this.features.find(({ featureName }) => featureName === name);

		if (feature) return `A ${this.$dsl(feature.featureType)} named '${name}' already exists.`;
	}

	save() {
		if (this.loading) return;

		this.error = null;
		this.loading = true;

		return Promise.resolve(this.geometry)
			.then(geometry => Object.assign(this.field, { geometry }))
			.then(field => api.field.create(field))
			.then(field => Promise.all([
				field,
				[
					buildProperty(this.parcel, 'Parcel Number', 'parcelNumber', 'number', 'name'),
					buildProperty(this.parcel, 'Parcel Acreage', 'parcelAcreage', 'acreage'),
					buildProperty(this.parcel, 'Parcel Owner', 'parcelOwner', 'owner'),
					buildProperty(this.parcel, 'Physical Address', 'physicalAddress'),
					buildProperty(this.parcel, 'Mailing Address', 'mailingAddress'),
					buildProperty(this.parcel, 'Last Updated', 'lastUpdated'),
					buildProperty(this.parcel, 'Parcel Municipality', 'parcelMunicipality'),
					buildProperty(this.parcel, 'Land Use Class', 'landUseClass'),
					buildProperty(this.parcel, 'Total Market Value', 'totalMarketValue'),
				]
					.filter(({ value }) => value)
					.concat(this.attributes),
			]))
			.then(([field, attributes]) => Promise.all([
				field,
				Promise.all(attributes.map((attribute, index) => api.attribute.create(Object.assign(attribute, {
					field_id : field.field_id,
					order    : index + 1,
				})))),
			]))
			.then(([{ fieldName, field_id, geometry }]) => {
				geometry.properties.featureType = 'field';
				geometry.properties.feature_id = field_id;
				geometry.properties.fieldName = fieldName;

				api.parcel.create(Object.assign({ field_id }, this.parcel)).catch(() => null);

				this.value.features.push(geometry);

				this.map.replaceFeatureCollection(this.collection, {
					type     : 'FeatureCollection',
					features : [geometry],
				});
			})
			.then(() => { this.$emit('close') })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('create-field-from-json-top-panel', CreateFieldFromJsonTopPanel);

function buildProperty(obj, label, ...keys) {
	return {
		label,
		value : findInObject(obj, ...keys),
	};
}

function findInObject(obj, ...paths) {
	const selected = paths.find(key => obj.hasOwnProperty(key) && obj[key] !== null);

	if (selected)
		return obj[selected];
}
