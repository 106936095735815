const component = () => import(/* webpackChunkName: "developer-test" */'./avatar');

export default {
	component,
	path : 'avatar',
	name : 'avatar-test',
	meta : {
		title  : 'Avatar Test',
		secure : false,
	},
};
