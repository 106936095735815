import Vue from 'vue';
import Component from 'vue-class-component';
import template from './ordered-list.html';

const props = {
	keyProp   : String,
	list      : Array,
	compact   : Boolean,
	clickable : Boolean,
};

@Component({
	template,
	props,
})
export class OrderedList extends Vue {
	key(item) {
		const { value = item } = item;

		return item.key || value[this.keyProp] || `${item.title}|${item.subtitle}`;
	}
}

Vue.component('ordered-list', OrderedList);
