import Vue from 'vue';
import Component from 'vue-class-component';
import template from './source-list.html';

const duration = 250;

const props = {
	list : Array,
};

@Component({
	template,
	props,
})
export class SourceList extends Vue {
	select(item) {
		clearTimeout(this.timeout);

		return new Promise(resolve => {
			this.timeout = setTimeout(resolve, duration);
		})
			.then(() => this.$emit('select', item));
	}
}

Vue.component('source-list', SourceList);
