import Vue from 'vue';
import Component from 'vue-class-component';
import Async from '@/plugins/async-decorator';
import template from './cloud-storage.html';
import flyoverService from '@/services/flyover.service';
import api from '@/api';

@Component({ template })
export class CloudStorageFlyover extends Vue {
	@Async
	get cloudStorage() {
		return flyoverService.getData()
			.catch(() => null);
	}

	save() {
		return api.cloudStorage.save(this.cloudStorage)
			.then(cloudStorage => flyoverService.resolve(cloudStorage));
	}
}

Vue.component('cloud-storage-flyover', CloudStorageFlyover);
