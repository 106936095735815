import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-add-feature-button.html';
import Async from '@/plugins/async-decorator';
import { checkFarmPrivilege, isDemoUser, showFarmUpsell } from '@/services/permission.service';
import modalService from '@/services/modal.service';

const props = {
	value    : Object,
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
};

@Component({
	template,
	props,
})
export class MapAddFeatureButton extends Vue {
	get map() {
		return this.value && this.value.map;
	}

	@Async(false)
	get isDemoUser() {
		return isDemoUser();
	}

	@Async(false)
	get canCreate() {
		return checkFarmPrivilege('create')
			.then(okay => okay && !this.isDemoUser);
	}

	@Async(false)
	get showFarmUpsell() {
		return showFarmUpsell();
	}

	onClick(event) {
		if (this.showFarmUpsell)
			return modalService.launchModal('access-upsell', {})
				.catch(() => null);

		this.$emit('click', event);

		this.addFeature(event);
	}

	addFeature(value) {
		this.$emit('panel', {
			panel : 'add-map-feature',
			value,
		});
	}
}

Vue.component('map-add-feature-button', MapAddFeatureButton);
