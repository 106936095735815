import api from './api-storage-list';
import company from './company';
import storage from '@/services/storage.service';
import lockout from '@/services/billing-lockout.service';
import config from './config';

export default {
	set,
	get,
	list,
	count,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	Farm : _extend,
};

function set(farm) {
	return Promise.resolve(appendCompany(farm))
		.then(farm => Promise.all([farm, config.read(), farm && farm.farm_id]))
		.then(([farm, record, farm_id]) => Promise.all([farm, Object.assign(record, { farm_id })]))
		.finally(() => api.resetQueue())
		.then(([farm, record]) => Promise.all([storage.set('farm', farm), config.save(record)]))
		.then(([farm]) => farm);

	function appendCompany(farm) {
		if (!farm) return farm;

		return company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => Object.assign(farm, { company_id }));
	}
}

function get() {
	return Promise.all([company.get(), storage.get('farm')])
		.then(([company, farm]) => [company ? company.company_id : null, farm])
		.then(([company_id, farm]) => company_id === farm.company_id ? farm : Promise.reject(null))
		.catch(() => restore());

	function restore() {
		return Promise.all([config.read(), list()])
			.then(([{ farm_id }, list]) => Promise.all([list.find(farm => farm.farm_id === farm_id) || list[0]]))
			.then(([restored, farm]) => restored || set(farm));
	}
}

function list(filters) {
	return filters
		? api.list('farm', filters)
			.then(list => list.map(farm => _extend(farm)))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? list({ company_id }) : []);
}

function count(filters) {
	return filters
		? api.count('farm', filters)
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? count({ company_id }) : { count : 0 });
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(farm_id) {
	return api.read('farm', farm_id)
		.then(farm => _extend(farm));
}

function create(farm) {
	return company.get()
		.then(({ company_id }) => company_id)
		.then(company_id => api.create('farm', Object.assign(farm, { company_id })))
		.then(farm => set(farm))
		.then(farm => reportUsage(farm));
}

function update(farm) {
	return api.update('farm', farm.farm_id, farm);
}

function save(farm) {
	return farm.farm_id ? update(farm) : create(farm);
}

function _delete(farm_id) {
	return api.delete('farm', farm_id)
		.then(result => Promise.all([result, get()]))
		.then(([result, farm]) => [result, farm ? farm.farm_id === farm_id : null])
		.then(([result, selected]) => selected ? Promise.all([result, set()]) : [result])
		.then(([result]) => result)
		.then(farm => reportUsage(farm));
}

function _extend(farm = {}) {
	return Object.assign({
		farmName : '',
	}, farm);
}

function reportUsage(value) {
	return Promise.resolve(lockout.reportUsage('farms'))
		.then(() => value);
}
