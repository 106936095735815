import Vue from 'vue';
import Component from 'vue-class-component';
import template from './login.html';
import api from '@/api';
import storage from '@/services/storage.service';

@Component({
	template,
	beforeRouteEnter(to, from, next) {
		if (from.name || to.params.redirect !== true) return next();

		return api.session.get()
			.then(session => session ? process.env.LANDING_PAGE : null)
			.catch(() => null)
			.then(name => name ? next({
				name,
				query : to.query,
			}) : next());
	},
})
export default class Login extends Vue {
	created() {
		storage.reset();
	}

	mounted() {
		api.session.logout();
	}

	get registrationDisabled() {
		return process.env.DISABLE_OPEN_REGISTRATION === 'true';
	}

	get isDemo() {
		return /^demo\./i.test(location.hostname);
	}

	get email() {
		return this.$route.query.email;
	}

	login() {
		return this.$router.push({
			name  : 'select-company',
			query : this.$route.query,
		});
	}
}
