import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-label-dropdown.html';
import service from '@/services/map-label.service';
import sessionStorage from '@/services/session-storage.service';
import Watch from '@/plugins/watch-decorator';

const props = {
	map : {
		type     : Object,
		required : true,
	},
	features : Array, // geoJson features, [{ geometry, properties, type }, ...]
};

@Component({
	template,
	props,
})
export class MapLabelDropdown extends Vue {
	data() {
		return {
			loading : null,
		};
	}

	get list() {
		return [{
			label : 'Name',
			value : '',
		}, {
			label : 'Parcel Number',
			value : 'APN',
		}, {
			label : 'Crop',
			value : 'Crop',
		}, {
			label : this.$dsl('Boundary Acres'),
			value : 'Boundary Acres',
		}, {
			label : 'Owner',
			value : 'Owner',
		}, {
			label : 'Parcel Acres',
			value : 'Parcel Acres',
		}, {
			label : 'Pesticide Permit',
			value : 'Pesticide Permit',
		}];
	}

	get label() {
		return this.map.labelType || 'Name';
	}

	get value() {
		return this.map.labelType || '';
	}

	set value(labelType) {
		this.map.labelType = labelType;
		sessionStorage.set('map-label', labelType);
	}

	@Watch(['value', 'features'], { immediate : true })
	updateLabels() {
		if (!this.features) return this.$emit('input', this.value);

		this.loading = true;

		return service.updateLabels(this.map, this.features, this.value)
			.then(() => { this.loading = false });
	}
}

Vue.component('map-label-dropdown', MapLabelDropdown);
