const component = () => import(/* webpackChunkName: "developer-test" */'./onboarding-progress-tree');

export default {
	component,
	path : 'onboarding-progress-tree',
	name : 'onboarding-progress-tree-test',
	meta : {
		title  : 'Onboarding Progress Tree Test',
		secure : false,
	},
};
