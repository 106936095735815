const component = () => import(/* webpackChunkName: "developer-test" */'./dsl');

export default {
	component,
	path : 'dsl',
	name : 'dsl-test',
	meta : {
		title  : 'Dsl Test',
		secure : false,
	},
};
