import Vue from 'vue';
import Component from 'vue-class-component';
import template from './add-company-modal.html';

@Component({ template })
export class AddCompanyModal extends Vue {
	data() {
		return {
			form : {
				companyName : '',
				farmName    : '',
			},
		};
	}

	get listeners() {
		return Object.assign({}, this.$listeners, {
			submit : () => null,
		});
	}
}
