import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-access-key-form.html';
import api from '@/api';

@Component({ template })
export class WorkOrderAccessKeyForm extends Vue {
	data() {
		return {
			error        : null,
			loading      : null,
			accessKey    : null,
			accountCheck : null, // String ['yes', 'no']
		};
	}

	get label() {
		return `Enter Your ${this.$dsl('Work Orders')} ${this.$dsl('Access Key')}`;
	}

	submit() {
		if (this.loading) return;

		const accessKey = this.accessKey;

		this.error = null;
		this.loading = true;

		api.workOrder.accessKey.create({ accessKey })
			.then(accessKey => { this.$emit('create', accessKey) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	register() {
		if (this.loading) return;

		this.error = null;
		this.loading = true;

		api.workOrder.accessKey.register()
			.then(accessKey => { this.$emit('create', accessKey) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('work-order-access-key-form', WorkOrderAccessKeyForm);
