import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "developer-test" */'./team-details');

export default {
	component,
	path : 'team-details',
	name : 'team-details-test',
	meta : {
		title  : `${dsl('Team')} Details`,
		secure : false,
	},
};
