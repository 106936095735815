import Vue from 'vue';
import Component from 'vue-class-component';
import template from './cloud-folder-list.html';

const props = {
	list : Array,
};

@Component({
	template,
	props,
})
export class CloudFolderList extends Vue {}

Vue.component('cloud-folder-list', CloudFolderList);
