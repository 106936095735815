import agricultureMarkerTypes from './domains/agriculture';
import collegeMarkerTypes from './domains/college';
import defaultMarkerTypes from './domains/default';
import eldritchMarkerTypes from './domains/eldritch';
import foodSafetyMarkerTypes from './domains/food-safety';
import groundwaterMarkerTypes from './domains/groundwater';
import piracyMarkerTypes from './domains/piracy';
import solarMarkerTypes from './domains/solar';
import realEstateMarkerTypes from './domains/real-estate';
import testingMarkerTypes from './domains/testing';
import { getCurrentDomain } from '@/services/dsl.service';

const domains = {
	// Standard
	'Default' : defaultMarkerTypes,
	'Testing' : testingMarkerTypes,

	// Custom
	'Agriculture' : agricultureMarkerTypes, // The basic package
	'Food Safety' : foodSafetyMarkerTypes, // FDA Food Safety Modernization Act
	'Groundwater' : groundwaterMarkerTypes, // California Sustainable Groundwater Management Act
	'Real Estate' : realEstateMarkerTypes,
	'Solar'       : solarMarkerTypes,

	// Fun
	'College'  : collegeMarkerTypes,
	'Piracy'   : piracyMarkerTypes,
	'Eldritch' : eldritchMarkerTypes,
};

const markerTypes = domains[getCurrentDomain()] || defaultMarkerTypes;

export function getAttributes(marker) {
	if (!marker) return [];

	const markerType = markerTypes.find(({ icon }) => icon === marker.icon) || {};

	return markerType.attributes || ['Type'];
}

export function getMarkerIcon({ markerType, icon }) {
	if (icon) return icon;

	const type = markerTypes.find(({ type }) => type === markerType);

	return type
		? type.icon
		: null;
}

export default markerTypes;
