import Vue from 'vue';
import Component from 'vue-class-component';
import template from './edit-line-top-panel.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

const props = {
	value : Object, // { Map, features, line }
};

@Component({
	template,
	props,
})
export class EditLineTopPanel extends Vue {
	data() {
		return {
			error   : null,
			loading : null,
			context : null, // { cancel(), done(), setColor(), setIcon(), acres }
			line    : Object.assign({}, this.value.line),
		};
	}

	mounted() {
		const { feature_id } = this.line;

		this.map.edit(feature_id, 'LineString')
			.then(context => { this.context = context });
	}

	beforeDestroy() {
		this.context.cancel();
	}

	get map() {
		return this.value.map;
	}

	get feature_id() {
		return this.line.feature_id;
	}

	get feature() {
		return this.map.getFeature(this.feature_id);
	}

	get features() {
		return this.value.features.map(({
			properties : {
				feature_id,
				featureType,
				fieldName,
				markerName,
				lineName,
			},
		}) => ({
			feature_id,
			featureType,
			featureName : fieldName || markerName || lineName,
		}));
	}

	@Watch('line.color')
	watchFieldColor() {
		if (this.context) this.context.setColor(this.line.color);
	}

	save() {
		this.error = null;
		this.loading = true;

		return this.context.done()
			.then(geometry => Object.assign(this.line, { geometry }))
			.then(line => api.line.update(line))
			.then(line => Object.assign(this.value.line, line))
			.then(({ lineName }) => this.feature.setProperty('lineName', lineName))
			.then(() => { this.$emit('close') })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	validateName(name) {
		const feature = this.features.find(({ featureName }) => featureName === name);

		if (feature) return `A ${this.$dsl(feature.featureType)} named '${name}' already exists.`;
	}
}

Vue.component('edit-line-top-panel', EditLineTopPanel);
