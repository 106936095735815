const OverlayView = window.google.maps.OverlayView; // eslint-disable-line no-undef

const defaultOptions = {
	align        : 'center',
	fontColor    : '#000000',
	fontFamily   : 'sans-serif',
	fontSize     : 16,
	strokeColor  : '#ffffff',
	strokeWeight : 3,
	zIndex       : 1e10,
};
const canvasProperties = [
	'fontFamily',
	'fontSize',
	'fontColor',
	'strokeWeight',
	'strokeColor',
	'align',
	'text',
];
const drawProperties = [
	'maxZoom',
	'minZoom',
	'position',
	'visible',
];

/**
 * Based on MapLabel by:
 *  Luke Mahe (lukem@google.com),
 *  Chris Broadfoot (cbro@google.com)
 *
 * - AM 03-12-19
 */
export default class MapLabel extends OverlayView {
	constructor(options) {
		super();

		this._canvas = null;
		this.setValues(Object.assign({}, defaultOptions, options));

		window.google.maps.event.addListener(this.getMap(), 'zoom_changed', () => {
			if (!this.zoom) return;

			this.setValues({
				fontSize : this.zoom + 2,
			});

			this._updateText();
		});
	}

	get canvas() {
		return this._canvas || null;
	}

	set canvas(canvas) {
		this._canvas = canvas;

		/*
		Sometimes, google fails to install properly.
		In these cases, we'll opt to give up and just reload the page.

		- AM 07/29/19
		*/
		try {
			this.layer.appendChild(canvas);
		}
		catch (_) {
			location.reload(true);
		}
	}

	get layer() {
		const panes = this.getPanes();

		return panes
			? panes.markerLayer
			: null;
	}

	get minZoom() {
		const map = this.getMap();

		return map.get('clustering-enabled') ? map.get('clustering-threshold') : 0;
	}

	get zoom() {
		const map = this.getMap();

		return map
			? map.getZoom()
			: null;
	}

	_updateText() {
		const canvas = this.canvas;
		const properties = {
			fontColor    : this.get('fontColor'),
			fontFamily   : this.get('fontFamily'),
			fontSize     : this.get('fontSize'),
			strokeColor  : this.get('strokeColor'),
			strokeWeight : Number(this.get('strokeWeight')),
			text         : this.get('text'),
			zIndex       : this.get('zIndex'),
		};

		return drawText(canvas, properties);
	}

	_getVisible() {
		if (this.get('visible') === false) return 'hidden';

		return this.zoom <= this.minZoom ? 'hidden' : '';
	}

	changed(prop) {
		if (canvasProperties.indexOf(prop) > -1)
			return this._updateText();

		if (drawProperties.indexOf(prop) > -1)
			return this.draw();
	}

	draw() {
		const projection = this.getProjection();
		const canvas = this.canvas;
		const latLng = this.get('position');

		if (!projection || !canvas || !latLng) return;

		const pixel = projection.fromLatLngToDivPixel(latLng);
		const style = canvas.style;

		style.top = `${pixel.y}px`;
		style.left = `${pixel.x}px`;
		style.visibility = this._getVisible();
	}

	onAdd() {
		const properties = {
			text         : this.get('text'),
			fontColor    : this.get('fontColor'),
			fontFamily   : this.get('fontFamily'),
			fontSize     : this.get('fontSize'),
			strokeColor  : this.get('strokeColor'),
			strokeWeight : Number(this.get('strokeWeight')),
			zIndex       : this.get('zIndex'),
		};

		this.canvas = createCanvas(properties);
	}

	onRemove() {
		const canvas = this._canvas;

		this._canvas = null;

		if (canvas && canvas.parentNode)
			canvas.parentNode.removeChild(canvas);
	}
}

function createCanvas(properties) {
	const canvas = document.createElement('canvas');
	const style = canvas.style;
	const context = canvas.getContext('2d');

	style.position = 'absolute';
	context.lineJoin = 'round';
	context.textBaseline = 'top';

	return properties
		? drawText(canvas, properties)
		: canvas;
}

function drawText(canvas, { // eslint-disable-line max-statements
	text,
	fontColor,
	fontFamily,
	fontSize,
	strokeColor,
	strokeWeight,
	zIndex,
}) {
	if (!canvas || !text) return canvas;

	const style = canvas.style;
	const context = canvas.getContext('2d');

	context.strokeStyle = strokeColor;
	context.fillStyle = fontColor;
	context.font = `${fontSize}px ${fontFamily}`;
	context.lineWidth = strokeWeight;
	context.clearRect(0, 0, canvas.width, canvas.height);
	context.strokeText(text, strokeWeight, strokeWeight);
	context.fillText(text, strokeWeight, strokeWeight);

	const textMeasure = context.measureText(text);
	const textWidth = textMeasure.width + strokeWeight;

	style.marginLeft = `-${textWidth / 2}px`;
	style.marginTop = '-0.4em';
	style.zIndex = zIndex;

	return canvas;
}
