import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-zoom-to-user-button.html';

const props = {
	map : {
		type     : Object,
		required : true,
	},
};

@Component({
	template,
	props,
})
export class MapZoomToUserButton extends Vue {
	center(location) {
		if (!this.map) return;

		const user = this.map.highlightLocation(location, location.accuracy);

		return this.map.flyToGeometry(user);
	}
}

Vue.component('map-zoom-to-user-button', MapZoomToUserButton);
