import Vue from 'vue';
import Component from 'vue-class-component';
import Async from '@/plugins/async-decorator';
import template from './user-list.html';
import api from '@/api';
import service from '@/services/ordered-list.service';

const props = {
	users       : Array,
	invitations : Array,
};

@Component({
	template,
	props,
})
export class UserList extends Vue {
	@Async(null, 'users')
	get userTiles() {
		return api.user.get()
			.then(current => Promise.all(this.users.map(user => service.convertUser(user, [{
				event   : 'delete',
				icon    : 'user-times',
				tooltip : `Remove ${this.$dsl('user')}`,
				hidden  : user.user_id === current.user_id,
			}]))));
	}

	@Async(null, 'invitations')
	get invitationTiles() {
		return Promise.all(this.invitations.map(invitation => service.convertUser(invitation, [{
			event   : 'resend',
			icon    : 'paper-plane',
			tooltip : `Resend ${this.$dsl('invitation')}`,
		}, {
			event   : 'reject',
			icon    : 'user-times',
			tooltip : `Remove ${this.$dsl('user')}`,
		}])));
	}

	@Async(null, ['userTiles', 'invitationTiles'])
	get list() {
		return [].concat(this.userTiles || [], this.invitationTiles || []);
	}
}

Vue.component('user-list', UserList);
