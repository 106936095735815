import Vue from 'vue';
import Component from 'vue-class-component';
import template from './folder-linker.html';

@Component({ template })
export class FolderLinker extends Vue {
	data() {
		return {
			source : null,
		};
	}

	selectFolder({ path, name }) {
		const { cloudStorage_id, cloudFolder_id } = this.source;

		this.source = null;

		return this.$emit('select', {
			name,
			path,
			cloudStorage_id,
			cloudFolder_id,
		});
	}
}

Vue.component('folder-linker', FolderLinker);
