import Vue from 'vue';
import Component from 'vue-class-component';
import template from './create-attribute-row.html';
import api from '@/api';

const props = {
	placeholder : {
		type      : Object,
		default   : () => ({}),
		validator : ({ label, value }) => (!label || typeof label === 'string') && (!value || typeof value === 'string'),
	},
	labels : Array,
	tag    : {
		type    : String,
		default : 'input',
	},
};

@Component({
	template,
	props,
})
export default class CreateAttributeRow extends Vue {
	data() {
		return {
			attribute : new api.attribute.Attribute(),
			open      : false,
		};
	}

	beforeDestroy() {
		if (this.isValid)
			this.$emit('create', this.attribute);
	}

	get isValid() {
		return this.attribute.label && this.attribute.value;
	}

	get disabled() {
		return !this.attribute.label || !this.attribute.value;
	}

	create() {
		if (!this.isValid) return;

		this.$emit('create', this.attribute);

		this.attribute = new api.attribute.Attribute();
	}
}
