import api from './api';

export default {
	list,
	count,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	CloudFolder : _extend,
};

function list(filters) {
	return api.list('cloud-folder', filters)
		.then(list => list.map(cloudFolder => _extend(cloudFolder)));
}

function count(filters) {
	return api.count('cloud-folder', filters);
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(cloudFolder_id) {
	return api.read('cloud-folder', cloudFolder_id)
		.then(cloudFolder => _extend(cloudFolder));
}

function create(cloudFolder) {
	const { cloudFolder_id = '' } = cloudFolder;

	delete cloudFolder.cloudFolder_id;

	return api.create(`cloud-folder/${cloudFolder_id}`, cloudFolder);
}

function update(cloudFolder) {
	return api.update('cloud-folder', cloudFolder.cloudFolder_id, cloudFolder);
}

function save(cloudFolder) {
	return cloudFolder.cloudFolder_id ? update(cloudFolder) : create(cloudFolder);
}

function _delete(cloudFolder_id) {
	return api.delete('cloud-folder', cloudFolder_id);
}

function _extend(cloudFolder = {}) {
	return Object.assign({

	}, cloudFolder);
}
