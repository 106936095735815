import Vue from 'vue';
import Component from 'vue-class-component';
import template from './onboarding-progress.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import service from '@/services/onboarding.service';

@Component({ template })
export class OnboardingProgress extends Vue {
	data() {
		return {
			progress : new service.Progress(),
		};
	}

	get tasks() {
		return service.tasks;
	}

	get count() {
		return this.tasks.map(task => this.progress[task] ? 1 : 0)
			.reduce((count, value) => count + value, 0);
	}

	get percentage() {
		const total = this.tasks.length;
		const count = this.count;

		return count / total;
	}

	@Async
	get farm() {
		return api.farm.get()
			.catch(() => ({}));
	}

	@Watch('farm')
	watchFarm() {
		service.updateProgress(this.progress);
	}
}

Vue.component('onboarding-progress', OnboardingProgress);
