const component = () => import(/* webpackChunkName: "developer-test" */'./sidebar-layout');

export default {
	component,
	path : 'sidebar-layout',
	name : 'sidebar-layout-test',
	meta : {
		title  : 'Sidebar Layout Test',
		secure : false,
	},
};
