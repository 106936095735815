import Vue from 'vue';
import Component from 'vue-class-component';
import template from './flyover-form.html';
import flyoverService from '@/services/flyover.service';

@Component({ template })
export class FlyoverForm extends Vue {
	cancel() {
		return flyoverService.reject();
	}
}

Vue.component('flyover-form', FlyoverForm);
