const component = () => import(/* webpackChunkName: "developer-test" */'./test');

import api from './api/api.route';
import appNotification from './app-notification/app-notification.route';
import asyncLoadingDecorator from './async-loading-decorator/async-loading-decorator.route';
import attributes from './attributes/attributes.route';
import autocomplete from './autocomplete/autocomplete.route';
import avatar from './avatar/avatar.route';
import buttons from './buttons/buttons.route';
import checkboxList from './checkbox-list/checkbox-list.route';
import chipListTest from './chip-list-test/chip-list-test.route';
import cloudStorage from './cloud-storage/cloud-storage.route';
import colorPickerTest from './color-picker-test/color-picker-test.route';
import config from './config/config.route';
import conversation from './conversation/conversation.route';
import csvParser from './csv-parser/csv-parser.route';
import datePicker from './date-picker/date-picker.route';
import debounce from './debounce/debounce.route';
import dropdown from './dropdown/dropdown.route';
import dsl from './dsl/dsl.route';
import errorDisplay from './error-display/error-display.route';
import fieldDefaultAttributes from './field-default-attributes/field-default-attributes.route';
import fileUpload from './file-upload/file-upload.route';
import filters from './filters/filters.route';
import headers from './headers/headers.route';
import iconButton from './icon-button/icon-button.route';
import icons from './icons/icons.route';
import indeterminateCheckboxInput from './indeterminate-checkbox-input/indeterminate-checkbox-input.route';
import leafList from './leaf-list/leaf-list.route';
import leftRightDropdown from './left-right-dropdown/left-right-dropdown.route';
import mainHeader from './main-header/main-header.route';
import mapFilters from './map-filters/map-filters.route';
import mapLabelDropdown from './map-label-dropdown/map-label-dropdown.route';
import mapMarker from './map-marker/map-marker.route';
import markerData from './marker-data/marker-data.route';
import markerIcon from './marker-icon/marker-icon.route';
import modalVerification from './modal-verification/modal-verification.route';
import modals from './modals/modals.route';
import onboardingProgressTree from './onboarding-progress-tree/onboarding-progress-tree.route';
import orderedListTest from './ordered-list-test/ordered-list-test.route';
import paginatedTable from './paginated-table/paginated-table.route';
import parcelDisplay from './parcel-display/parcel-display.route';
import permissionControl from './permission-control/permission-control.route';
import print from './print/print.route';
import queueDecorator from './queue-decorator/queue-decorator.route';
import radioInput from './radio-input/radio-input.route';
import sidebarLayout from './sidebar-layout/sidebar-layout.route';
import spinner from './spinner/spinner.route';
import statusBullet from './status-bullet/status-bullet.route';
import style from './style/style.route';
import table from './table/table.route';
import teamDetails from './team-details/team-details.route';
import topPanel from './top-panel/top-panel.route';
import userLocationButton from './user-location-button/user-location-button.route';
import validatedInput from './validated-input/validated-input.route';

const children = [
	api,
	appNotification,
	asyncLoadingDecorator,
	attributes,
	autocomplete,
	avatar,
	buttons,
	checkboxList,
	chipListTest,
	cloudStorage,
	colorPickerTest,
	config,
	conversation,
	csvParser,
	datePicker,
	debounce,
	dropdown,
	dsl,
	errorDisplay,
	fieldDefaultAttributes,
	fileUpload,
	filters,
	headers,
	iconButton,
	icons,
	indeterminateCheckboxInput,
	leafList,
	leftRightDropdown,
	mainHeader,
	mapFilters,
	mapLabelDropdown,
	mapMarker,
	markerData,
	markerIcon,
	modalVerification,
	modals,
	onboardingProgressTree,
	orderedListTest,
	paginatedTable,
	parcelDisplay,
	permissionControl,
	print,
	queueDecorator,
	radioInput,
	sidebarLayout,
	spinner,
	statusBullet,
	style,
	table,
	teamDetails,
	topPanel,
	userLocationButton,
	validatedInput,
];

export default {
	children,
	component,
	path : '/test',
	name : 'test',
	meta : {
		title  : 'Test',
		secure : false,
		routes : children.map(({
			path,
			name,
			meta,
		}) => name && {
			name,
			path,
			title : meta.title,
		})
			.filter(name => name),
	},
};
