const component = () => import(/* webpackChunkName: "developer-test" */'./top-panel');

export default {
	component,
	path : 'top-panel',
	name : 'top-panel-test',
	meta : {
		title  : 'Top Panel Test',
		secure : false,
	},
};
