import Vue from 'vue';

export default function fileSize(value) {
	if (!value && value !== 0) return value;

	const fixed = 3;

	const result = [
		'K',
		'M',
		'G',
	].reduce((result, prefix) => result.value >= 1000 ? {
		value : (result.value / 1000).toFixed(fixed),
		prefix,
	} : result, {
		value,
		prefix : '',
	});

	return `${result.value} ${result.prefix}B`;
}

Vue.filter('file-size', fileSize);
