import analytics from './../services/analytics.service';
import http from './http';
import service from '@/services/modal.service';
import sort from '@/services/sorting.service';
import camelCase from '@/filters/camel-case.filter';

const baseUrl = getBaseUrl();
const duration = 1000; // how long to keep the http requests cached

export default {
	get,
	queueGet,
	post,
	list,
	queueList,
	count,
	read,
	queueRead,
	download,
	create,
	update,
	destroy,
	delete : verifyDelete,
	resetQueue,
};

const deleted = {};
const cache = {};

function list(collection, params, opts = {}) {
	return requestList(http.get, collection, params, opts);
}

function queueList(collection, params, opts = {}) {
	return requestList(http.queueGet, collection, params, opts);
}

// eslint-disable-next-line max-params
function requestList(method, collection, params, opts = {}) {
	const query = buildQuery(params);
	const options = Object.assign({
		key    : `${camelCase(collection)}_id`,
		sortBy : `${camelCase(collection)}Name`,
	}, opts);

	if (!cache[collection]) cache[collection] = {};
	if (!cache[collection][query] || cache[collection][query].expires < Date.now())
		cache[collection][query] = {
			expires : Infinity,
			request : method(`${baseUrl}/${collection}`, { params })
				.then(list => list.sort((one, two) => sort(one, two, options.sortBy)))
				.finally(() => {
					if (cache[collection][query]) cache[collection][query].expires = Date.now() + duration;
				}),
		};

	return cache[collection][query].request
		.then(list => list.filter(item => !deleted[item[options.key]]));
}

function count(collection, params) {
	const query = `${buildQuery(params)}/count`;

	if (!cache[collection]) cache[collection] = {};
	if (!cache[collection][query] || cache[collection][query].expires < Date.now())
		cache[collection][query] = {
			expires : Infinity,
			request : http.get(`${baseUrl}/${collection}/count`, { params })
				.finally(() => {
					if (cache[collection][query]) cache[collection][query].expires = Date.now() + duration;
				}),
		};

	return cache[collection][query].request;
}

function get(collection, params) {
	return http.get(`${baseUrl}/${collection}`, { params });
}

function queueGet(collection, params) {
	return requestGet(http.get, collection, params);
}

function requestGet(method, collection, params) {
	const query = buildQuery(params);

	if (!cache[collection]) cache[collection] = {};
	if (!cache[collection][query] || cache[collection][query].expires < Date.now())
		cache[collection][query] = {
			expires : Infinity,
			request : method(`${baseUrl}/${collection}`, { params })
				.finally(() => {
					if (cache[collection][query]) cache[collection][query].expires = Date.now() + duration;
				}),
		};

	return cache[collection][query].request;
}

function read(collection, record_id = '', params) {
	return requestRead(http.get, collection, record_id, params);
}

function queueRead(collection, record_id = '', params) {
	return requestRead(http.queueGet, collection, record_id, params);
}

// eslint-disable-next-line max-params
function requestRead(method, collection, record_id, params) {
	if (!cache[collection]) cache[collection] = {};
	if (!cache[collection][record_id] || cache[collection][record_id].expires < Date.now())
		cache[collection][record_id] = {
			expires : Infinity,
			request : method(`${baseUrl}/${collection}/${record_id}`, { params })
				.finally(() => {
					if (cache[collection][record_id]) cache[collection][record_id].expires = Date.now() + duration;
				}),
		};

	return cache[collection][record_id].request;
}

function download(collection, record_id = '', params) {
	const filename = params && params.filename ? params.filename : collection;
	const query = Object.entries(Object.assign({}, params, {
		filename : `${filename}.json`,
	}))
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join('&');

	return window.open(`${baseUrl}/${collection}/${record_id}?${query}`, '_self');
}

function post(collection, record, config) {
	return http.post(`${baseUrl}/${collection}`, record, config);
}

function create(collection, record, config) {
	cache[collection] = {};

	return http.post(`${baseUrl}/${collection}`, record, config)
		.then(result => {
			analytics.trackEvent({
				action : 'create',
				label  : collection,
			});

			return result;
		});
}

function update(collection, record_id, record) {
	cache[collection] = {};

	return typeof record_id === 'object'
		? http.put(`${baseUrl}/${collection}`, record_id)
		: http.put(`${baseUrl}/${collection}/${record_id}`, record);
}

function destroy(collection, record_id = '', metadata) {
	return http.delete(`${baseUrl}/${collection}/${record_id}`, { data : metadata });
}

function verifyDelete(collection, record, metadata) {
	const name = typeof record === 'string' ? null : record[`${collection}Name`] || record.name;
	const record_id = typeof record === 'string' ? record : record[`${collection}_id`];

	return service.launchModal('verify-deletion', {
		collection,
		name,
	})
		.then(() => destroy(collection, record_id, metadata));
}

function resetQueue() {
	return Promise.resolve(http.resetQueue());
}

////

function getBaseUrl() {
	if (process.env.SERVER_URL) return process.env.SERVER_URL;

	const minPort = 1024; // minimum port number to mess with
	const portJump = 2000; // how far about the webhost and apihost port numbers are expected to be
	const minDomain = 3; // sub.domain.tld vs localhost

	const {
		protocol, // 'http:' or 'https:'
		hostname, // 'localhost', 'sub.domain.tld', does not have the port number on it
		port, // numeric string or empty string; whatever port is explicitly specified (80 and 443 can be implicit)
	} = window.location;

	const domain = hostname.split('.');

	if (domain[0] === 'app') domain[0] = 'api'; // app.montage.ag -> api.montage.ag

	else if (domain.length >= minDomain) domain[0] += '-api'; // demo.montage.ag -> demo-api.montage.ag

	return port
		? `${protocol}//${domain.join('.')}:${Number(port) > minPort ? Number(port) + portJump : port}`
		: `${protocol}//${domain.join('.')}`;
}

function buildQuery(params) {
	return Object.keys(params || {}).sort()
		.map(key => `${key}=${encodeURIComponent(params[key])}`)
		.join('&');
}
