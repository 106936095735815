import Vue from 'vue';
import Component from 'vue-class-component';
import Async from '@/plugins/async-decorator';
import template from './flyover-container.html';
import flyoverService from '@/services/flyover.service';

@Component({ template })
export class FlyoverContainer extends Vue {
	@Async
	get flyover() {
		return flyoverService.getCurrent();
	}

	clearFlyovers() {
		flyoverService.clear();
	}
}

Vue.component('flyover-container', FlyoverContainer);
