import Vue from 'vue';
import Component from 'vue-class-component';
import template from './modal-layout.html';

const props = {
	cancellable : Boolean,
	noFooter    : Boolean,
};

@Component({
	template,
	props,
})
export class ModalLayout extends Vue {
	dismiss() {
		if (this.cancellable)
			return this.$emit('cancel');
	}
}

Vue.component('modal-layout', ModalLayout);
