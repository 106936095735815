import Vue from 'vue';
import Component from 'vue-class-component';
import template from './farm-details-flyover.html';
import api from '@/api';
import Async from '@/plugins/async-decorator';
import flyoverService from '@/services/flyover.service';

@Component({ template })
export class FarmDetailsFlyover extends Vue {
	data() {
		return {
			error   : null,
			loading : false,
		};
	}

	@Async
	get farm() {
		return flyoverService.getData()
			.then(({ farm_id, farmName }) => ({
				farm_id,
				farmName,
			}))
			.catch(() => null);
	}

	save() {
		if (this.loading) return;

		this.loading = true;
		this.error = null;

		return api.farm.update(this.farm)
			.then(farm => flyoverService.resolve(farm))
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('farm-details-flyover', FarmDetailsFlyover);
