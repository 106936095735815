const component = () => import(/* webpackChunkName: "developer-test" */'./radio-input');

export default {
	component,
	path : 'radio-input',
	name : 'radio-input-test',
	meta : {
		title  : 'Radio Input Test',
		secure : false,
	},
};
