import Vue from 'vue';
import Component from 'vue-class-component';
import template from './onboarding-features.html';

const ONBOARDING_FEATURES_IFRAME = process.env.ONBOARDING_FEATURES_IFRAME;
const ONBOARDING_FEATURES_VIDEO = process.env.ONBOARDING_FEATURES_VIDEO;

const props = {
	next : Array,
};

@Component({
	template,
	props,
})
export default class OnboardingFeatures extends Vue {
	get ONBOARDING_FEATURES_IFRAME() {
		return ONBOARDING_FEATURES_IFRAME;
	}

	get ONBOARDING_FEATURES_VIDEO() {
		return ONBOARDING_FEATURES_VIDEO;
	}

	submit() {
		if (!this.next || !this.next.length) return this.$router.push({ name : 'onboarding-company-information' });

		return this.$router.push({
			name   : this.next[0],
			params : {
				next : this.next.slice(1),
			},
		});
	}
}
