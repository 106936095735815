import Bounds from '../classes/Bounds';

////

export default function getBoundsOfFeature(feature) {
	const bounds = new Bounds();

	const geometry = feature.getGeometry
		? feature.getGeometry()
		: convertGeojsonToGoogleGeometry(feature);

	geometry.forEachLatLng(latLng => {
		bounds.extend(latLng);
	});

	return bounds;

	function convertGeojsonToGoogleGeometry(feature) {
		const geometry = (feature && feature.geometry) || {};
		const coordinates = geometry.coordinates;
		const Data = window.google.maps.Data;

		const convert = {
			Point : ([lng, lat]) => ({
				lat,
				lng,
			}),
			LineString   : coordinates => coordinates.map(convert.Point),
			Polygon      : coordinates => coordinates.map(convert.LineString),
			MultiPolygon : coordinates => coordinates.map(convert.Polygon),
		};

		const handler = convert[geometry.type];

		return handler && new Data[geometry.type](handler(coordinates));
	}
}
