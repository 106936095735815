import fullstory from './services/fullstory.lib';
import googleAnalytics from './services/google-analytics.lib';
import intercom from './services/intercom.lib';
import sentry from './services/sentry.lib';

const services = [
	fullstory,
	googleAnalytics,
	intercom,
	sentry,
];

export default {
	reset      : () => callMethod('reset'),
	identify   : user => callMethod('identify', user),
	update     : data => callMethod('update', data),
	trackEvent : event => callMethod('trackEvent', event),
	trackPage  : page => callMethod('trackPage', page),
};

function callMethod(methodName, metadata) {
	services.forEach(service => {
		if (service[methodName] && typeof service[methodName] === 'function')
			service[methodName](metadata);
	});
}
