import defaultDrawingOptions from '../../drawing-options.js';
import defaultColors from '@/config/colors.json';

const defaultColor = defaultColors[0];
const mapMarker = window.mapMarkerAwesomeFactory(true);

////

export default function copyFeature(feature, options) {
	const geometry = feature.getGeometry();
	const type = geometry.getType();

	const converter = {
		Point : options => {
			const position = geometry.get();
			const defaultOptions = defaultDrawingOptions.markerOptions;
			const icon = {
				url : mapMarker(feature.getProperty('icon'), {
					fill : feature.getProperty('color') || defaultColor,
				}),
			};

			return new window.google.maps.Marker(Object.assign({}, defaultOptions, { icon }, options, { position }));
		},

		LineString : options => {
			const path = geometry.getArray();
			const defaultOptions = defaultDrawingOptions.polylineOptions;
			const strokeColor = feature.getProperty('color') || defaultColor;

			return new window.google.maps.Polyline(Object.assign({}, defaultOptions, { strokeColor }, options, { path }));
		},

		Polygon : options => {
			const paths = geometry.getArray().map(ring => ring.getArray());
			const defaultOptions = defaultDrawingOptions.polygonOptions;
			const strokeColor = feature.getProperty('color') || defaultColor;
			const fillColor = feature.getProperty('color') || defaultColor;

			return new window.google.maps.Polygon(Object.assign({}, defaultOptions, {
				strokeColor,
				fillColor,
			}, options, { paths }));
		},

		MultiPolygon : options => {
			const polygons = geometry.getArray().map(polygon => polygon.getArray().map(ring => ring.getArray()));
			const defaultOptions = defaultDrawingOptions.polygonOptions;
			const strokeColor = feature.getProperty('color') || defaultColor;
			const fillColor = feature.getProperty('color') || defaultColor;

			return polygons.map(paths => new window.google.maps.Polygon(Object.assign({}, defaultOptions, {
				strokeColor,
				fillColor,
			}, options, { paths })));
		},
	}[type];

	if (!converter) throw new Error(`Unsupported Type ${type}. Cannot convert to editable.`);

	return converter(options);
}
