import mapService from '../google.lib';

const defaultRadius = 1000;

export default {
	getFeature(feature_id) {
		return this._map.data.getFeatureById(feature_id);
	},

	removeFeature(feature_id) {
		return this._map.data.remove(this.getFeature(feature_id));
	},

	removeFeatureCollection(collection) {
		const data = this._map.data;

		return collection.forEach(feature => data.remove(feature));
	},

	addFeatureCollection(collection) {
		if (collection.type !== 'FeatureCollection')
			return this._addFeatureCollection({
				type     : 'FeatureCollection',
				features : Array.isArray(collection) ? collection : [collection],
			});

		return this._addFeatureCollection(collection);
	},

	setFeatureCollection(collection) {
		this._clear();
		this._addFeatureCollection(collection);
	},

	replaceFeatureCollection(remove, insert) {
		if (remove) this.removeFeatureCollection(remove);

		return this._addFeatureCollection(insert);
	},

	highlightLocation(center, radius = defaultRadius) {
		if (this.removeLocationHighlight)
			this.removeLocationHighlight();

		const delay = 4000;
		const timeout = setTimeout(() => remove(), delay);
		const circle = new mapService.Circle({
			center,
			radius,
			map         : this._map,
			fillOpacity : 0,
			strokeColor : '#3DCBF3',
		});

		this.removeLocationHighlight = remove;

		return circle;

		function remove() {
			clearTimeout(timeout);
			circle.setMap(null);
		}
	},

	disableFeatureClick() {
		const data = this._map.data;

		return data.forEach(feature => feature.setProperty('disable-click', true));
	},

	enableFeatureClick() {
		const data = this._map.data;

		return data.forEach(feature => feature.setProperty('disable-click', false));
	},

	_addFeatureCollection(collection) {
		return this._map.data.addGeoJson(collection, { idPropertyName : 'feature_id' });
	},

	_clear() {
		return this.removeFeatureCollection(this._map.data);
	},
};
