const component = () => import(/* webpackChunkName: "settings-core" */'./billing-settings-dashboard');
import api from '@/api';
import permissions from '@/services/permission.service';
import lockoutService from '@/services/billing-lockout.service';

export default {
	component,
	path : 'dashboard',
	name : 'billing-settings-dashboard',
	meta : {
		title           : 'Billing Settings Dashboard',
		secure          : true,
		onCompanyChange : { name : 'billing-settings' },
	},
	async beforeEnter(to, from, next) { // eslint-disable-line complexity
		const isAdmin = await permissions.isAdmin();
		const enabled = await lockoutService.isAppEnabled();
		const account = await api.billingReplacement.getAccount();
		const isMissingAccount = !account || !account.account_id || (account.account_type === 'paid' && !account.stripe_customer_id);

		if (enabled && isAdmin && isMissingAccount)
			return next({ name : 'billing-settings-new-account' });

		return next();
	},
};
