const adaptive = process.env.BRAND_KEY === 'adaptive' || process.env.BRAND_ADAPTIVE === 'true';
const brandkey = (adaptive && domain()) || `${process.env.BRAND_KEY || process.env.BRAND_NAME}`.toLowerCase();
const images = {
	'default:mobile'    : '/assets/images/m4a-mark.svg',
	'default:desktop'   : '/assets/images/montage/branding-green.svg',
	'montage:desktop'   : '/assets/images/montage/branding-green.svg',
	'mapscout:desktop'  : '/assets/images/mapscout/mapscout-small-transparent.svg',
	'mapscout:external' : '/assets/images/mapscout/mapscout-transparent-powered-by.svg',
};
const brands = {
	default  : 'Montage',
	montage  : 'Montage',
	mapscout : 'MapScout',
};

export default {
	BRAND_NAME : (adaptive && brands[domain()]) || process.env.BRAND_NAME,
	contact    : {
		tel   : process.env.BRAND_CONTACT_TEL,
		email : process.env.BRAND_CONTACT_EMAIL,
	},
	href : {
		marketing : process.env.BRAND_MARKETING_URL,
		support   : process.env.BRAND_SUPPORT_URL,
		contact   : process.env.BRAND_CONTACT_URL,
		self      : location.host,
	},
	src : {
		mobile   : images[`${brandkey}:mobile`] || images['default:mobile'],
		desktop  : images[`${brandkey}:desktop`] || images['default:desktop'],
		external : images[`${brandkey}:external`] || images['default:desktop'],
	},
};

function domain() {
	const re = /^[\w-]+\.([\w-]+)\.(com|ag|solar)$/i;
	const host = location ? location.host : null;

	if (host && re.test(host))
		return host.match(re)[1] || null;

	return null;
}
