import Vue from 'vue';
import Component from 'vue-class-component';
import template from './map-legend.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import toFixed from '@/filters/to-fixed.filter';
import { checkFarmPrivilege, isDemoUser, showFarmUpsell } from '@/services/permission.service';
import { loadFeatureAttributes } from '@/services/attribute.service';
import { farmableAcresRegExp } from '@/services/farmable-acres.service.js';
import modalService from '@/services/modal.service';

const FIELD_LIMIT = Number(process.env.FIELD_LIMIT) || Infinity;

const props = {
	map      : Object, // Google Map object
	features : Array, // Array of GeoJSON objects
	filters  : Array, // [{ type, operation, value }]
	selected : Object, // currently selected Field, Marker or Line
};

@Component({
	template,
	props,
})
export class MapLegend extends Vue {
	data() {
		return {
			editing : null, // which accordion group is being edited ['field', 'marker', 'line']
		};
	}

	get loaded() {
		return [
			this.fields,
			this.markers,
			this.lines,
		].every(list => list);
	}

	@Async(false)
	get isDemoUser() {
		return isDemoUser();
	}

	@Async(false)
	get canMakeMore() {
		if (!Number.isFinite(FIELD_LIMIT))
			return true;

		return api.field.count()
			.then(({ count }) => count < FIELD_LIMIT);
	}

	@Async(false)
	get canCreate() {
		return checkFarmPrivilege('create')
			.then(okay => okay && !this.isDemoUser && this.canMakeMore);
	}

	@Async(false)
	get canUpdate() {
		return checkFarmPrivilege('update')
			.then(okay => okay && !this.isDemoUser);
	}

	@Async(false)
	get canDelete() {
		return checkFarmPrivilege('delete')
			.then(okay => okay && !this.isDemoUser);
	}

	@Async(false)
	get showFarmUpsell() {
		return showFarmUpsell();
	}

	@Async
	get fields() {
		return api.field.list();
	}

	@Async
	get markers() {
		return api.marker.list();
	}

	@Async
	get lines() {
		return api.line.list();
	}

	@Async
	get loadedAttributes() {
		return Promise.all(this.features.map(feature => loadFeatureAttributes(feature)))
			.then(() => true);
	}

	@Watch(['loaded', 'selected'])
	watchSelected() {
		if (!this.loaded || !this.selected) return;

		const {
			feature_id,
			featureType,
		} = this.selected;

		if (!featureType) return;

		const list = {
			field  : this.fields,
			marker : this.markers,
			line   : this.lines,
		}[featureType];

		const selected = list.find(entry => entry.feature_id === feature_id);

		this.select(selected, featureType);
	}

	@Watch('editing')
	watchEditing() {
		this.$emit('panel');
	}

	getSubtitle({ feature_id }) {
		if (!this.loadedAttributes) return null;

		const feature = this.features.find(({ properties }) => properties.feature_id === feature_id);

		if (!feature) return null;

		const { properties } = feature;
		const { attributes, featureType } = properties;

		if (!attributes) return null;

		const subtitle = {
			field : [
				[
					attributes.find(({ label }) => /^apn|parcel number$/i.test(label)),
					attribute => ({
						label : this.$dsl('Parcel'),
						value : attribute.value,
					}),
				], [
					attributes.find(({ label }) => /^(apn|parcel) acres$/i.test(label)),
					attribute => ({
						label : this.$dsl('Parcel Acres'),
						value : toFixed(attribute.value),
					}),
				], [
					attributes.find(({ label }) => farmableAcresRegExp.test(label)),
					attribute => ({
						label : 'Acres',
						value : toFixed(attribute.value),
					}),
				],
			],
		}[featureType];

		if (subtitle) return subtitle.filter(([attribute]) => attribute && attribute.value)
			.map(([attribute, mutate]) => attribute && mutate(attribute))
			.map(({ label, value }) => `${label}: ${value}`)
			.join(' | ');
	}

	addFeature(value) {
		if (this.showFarmUpsell)
			return modalService.launchModal('access-upsell', {})
				.catch(() => null);

		this.$emit('add-feature', value);
	}

	edit(editing) {
		this.editing = this.editing === editing ? null : editing;
	}

	select(selected, type) {
		this.$emit('select', null);

		if (this.showFarmUpsell && this.editing === type)
			return modalService.launchModal('access-upsell', {})
				.catch(() => null);

		if (this.canUpdate && this.editing === type)
			this.$emit('edit', selected);

		else if (selected) this.$nextTick()
			.then(() => {
				this.$emit('select', selected);
				this.$emit('panel', `${type}-details-panel`);
				this.map.flyToFeature(selected.feature_id);
			});

		this.editing = null;
	}

	deleteField({ field_id }) {
		return api.field.delete(field_id)
			.then(() => {
				this.fields.splice(this.fields.findIndex(field => field.field_id === field_id), 1);
				this.features.splice(this.features.findIndex(feature => feature.field_id === field_id), 1);
				this.map.removeFeature(field_id);
			});
	}

	deleteMarker({ marker_id }) {
		return api.marker.delete(marker_id)
			.then(() => {
				this.markers.splice(this.markers.findIndex(marker => marker.marker_id === marker_id), 1);
				this.features.splice(this.features.findIndex(feature => feature.marker_id === marker_id), 1);
				this.map.removeFeature(marker_id);
			});
	}

	deleteLine({ line_id }) {
		return api.line.delete(line_id)
			.then(() => {
				this.lines.splice(this.lines.findIndex(line => line.line_id === line_id), 1);
				this.features.splice(this.features.findIndex(feature => feature.line_id === line_id), 1);
				this.map.removeFeature(line_id);
			});
	}
}

Vue.component('map-legend', MapLegend);
